<template>
    <div>
        <div class="d-flex flex-stack flex-wrap">
            <div class="page-title d-flex flex-column me-3 mt-3"> 
                <h1 class="d-flex fw-bolder my-1 fs-3 text-gray-800">{{ $t('Document Settings') }} </h1>  
                <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
                    <li class="breadcrumb-item text-gray-600">
                        <a href="#" class="text-gray-600 text-hover-primary">
                            <router-link :to="{ name: 'Home' }">{{ $t('Home') }}</router-link>
                        </a>
                    </li> 
                    <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'Settings' }">{{ $t('Settings') }}</router-link></li>      
                    <li class="breadcrumb-item text-gray-600">{{ $t('Document Settings') }}
                    <HelpPageIcon v-if="this.iconView == true" :menuSelection="this.menuSelection"></HelpPageIcon>
                    </li>  
                </ul> 
            </div>  
            <div class="d-flex align-items-center py-1">
                <router-link to="/Settings">
                    <button type="button" class="btn btn-light shadow-sm btn-arrow-left border border-gray-400 me-3">
                      <span class="ms-2 fs-7"> {{ $t('Return back to Settings') }} </span>
                    </button>
                </router-link> 
            </div>
        </div>
        <div class="content flex-row-fluid mt-4" id="kt_content">
            <RequiredField></RequiredField>
            <div class="card card-xxl-stretch">
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-12">
                            <form id="kt_ecommerce_settings_general_form"
                                class="form fv-plugins-bootstrap5 fv-plugins-framework">
                                <div class="fv-row row">
                                    <div class="py-3">
                                        <div class="rounded border p-10 py-3 border-gray-300">
                                            <div class="row">
                                                <div class="col-lg-4">
                                                    <label class="fs-6 fw-bold form-label mt-3">{{ $t('Is VAT Account Active?') }}</label> 
                                                    <br>
                                                    <div class="form-check form-check-inline mt-3">
                                                        <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="false"
                                                            v-model="form.isVATAccountActive">
                                                        <label class="form-check-label" for="inlineRadio1">{{ $t('No') }}</label>
                                                    </div>
                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="true"
                                                        v-model="form.isVATAccountActive">
                                                        <label class="form-check-label" for="inlineRadio2">{{ $t('Yes') }}</label>
                                                    </div>
                                                </div>
                                                <div class="col-lg-8">
                                                    <div class="alert bg-lighten d-flex flex-column flex-sm-row w-100 p-3 m-0">
                                                        <i class="bi bi-info-circle fs-1 me-4 mb-5 mb-sm-0"></i>
                                                        <div class="d-flex flex-column">
                                                            <h6 class="fw-semibold">{{ $t('What is a VAT Account?') }}</h6>
                                                            <span class="fs-7">{{ $t('Having a VAT Account means you may claim the VAT back on VAT rated business expenses and capital asset purchases. It also means you will charge VAT on your invoices') }}.</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="py-3">
                                        <div class="rounded border p-10 py-3 border-gray-300">
                                            <div class="row">
                                                <div class="col-lg-4">
                                                    <label class="fs-6 fw-bold form-label mt-3">{{ $t('VAT Number') }}</label> 
                                                    <input type="text" class="form-control" placeholder="" name="name" v-model="form.company_vat_number"> 
                                                </div>
                                                <div class="col-lg-8">
                                                    <div class="alert bg-lighten d-flex flex-column flex-sm-row w-100 p-3 m-0">
                                                        <i class="bi bi-info-circle fs-1 me-4 mb-5 mb-sm-0"></i>
                                                        <div class="d-flex flex-column">
                                                            <h6 class="fw-semibold">{{ $t('Why is the VAT number required?') }}</h6>
                                                            <span class="fs-7">{{ $t('If you have VAT account, you must enter the VAT Number') }}.</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="py-3">
                                        <div class="rounded border p-10 py-3 border-gray-300">
                                            <div class="row">
                                                <div class="col-lg-4">
                                                    <label class="fs-6 fw-bold form-label mt-3">{{ $t('Is Show Due Date?') }}</label>
                                                    <br>
                                                    <input class="form-check-input" type="checkbox" value="" id="isShowDueDate" v-model="form.is_show_due_date">
                                                </div>
                                                <div class="col-lg-8">
                                                    <div class="alert bg-lighten d-flex flex-column flex-sm-row w-100 p-3 m-0">
                                                        <i class="bi bi-info-circle fs-1 me-4 mb-5 mb-sm-0"></i>
                                                        <div class="d-flex flex-column">
                                                            <h6 class="fw-semibold">{{ $t('Where to display Due Date?') }}</h6>
                                                            <span class="fs-7">{{ $t('If this option is checked, the due date of the invoice will be displayed in the footer description field of the Invoice Transaction') }}.</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="py-3">
                                        <div class="rounded border p-10 py-3 border-gray-300">
                                            <div class="row">
                                                <div class="col-lg-4">
                                                    <label class="fs-6 fw-bold form-label mt-3">{{ $t('Invoice Template') }} </label> 
                                                    <Select2 v-model="form.invoice_report_template" :options="invoiceTemplateList" :settings="{width: '100%'}"/>
                                                </div>
                                                <div class="col-lg-8">
                                                    <div class="alert bg-lighten d-flex flex-column flex-sm-row w-100 p-3 m-0">
                                                        <i class="bi bi-info-circle fs-1 me-4 mb-5 mb-sm-0"></i>
                                                        <div class="d-flex flex-column">
                                                            <h6 class="fw-semibold">{{ $t('To Select Invoice Template') }}</h6>
                                                            <span class="fs-7">{{ $t('You can choose the template that suits you from the templates in the list. The printable output of the invoice will be displayed in the template you selected') }}.</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="py-3">
                                        <div class="rounded border p-10 py-3 border-gray-300">
                                            <div class="row">
                                                <div class="col-lg-4">
                                                    <label class="fs-6 fw-bold form-label mt-3">{{ $t('Receipt Template') }} ({{ $t('Cash/Bank') }}) </label> 
                                                    <Select2 v-model="form.receipt_report_template" :options="receiptTemplateList" :settings="{width: '100%'}"/>
                                                </div>
                                                <div class="col-lg-8">
                                                    <div class="alert bg-lighten d-flex flex-column flex-sm-row w-100 p-3 m-0">
                                                        <i class="bi bi-info-circle fs-1 me-4 mb-5 mb-sm-0"></i>
                                                        <div class="d-flex flex-column">
                                                            <h6 class="fw-semibold">{{ $t('To Select Receipt Template') }}</h6>
                                                            <span class="fs-7">{{ $t('You can choose the template that suits you from the templates in the list. The printable output of the receipt will be displayed in the template you selected') }}.</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="py-3">
                                        <div class="rounded border p-10 py-3 border-gray-300">
                                            <div class="row">
                                                <div class="col-lg-4">
                                                    <label class="fs-6 fw-bold form-label mt-3">{{ $t('Statement Template') }} </label> 
                                                    <Select2 v-model="form.statement_report_template" :options="statementTemplateList" :settings="{width: '100%'}"/>
                                                </div>
                                                <div class="col-lg-8">
                                                    <div class="alert bg-lighten d-flex flex-column flex-sm-row w-100 p-3 m-0">
                                                        <i class="bi bi-info-circle fs-1 me-4 mb-5 mb-sm-0"></i>
                                                        <div class="d-flex flex-column">
                                                            <h6 class="fw-semibold">{{ $t('To Select Statement Template') }}</h6>
                                                            <span class="fs-7">{{ $t('You can choose the template that suits you from the templates in the list. The printable output of the receipt will be displayed in the template you selected') }}.</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="py-3">
                                        <div class="rounded border p-10 py-3 border-gray-300">
                                            <div class="row">
                                                <div class="col-lg-4">
                                                    <label class="fs-6 fw-bold form-label mt-3">{{ $t('Invoice Description') }}</label>
                                                    <div class="form-check form-switch p-0">
                                                        <input class="form-check-input h-20px w-30px ms-0 me-1" type="checkbox" id="flexSwitch20x30"  v-model="form.isShowInvoiceDescription"/>
                                                        <label class="form-check-label text-muted form-label" for="flexSwitch20x30">
                                                            {{ $t('Show Description Editor') }}
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="col-lg-8">
                                                    <div class="alert bg-lighten d-flex flex-column flex-sm-row w-100 p-3 m-0">
                                                        <i class="bi bi-info-circle fs-1 me-4 mb-5 mb-sm-0"></i>
                                                        <div class="d-flex flex-column">
                                                            <h6 class="fw-semibold">{{ $t('Where to display Invoice Description?') }}</h6>
                                                            <span class="fs-7">{{ $t('The description entered here will be displayed in the description field of the invoices added from the Invoice Transaction. Switch `Show Description Editor` to add or update the description') }}.</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 mt-3" v-if="form.isShowInvoiceDescription">
                                                <div class="widget-container">
                                                    <Editor v-model="form.invoice_description"
                                                        :api-key="htmlEditorKey"
                                                        :init="{
                                                            inline: false,
                                                            plugins: 'print preview paste importcss searchreplace autolink autosave save directionality code fullscreen image link media template table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap uploadimage textcolor colorpicker',
                                                            toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat colorpicker | pagebreak | charmap | fullscreen  preview  print | insertfile image media pageembed link anchor | a11ycheck ltr rtl | showcomments addcomment | uploadimage',
                                                            min_height: 200, 
                                                            valid_elements: '*[*]',
                                                            extended_valid_elements: '*[*]',
                                                            valid_children: '+a[div|h1|h2|h3|h4|h5|h6|p|#text]',
                                                            custom_elements: 'style,link,~link',
                                                            force_br_newlines: true,
                                                            forced_root_block: false,
                                                            relative_urls: false,
                                                            remove_script_host: false,
                                                            convert_urls: false,
                                                            fontsize_formats: '8pt 10pt 11pt 12pt 13pt 14pt 18pt 20pt 24pt 32pt 36pt 40pt 44pt 46pt 48pt 50pt 54pt 60pt 72pt 76pt 80pt 84pt 86pt 90pt 100pt',
                                                            allow_html_in_named_anchor: true,
                                                            allow_unsafe_link_target: true,
                                                        }"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </form>                        
                            <div class="d-grid gap-2">
                                <button type="button" class="btn btn-success" @click="this.saveData()" data-kt-indicator="on">
                                    <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                    <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                    </svg>
                                    </span> 
                                    <strong v-if="!this.isProgressing"> {{ $t('Save Document Settings') }}</strong> 
                                        <span class="indicator-progress" v-if="this.isProgressing">
                                            {{ $t('Saving Data...') }} <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                        </span> 
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</template>

<script>
import axios from 'axios';
// import Swal from "sweetalert2";
 
export default {
  name: "DocumentSettings", 
  data() {
    return {
      isProgressing: false,
      form:{
        isVATAccountActive: 'true',
        is_vat_active: 0,
        company_vat_number: null,
        is_show_due_date: false,
        invoice_description: null,
        isShowInvoiceDescription: false,
        invoice_report_template: '-1',
        receipt_report_template: '-1',
        statement_report_template: '-1',
      },
      menuSelection: '/InvoiceSettingsHelppage',
      iconView : false,
      designList: [],
      htmlEditorKey: ""
    };
  },
  computed: {
    invoiceTemplateList() {
        return this.designList.filter(p => { 
            return p.id == '-1' || p.category == 1;
        });
    },
    receiptTemplateList() {
        return this.designList.filter(p => { 
            return p.id == '-1' || (p.category == 2 && p.receipt_type == 0);
        });
    },
    statementTemplateList() {
        return this.designList.filter(p => { 
            return p.id == '-1' ||  p.category == 3;
        });
    },
  },
  methods: {
    getData() { 
        document.addEventListener('focusin', (e) => {
            if (e.target.closest(".tox-tinymce-aux, .moxman-window, .tam-assetmanager-root") !== null) {
                e.stopImmediatePropagation();
            }
        });
        const parameters = {
            session_id: this.$getUser().session_id,
            category: -1
        };        
        const requestOne =  axios.post('/api/CompanyAccounts/GetInvoiceSettings', parameters, {'Content-Type': 'application/json'});
        const requestTwo =  axios.post('/api/Reports/GetControllerReportDesigns', parameters, {'Content-Type': 'application/json'}); 
        const requestThree =  axios.get('/api/Auth/GetHEK');
        axios.all([requestOne, requestTwo, requestThree]).then(axios.spread((...responses) => {            
            this.form = {
                isVATAccountActive: responses[0].data.is_vat_active == 1 ? 'true' : 'false',
                is_vat_active: responses[0].data.is_vat_active,
                company_vat_number: responses[0].data.company_vat_number,
                is_show_due_date: responses[0].data.is_show_due_date == 1 ? true : false,
                invoice_description: responses[0].data.invoice_description,
                invoice_report_template: responses[0].data.invoice_report_template == null || responses[0].data.invoice_report_template == '' ? '-1' : responses[0].data.invoice_report_template,
                receipt_report_template: responses[0].data.receipt_report_template == null || responses[0].data.receipt_report_template == '' ? '-1' : responses[0].data.receipt_report_template,
                statement_report_template: responses[0].data.statement_report_template == null || responses[0].data.statement_report_template == '' ? '-1' : responses[0].data.statement_report_template,
            };
            this.designList = responses[1].data.report_designs;
            this.designList.unshift({id: '-1', text: 'Select an option'});

            this.htmlEditorKey = this.$unlock(responses[2].data);

            if(this.form.receipt_report_template == '-1' || this.form.statement_report_template == '-1' || this.form.invoice_report_template == '-1') {
                this.designList.forEach(element => {
                    if(element.is_default && element.category == 1) { this.form.invoice_report_template = element.id; }
                    if(element.is_default && element.category == 2 && element.receipt_type == 0) { this.form.receipt_report_template = element.id; }
                    if(element.is_default && element.category == 2 && element.receipt_type == 4) { this.form.statement_report_template = element.id; }
                });
            }
        }))
        .catch(errors => { 
            console.error(errors); 
        }); 
    },
    saveData(){
        this.isProgressing = true;
        const params = {
            session_id: this.$getUser().session_id,
            is_vat_active: this.form.isVATAccountActive == 'true' ? 1 : 0,
            company_vat_number: this.form.company_vat_number,
            is_show_due_date: this.form.is_show_due_date == true ? 1 : 0,
            invoice_description: this.form.invoice_description,
            invoice_report_template: this.form.invoice_report_template !== '-1' ? this.form.invoice_report_template : null,
            receipt_report_template: this.form.receipt_report_template !== '-1' ? this.form.receipt_report_template : null,
            statement_report_template: this.form.statement_report_template !== '-1' ? this.form.statement_report_template : null,
        };
        const that = this;     
        axios.post('/api/CompanyAccounts/UpdateDocumentSettings', params, {'Content-Type': 'application/json'})
        .then((response) => { 
            if(response.data != null && response.data.length == 36){
                this.$swal("Success", "Data saved succesfully..!", 'success');  
            }
            
        })
        .catch(function (error) {
            console.log(error);
        })
        .finally(function () { 
            setTimeout(() => {
                that.isProgressing = false;
            }, 500);
        }); 
      },
      async helpPageViewIcon(){
        const queryparameter = {
            session_id : this.$getUser().session_id,
            menu_selection : '/InvoiceSettingsHelppage'
        };
        axios.post('api/HelpPage/HelpPagesContent', queryparameter, {'Content-Type': 'application/json'})
        .then((response) => { 
            if(!response.data.error){ 
                this.iconView = response.data;
            }
        })
        }, 
    }, 
    created() {
        this.helpPageViewIcon();
        this.getData();
    }
}; 
</script>