<template>
    <div>
        <div class="d-flex flex-stack flex-wrap">
            <div class="page-title d-flex flex-column me-3 mt-3"> 
                <h1 class="d-flex fw-bolder my-1 fs-3 text-gray-800">{{ $t('Update Numerator Settings') }} </h1>  
                <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
                    <li class="breadcrumb-item text-gray-600">
                        <a href="#" class="text-gray-600 text-hover-primary">
                            <router-link :to="{ name: 'Home' }">{{ $t('Home') }}</router-link>
                        </a>
                    </li> 
                    <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'Settings' }">{{ $t('Settings') }}</router-link></li>     
                    <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'NumeratorSettingsList' }">{{ $t('Numerator Setting') }}</router-link></li>    
                    <li class="breadcrumb-item text-gray-600">{{ $t('Update Numerator Setting') }}
                    <HelpPageIcon v-if="this.iconView == true" :menuSelection="this.menuSelection"></HelpPageIcon>
                    </li> 
                </ul> 
            </div>  
            <div class="d-flex align-items-center py-1">
                <router-link to="/NumeratorSettingsList">
                    <button type="button" class="btn btn-light shadow-sm btn-arrow-left border border-gray-400 me-3">
                     <span class="ms-2 fs-7"> {{ $t('Return back to List') }}</span>
                    </button>
                </router-link>  
                &nbsp;
                <div class="dropdown">
                    <button class="btn btn-light-info border border-info dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="true" id="numeratorActions" type="button">
                    <span class="svg-icon svg-icon-muted"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path opacity="0.3" d="M22.1 11.5V12.6C22.1 13.2 21.7 13.6 21.2 13.7L19.9 13.9C19.7 14.7 19.4 15.5 18.9 16.2L19.7 17.2999C20 17.6999 20 18.3999 19.6 18.7999L18.8 19.6C18.4 20 17.8 20 17.3 19.7L16.2 18.9C15.5 19.3 14.7 19.7 13.9 19.9L13.7 21.2C13.6 21.7 13.1 22.1 12.6 22.1H11.5C10.9 22.1 10.5 21.7 10.4 21.2L10.2 19.9C9.4 19.7 8.6 19.4 7.9 18.9L6.8 19.7C6.4 20 5.7 20 5.3 19.6L4.5 18.7999C4.1 18.3999 4.1 17.7999 4.4 17.2999L5.2 16.2C4.8 15.5 4.4 14.7 4.2 13.9L2.9 13.7C2.4 13.6 2 13.1 2 12.6V11.5C2 10.9 2.4 10.5 2.9 10.4L4.2 10.2C4.4 9.39995 4.7 8.60002 5.2 7.90002L4.4 6.79993C4.1 6.39993 4.1 5.69993 4.5 5.29993L5.3 4.5C5.7 4.1 6.3 4.10002 6.8 4.40002L7.9 5.19995C8.6 4.79995 9.4 4.39995 10.2 4.19995L10.4 2.90002C10.5 2.40002 11 2 11.5 2H12.6C13.2 2 13.6 2.40002 13.7 2.90002L13.9 4.19995C14.7 4.39995 15.5 4.69995 16.2 5.19995L17.3 4.40002C17.7 4.10002 18.4 4.1 18.8 4.5L19.6 5.29993C20 5.69993 20 6.29993 19.7 6.79993L18.9 7.90002C19.3 8.60002 19.7 9.39995 19.9 10.2L21.2 10.4C21.7 10.5 22.1 11 22.1 11.5ZM12.1 8.59998C10.2 8.59998 8.6 10.2 8.6 12.1C8.6 14 10.2 15.6 12.1 15.6C14 15.6 15.6 14 15.6 12.1C15.6 10.2 14 8.59998 12.1 8.59998Z" fill="currentColor"/>
                    <path d="M17.1 12.1C17.1 14.9 14.9 17.1 12.1 17.1C9.30001 17.1 7.10001 14.9 7.10001 12.1C7.10001 9.29998 9.30001 7.09998 12.1 7.09998C14.9 7.09998 17.1 9.29998 17.1 12.1ZM12.1 10.1C11 10.1 10.1 11 10.1 12.1C10.1 13.2 11 14.1 12.1 14.1C13.2 14.1 14.1 13.2 14.1 12.1C14.1 11 13.2 10.1 12.1 10.1Z" fill="currentColor"/>
                    </svg></span>
                        {{ $t('Actions') }}
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="numeratorActions"> 
                        <li>
                            <a class="dropdown-item fs-4 text-danger" href="#" @click="deleteItem()"><span class="svg-icon svg-icon-muted svg-icon-2 text-danger"><svg width="24" height="24" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="currentColor"/>
                            <path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="currentColor"/>
                            <path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="currentColor"/>
                            </svg>
                            </span>{{ $t('Delete') }} </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="content flex-row-fluid mt-4" id="kt_content">
            <RequiredField></RequiredField>          
            <div class="card card-xxl-stretch">
                <div class="row p-10">
                    <div class="col-lg-12">
                        <div class="card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                            <div class="card-body pb-0">
                                <form id="kt_ecommerce_settings_general_form"
                                    class="form fv-plugins-bootstrap5 fv-plugins-framework">                                
                                    <div class="fv-row mb-7 row">                                        
                                        <div class="col-lg-12">
                                            <label class="fs-6 fw-bold form-label mt-3 required">
                                                {{ $t('Type') }}
                                            </label>
                                            <Select2 v-model="form.ai_type" :options="aiTypes" disabled :settings="{ width: '100%' }"/> 
                                        </div>
                                        <div class="col-lg-4">
                                            <label class="fs-6 fw-bold form-label mt-3">
                                                {{ $t('Prefix') }}
                                            </label> 
                                            <input type="text" class="form-control" name="prefix" v-model="form.prefix">
                                            <div class="form-text">
                                                {{ $t('A short code that will be used as prefix. E.g. "CUS". Prefix setting can be empty.') }} 
                                            </div>
                                        </div>
                                        <div class="col-lg-4">
                                            <label class="fs-6 fw-bold form-label mt-3 required">
                                                {{ $t('Total Length') }}  
                                            </label> 
                                            <CurrencyInput type="text" class="form-control" name="total_length" v-model="form.total_length" :precision="0" @focus="$event.target.select()" :min="0"/>
                                            <div class="form-text">
                                                {{ $t('Total length of the full code.') }}
                                            </div>
                                        </div>
                                        <div class="col-lg-4">
                                            <label class="fs-6 fw-bold form-label mt-3 required">
                                                {{ $t('Start From') }}
                                            </label> 
                                            <CurrencyInput type="text" class="form-control" name="start_from" v-model="form.start_from" :precision="0" @focus="$event.target.select()" :min="0"/>
                                            <div class="form-text">
                                            {{ $t('Defines the number where code will start from. E.g. 100.') }}
                                            </div>
                                        </div>                                        
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="d-grid gap-2">
                            <button type="button" class="btn btn-primary" @click="this.saveData()"  data-kt-indicator="on">
                                <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                </svg>
                                </span> 
                                <strong v-if="!this.isProgressing"> {{ $t('Update Numerator Setting') }} </strong> 
                                    <span class="indicator-progress" v-if="this.isProgressing">
                                        {{ $t('Saving Data...') }} <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span> 
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
    </div>
</template>

<script>
import axios from 'axios';
import Swal from "sweetalert2";  
export default {
  name: "NumeratorSettingsUpdate", 
  data() {
    return {
      aiTypes:[],
      isProgressing: false,
      form:{
        ai_type: -1,
        prefix: '',
        total_length: 10,
        start_from: 1
      },
      menuSelection: '/NumeratorSettingsHelppage',
      iconView : false
    };
  },  
  methods: {
        getData() {  
            this.$Progress.start(); 
            this.$setSessionStorage('refreshList', true);

            const parameters = {
                session_id: this.$getUser().session_id,
                ai_type: this.$getSessionStorage('ai_type'),
                tip: 11
            };  
            const requestOne =  axios.post('/api/DocumentSettings/GetSettingById', parameters, {'Content-Type': 'application/json'});
            const requestTwo =  axios.post('/api/Helper/GetConst', parameters, {'Content-Type': 'application/json'}); 
            
            axios.all([requestOne, requestTwo]).then(axios.spread((...responses) => {
                this.form = responses[0].data;
                this.aiTypes = responses[1].data;
                this.aiTypes.unshift({id: -1, text: 'Select an option'});
                this.$Progress.finish();
            }))
            .catch(errors => { 
                console.error(errors); 
                this.$Progress.finish();
            });
        },
        saveData(){
            if(this.form.ai_type == null || this.form.ai_type == '' || this.form.ai_type == -1) {
                this.$swal("Warning", "Type must be selected..!", 'info');
                return;
            }
            this.isProgressing = true;
            const that = this;
            this.$setSessionStorage('refreshList', true);
            const params = {
                session_id : this.$getUser().session_id,
                ai_type: this.$getSessionStorage('ai_type'),
                prefix: this.form.prefix,
                total_length:this.form.total_length,
                start_from:this.form.start_from
            };
            axios.post('api/DocumentSettings/UpdateSetting', params, {'Content-Type': 'application/json'})
            .then((response) => { 
                if(!response.data.error){
                    this.$swal("Success", "Numerator Setting saved succesfully..!", 'success');  
                    this.$Progress.finish();
                } 
            })
            .catch(function (error) { 
                console.log(error);
                that.isProgressing = false;
            })
            .finally(function () { 
                that.isProgressing = false;
            }); 
        },
        deleteItem() { 
            this.$swal.fire({
                title: 'Are you sure you want to delete this data?',
                confirmButtonColor: '#f1416c',
                showDenyButton: false,
                showCancelButton: true,
                confirmButtonText: 'Yes',
                icon: 'warning'
            }).then((result) => { 
                if (result.isConfirmed) {
                const queryParameter = {
                    ai_type: this.$getSessionStorage('ai_type'),
                    session_id: this.$getUser().session_id,
                };
                axios.post('/api/DocumentSettings/DeleteSetting', queryParameter, {'Content-Type': 'application/json'})
                    .then((response) => { 
                        if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){  
                            new Swal( { icon: 'error', title: 'Deleted...', text: 'Data deleted..!' });
                            this.$setSessionStorage('refreshList', true);
                            this.$router.push({ name: 'NumeratorSettingsList' });
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                        this.$swal("Failed", "Data could not be saved..Please refresh the page and try again..!");
                    });
                }
            })  
        },
        async helpPageViewIcon(){
            const queryparameter = {
                session_id : this.$getUser().session_id,
                menu_selection : '/NumeratorSettingsHelppage'
            };
            axios.post('api/HelpPage/HelpPagesContent', queryparameter, {'Content-Type': 'application/json'})
            .then((response) => { 
                if(!response.data.error){ 
                    this.iconView = response.data;
                }
            })
        }, 
    }, 
    created() {
        this.helpPageViewIcon();
        this.$setSessionStorage('refreshList', true);
        this.getData();
    }
}; 
</script>