import { createApp } from 'vue' ;
import i18n from './i18n';
import App from './App.vue';
import router from './router'; 
import vuex from 'vuex';
import VueSweetalert2 from 'vue-sweetalert2';
import Select2 from 'vue3-select2-component';   
import { VueperSlides, VueperSlide } from 'vueperslides';
import Datepicker from '@vuepic/vue-datepicker';
import VueProgressBar from '@aacassandra/vue3-progressbar';
import ColorInput from 'vue-color-input'; 
import Maska from 'maska';
import CurrencyInput from "./directives/CurrencyInput.vue";
import ListOptions from "./directives/ListOptions.vue";
import FileManager from "./directives/FileManager.vue";
// import VeeValidate from 'vee-validate';
import Pagination from 'v-pagination-3'; 
import Popper from "vue3-popper";
import NoTableResults from "./directives/NoTableResults.vue";
import StatsBar from "./directives/StatsBar.vue";
import RequiredField from "./directives/RequiredField.vue";
import RequiredFieldOnModal from "./directives/RequiredFieldOnModal.vue";
import HelpPageIcon from "./directives/HelpPageIcon.vue";
import 'sweetalert2/dist/sweetalert2.min.css';   
import 'vueperslides/dist/vueperslides.css';
import '@vuepic/vue-datepicker/dist/main.css';
// import 'devextreme/dist/css/dx.light.css';
import '/src/assets/css/custom.css';   
import store from "./store";    
import SecureLS from "secure-ls";  
import UUID from "vue3-uuid";
import AccountReports from "./directives/AccountReports.vue";
import mitt from 'mitt';
import  Editor  from '@tinymce/tinymce-vue';
import CryptoJS from 'crypto-js';
import html2pdf from 'html2pdf.js'; 
import AccountInfo from "./directives/AccountInfo.vue";
import { jsPDF } from 'jspdf';
import { exportDataGrid  as exportDataGridToPdf} from 'devextreme/pdf_exporter';
import { exportDataGrid  as exportDataGridToXls } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';
import Vidle from 'v-idle';
import VueCookies from 'vue3-cookies';
import GetAddress from "./directives/GetAddress.vue";


// import 'devextreme/integration/jquery';
// import { StripePlugin } from '@vue-stripe/vue-stripe';

const emitter = mitt();
const app = createApp(App); 
const ls = new SecureLS({ isCompression: false });
import Swal from 'sweetalert2';

/** PROTOTYPES **/
app.config.globalProperties.$getUser = function() { 
  var data = {};
  try{
    data = (sessionStorage.getItem('sid') == null || sessionStorage.getItem('sid') == undefined || sessionStorage.getItem('sid') == '') ? ls.get('user') : ls.get('user_' + sessionStorage.getItem('sid'));
  }
  catch(Err){
    console.error('Error: while parsing User Storage data: ' + Err);
  }
  return data;  
};
app.config.globalProperties.$setSessionStorage = function(key, data) {
  if(key !== null && key !== undefined && key !== '' && data !== null && data !== undefined && data !== ''){
    try{
      sessionStorage.setItem(key, JSON.stringify(data));
    }
    catch(Err){
      console.error('Error: while parsing Session Storage data.');
    }
  }   
};
app.config.globalProperties.$removeUser = function(key) {
  if(key !== null && key !== undefined && key !== ''){
    ls.remove(key);
  }   
};
app.config.globalProperties.$getSessionStorage = function(key) {
  var data = null;
  try{
    if(sessionStorage.getItem(key) !== null && sessionStorage.getItem(key) !== undefined && sessionStorage.getItem(key) !== ''){
      data = JSON.parse(sessionStorage.getItem(key));
    }
  }
  catch(Err){
    if(sessionStorage.getItem(key).length == 36){
      data = sessionStorage.getItem(key);
    } 
  }
  return data; 
};
app.config.globalProperties.$removeSessionStorage = function(key) {
  if(key !== null && key !== undefined && key !== ''){
    try{
      sessionStorage.removeItem(key);
    }
    catch(Err){
      console.error('Error: while parsing Session Storage data.');
    }
  }   
};
app.config.globalProperties.$setLS = function(key, data) {
  if(key !== null && key !== undefined && key !== '' && data !== null && data !== undefined && data !== ''){
    try{
      ls.set(key, data);
    }
    catch(Err){
      console.error('Error: while parsing Local Storage data.');
    }
  }   
};
app.config.globalProperties.$getLS = function(key) {
  var data = null;
  try{
    if(ls.get(key) !== null && ls.get(key) !== undefined && ls.get(key) !== ''){
      data = ls.get(key);
    }
  }
  catch(Err){
    console.error('Error: while parsing Local Storage data.');
  }
  return data; 
};
app.config.globalProperties.$checkSelect2Validation = function(formName) {
  if(formName !== null && formName !== undefined && formName !== ''){    
    try{
      const formElements = document.forms[formName];
      const formElementArray = Array.from(formElements);
      let requiredField = formElementArray.find((item) => item.tagName == 'SELECT' && item.hasAttribute('required') && item.value < 0 && ( item.value == '-1' && item.textContent.includes('Select an option')));
      if(requiredField !== null && requiredField !== undefined) requiredField.value = null;
    }
    catch(Err){console.error(Err);}
  }   
};
app.config.globalProperties.$lock = function(value){
  return this.$CryptoJS.AES.encrypt(value, "UxLPuYFIzdOPKcTW").toString();
};

app.config.globalProperties.$unlock = function(encryptedText){
  encryptedText = CryptoJS.enc.Base64.parse(encryptedText);
  var key = CryptoJS.enc.Utf8.parse("UxLPuYFIzdOPKcTW");
  var aes = CryptoJS.algo.AES.createDecryptor(key, {
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
    iv: key
  });
  var decrypted = aes.finalize(encryptedText);
  return CryptoJS.enc.Utf8.stringify(decrypted);
};

/** Form Validations */
app.config.globalProperties.$getFormValidation = function(key) {
  var data = null;
  var validation = null;
  try{
      data = sessionStorage.getItem('frm_val') ? sessionStorage.getItem('frm_val') : [];
      validation = data.length > 0 ? JSON.parse(data).find((item) => item.field == key) : null;
  }
  catch(Err){
    console.warn(Err);
  }
  return validation;
};
app.config.globalProperties.$getFormRequirements = function(key) {
  if(key == null || key == '' || key == undefined){
      return;
  }
  return this.$getFormValidation(key);
};
app.config.globalProperties.$numericSort = function(a, b) {
  if (parseFloat(a.replace(/,/g, '')) > parseFloat(b.replace(/,/g, ''))) return 1;
  if (parseFloat(a.replace(/,/g, '')) < parseFloat(b.replace(/,/g, ''))) return -1;
  return 0;
};
app.config.globalProperties.$globalAdvanceParseFloat = function(number, fractionDigits, decimal, separator) {
  fractionDigits = isNaN(fractionDigits = Math.abs(fractionDigits)) ? 2 : fractionDigits;  
  decimal = typeof (decimal) === "undefined" ? "." : decimal;
  separator = typeof (separator) === "undefined" ? "," : separator; 
  var neg = number < 0 ? "-" : "";
  var wholePart = parseInt(number = Math.abs(+number || 0).toFixed(fractionDigits)) + "";
  var separtorIndex = (separtorIndex = wholePart.length) > 3 ? separtorIndex % 3 : 0;
  return neg +
  (separtorIndex ? wholePart.substr(0, separtorIndex) + separator : "") +
  wholePart.substr(separtorIndex).replace(/(\d{3})(?=\d)/g, "$1" + separator) +
  (fractionDigits ? decimal + Math.abs(number - wholePart).toFixed(fractionDigits).slice(2) : "");
};
app.config.globalProperties.$globalParseFloat = function(number) {
  return Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(number)
};
app.config.globalProperties.$onExporting= function(e,listName) {
  if (e.format === 'xlsx') {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet('Sheet');
      exportDataGridToXls({
          component: e.component,
          worksheet,
          autoFilterEnabled: true,
          customizeCell({ gridCell, excelCell }) {
            if (gridCell.rowType === 'data' && gridCell.column.dataField === 'is_active') {
              excelCell.value = gridCell.value === 1 ? 'Active' : 'Passive';
            }
            if (gridCell.rowType === 'data' && gridCell.column.dataField === 'is_default') {
              excelCell.value = gridCell.value === 1 ? 'True' : 'False';
            }
          }
      }).then(() => {
          workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), listName + '.xlsx');
          });
      });
      e.cancel = true;
  }
  else if (e.format === 'pdf') { 
      const doc = new jsPDF({
          format: 'a3',
          orientation: 'landscape'
      });
      doc.addFont('../public/assets/fonts/Roboto-Regular.ttf','Roboto-Regular','normal')
      doc.setFont('Roboto-Regular');
      const lastPoint = { x: 0, y: 0 };
      exportDataGridToPdf({
          jsPDFDocument: doc,
          component: e.component,
          indent: 2,
          customizeCell({ gridCell, pdfCell }) {
              if (gridCell.rowType === 'group') {
                  pdfCell.backgroundColor = '#FFE082';
              }
              if (gridCell.rowType === 'data' && gridCell.column.dataField === 'record_date') {
                  if(pdfCell.text == '1'){
                      pdfCell.backgroundColor = '#FFE082';
                  }
              }
              if (gridCell.rowType === 'data' && gridCell.column.dataField === 'is_active') {
                pdfCell.text = gridCell.value === 1 ? 'Active' : 'Passive';
              }
              if (gridCell.rowType === 'data' && gridCell.column.dataField === 'is_default') {
                pdfCell.text = gridCell.value === 1 ? 'True' : 'False';
              }
          }
      }).then(() => {
          const footer = 'CreatorCUBE by Polisoft Software © 2022';
          doc.setFontSize(9);
          doc.setTextColor('#cccccc');
          const footerWidth = doc.getTextDimensions(footer).w;
          doc.text(footer, (lastPoint.x - footerWidth), lastPoint.y + 5);
          doc.save(listName + '.pdf');
      });
  }
};
app.config.globalProperties.$checkIfDateFieldRequired = function(key){
  var data = sessionStorage.getItem('frm_val') ? sessionStorage.getItem('frm_val') : [];
  var validation = data.length > 0 ? JSON.parse(data).find((item) => item.field == key) : null;
  if(validation !== null && validation !== undefined && validation !== ''){
      if(validation.is_required){
         return true;
      }
      else{
          return false;
      }
  }
  return false;
};

app.config.globalProperties.$isAdmin = function() {
  return this.$getUser().ia == 1;
};

app.config.globalProperties.$isCredentialActive = function(module, operation, parentModule) {
  let credentialStatus = false;
  const credentialList = this.$getUser().credentials;
  if(credentialList !== null && credentialList !== '' && credentialList !== undefined) {
    credentialStatus = credentialList.includes('#'+operation + module + '#');

    if(credentialStatus && (parentModule !== null && parentModule !== undefined && parentModule !== '')) {
      credentialStatus = credentialList.includes('#'+ parentModule + '#');
    }
  }
  return credentialStatus;
};

app.config.globalProperties.$isModuleActive = function(module) {
  let moduleStatus = false;
  const modules = this.$getUser().modules;
  if(modules !== null && modules !== '' && modules !== undefined) {
    moduleStatus = modules.includes('#'+ module + '#');
  }
  return moduleStatus;
};

app.config.globalProperties.$signOutWithTimer = function(alertTitle, alertText) {
  let timerInterval;
  Swal.fire({
      title: alertTitle,
      html: alertText,
      icon: 'warning',
      showCancelButton: false,
      showConfirmButton: false,
      allowOutsideClick: false,
      timer: 10000,
      timerProgressBar: true,
      didOpen: () => {
          Swal.showLoading();
          const b = Swal.getHtmlContainer().querySelector('b');
          b.textContent = 10;
          timerInterval = setInterval(() => {
              b.textContent = (Swal.getTimerLeft() / 1000).toFixed(0)
          }, 1000)
      },
      willClose: () => {
          clearInterval(timerInterval);
          this.$removeUser('user_' + this.$getUser().session_id);
          if(this.$getSessionStorage('is_instant_login')){
              sessionStorage.clear();
              window.close();
          }
          else{ 
              sessionStorage.clear();
              this.$router.push({ name: 'Login' });
          }
      }
  });
};

const loadingBarOptions = { 
  color: "#009ef7", failedColor: "#E91E63", thickness: "8px", transition: { speed: "0.5s", opacity: "0.6s", termination: 300, }, autoRevert: true, location: "top", inverse: false
 };  

app.directive('custom', (el) => {
  var data = null;
  var validation = null;
  var select2validation = null;
  try{
    data = sessionStorage.getItem('frm_val') ? sessionStorage.getItem('frm_val') : [];
    validation = data.length > 0 ? JSON.parse(data).find((item) => item.field == el.name) : null;
    if(validation !== null && validation !== undefined && validation !== ''){
      if(validation.is_required){
        el.setAttribute("required", 'true');
      } 
    }

    if(el.firstChild !== null && el.firstChild !== undefined && el.firstChild !== '') {
      select2validation = data.length > 0 ? JSON.parse(data).find((item) => item.field == el.firstChild.name) : null;
      if(select2validation !== null && select2validation !== undefined && select2validation !== ''){
        if(select2validation.is_required){
          el.firstChild.setAttribute("required", 'true');
        } 
      }
    }
  }
  catch(Err){
    console.error(Err);
  }
}); 

app.directive('tag', (el, binding) => {
  var data = null;
  var validation = null;
  try{
      data = sessionStorage.getItem('frm_val') ? sessionStorage.getItem('frm_val') : [];
      validation = data.length > 0 ? JSON.parse(data).find((item) => item.field == binding.value) : null;
      if(validation !== null && validation !== undefined && validation !== ''){
        if(validation.is_required){
          el.classList.add('required');
        } 
      }
  }
  catch(Err){
    console.error(Err);
  }
});

app.use(router);
app.use(vuex);
app.use(store);
app.use(VueSweetalert2);   
app.use(VueProgressBar, loadingBarOptions);
app.use(ColorInput);
app.use(Maska); 
app.use(UUID);
app.use(VueCookies);
// app.use(VeeValidate);
app.use(CryptoJS);
app.component('Select2', Select2)   
app.component('VueperSlides', VueperSlides)   
app.component('VueperSlide', VueperSlide)    
app.component('Datepicker', Datepicker); 
app.component('CurrencyInput', CurrencyInput);
app.component('ListOptions', ListOptions);
app.component('FileManager', FileManager);
app.component('pagination', Pagination);
app.component('NoTableResults', NoTableResults);
app.component("Popper", Popper);
app.component("StatsBar", StatsBar); 
app.component("RequiredField", RequiredField); 
app.component("RequiredFieldOnModal", RequiredFieldOnModal); 
app.component("HelpPageIcon", HelpPageIcon);
app.component("AccountReports", AccountReports);
app.component("Editor", Editor);
app.component("html2pdf ", html2pdf);
app.component("AccountInfo", AccountInfo); 
app.component("Vidle", Vidle); 
app.component('GetAddress', GetAddress);
app.config.globalProperties.emitter = emitter;
//app.mount('#app');
//i18n(createApp(App)).mount('#app')
i18n(app).mount('#app');
