<template> 
    <div> 
      <div class="page-title flex-row me-3 mt-3"> 
          <h1 class="d-flex fw-bolder my-1 fs-3 text-gray-800">{{ $t('Supplier List') }}
          </h1>    
          <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
              <li class="breadcrumb-item text-gray-600">
                  <a href="#" class="text-gray-600 text-hover-primary">
                      <router-link :to="{ name: 'Home' }">{{ $t('Home') }}</router-link>
                  </a>
              </li> 
              <li class="breadcrumb-item text-primary">{{ $t('Accounts') }}</li>     
              <li class="breadcrumb-item text-gray-600">{{ $t('Supplier List') }}
                <HelpPageIcon v-if="this.iconView == true" :menuSelection="this.menuSelection"></HelpPageIcon>
              </li> 
          </ul> 
      </div>  
      <div class="content flex-row-fluid mt-4" id="kt_content"> 
          <!-- <StatsBar></StatsBar> -->
          <div class="card">
              <div class="card-header border-0 pt-6">
                  <div class="card-title">
                      <router-link to="/SupplierCreate" v-if="this.$isCredentialActive(11,'I')">
                          <button type="button" class="btn btn-light-primary border border-primary me-3">
                              <span class="svg-icon svg-icon-muted"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                  <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="currentColor"/>
                                  <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="currentColor"/>
                                  <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="currentColor"/>
                              </svg>
                              </span>
                              {{ $t('Create New Account') }}
                          </button>
                      </router-link>
                      <div class="d-flex align-items-center position-relative my-1">
                          
                      </div>
                  </div>
                  <div class="card-toolbar">
                    <div class="d-flex justify-content-end" data-kt-customer-table-toolbar="base" v-if="this.$isCredentialActive(11,'R')">
                        <button type="button" class="btn btn-light-primary border border-primary me-3" data-bs-toggle="modal" 
                            data-bs-target="#kt_search_account_modal" @click="openSearchModal()">
                            <i class="fa fa-search"></i>
                            {{ $t('Search') }}
                        </button>
                        <button type="button" class="btn btn-light border border-gray-400" @click="getData()" data-kt-indicator="on" v-if="this.$getUser().is_allow_list_all_data" >
                            <span class="svg-icon svg-icon-muted"><svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect y="6" width="16" height="3" rx="1.5" fill="currentColor"/>
                            <rect opacity="0.3" y="12" width="8" height="3" rx="1.5" fill="currentColor"/>
                            <rect opacity="0.3" width="12" height="3" rx="1.5" fill="currentColor"/>
                            </svg>
                            </span>
                            <span v-if="!this.isListProgressing"> {{ $t('All Accounts') }} </span>
                            <span class="indicator-progress" v-if="this.isListProgressing">
                                {{ $t('Getting Data...') }} <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span> 
                        </button>
                    </div>
                    <div class="d-flex justify-content-end align-items-center d-none" data-kt-customer-table-toolbar="selected">
                        <div class="fw-bolder me-5">
                        <span class="me-2" data-kt-customer-table-select="selected_count"></span>{{ $t('Selected') }}</div>
                        <button type="button" class="btn btn-danger" data-kt-customer-table-select="delete_selected">{{ $t('Delete Selected') }}</button>
                    </div>
                  </div>
              </div>
              <div class="card-body pt-3">
                    <div class="separator my-3 mt-0 border-gray-300" style="margin-top: -10px !important;"></div>
                    <div class="dataTables_wrapper dt-bootstrap4 no-footer">
                        <div class="table-responsive min-h-300px">                          
                            <ListOptions :name="this.$options.name" @click="SaveDXStateToStorage" ref="listOptionComponent"></ListOptions>
  
                            <DxDataGrid id="gridContainer" :show-borders="true" 
                                key-expr="id" 
                                :data-source="lists" 
                                :allow-column-reordering="true"  
                                :allow-column-resizing="true" 
                                :column-auto-width="true" 
                                :row-alternatin-enabled="true"
                                :hover-state-enabled="true" 
                                :show-row-lines="true" 
                                :ref="'dataGrid'"
                                @exporting="this.$onExporting($event , 'Supplier-Account-List')"> 

                                <DxFilterRow :visible="true"/>
                                <DxHeaderFilter :visible="true"/>
                                <DxGroupPanel :visible="true"/>      
                                <DxExport :enabled="this.$getUser().is_allow_export" :formats="['xlsx', 'pdf']" :allow-export-selected-data="false"/>
                                <DxPaging :page-size="pageSize"/>
                                <DxPager :visible="false" :allowed-page-sizes="pageSizes" :show-page-size-selector="showPageSizeSelector" :show-info="true" :show-navigation-buttons="true"/>
                                <DxSearchPanel :visible="true"/>
                                <DxScrolling
                                    mode="virtual"
                                    row-rendering-mode="virtual"
                                />
                    
                                <DxColumn data-field="detail" caption="..." cell-template="detail-cell-template" alignment="center" :allow-exporting="false" :width="45"/> 
                                <DxColumn v-if="isViewAccountId" data-field="account_id" :caption="$t('Code')" alignment="left" cell-template="account_id-cell-template"/> 
                                <DxColumn data-field="name" :caption="$t('Name')" alignment="left" cell-template="name-cell-template"/> 
                                <DxColumn data-field="supplier_account_number" :caption="$t('Registration Number')" header-cell-template="supplier_account_number-header" alignment="left" cell-template="supplier_account_number-cell-template"/> 
                                <DxColumn data-field="supplier_company_account_type" :caption="$t('Account Type')" header-cell-template="supplier_company_account_type-header" alignment="left" cell-template="supplier_company_account_type-cell-template"/> 
                                <DxColumn data-field="supplier_type_name" :caption="$t('Supplier Type')" header-cell-template="supplier_type_name-type-header" alignment="left" cell-template="supplier_type_name-cell-template"/> 
                                <DxColumn data-field="supplier_account_type_name" :caption="$t('Supplier Acc. Type')" header-cell-template="supplier_account_type_name-type-header" alignment="left" cell-template="supplier_account_type_name-cell-template"/> 
                                <DxColumn data-field="debit" :sorting-method="this.$numericSort" :caption="$t('Debit')" alignment="right" cell-template="debit-cell-template"/> 
                                <DxColumn data-field="credit" :sorting-method="this.$numericSort" :caption="$t('Credit')" alignment="right" cell-template="credit-cell-template"/> 
                                <DxColumn data-field="balance" :sorting-method="this.$numericSort" :caption="$t('Balance')" alignment="right" cell-template="balance-cell-template"/>  
                                <DxColumn data-field="supplier_country" :caption="$t('Country')" alignment="left" cell-template="supplier_country-cell-template"/>  
                                <DxColumn data-field="supplier_city" :caption="$t('City')" alignment="left" cell-template="supplier_city-cell-template"/>  
    
                                <template #company-type-header>
                                    <div>{{ $t('Company') }}</div><div>{{ $t('Type') }}</div>
                                </template>
                                <template #supplier_type_name-type-header>
                                    <div>{{ $t('Supplier') }}</div><div>{{ $t('Type') }}</div>
                                </template>
                                <template #supplier_account_type_name-type-header>
                                    <div>{{ $t('Supplier') }}</div><div>{{ $t('Acct. Type') }}</div>
                                </template>
                                <template #supplier_account_number-header>
                                    <div>{{ $t('Registration') }}</div><div>{{ $t('Number') }}</div>
                                </template>
                                <template #supplier_company_account_type-header>
                                    <div>{{ $t('Account') }}</div><div>{{ $t('Type') }}</div>
                                </template>

                                <template #detail-cell-template="{ data }">
                                    <a href="#" class="btn btn-sm btn-icon text-hover-primary w-20px h-20px" 
                                        @click="onOpenAccountReport(data.data)" data-bs-toggle="modal" data-bs-target="#kt_modal_account_report_selection" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                                        <i class="fa fa-eye text-gray-600 fs-3"></i>
                                    </a>
                                </template>
                                <template v-if="isViewAccountId" #account_id-cell-template="{ data }">
                                    <a href="#" class="text-hover-primary mb-1" @click="updateItem(data.data.id)">
                                        <div>
                                        <span class="badge badge-circle badge-light me-3">
                                            <i class="bi bi-pencil text-gray-900"></i> 
                                        </span> 
                                        <span class="text-gray-900"> 
                                            {{ data.data.account_id }} 
                                        </span> 
                                        </div>
                                    </a>
                                </template>
                                <template #name-cell-template="{ data }">
                                    <a href="#" class="text-gray-800 fw-bold text-hover-primary mb-1" @click="updateItem(data.data.id)">
                                        <span>{{ data.data.name }}</span>
                                        <span class="badge badge-light-success ms-5 float-end" v-if="data.data.is_main_group == 1">{{ $t('Main') }}</span>
                                    </a>
                                </template>
                                <template #supplier_account_number-cell-template="{ data }">
                                    <a href="#" class="text-gray-800 fw-bold text-hover-primary mb-1" @click="updateItem(data.data.id)">
                                        {{ data.data.supplier_account_number }}
                                    </a>
                                </template>
                                 <template #supplier_company_account_type-cell-template="{ data }">
                                    <a href="#" class="text-gray-800 text-hover-primary mb-1" @click="updateItem(data.data.id)">
                                        {{ data.data.supplier_company_account_type }}
                                    </a>
                                 </template>
                                  <template #supplier_type_name-cell-template="{ data }">
                                      <a href="#" class="text-gray-800 text-hover-primary mb-1" @click="updateItem(data.data.id)">
                                          {{ data.data.supplier_type_name }}
                                      </a>
                                  </template>
                                  <template #supplier_account_type_name-cell-template="{ data }">
                                      <a href="#" class="text-gray-800 text-hover-primary mb-1" @click="updateItem(data.data.id)">
                                          {{ data.data.supplier_account_type_name }}
                                      </a>
                                  </template>
                                  <template #debit-cell-template="{ data }">
                                      <a href="#" class="text-gray-800 text-hover-primary mb-1" @click="updateItem(data.data.id)">
                                          {{ data.data.debit }}
                                      </a>
                                  </template>
                                  <template #credit-cell-template="{ data }">
                                      <a href="#" class="text-gray-800 text-hover-primary mb-1" @click="updateItem(data.data.id)">
                                          {{ data.data.credit }}
                                      </a>
                                  </template>
                                  <template #balance-cell-template="{ data }">
                                      <a href="#" class="text-gray-800 text-hover-primary mb-1" @click="updateItem(data.data.id)">
                                          {{ data.data.balance }}
                                      </a>
                                  </template>
                                  <template #supplier_country-cell-template="{ data }">
                                      <a href="#" class="text-gray-800 text-hover-primary mb-1" @click="updateItem(data.data.id)">
                                          {{ data.data.supplier_country }}
                                      </a>
                                  </template>
                                  <template #supplier_city-cell-template="{ data }">
                                      <a href="#" class="text-gray-800 text-hover-primary mb-1" @click="updateItem(data.data.id)">
                                          {{ data.data.supplier_city }}
                                      </a>
                                  </template> 
  
                                  <DxSummary :calculate-custom-summary="setSummaries">
                                      <DxTotalItem column="id" summary-type="count" display-format="Record: {0}" show-in-column="name"/>
                                      <DxGroupItem
                                          :show-in-group-footer="true"
                                          name="debit"
                                          summary-type="custom"
                                          show-in-column="debit"
                                          display-format="{0}"
                                      /> 
                                      <DxGroupItem
                                          :show-in-group-footer="true"
                                          name="credit"
                                          summary-type="custom"
                                          show-in-column="credit"
                                          display-format="{0}"
                                      /> 
                                      <DxGroupItem
                                          :show-in-group-footer="true"
                                          name="balance"
                                          summary-type="custom"
                                          show-in-column="balance"
                                          display-format="{0}"
                                      /> 
                                      <DxTotalItem name="debit"
                                          summary-type="custom"
                                          show-in-column="debit"
                                      />
                                      <DxTotalItem name="credit"
                                          summary-type="custom"
                                          show-in-column="credit"
                                      />
                                      <DxTotalItem name="balance"
                                          summary-type="custom"
                                          show-in-column="balance"
                                      />
                                  </DxSummary>
  
                                  <DxStateStoring
                                          :enabled="true"
                                          type="custom"
                                          :storage-key="this.storageKey"
                                          :ref="dataGrid" 
                                          :saving-timeout="1000"
                                          :custom-load="LoadDXState"
                                      />
                          </DxDataGrid>   
                      </div>
                  </div> 
              </div>
          </div> 
        </div>
      
        <!-- Search Account Modal -->
        <div class="modal fade" id="kt_search_account_modal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <SearchAccount ref="SearchAccount" :account-type="2"/>
        </div> 

        <!--AccountReports-->
        <div class="modal fade" id="kt_modal_account_report_selection" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
            <AccountReports ref="AccountReports" :account-name="accountReport.name" :account-id="accountReport.id" :account-type="2" />
        </div>
    </div>  
</template>
  
  <script>
  import axios from 'axios' 
  import moment from 'moment';
  // import Swal from 'sweetalert2';
  import { DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSummary, DxTotalItem, DxSearchPanel, DxStateStoring, DxGroupItem, DxScrolling } from 'devextreme-vue/data-grid'; 
  import SearchAccount from '../../Accounts/Modals/SearchAccount.vue';
 
  export default {
    name: "SupplierList",
    components:{
          DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSummary, DxTotalItem, DxSearchPanel, DxStateStoring, DxGroupItem, SearchAccount, DxScrolling
    },
    data() {
        return {
            storageKey: 'dx_' + this.$options.name,
            pageSize: 20,
            lists: [],
            searchbar: '',
            isListProgressing: false,
            summary: {},
            page_size: 10,
            page: 1,
            menuSelection: '/SupplierHelppage',
            iconView : false,
            isViewAccountId: false,
            accountReport: {
                id: null,
                name: null
            }
        };
    },
    computed: {
      searchableList() {
        return this.lists.filter(p => { 
          return p.name.toLowerCase().indexOf(this.searchbar.toLowerCase()) != -1;
        }).slice((this.page - 1) * this.page_size, this.page * this.page_size);
      },
      previewDate(){
        return (value)=>{
         if (value) {
            return moment(new Date(value)).format('DD.MM.YYYY')
          }
        }
      },
      previewShortDate(){
        return (value)=>{
         if (value) {
            return moment(new Date(value)).format('DD.MM.YYYY')
          }
        }
      },
    showPageSizeSelector() {
        return this.lists.length > this.pageSize;
    },
    pageSizes() {
        let arr = [];
        let x = 1;
        let loop = (this.lists.length / this.pageSize);
        for (let i = 1; i <= loop && i <= 5; i++) {
            arr.push(this.pageSize * x);
            x++;
        }
        if(loop > 5) arr.push(100);
        if(loop > 1) arr.push('all');
        return arr;
    }
    },
    methods: {
        loadState() {
            return JSON.parse(localStorage.getItem(this.storageKey));
        },
        saveState(state) {
            if (state) {
                for (let i = 0; i < state.columns.length; i++) {
                    state.columns[i].filterValue = null;
                }
            }
            localStorage.setItem(this.storageKey, JSON.stringify(state));
        },
        onStateResetClick() {
            this.$refs.dataGrid.instance.state(null);
        },
        getData(searchText, searchParameter) {
            if((searchText == '' || searchText == undefined || searchText.length < 3) && !this.$getUser().is_allow_list_all_data) return;
            this.$Progress.start();
            if(searchText == '' || searchText == undefined || searchText == null){
                this.isListProgressing = true;
            }
            const parameters = {
                session_id : this.$getUser().session_id,
                account_type : 2, // Supplier: 2
                list_name: ["account_setting"],
                search_text: searchText,
                search_parameter: (searchText !== null && searchText !== '' && searchText !== undefined) ? searchParameter : 0  // 0: All, 1: Search By Account Name, 2: Search By Registration Number
            };  
            const requestOne =  axios.post('/api/GetCompanyAccounts/GetCustomerAccounts', parameters, {'Content-Type': 'application/json'});
            const requestTwo =  axios.post('/api/Helper/GeneralLists', parameters, {'Content-Type': 'application/json'}); 
            
            axios.all([requestOne, requestTwo]).then(axios.spread((...responses) => {
                if(responses[0].data.error == null || responses[0].data.error == 'undefined' || responses[0].data.error == ''){ 
                    this.lists = responses[0].data.account_data;
                    this.summary = responses[0].data.account_summary;
                    
                    this.lists.forEach(element => {
                        element.name = this.$unlock(element.name);
                        element.account_id = this.$unlock(element.account_id);
                        element.text = this.$unlock(element.text);
                        element.country = this.$unlock(element.country);
                        element.city = this.$unlock(element.city);
                        element.supplier_country = this.$unlock(element.supplier_country);
                        element.supplier_city = this.$unlock(element.supplier_city);
                        element.customer_account_number = this.$unlock(element.customer_account_number);
                        element.supplier_account_number = this.$unlock(element.supplier_account_number);
                        element.balance = this.$unlock(element.balance);
                        element.debit = this.$unlock(element.debit);
                        element.credit = this.$unlock(element.credit);
                    });
                }
                if (responses[1].data.account_setting.is_default == false) {
                    this.isViewAccountId = true;
                }
                this.$Progress.finish();
                setTimeout(() => {
                    this.isListProgressing = false;
                }, 500);
            }))
            .catch(errors => { 
                console.error(errors);
                this.$Progress.finish();
                setTimeout(() => {
                    this.isListProgressing = false;
                }, 500);
            });
        },
        updateItem(id){ 
          this.$setSessionStorage('record_id', id);
          this.$router.push({ name: 'SupplierUpdate' });
        },
        setSummaries(options){
          if(options.name == "debit"){
              if(options.summaryProcess === 'start'){
                  options.totalValue = 0;
              }
              else if(options.summaryProcess === 'calculate'){ 
                  var debit = parseFloat(options.value.debit.replaceAll(',', ''));
                  (options.totalValue += debit); 
              }
              else if(options.summaryProcess === 'finalize'){
                  options.totalValue = Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(options.totalValue);
              }
          }
          if(options.name == "credit"){
              if(options.summaryProcess === 'start'){
                  options.totalValue = 0;
              }
              else if(options.summaryProcess === 'calculate'){
                  var credit = parseFloat(options.value.credit.replaceAll(',', ''));
                  (options.totalValue += credit);
              }
              else if(options.summaryProcess === 'finalize'){ 
                  options.totalValue = Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(options.totalValue);
              }
          }
          if(options.name == "balance"){
              if(options.summaryProcess === 'start'){
                  options.totalValue = 0;
              }
              else if(options.summaryProcess === 'calculate'){
                  var balance = parseFloat(options.value.balance.replaceAll(',', ''));
                  (options.totalValue += balance);
              }
              else if(options.summaryProcess === 'finalize'){ 
                  options.totalValue = Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(options.totalValue);
              }
          }
        },
        onOpenAccountReport(item){
            let accountInfo = {
                id: item.id, 
                name: item.name
            };
            this.$refs.AccountReports.getData(accountInfo);
            this.$refs.AccountReports.setModalCriteriasToDefault();
        },
        async helpPageViewIcon()
        {
            const queryparameter = {
                session_id : this.$getUser().session_id,
                menu_selection : '/SupplierHelppage'
            };
            axios.post('api/HelpPage/HelpPagesContent', queryparameter, {'Content-Type': 'application/json'})
            .then((response) => { 
                if(!response.data.error){ 
                    this.iconView = response.data;
                }
            })
        }, 
        /** DX STATE OPERATIONS **/  
        LoadDXState() {
            return JSON.parse(localStorage.getItem(this.storageKey));
        }, 
        SaveDXStateToStorage() { 
            const state = this.$refs.dataGrid.instance.state();
            localStorage.setItem(this.storageKey, JSON.stringify(state));
            this.$refs.listOptionComponent.getState();        
        },
        openSearchModal() {
            this.$refs.SearchAccount.getData();
        }
    },
    created() {
        this.helpPageViewIcon();
        this.lists = [];
    }
  }; 
  </script>