<template>
  <div>
    <div class="engage-toolbar d-flex position-fixed px-5 fw-bolder zindex-2 top-50 end-0 transform-90 mt-20 gap-2">
      <button v-if="this.$isModuleActive(35)" class="engage-demos-toggle btn btn-flex h-35px bg-body btn-color-gray-700 btn-active-color-gray-900 shadow-sm fs-2 px-4 rounded-top-0" @click="this.openTimeLineDrawer('liabilities')">
        <span id="kt_engage_demos_label">{{ $t('Liabilities') }}</span>
      </button> 
      <button class="engage-demos-toggle btn btn-flex h-35px bg-body btn-color-gray-700 btn-active-color-gray-900 shadow-sm fs-2 px-4 rounded-top-0" @click="this.openTimeLineDrawer('rates')">
        <span id="kt_engage_demos_label">{{ $t('Rates') }}</span>
      </button> 
    </div>

    <div id="kt_scrolltop" class="scrolltop" data-kt-scrolltop="true">
      <span class="svg-icon">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <rect
            opacity="0.5"
            x="13"
            y="6"
            width="13"
            height="2"
            rx="1"
            transform="rotate(90 13 6)"
            fill="currentColor"
          />
          <path
            d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
            fill="currentColor"
          />
        </svg>
      </span>
    </div>

    <!-- Liabilities -->
    <div id="kt_activities" class="bg-body bg-secondary drawer drawer-end drawer-on animated fadeInLeft mw-50" v-if="isLiabilityDrawerOpen" 
      style="border-left: 3px dashed; border-color: #E4E6EF; box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 20px 20px rgba(0,0,0,0.33);">
        <div class="card h-md-100 bg-light" v-if="this.liabilities">
            <div class="card-header align-items-center border-0 mt-4">
                <h3 class="card-title align-items-start flex-column">
                    <span class="fw-bolder mb-2 text-gray-800">{{ $t('Liabilities') }}</span>
                </h3> 
                  <div class="card-toolbar">
                    <button type="button" class="btn btn-sm btn-icon btn-active-light-primary me-n5" id="kt_activities_close" @click="this.closeTimeLineDrawer()">
                        <span class="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                    transform="rotate(-45 6 17.3137)" fill="currentColor" />
                                <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)"
                                    fill="currentColor" />
                            </svg>
                        </span>
                    </button>
                </div>
            </div>
            <div class="card-body pt-5">
              <div class="row">
                  <div class="col-lg-12">
                    <div class="card shadow card-xxl-stretch mb-6">
                      <div class="card-body p-3">                            
                        <ul class="nav nav-tabs nav-line-tabs nav-line-tabs-2x justify-content-center align-items-center mb-2 fs-6">
                            <li class="nav-item" role="presentation">
                                <a id="nav_tab_2" class="nav-link shadow-sm rounded-1 py-3 text-gray-600 text-active-primary fw-bold px-4 active"
                                    data-bs-toggle="tab" href="nav_tab_2" target="_self" @click="onChangeDateCriteria(2)">{{ $t('On This Month') }} ({{this.totals.this_month_total}}) </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a id="nav_tab_3" class="nav-link shadow-sm rounded-1 py-3 text-gray-600 text-active-primary fw-bold px-4"
                                    data-bs-toggle="tab" href="nav_tab_3" target="_self" @click="onChangeDateCriteria(3)">{{ $t('Next Month') }} ({{this.totals.next_month_total}})</a>
                            </li>
                        </ul>                            
                      </div>
                    </div>
                  </div>
                  <div>
                    <div class="py-3" v-for="(liabilityItem, index) in this.liabilities" v-bind:key="liabilityItem">
                        <div class="card shadow ribbon ribbon-top" :class="{'border': liabilityItem.border_color !== '' && liabilityItem.border_color !== null}" :style="'border-color:' + liabilityItem.border_color + '!important'">
                            <div class="ribbon-label py-1 ribbon-label-right-12 fs-8 bg-light-danger text-danger border border-danger" v-if="liabilityItem.amount_type == 0">{{ $t('No Amount') }}</div>
                            <div class="ribbon-label py-1 ribbon-label-right-12 fs-8 bg-light-success text-success border border-success" v-if="liabilityItem.amount_type == 1">{{ $t('Payment') }}</div>
                            <div class="ribbon-label py-1 ribbon-label-right-12 fs-8 bg-light-primary text-primary border border-primary" v-if="liabilityItem.amount_type == 2">{{ $t('Collection') }}</div>

                            <span class="ribbon-label py-1 fs-8 bg-primary border border-primary" v-if="liabilityItem.source == 1">{{ $t('Standard') }}</span>
                            <span class="ribbon-label py-1 fs-8 bg-warning border border-warning" v-if="liabilityItem.source == 2">{{ $t('Transaction') }}</span>
                            <span class="ribbon-label py-1 fs-8 bg-info border border-info" v-if="liabilityItem.source == 3">{{ $t('User Manuel') }}</span>  

                            <div v-if="liabilityItem.location == 3" class="ribbon ribbon-triangle ribbon-top-start border-primary cursor-pointer" style="border-top-left-radius: 12px">                    
                                <Popper hover placement="top">
                                    <div class="ribbon-icon mt-n5 ms-n6">
                                        <i class="bi bi-check2 fs-2 text-white"></i>
                                    </div>
                                    <template #content>
                                        <span class="text-gray-800 fw-bold fs-7"> {{ $t('Only Company') }} </span>
                                    </template>
                                </Popper>
                            </div>             
                            <div class="card-header py-3 pt-8 px-10">
                                <h3 class="card-title">
                                    <div class="d-flex flex-center rounded-3 bg-opacity-90 collapsible cursor-pointer rotate collapsed me-10 border bg-light-primary" data-bs-toggle="collapse" :data-bs-target="'#kt_accordion_liability_drawer'+ index" @click="liabilityItem.activeDetailTab = 'general'">
                                        <span class="svg-icon svg-icon-3x rotate-180">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="currentColor"></path>
                                            </svg>
                                        </span>
                                    </div>
                                    <div class="d-flex align-items-center">
                                        <div class="d-flex align-items-center flex-grow-1">
                                            <div class="symbol symbol-45px me-5">
                                                <i class="fs-3x" :class="!liabilityItem.icon ? 'fa fa-bell' : liabilityItem.icon" :style="{ 'color': liabilityItem.color }"></i>
                                            </div>
                                            <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-7">
                                                <div class="fs-6 text-gray-800 fw-bold text-center">{{previewOnlyDate(liabilityItem.value_date)}}</div>
                                                <div class="fs-7 fw-semibold text-gray-400 text-center">{{ $t('Value Date') }}</div>
                                                <div class="fs-7 fw-semibold text-center text-primary" :class="{'text-danger': liabilityItem.value_remain_day < 0}">
                                                    {{liabilityItem.value_remain_day}} {{ $t('days') }}
                                                </div>
                                            </div>
                                            <div class="d-flex flex-column">
                                                <a href="#" class="text-gray-800 text-hover-primary fs-6 fw-bold">{{liabilityItem.liability_type_name}}</a>
                                                <span class="fs-7 text-gray-400 fw-bold">{{liabilityItem.liability_group_name}}</span>
                                                <span class="fs-7 text-gray-400 fw-bold">{{ previewOnlyDate(liabilityItem.period_start_date) }} - {{ previewOnlyDate(liabilityItem.period_end_date) }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </h3>
                                <div class="card-toolbar">
                                    <div class="d-flex flex-column mx-10">
                                        <div class="border border-gray-300 border-dashed rounded min-w-150px py-3 px-4">
                                            <div class="fs-6 text-gray-800 fw-bold text-center" v-if="liabilityItem.amount_type != 0">
                                                <span class="fs-3 badge badge-light-danger fs-base mt-n3"> {{liabilityItem.amount}} </span>
                                                <span class="fs-7 fw-semibold text-gray-400 me-1 mt-n1"> {{this.localCurrency}} </span>
                                            </div>
                                            <div class="fw-semibold text-gray-400 fs-7 text-center" v-if="liabilityItem.amount_type != 0">{{ $t('Amount') }}</div>
                                            <div class="fw-semibold text-gray-400 fs-5 text-center" v-if="liabilityItem.amount_type == 0">{{ $t('No Amount') }}</div>
                                        </div>
                                        <div class="fs-8 text-gray-400 mt-1 px-3">
                                            {{ $t('Starting Date') }}:  <b> {{previewOnlyDate(liabilityItem.at)}} </b>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body p-10 py-2">
                                <div class="row"> 
                                    <div class="col-lg-6">
                                        <span class="badge badge-sm" :class="'badge-' + liabilityStatusColor(liabilityItem.status)">{{liabilityStatusName(liabilityItem.status)}}</span>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="text-end text-muted"> 
                                            {{previewDate(liabilityItem.last_transaction_date)}} <span class="fs-8"> {{ $t('by') }} {{liabilityItem.last_transaction_by}} </span>

                                            <span class="ms-6 badge badge-secondary" v-if="liabilityItem.source == 1">{{ $t('Standard') }}</span>
                                            <span class="ms-6 badge badge-warning" v-if="liabilityItem.source == 2">{{ $t('Transaction') }}</span>
                                            <span class="ms-6 badge badge-info" v-if="liabilityItem.source == 3">{{ $t('User Manuel') }}</span>                                
                                        </div>
                                    </div>
                                    <div class="col-lg-12 border border-gray-300 border-dashed rounded bg-light mx-2 my-3 p-3" v-if="liabilityItem.last_transaction_description !== ''">
                                        <a href="#kt_liability_tab_2" @click="getLiabilityDescriptions(liabilityItem), liabilityItem.activeDetailTab = 'descriptions'"
                                            data-bs-toggle="collapse" :data-bs-target="'#kt_accordion_liability_drawer'+ index">
                                            {{liabilityItem.last_transaction_description}}
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div :id="'kt_accordion_liability_drawer'+ index" class="collapse">
                                <div class="card-body p-0">
                                  <ul class="nav nav-tabs nav-line-tabs fs-6 p-10 py-2">
                                      <li class="nav-item">
                                          <a class="nav-link text-gray-600" @click="liabilityItem.activeDetailTab = 'general'" :class="{'active': liabilityItem.activeDetailTab == 'general'}"
                                              data-bs-toggle="tab" href="#kt_liability_tab_1">{{ $t('General') }}</a>
                                      </li>
                                      <li class="nav-item">
                                          <a class="nav-link" @click="getLiabilityDescriptions(liabilityItem), liabilityItem.activeDetailTab = 'descriptions'" :class="{'active': liabilityItem.activeDetailTab == 'descriptions'}"
                                              data-bs-toggle="tab" href="#kt_liability_tab_3">{{ $t('Descriptions') }}</a>
                                      </li>
                                  </ul>
                                  <div class="card-body py-3 scroll mh-350px">
                                      <div class="tab-content" id="myTabContent">
                                          <div v-if="liabilityItem.activeDetailTab === 'general'" class="tab-pane fade show active" id="kt_liability_tab_1" role="tabpanel">                                
                                              <div class="row align-items-center mx-0 w-100">
                                                  <div class="col-lg-12 border border-gray-300 border-dashed rounded bg-light-primary py-3 px-4 me-7 mb-3">
                                                      <div class="row">
                                                          <li class="d-flex align-items-center py-2 pe-6">
                                                              <span class="bullet bullet-md me-2 bg-primary"></span>
                                                              <span class="me-3"> {{ $t('To be calculate on') }}: </span> {{previewOnlyDate(liabilityItem.at)}}
                                                          </li>
                                                          <li class="d-flex align-items-center py-2 pe-6">
                                                              <span class="bullet bullet-md me-2 bg-warning"></span>
                                                              <span class="me-3"> {{ $t('Company Number') }}: </span> {{liabilityItem.company_vkn}} 
                                                          </li>
                                                          <li class="d-flex align-items-center py-2 pe-6" v-if="liabilityItem.company_vat_number !== ''">
                                                              <span class="bullet bullet-md me-2 bg-success"></span> 
                                                              <span class="me-3"> {{ $t('VAT Number') }}: </span> {{liabilityItem.company_vat_number}}
                                                          </li>
                                                          <li class="d-flex align-items-center py-2 pe-6" v-if="liabilityItem.account_office_reference !== ''">
                                                              <span class="bullet bullet-md me-2 bg-info"></span> 
                                                              <span class="me-3"> {{ $t('Account Office Reference') }}: </span> {{liabilityItem.account_office_reference}}
                                                          </li>
                                                          <li class="d-flex align-items-center py-2 pe-6">
                                                              <span class="bullet bullet-md me-2 bg-danger"></span> 
                                                              <span class="me-3"> {{ $t('User') }}: </span> {{ $t('Test User') }}
                                                          </li>
                                                          <li class="d-flex align-items-center py-2 pe-6">
                                                              <span class="bullet bullet-md me-2 bg-secondary"></span> 
                                                              <span class="me-3"> {{ $t('Location') }}: </span> {{ $t('Controller') }}
                                                          </li>
                                                      </div>
                                                  </div>
                                                  <div class="col-lg-6 px-0">
                                                      <div class="d-flex flex-column content-justify-center">
                                                          <div class="border border-gray-300 border-dashed rounded bg-lighten scroll py-3 px-4 me-7 mb-3">
                                                              <div class="fw-semibold text-gray-600">{{ $t('Description Title') }}</div>
                                                              <div class="fw-semibold text-gray-600 min-h-50px mh-lg-50px">{{liabilityItem.description_title}}</div>
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div class="col-lg-6 px-0">
                                                      <div class="d-flex flex-column content-justify-center">
                                                          <div class="border border-gray-300 border-dashed rounded bg-lighten scroll py-3 px-4 me-7 mb-3">
                                                              <div class="fw-semibold text-gray-600">{{ $t('Payment Title') }}</div>
                                                              <div class="fw-semibold text-gray-600  min-h-50px mh-lg-50px">{{liabilityItem.payment_title}}</div>
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div class="col-lg-6 px-0">
                                                      <div class="d-flex flex-column content-justify-center">
                                                          <div class="border border-gray-300 border-dashed rounded bg-lighten py-3 px-4 me-7 mb-3">
                                                              <div class="fw-semibold text-gray-600">{{ $t('Description URL') }}</div>
                                                              <a class="min-h-25px mh-lg-25px fw-semibold" :href="liabilityItem.description_url" target="_blank">{{liabilityItem.description_url}}</a>
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div class="col-lg-6 px-0">
                                                      <div class="d-flex flex-column content-justify-center">
                                                          <div class="border border-gray-300 border-dashed rounded bg-lighten py-3 px-4 me-7 mb-3">
                                                              <div class="fw-semibold text-gray-600">{{ $t('Payment URL') }}</div>
                                                              <a class="min-h-25px mh-lg-25px fw-semibold" :href="liabilityItem.payment_url" target="_blank">{{liabilityItem.payment_url}}</a>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                          <div v-if="liabilityItem.activeDetailTab === 'descriptions'" class="tab-pane fade show active" id="kt_liability_tab_3" role="tabpanel">                                
                                              <div class="card-body scroll p-0 mh-300px rounded" v-if="liabilityItem.descriptions !== null && liabilityItem.descriptions !== '' && liabilityItem.descriptions !== undefined">
                                                  <div class="alert bg-light-primary d-flex flex-column flex-sm-row p-5 mb-10 mt-10" v-if="liabilityItem.descriptions.length == 0"> 
                                                      <span class="svg-icon svg-icon-2hx svg-icon-primary me-4 mb-5 mb-sm-0">
                                                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                          <path opacity="0.3" d="M14 2H6C4.89543 2 4 2.89543 4 4V20C4 21.1046 4.89543 22 6 22H18C19.1046 22 20 21.1046 20 20V8L14 2Z" fill="currentColor"></path>
                                                          <path d="M20 8L14 2V6C14 7.10457 14.8954 8 16 8H20Z" fill="currentColor"></path>
                                                          <rect x="13.6993" y="13.6656" width="4.42828" height="1.73089" rx="0.865447" transform="rotate(45 13.6993 13.6656)" fill="currentColor"></rect>
                                                          <path d="M15 12C15 14.2 13.2 16 11 16C8.8 16 7 14.2 7 12C7 9.8 8.8 8 11 8C13.2 8 15 9.8 15 12ZM11 9.6C9.68 9.6 8.6 10.68 8.6 12C8.6 13.32 9.68 14.4 11 14.4C12.32 14.4 13.4 13.32 13.4 12C13.4 10.68 12.32 9.6 11 9.6Z" fill="currentColor"></path>
                                                          </svg>
                                                      </span>
                                                      <div class="d-flex flex-column pe-0 pe-sm-10">
                                                          <h4 class="fw-semibold">{{ $t('No description added yet') }}.</h4>
                                                          <span>{{ $t('Please use Update button to add descriptions..!') }}</span>
                                                      </div> 
                                                  </div>
                                                  <div v-if="liabilityItem.descriptions.length > 0">
                                                      <DxDataGrid id="gridContainer" :show-borders="true" 
                                                              key-expr="id" 
                                                              :data-source="liabilityItem.descriptions" 
                                                              :allow-column-reordering="true"  
                                                              :allow-column-resizing="true" 
                                                              :column-auto-width="true" 
                                                              :row-alternatin-enabled="true"
                                                              :hover-state-enabled="true" 
                                                              :show-row-lines="true"
                                                              @row-prepared="onRowPrepared"> 

                                                          <DxFilterRow :visible="true"/>
                                                          <DxHeaderFilter :visible="true"/>
                                                          <DxGroupPanel :visible="true"/>
                                                          <DxSearchPanel :visible="true"/>  
                                                          <DxScrolling
                                                              mode="virtual"
                                                              row-rendering-mode="virtual"
                                                          />
                                                          <DxColumn data-field="last_transaction_date" :width="80" caption="Transaction Date" data-type="date" alignment="right" format="dd.MM.yyyy HH:mm" /> 
                                                          <DxColumn data-field="description" caption="Description" /> 
                                                          <DxColumn data-field="status" caption="Status" cell-template="status-cell-template" /> 
                                                          <DxColumn data-field="last_transaction_by" caption="User"/>

                                                          <template #status-cell-template="{ data: data }">
                                                              <span class="align-middle text-center">                                                    
                                                                  <span class="badge badge-sm" :class="'badge-' + liabilityStatusColor(data.data.status)">{{liabilityStatusName(data.data.status)}}</span>
                                                              </span>
                                                          </template>
                                                      </DxDataGrid>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-cloak class="py-3" v-if="this.liabilities.length == 0">
                        <div class="card shadow-sm">
                            <div class="card-body d-flex align-items-end">
                              <span class="fs-6"> {{ $t('No liabilities found...') }} </span>
                            </div>
                        </div>
                    </div>
                  </div>
              </div>
            </div>
        </div>
    </div>

    <!-- Rates -->
    <div id="kt_rate_drawer" class="drawer drawer-end drawer-on animated fadeInLeft" v-if="isRateDrawerOpen" 
      style="border-left: 3px dashed; border-color: #E4E6EF; box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 20px 20px rgba(0,0,0,0.33);">
        <div class="card border-0">
            <div class="card-header align-items-center border-0 mt-4">
                <h3 class="card-title align-items-start flex-column">
                    <span class="fw-bolder mb-2 text-gray-800">{{typeSelectionName}} ({{userData.currency_code}}) </span>
                </h3> 
                <div class="card-toolbar">
                  <button type="button" class="btn btn-sm btn-icon btn-active-light-primary me-n5" id="kt_rate_drawer_close" @click="this.closeTimeLineDrawer()">
                      <span class="svg-icon svg-icon-1">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                              <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                  transform="rotate(-45 6 17.3137)" fill="currentColor" />
                              <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)"
                                  fill="currentColor" />
                          </svg>
                      </span>
                  </button>
                </div>
            </div>
            <div class="card-body p-3">
              <div class="table-responsive">
                  <table class="table table-rounded gy-4 gs-4">
                    <thead>
                        <tr class="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0"> 
                            <th>{{ $t('Currency') }}</th>
                            <th></th>
                            <th class="text-end">{{ $t('Rate') }}</th>
                            <th class="text-end">{{ $t('Value') }}</th> 
                            <th class="text-end">{{ $t('Percent') }}</th> 
                            <th>{{ $t('Date') }}</th>
                        </tr>
                    </thead>
                    <tbody class="fw-bold text-gray-600" v-if="this.rateList"> 
                      <tr class="border-bottom border-gray-300" v-for="item in rateList" v-bind:key="item" 
                        :class="{'bg-light': item.is_priority && item.change_percent == '0', 'bg-light-success': item.is_priority && item.change_percent > '0', 'bg-light-danger': item.is_priority && item.change_percent < '0'}">
                        <td>
                          <span class="text-gray-800 mb-1">
                            {{ item.currency }}
                          </span>
                        </td>
                        <td>
                          <i class="bi bi-dash-lg fs-5 ms-n1" v-if="item.change_percent == '0'"></i> 
                          <i class="bi bi-arrow-up fs-5 text-success ms-n1" v-if="item.change_percent > '0'"></i> 
                          <i class="bi bi-arrow-down fs-5 text-danger ms-n1" v-if="item.change_percent < '0'"></i> 
                        </td>
                        <td class="text-end">
                          <span class="text-gray-800 mb-1">
                            {{ item.rate }}
                          </span>
                        </td>
                        <td class="text-end">
                          <span class="mb-1" :class="{'text-success': item.change_value > '0', 'text-danger': item.change_value < '0'}">
                              {{ item.change_value }}
                          </span>
                        </td>  
                        <td class="text-end">
                          <span class="badge badge-light fs-base" v-if="item.change_percent == '0'">
                              {{ item.change_percent }} %
                          </span>
                          <span class="badge badge-light-success fs-base" v-if="item.change_percent > '0'">
                              {{ item.change_percent }} %
                          </span>
                          <span class="badge badge-light-danger fs-base" v-if="item.change_percent < '0'">
                              {{ item.change_percent }} %
                          </span>
                        </td>  
                        <td>
                          <span class="text-gray-800 mb-1">
                            {{ previewDate(item.created_at) }}
                          </span>
                        </td>
                      </tr> 
                    </tbody>
                  </table>
              </div>
          </div>
        </div>
    </div>
  </div>
</template> 
<style scoped>
.timeline-label:before {
    left: 126px !important;
}
</style>  
<script> 
import axios from 'axios' 
// import Swal from 'sweetalert2';
import moment from 'moment';
import { DxDataGrid, DxFilterRow, DxHeaderFilter, DxColumn, DxSearchPanel, DxScrolling } from 'devextreme-vue/data-grid';
 
export default {
  name: 'Home',
  components: {
    DxDataGrid, DxFilterRow, DxHeaderFilter, DxColumn, DxSearchPanel, DxScrolling
  },
  data() {
    return {
      form: {
        period_id: '-1',
        liability_group_id: '-1',
        start_date: moment().startOf('month'),
        end_date: moment().endOf('month'),
        status: -2,
        periodSelection: {
          start_date: null,
          end_date: null,
        }
      },
      userData: this.$getUser(),
      tasks: [],
      liabilities: [],
      totals: {},
      page: 1,
      page_size: 5,
      rate_page_size: 50,
      isTasksDrawerOpen: false,
      isLiabilityDrawerOpen: false,
      isRateDrawerOpen: false,
      activeView: 'list',
      activeDetailTab: 'general',
      statusList: [
          {id: -2, text: 'Not Completed', color: 'secondary'},
          {id: -1, text: 'All Status', color: 'secondary'},
          {id: 0, text: 'Awaiting', color: 'secondary'},
          {id: 1, text: 'Pending', color: 'warning'},
          {id: 2, text: 'Calculated', color: 'info'},
          {id: 3, text: 'Paid', color: 'primary'},
          {id: 4, text: 'Not Paid', color: 'light-danger'},
          {id: 5, text: 'Completed', color: 'success'},
          {id: 6, text: 'Hidden', color: 'dark'},
          {id: 7, text: 'Canceled', color: 'danger'},
      ],
      liabilityTransactionList: [],
      typeSelection: null,
      typeSelectionName: null,
      typeList: [],
      rateList: [],
      isListProgressing: false
    };
  },
  props: {msg: String},
  computed:{
    searchableList() { 
        return this.tasks.slice((this.page - 1) * this.page_size, this.page * this.page_size);
    },
    previewDate(){
      return (value)=>{
       if (value) {
          return moment(new Date(value)).format('DD.MM.YYYY HH:mm')
        }
      }
    },
    previewOnlyDate(){
      return (value)=>{
       if (value) {
          return moment(new Date(value)).format('DD.MM.YYYY')
        }
      }
    },
    liabilityStatusName(){
      return (value)=>{
       if (value !== null && value !== '' && value !== undefined) {
          return this.statusList.find(element => {
            return element.id == value
          }).text;
        }
      }
    },
    liabilityStatusColor(){
      return (value)=>{
       if (value !== null && value !== '' && value !== undefined) {
          return this.statusList.find(element => {
            return element.id == value
          }).color;
        }
      }
    },
    searchableRateList() {
      return this.rateList.slice((this.page - 1) * this.rate_page_size, this.page * this.rate_page_size);
    },
  },
  methods:{
    getLiabilities() {
      const parameters = {
        session_id: this.$getUser().session_id,
        period_id: this.form.period_id,
        liability_group_id: this.form.liability_group_id,
        start_date: moment(this.form.start_date).format('YYYY-MM-DD[T]HH:mm:ss'),
        end_date: moment(this.form.end_date).format('YYYY-MM-DD[T]HH:mm:ss'),
        source: -1,
        amount_type: -1,
        status: -1
      }        
      const requestOne =  axios.post('/api/Liabilities/Get', parameters, {'Content-Type': 'application/json'});        
      axios.all([requestOne]).then(axios.spread((...responses) => {           
        this.liabilities = responses[0].data.liabilities;
        this.summary = responses[0].data.summary;
        this.totals = responses[0].data.totals;
      }))
      .catch(errors => { 
        console.error(errors); 
      });
    },
    onChangeDateCriteria(type) {
      /* On This Month */
      if(type == 2) {
        this.form.start_date = moment().startOf('month');
        this.form.end_date = moment().endOf('month');
      }
      /* Next 1 Month */
      if(type == 3) { 
        this.form.start_date = moment().add(1, 'months').startOf('month');
        this.form.end_date = moment().add(2, 'months').endOf('month');
      }
      this.getLiabilities();
    },    
    getLiabilityDocuments(liabilityItem){
      this.requestedFile = null;
      const parameters = { 
        session_id: this.$getUser().session_id,
        folder_id: this.activeFolder.id,
        liability_id: liabilityItem.id,
      }; 
      axios.post('/api/Liabilities/GetLiabilityDocuments', parameters, {'Content-Type': 'application/json'})
      .then((response) => {
          if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){  
            liabilityItem.documents = response.data;
          }
        }) 
      .catch(function (error) {
        console.log(error);
        this.Progress.finish();
      });
    },
    getLiabilityDescriptions(liabilityItem){
      const parameters = {
        session_id: this.$getUser().session_id,
        liability_id: liabilityItem.id,
        is_have_description: 1
      };
      axios.post('/api/Liabilities/GetLiabilityTransactions', parameters, {'Content-Type': 'application/json'})
      .then((response) => {
        if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){  
          liabilityItem.descriptions = response.data;
        }
      })
      .catch(function (error) {
        console.log(error); 
      });
    },
    getRateTypes() {
      const params = {
        session_id: this.$getUser().session_id
      };
      axios.post('/api/Rate/RateTypes', params, {'Content-Type': 'application/json'})
      .then((typeResponse) => {
        this.typeList = typeResponse.data;
        this.typeList.forEach((item) => { 
          if(item.is_default == 1){
            this.typeSelection = item.id;
            this.typeSelectionName = item.title;
          }
        });  
        this.getRates();
      })
      .catch(function (error) {
        console.log(error); 
      }); 
    },
    getRates() {
      const params = {
        session_id: this.$getUser().session_id,
        rate_date: moment(),
        bank_id: 0,
        currency: null
      };
      var source = this.typeList.find((element) => element.id == this.typeSelection);
      params.bank_id = source.type; // 1: European Bank, 2: HMRC, 3: Live Rate
      axios.post('/api/Rate/GetRates', params, {'Content-Type': 'application/json'})
      .then((response) => {
        this.rateList = response.data;
        this.page = 1;
        this.$Progress.finish();
        setTimeout(() => {
            this.isListProgressing = false;
        }, 500);
      })
      .catch(function (error) {
          console.log(error);
          this.$Progress.finish();
          setTimeout(() => {
              this.isListProgressing = false;
          }, 500);
      });  
    },
    openTimeLineDrawer(type){
      if (type == 'liabilities') {
        this.isLiabilityDrawerOpen = true;
        this.isTasksDrawerOpen = false;
        this.isRateDrawerOpen = false;
        this.getLiabilities();
      }
      if (type == 'rates') {
        this.isRateDrawerOpen = true;
        this.isLiabilityDrawerOpen = false;
        this.isTasksDrawerOpen = false;
        this.getRateTypes();
      }
    },
    closeTimeLineDrawer(){
      this.isTasksDrawerOpen = false;
      this.isLiabilityDrawerOpen = false;
      this.isRateDrawerOpen = false;
    }
  },
  mounted() {  
  }
}
</script> 