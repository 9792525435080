<template>
	<div>
		<div class="d-flex flex-column flex-lg-row flex-column-fluid">
			<div class="d-flex w-50">
				<div class="d-flex flex-column flex-center pb-0 pb-lg-10 p-10 w-100"> 
					<img class="theme-light-show mx-auto mw-100 w-150px w-lg-300px mb-10 mb-lg-20" src="/assets/theme/media/illustrations/unitedpalms-1/15.png" alt="">
					<img class="theme-dark-show mx-auto mw-100 w-150px w-lg-300px mb-10 mb-lg-20" src="/assets/theme/media/illustrations/unitedpalms-1/15-dark.png" alt="">

					<h1 class="mb-5 text-center"> 
						Your Business Habitat
					</h1>  
					<h3 class="mb-5"> 
						Strong and Reliable
					</h3>  

					<div class="text-gray-600 fs-base text-center fw-semibold mb-10">
						We will be stronger with you. We will be stronger with your membership and your ideas. We do not ask for any fees or card information as long as you use our systems to try out.
					</div>
					<div class="text-gray-500 text-center fw-semibold fs-6">
						Already have an account?
						<router-link to="/Login">
							<a href="#" class="link-primary fw-semibold ps-2">
									Sign in
							</a>
						</router-link>
					</div>
					<div class="text-muted mt-3">
						<span class="text-muted fw-bold me-1">CreatorCube</span> 
						<div class="bullet bg-secondary h-15px w-1px mx-5"></div>
						<a href="https://polisoft.co.uk" target="_blank" class="text-muted text-hover-primary">
							by polisoft
						</a>
					</div> 
				</div>
			</div>

			<div class="d-flex w-100 p-12">
				<div class="bg-body d-flex flex-column flex-center rounded-4 w-100 p-10">
					<!-- Step 1: Mail verification -->
					<div class="d-flex flex-center flex-column align-items-stretch h-lg-100 w-md-500px" v-if="memberStatus == -1">
						<div class="d-flex flex-center flex-column flex-column-fluid pb-15 pb-lg-20 pt-15">
							<form class="form w-100 fv-plugins-bootstrap5 fv-plugins-framework" @submit.prevent="onBeforeSubmit">
								<div class="text-center mb-11">
									<h1 class="fw-bolder mb-3">
										We will be stronger with you
									</h1>
									<div class="text-gray-500 fw-semibold fs-6 mb-5">
										First, you must Sign Up for our Basic product's Trial Version. Then, If you wish, you can purchase products you prefer during the trial period with safe payment methods.
									</div>
									<div class="text-gray-500 fw-semibold fs-6">
										To create a new account, you must enter a valid email address. We will use this email address to manage your account and contact you.
									</div>
								</div>
								<div class="fv-row row mb-8">
									<div class="col-lg-12">
										<input type="email" class="form-control bg-transparent"
										placeholder="Email" name="email" v-model="form.user_mail" :class="{'border border-success': isEmailConfirm == true, 'border border-danger': isValidEmail(form.user_mail) == false}">
										<small class="text-danger" v-if="!isValidEmail(form.user_mail) && form.user_mail !== null && form.user_mail !== '' ">Email is not valid</small>
									</div>
									<div class="col-lg-12">
										<input type="email" class="form-control bg-transparent mt-5"  placeholder="Confirm Email" name="confirm_email" v-model="form.confirm_email" @paste.prevent
										:class="{'border border-success': isEmailConfirm == true, 'border border-danger': isEmailConfirm == false}">
									</div>
								</div>
								
								<div class="fv-row mb-1 text-center text-gray-500 fw-semibold fs-7">
									In the next step, we will ask you to verify your email address.
								</div>
								<div class="d-grid mb-10">
									<button type="button" class="btn btn-primary" @click="onBeforeSubmit()" data-kt-indicator="on" :disabled="isEmailConfirm !== true || isValidEmail(form.user_mail) == false">
										<span v-if="!this.isProgressing">Sign Up</span>
										<span class="indicator-progress" v-if="this.isProgressing">
											Please wait...
											<span class="spinner-border spinner-border-sm align-middle ms-2"></span>
										</span>
									</button>
								</div>
								<div class="text-gray-500 text-center fw-semibold fs-6">
									Already have an Account?
									<router-link to="/Login">
										<a href="#" class="link-primary fw-semibold">
											Sign in
										</a>
									</router-link>
								</div>
							</form>
						</div>
					</div>
					<div class="d-flex flex-center flex-column align-items-stretch h-lg-100 w-md-400px" v-if="memberStatus == 0">
						<div class="d-flex flex-center flex-column flex-column-fluid pb-15 pb-lg-20 pt-15">
							<form class="form w-100 fv-plugins-bootstrap5 fv-plugins-framework" @submit.prevent="confirmCode">
								<div class="text-center mb-10">
									<h1 class="mb-3">
										Two-Factor Verification
									</h1> 
 
									<div class="text-muted fw-semibold fs-5 mb-3">Enter the verification code we sent to</div> 
									<div class="fw-bold text-primary fs-5"> {{form.user_mail}} </div>
								</div>
								<div class="fw-bold text-start fs-6 mb-1 ms-1">Type your 6 digit security code</div>
								<div class="mb-10" style="display: flex; flex-direction: row">
									<v-otp-input
										ref="otpInput"
										separator=""
										v-model="form.code"
										input-classes="otp-input form-control bg-transparent h-60px w-60px fs-1 text-center mx-1 my-2"
										:num-inputs="6"
										:should-auto-focus="true"
										input-type="numeric"
										@on-change="handleOnChange"
										@on-complete="handleOnComplete"
									/>
								</div>
								<div class="fv-row mb-1 text-center text-gray-500 fw-semibold fs-7">
									In the next step, we will ask your account details to set up account.
								</div>
								<div class="d-grid mb-10">
									<button type="button" class="btn btn-primary" @click="confirmCode()" data-kt-indicator="on"
										:disabled="form.code == null || form.code == '' || form.code == undefined || form.code.length < 6">
										<span v-if="!this.isProgressing">Confirm</span>
										<span class="indicator-progress" v-if="this.isProgressing">
											Please wait...
											<span class="spinner-border spinner-border-sm align-middle ms-2"></span>
										</span>
									</button>
									<button type="button" class="btn btn-success mt-3" @click="sendMailAgain()" data-kt-indicator="on"
										:disabled="isCountDownActive">
										<span class="me-2" v-if="!this.isSendMailProgressing">Send Again</span>
										<span id="interval_timer_text" ref="timerCountdown"> (03:00) </span>
										<span class="indicator-progress" v-if="this.isSendMailProgressing">
											Please wait...
											<span class="spinner-border spinner-border-sm align-middle ms-2"></span>
										</span>
									</button>
								</div>
							</form>
						</div>
					</div>
					<div class="d-flex flex-center flex-column flex-column-fluid pb-15 pb-lg-20 mt-20 p-20" v-if="memberStatus == 1">
						<h1 class="fw-bolder text-gray-800 mb-5">
							This e-mail address is already registered
						</h1>
						<div class="fw-semibold fs-6 text-gray-500 mb-8 text-center p-5">
							You can log in with this e-mail address by clicking the "Sign In" button.
							<br>
							If you have forgotten your password, you can reset your password by clicking the "Forgot Password?" link.
						</div>
						<div class="mb-11">
							<router-link to="/Login">
								<a href="#" class="btn btn-sm btn-primary me-5">
									Sign In
								</a>
							</router-link>

							<router-link to="/ForgotPassword">
								<a href="#" class="btn btn-sm btn-info">
									Forgot Password
								</a>
							</router-link>
						</div>
					</div>

					<!-- Step 2: User and Company Informations -->
					<div class="d-flex flex-center flex-column flex-column-fluid pb-15 pb-lg-20">
						<div class="stepper stepper-links d-flex flex-column" data-kt-stepper="true" v-if="activeStep > 0">
							<div class="stepper-nav justify-content-center py-2">
								<div class="stepper-item mx-10 me-md-20" data-kt-stepper-element="nav">
									<h3 class="stepper-title"><i class="bi bi bi-envelope-check fs-2qx" :class="{'text-primary': this.activeStep == 1, 'text-secondary': this.activeStep !== 1}"></i></h3>
								</div>
								<div class="stepper-item mx-10 me-md-20" data-kt-stepper-element="nav">
									<h3 class="stepper-title"><i class="bi bi-person-fill fs-2qx" :class="{'text-info': this.activeStep == 2, 'text-secondary': this.activeStep !== 2}"></i></h3>
								</div>
								<div class="stepper-item mx-10 me-md-20" data-kt-stepper-element="nav">
									<h3 class="stepper-title"><i class="bi bi-briefcase-fill fs-2qx" :class="{'text-dark': this.activeStep == 3, 'text-secondary': this.activeStep !== 3}"></i></h3>
								</div>
								<div class="stepper-item mx-10 me-md-20" data-kt-stepper-element="nav">
									<h3 class="stepper-title"><i class="bi bi-pin-map-fill fs-2qx" :class="{'text-warning': this.activeStep == 4, 'text-secondary': this.activeStep !== 4}"></i></h3>
								</div>
								<div class="stepper-item mx-10 me-md-20" data-kt-stepper-element="nav">
									<h3 class="stepper-title"><i class="bi bi-question-circle fs-2qx" :class="{'text-danger': this.activeStep == 5, 'text-secondary': this.activeStep !== 5}"></i></h3>
								</div>
								<div class="stepper-item mx-10 me-md-20" data-kt-stepper-element="nav">
									<h3 class="stepper-title"><i class="bi bi-patch-check-fill fs-2qx" :class="{'text-success': this.activeStep == 6, 'text-secondary': this.activeStep !== 6}"></i></h3>
								</div>
							</div>
							<div class="separator"></div>
							<form class="row mw-800px pt-10 pb-10 fv-plugins-bootstrap5 fv-plugins-framework" novalidate="novalidate" @submit.prevent="">
								<div :class="{'current': this.activeStep == 1}" class="row" v-if="this.activeStep == 1">
									<div class="row">
										<div class="fv-row row">
											<div class="col-lg-12 p-0 mb-5">
												<label class="required form-label mt-3">Country</label>
												<Select2 v-model="form.company_country_code" :options="countryList" :settings="{ width: '100%' }" @select="onChangeCountry($event)"/>
											</div>
										</div>
										<div class="fv-row row" v-if="form.company_country_code !== '-1' && form.company_country_code !== '' && form.company_country_code !== null">
											<div class="card card-bordered mb-5 p-0" v-for="(item, index) in aggrementContents" v-bind:key="item">
												<div class="card-header ps-3 min-h-30px">
													<div class="card-title"> 
														<h6>{{item.name}}</h6> 
														<small class="ms-3">({{previewDate(item.release_date)}})</small>
													</div>
												</div>
												<div class="card-body card-scroll bg-lighten h-250px p-3">
													<div ref="documentToDownload" v-html="item.pages_content_content"></div>
												</div>
												<div class="card-footer p-2">
													<input class="form-check-input fs-6 my-0 fw-bold form-label me-3" :class="{'border border-danger': item.is_required == 1 && !form[item.modelName]}"
														type="checkbox" v-model="form[item.modelName]">
													<span @click="form[item.modelName] = !form[item.modelName]" class="fw-bold cursor-pointer" :class="{'text-danger': item.is_required == 1 && !form[item.modelName]}"> 
														I have read and accepted the {{item.name}} text.
													</span>
													<button type="button" class="btn btn-xs btn-secondary float-end" @click="exportToPDF(item, index)">
														<i class="bi bi-download pe-0"></i>
													</button>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div :class="{'current': this.activeStep == 2}" class="row" v-if="this.activeStep == 2">
									<div class="row">
										<div class="pb-10 pb-lg-15">
											<h2 class="fw-bold d-flex align-items-center">Start to Create Company Account
											<!-- <i class="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" aria-label="Company name will be used as reference within your campaign reports" data-kt-initialized="1"></i> -->
											</h2>
											<!-- <div class="text-muted fw-semibold fs-6">If you need more info, please check out 
												<a href="" class="link-primary fw-bold">Help Page</a>.
											</div> -->
										</div>
										<div class="fv-row row"> 
											<div class="col-lg-12">
                                                <label class="form-label">
													What are your pronouns? <br>
													<small>This helps us understand the best way to address you.</small>	
												</label> 
												<Select2 v-model="form.gender" :options="genderList" :settings="{ width: '100%' }"/>
                                            </div>
											<div class="col-lg-12">
												<label class="required form-label mt-3">Name</label>
												<input type="text" class="form-control form-control-lg" name="name_surname" v-model="form.name_surname">
											</div>
											<div class="col-lg-12">
												<label class="required form-label mt-3">Mobile</label>
												<div class="input-group">
													<span class="input-group-text fs-7 text-primary" id="basic-addon1">
														{{form.company_country_code}} {{form.phone_country_code}}
													</span>
													<input type="text" class="form-control form-control-lg" name="phone" v-model="form.phone">
												</div>
											</div>
										</div>
									</div>
								</div>
								<div :class="{'current': this.activeStep == 3}" class="row" v-if="this.activeStep == 3">
									<div class="row">
										<form class="form w-100 fv-plugins-bootstrap5 fv-plugins-framework" @submit.prevent="">
											<div class="pb-10 pb-lg-15">
												<h2 class="fw-bold d-flex align-items-center">Setup Company Details
													<!-- <i class="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" aria-label="Company name will be used as reference within your campaign reports" data-kt-initialized="1"></i> -->
												</h2>
												<!-- <div class="text-muted fw-semibold fs-6">If you need more info, please check out 
													<a href="" class="link-primary fw-bold">Help Page</a>.
												</div> -->
											</div>
											<div class="fv-row row">
												<div class="col-lg-12">
													<label class="required form-label mt-3">Member Type</label>
													<Select2 v-model="form.company_member_type" :options="companyMemberTypeList" :settings="{ width: '100%' }"/>
												</div>
												<div class="col-lg-12">
													<label class="required form-label mt-3">Company/Account Name</label>
													<div class="input-group">
														<input type="text" class="form-control"
															name="name" v-model="form.company_name">
														<button type="button" v-if="form.company_member_type == 1"
																class="input-group-text m-0 text-hover-success cursor-pointer" 
																id="basic-addon2" 
																@click="onSearchCompanyByName()" 
																data-bs-toggle="modal" 
																data-bs-target="#kt_modal_search_company" 
																data-kt-menu-trigger="click" 
																data-kt-menu-placement="bottom-end"
																:disabled="form.company_name == null || form.company_name == ''">
															<i class="fas fa-search fs-4 me-3"></i> Search by Name
														</button>
													</div>
												</div>   
												<div class="col-lg-12" v-if="form.company_member_type == 1">
													<label class="form-label mt-3">Company/Account Number</label>
													<div class="input-group">
														<input type="text" class="form-control form-control-lg required" name="registration_number" v-model="form.company_vkn">
														<button type="button" class="input-group-text m-0 cursor-pointer text-hover-primary" id="basic-addon2" data-kt-indicator="on"
															@click="onSearchCompanyByNo()" :disabled="form.company_vkn == null || form.company_vkn == ''">
															<span v-if="!this.isCompanyInfoProgressing">
																<i class="fas fa-search fs-4 me-3"></i> Search by No
															</span>
															<span class="indicator-progress" v-if="this.isCompanyInfoProgressing">Please wait... 
																<span class="spinner-border spinner-border-sm align-middle ms-2"></span>
															</span>
														</button>
													</div>
												</div>
												<div class="col-lg-12">
													<label class="required form-label mt-3">Primary Person</label>
													<input type="text" class="form-control form-control-lg" name="primary_person" v-model="form.primary_person">
												</div>
											</div>
										</form>
									</div>
								</div>
								<div :class="{'current': this.activeStep == 4}" class="row" v-if="this.activeStep == 4">
									<div class="row">
										<div class="pb-10 pb-lg-15">
											<h2 class="fw-bold d-flex align-items-center">Address Informations
												<!-- <i class="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" aria-label="Company name will be used as reference within your campaign reports" data-kt-initialized="1"></i> -->
											</h2>
											<!-- <div class="text-muted fw-semibold fs-6">If you need more info, please check out 
												<a href="" class="link-primary fw-bold">Help Page</a>.
											</div> -->
										</div>
										<div class="fv-row row">											
                                            <GetAddress @address-selected="onSelectAddress" :is-new-company="true" :controller-id="this.ControllerId" v-if="AddressInquirySubType == 2"/>
											<div class="col-lg-6">
												<label class="form-label required mt-3">Post Code</label>
												<div class="input-group">
													<input type="text" class="form-control" name="post_code" v-model="form.company_postcode">
													<button v-if="AddressInquirySubType == 1" type="button" class="input-group-text m-0 cursor-pointer text-hover-info" 
														id="basic-addon2" data-kt-indicator="on" @click="this.onFindAddressByPostCode()"
														:disabled="this.form.company_postcode == null || this.form.company_postcode == ''">
														<span v-if="!isSearchingByPostCode"><i class="fas fa-search fs-4 me-3"></i> {{ $t('Search') }}</span>
														<span v-if="isSearchingByPostCode">{{ $t('Searching...') }}</span> 
													</button>
												</div>
											</div>
											<div class="col-lg-6">
												<div class="fv-row">
													<label class="fs-6 fw-bold form-label mt-3" v-tag="'address_code'">{{ $t('Address Code') }}</label>
													<div class="input-group">
														<input type="text" class="form-control" name="address_code" v-model="form.uprn" v-custom>
														<button type="button" v-if="AddressInquirySubType == 1" class="input-group-text m-0 cursor-pointer text-hover-info" 
															id="basic-addon2" data-kt-indicator="on" @click="this.onFindAddressByAddressCode()"
															:disabled="this.form.uprn == null || this.form.uprn == ''">
															<span v-if="!isSearchingByAddressCode"><i class="fas fa-search fs-4 me-3"></i> {{ $t('Search') }}</span>
															<span v-if="isSearchingByAddressCode">{{ $t('Searching...') }}</span> 
														</button>
													</div>
												</div>
											</div>
											<div class="col-lg-12" v-if="AddressInquirySubType == 1">
												<div class="fv-row">
													<label class="fs-6 fw-bold form-label mt-3" v-tag="'address'">{{ $t('Select Address') }}</label>
													<Select2 v-model="form.address_id" :options="addressList" name="address" v-custom @select="onSelectAddressSearchResult($event)" 
													:settings="{ width: '100%'}" :disabled="addressList.length == 0"/>
												</div>
											</div>
											<div class="col-lg-12">
												<label class="form-label required mt-3">Address</label>
												<textarea rows="3" type="text" class="form-control form-control-lg" name="company_address" v-model="form.company_address"> </textarea>
											</div>                     
											<div class="col-lg-12">
												<label class="form-label required mt-3">City</label>
												<input type="text" class="form-control form-control-lg" name="company_city" v-model="form.company_city">
											</div>
											<div class="col-lg-12">
												<label class="form-label mt-3">District</label>
												<input type="text" class="form-control form-control-lg" name="company_district" v-model="form.company_district">
											</div>
										</div>
									</div>
								</div>
								<div :class="{'current': this.activeStep == 5}" class="row" v-if="this.activeStep == 5">
									<div class="row">										
										<div class="text-center">
											<h1 class="fw-bolder text-gray-800 mb-5">
												We are about to create your account.
											</h1>
											<div class="fs-6 mb-5">
												<span class="fw-semibold text-gray-500 mb-5">
													Please make sure that all the information you have entered is correct. In the next step, we will send a password setup link to your email address. Your account will be activated when you set your password with this link.
												</span>
											</div>
											<div class="mb-11">
												<img src="/assets/theme/media/illustrations/sigma-1/20.png" class="mw-100 mh-200px theme-light-show" alt="">
												<img src="/assets/theme/media/illustrations/sigma-1/20-dark.png" class="mw-100 mh-200px theme-dark-show" alt="">
											</div>
										</div>
									</div>
								</div>
								<div :class="{'current': this.activeStep == 6}" class="row" v-if="this.activeStep == 6">
									<div class="row">										
										<div class="text-center">
											<h1 class="fw-bolder text-gray-800 mb-5">
												Your membership has been created.
											</h1>
											<div class="fs-6 mb-5">
												<span class="fw-semibold text-gray-500 mb-5">To verify your account, please click the verify link that we sent to your e-mail address.</span>
											</div>
											<div class="mb-11">
												<img src="/assets/theme/media/illustrations/unitedpalms-1/16.png" class="mw-100 mh-200px theme-light-show" alt="">
												<img src="/assets/theme/media/illustrations/unitedpalms-1/16-dark.png" class="mw-100 mh-200px theme-dark-show" alt="">
											</div>
											<div class="mb-5">
												<router-link to="/Login">
													<button type="button" class="btn btn-sm btn-primary">
														Login
													</button>
												</router-link>
											</div>
											<div class="fs-6">
												<span class="fw-semibold text-gray-500">Didn’t receive an email? <br> Please check your spam folder or</span>

												<a href="#" class="link-primary fw-bold" @click="sendActivationLink(true)"> send mail again</a>
											</div>
										</div>
									</div>
								</div>
								<div class="d-flex flex-stack pt-10">
									<div class="me-2">
										<button type="button" class="btn btn-lg btn-light-primary me-3" v-if="this.activeStep !== 1 && this.activeStep !== 6" @click="onBackStep()">
											<span class="svg-icon svg-icon-3 me-1">
												<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
													<rect opacity="0.5" x="6" y="11" width="13" height="2" rx="1" fill="currentColor"></rect>
													<path d="M8.56569 11.4343L12.75 7.25C13.1642 6.83579 13.1642 6.16421 12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75L5.70711 11.2929C5.31658 11.6834 5.31658 12.3166 5.70711 12.7071L11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25C13.1642 17.8358 13.1642 17.1642 12.75 16.75L8.56569 12.5657C8.25327 12.2533 8.25327 11.7467 8.56569 11.4343Z" fill="currentColor"></path>
												</svg>
											</span>
											Back
										</button>
									</div>
									<div>
										<button type="button" class="btn btn-lg btn-primary" v-if="this.activeStep !== 5 && this.activeStep !== 6" 
											@click="onNextStep()" :disabled="isNextButtonDisabled" data-kt-indicator="on">
											<span v-if="!this.isProgressing"> <span v-if="activeStep == 1"> Confirm and </span> Continue </span>
											<span class="svg-icon svg-icon-3 ms-1 me-0">
												<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
													<rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1" transform="rotate(-180 18 13)" fill="currentColor"></rect>
													<path d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z" fill="currentColor"></path>
												</svg>
											</span>
											<span class="indicator-progress" v-if="this.isProgressing">Please wait... 
												<span class="spinner-border spinner-border-sm align-middle ms-2"></span>
											</span>
										</button>
										<button type="button" class="btn btn-lg btn-success" v-if="this.activeStep == 5" @click="createMember()" data-kt-indicator="on" :disabled="isNextButtonDisabled">
											<span v-if="!this.isProgressing">Submit 
											<span class="svg-icon svg-icon-3 ms-2 me-0">
												<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
													<rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1" transform="rotate(-180 18 13)" fill="currentColor"></rect>
													<path d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z" fill="currentColor"></path>
												</svg>
											</span>
											</span>
											<span class="indicator-progress" v-if="this.isProgressing">Please wait... 
												<span class="spinner-border spinner-border-sm align-middle ms-2"></span>
											</span>
										</button>
									</div>
								</div>
							</form>
						</div>
					</div>
	
					<div class=" d-flex flex-stack">
						<!-- <div class="me-10">             
							<button class="btn btn-flex btn-link btn-color-gray-700 btn-active-color-primary rotate fs-base" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-start" data-kt-menu-offset="0px, 0px">								
								<span data-kt-element="current-lang-name" class="me-1">English</span>

								<i class="ki-duotone ki-down fs-5 text-muted rotate-180 m-0"></i>
							</button>
							<div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-4 fs-7" data-kt-menu="true" id="kt_auth_lang_menu">
								<div class="menu-item px-3">
									<a href="#" class="menu-link d-flex px-5" data-kt-lang="English">
										<span class="symbol symbol-20px me-4">
										</span>
										<span data-kt-element="lang-name">English</span>
									</a>
								</div>
							</div>           
						</div>  -->

						<div class="d-flex fw-semibold text-primary fs-base gap-5">
							<!-- <a href="#" target="_blank">Terms</a>

							<a href="#l" target="_blank">Plans</a> -->
							
							<a href="#" target="_blank" data-bs-toggle="modal" data-bs-target="#contactusmodal" @click="clearContactUsForm();"> Contact Us </a>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- CONTACT US MODAL -->
		<div class="modal fade" tabindex="-1" id="contactusmodal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel" aria-hidden="true" >
          <div class="modal-dialog modal-md modal-dialog-scrollable">
              <div class="modal-content">
                  <div class="modal-header">
                        <h5 class="modal-title">Contact Us</h5>                         
                        <div class="btn btn-icon btn-sm btn-active-icon-primary"
                            data-bs-dismiss="modal" aria-label="Close" ref="closeContactModal">
                            <span class="svg-icon svg-icon-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none">
                                    <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                        transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                    <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                        transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                                </svg>
                            </span>
                        </div>
                  </div>  
                  <div class="modal-body">
					<div class="col-lg-12">
						<input type="text" class="form-control form-control-lg" placeholder="Your name" v-model="contactUsform.name">
					</div>
					<div class="col-lg-12">
						<input type="email" class="form-control form-control-lg my-5"  placeholder="Your e-mail address" v-model="contactUsform.mail"
						:class="{'border border-danger': isValidEmail(contactUsform.mail) == false}">
					</div>
					<div class="col-lg-12">
						<textarea class="form-control form-control-lg" v-model="contactUsform.description" placeholder="Explain the subject in a few sentences"></textarea>
					</div> 
                  </div>   
                  <div class="modal-footer">
                        <div class="col-lg-12 mt-0">
							<div class="d-grid gap-2"> 
								<button type="button" class="btn btn-success" @click="onSubmitContactUs()">Submit</button>
							</div>
						</div>
                  </div>
              </div>
          </div>
      </div> 
		<!-- SEARCH COMPANY BY NAME RESULT -->
		<div class="modal fade" id="kt_modal_search_company" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
            <div class="modal-dialog modal-dialog-centered modal-xl">
                <div class="modal-content"  >
                    <div class="modal-header">
                        <h2 class="modal-title fw-bolder col-md-10">
                            Search Result
                        </h2>
                        <div class="btn btn-icon btn-sm btn-active-icon-primary"
                            data-bs-dismiss="modal" aria-label="Close" ref="closeModal">
                            <span class="svg-icon svg-icon-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none">
                                    <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                        transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                    <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                        transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                                </svg>
                            </span>
                        </div>
                    </div>
                    <div class="modal-body scroll-y">
                        <span class="svg-icon svg-icon-1 position-absolute ms-6" v-show="!this.isCompanySearchInProgress && !this.companySearchResult.length == 0">
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                  <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="currentColor"></rect>
                                  <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="currentColor"></path>
                              </svg>
                          </span>
                        <input type="text" data-kt-customer-table-filter="search" class="form-control form-control-solid w-250px ps-15 form-control-sm" placeholder="Search in Results" v-model="searchBar" v-show="!this.isCompanySearchInProgress && !this.companySearchResult.length == 0"/>
                        <div class="progress" v-if="this.isCompanySearchInProgress">
                            <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%">
                                Getting Company List. Please wait...
                            </div>
                        </div>
                        <h5 v-if="this.companySearchResult.length == 0 && !this.isCompanySearchInProgress">
                            No result found.
                        </h5>
                        <table class="table table-row-dashed text-gray-800 table-row-gray-300 table-hover" v-show="!this.isCompanySearchInProgress && !this.companySearchResult.length == 0">
                            <thead>
                                <tr>
                                    <th>Title</th>
                                    <th>Registration Number</th>
                                    <th>Date of Creation</th>
                                    <th>Address</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item of searchableList" v-bind:key="item">
                                    <td>
                                        <strong>{{ item.title }}</strong> <br>
                                        <small>{{ item.description }}</small>
                                    </td>
                                    <td>{{ item.companyNumber }}</td> 
                                    <td>{{ previewDate(item.dateOfCreation) }}</td>
                                    <td> 
                                        <small>
                                            {{ item.address.addressLine1 }} {{ item.address.postalCode }}<br>
                                            {{ item.address.locality }} / {{ item.address.country }}
                                        </small>
                                    </td>
                                    <td>
                                        <button type="button" class="btn btn-sm btn-primary" @click="onSelectSearchResult(item.companyNumber)">Select</button>
                                    </td>
                                </tr> 
                            </tbody>
                        </table> 
                    </div>
                </div>
            </div>
        </div>
	</div>
</template>

<script>
import axios from "axios";
import { load } from 'recaptcha-v3';
import VOtpInput from "vue3-otp-input";
import moment from 'moment';
import Swal from 'sweetalert2';
import html2pdf from 'html2pdf.js';

export default {
	name: "NewCompany",
	components: {
		VOtpInput
	},
	data() {
		return {
			sid: null,
			log_id: null,
			memberStatus: -1,
			activeStep: 0,
			form: {
				id: null,
				user_mail: null,
				confirm_email: null,
				code: null,
				is_gdpr_confirmed: false,
				is_privacy_policy_confirmed: false,
				terms_condition_confirmation: false,
				membership_aggrement_confirmed: false,
				gender: '-1',
				name_surname: null,
				phone: null,
				company_country_code: '-1',
				company_type_id: null,
				company_member_type: '-1',
				company_name: null,
				company_vkn: null,
				primary_person: null,
				company_address: null,
				company_city: null,
				company_postcode: null,
				company_region: null,
				company_region_select: null,
				is_company_number_required: 0,
				is_gdpr_required: false,
				is_privacy_policy_required: false,
				terms_condition_required: false,
				membership_aggrement_required: false,
				phone_country_code: null,
				company_district: null,
				x_coordinate: 0,
				y_coordinate: 0,
				uprn: null,
				udprn: null,
				last_confirmation_statement: null,
				next_confirmation_statement: null,
				next_confirmation_due_date: null,
				last_account_date: null,
				next_account_date: null,
				next_account_due_date: null,
				official_company_type: null,
				incorporated_at: null,
				address_id: '-1'
			},
            addressList: [{id: '-1', text: 'Select an address from the list after the postcode query'}],
			countryList: [],
			regionList: [],
			companyMemberTypeList: [
				{id: '-1', text: 'Select an option'},
				{id: 1, text: 'Company'},
				{id: 2, text: 'Person'},
			],
			phoneMaskList: [],
			genderList: [
				{id: '-1', text: 'Select an option'},
				{id: 'M', text: 'Male'},
				{id: 'F', text: 'Female'},
				{id: 'O', text: 'Other'},
			],
			isCountDownActive: false,
			countdown: null,
			aggrementContents: [],
			isVerifyRecaptcha: false,
			rc_sk: '',
			isProgressing: false,
			isSendMailProgressing: false,
			companySearchResult: [],
			searchBar: '',
			isCompanySearchInProgress: false,
			isCompanyInfoProgressing: false,
			ControllerId: null,
			AddressInquirySubType: null,
			contactUsform: {
				name: null,
				mail: null,
				description: null
			},
			isSearchingByPostCode: false,
			isSearchingByAddressCode: false,
		};
	},
	computed: {
		isEmailConfirm(){
			if(this.form.user_mail !== null && this.form.user_mail !== '' && this.form.confirm_email !== '' && this.form.confirm_email !== null) {
				if(this.form.user_mail == this.form.confirm_email) return true;
				else return false;
			}
			else return null;
		},
		isValidEmail(){
			return (email) => {
				if (email) {
					return (email == null || email == '' || email == undefined) ? null : /^[^@]+@\w+(\.\w+)+\w$/.test(email);
				}
			}
		},
		isNextButtonDisabled() {
			if(this.activeStep == 1) {
				return (this.form.is_privacy_policy_required && this.form.is_privacy_policy_confirmed == false) 
				|| (this.form.is_gdpr_required && this.form.is_gdpr_confirmed == false)
				|| (this.form.terms_condition_required && this.form.terms_condition_confirmation == false)
				|| (this.form.membership_aggrement_required && this.form.membership_aggrement_confirmed == false)
				|| this.form.company_country_code == null || this.form.company_country_code == '' || this.form.company_country_code == '-1';
			}
			if(this.activeStep == 2) {
				return this.form.gender == null || this.form.gender == '' || this.form.gender == '-1' || this.form.name_surname == null 
				|| this.form.name_surname == '' || this.form.phone == null || this.form.phone == '';
			}
			if(this.activeStep == 3) {
				return this.form.company_member_type == null || this.form.company_member_type == '' || this.form.company_member_type == '-1' ||  this.form.company_name == null || this.form.company_name == '' 
				|| this.form.primary_person == null || this.form.primary_person == '' || (this.form.is_company_number_required == 1 && (this.form.company_vkn == null || this.form.company_vkn == ''));
			}
			if(this.activeStep == 4) {
				return this.form.company_postcode == null || this.form.company_address == null || this.form.company_city == null || this.form.company_postcode == '' || this.form.company_address == '' || this.form.company_city == '';
			}
			else {
				return false;
			}
		},
		previewDate(){
			return (value) => {
					if (value) {
						return moment(new Date(value)).format('DD.MM.YYYY')
				}
			}
		},
		searchableList() {
            return this.companySearchResult.filter(p => { 
                return p.title.toLowerCase().indexOf(this.searchBar.toLowerCase()) != -1 || 
                p.companyNumber.toLowerCase().indexOf(this.searchBar.toLowerCase()) != -1
            });
        }
	},
	methods: {
		getData(){
			document.body.style.backgroundImage = '';
			const parameters = {
				sid: this.sid
			};  
			
			const requestOne =  axios.post('/api/Logs/Open', parameters, {'Content-Type': 'application/json'});
			const requestTwo =  axios.get('/api/GeneralUserParameters/GetRc'); 
			
			axios.all([requestOne, requestTwo]).then(axios.spread((...responses) => {
				this.sid = responses[0].data.sid;
				this.log_id = responses[0].data.id;
				this.isVerifyRecaptcha = responses[1].data.is_view_rc_cnc == 1 ? true : false;
				this.rc_sk = this.$unlock(responses[1].data.sk);
				
				// New Account icin lisans parametresi yoksa islemi engelliyoruz
				if(responses[1].data.is_c_n_cp == 0) {
					this.$router.push({ name: 'Login' });
					return;
				}
				this.loadReCaptcha();
			}))
			.catch(errors => { 
				console.error(errors); 
			});
		},
		async loadReCaptcha() {
			if(this.rc_sk !== null && this.rc_sk !== '' && this.rc_sk !== undefined) {
				const recaptcha = await load(this.rc_sk);
				if(!this.isVerifyRecaptcha) recaptcha.hideBadge();
				else recaptcha.showBadge();
			}
		},
		async onBeforeSubmit() {
			if(this.isVerifyRecaptcha) {
				const recaptcha = await load(this.rc_sk);
				const token = await recaptcha.execute('login');
				this.preSignUp(token, true);
			} else {
				this.preSignUp();
			}
		},
		preSignUp(token, is_recaptcha_active) {
			this.isProgressing = true;
			const parameters = {
				email: this.form.user_mail,
				sid: this.sid,
				tk: token,
				ira: is_recaptcha_active
			};  
			const that = this;
			axios.post('/api/NewCompany/CheckMail', parameters, {'Content-Type': 'application/json'})
			.then((response) => {
				if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
					this.memberStatus = response.data.ms;
					this.log_id = response.data.lid;
					this.isProgressing = false;
					this.startTimer();
					this.isCountDownActive = true;
				}
			})
			.catch(function (error) {
				console.log(error);
				that.isProgressing = false;
			})
			.finally(function () {
				that.isProgressing = false;
			});
		},
		sendMailAgain() {
			if(this.isCountDownActive) return;
			this.clearInput();
			this.isSendMailProgressing = true;
			const parameters = {
				email: this.form.user_mail,
				sid: this.sid
			};  
			const that = this;
			axios.post('/api/NewCompany/SendConfirmationMail', parameters, {'Content-Type': 'application/json'})
			.then((response) => {
				if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
					this.log_id = response.data;
					this.startTimer();
					this.isCountDownActive = true;
					this.isSendMailProgressing = false;					
					that.$swal("Success", "Verification code sent successfully.", 'success'); 
				}
			})
			.catch(function (error) {
				console.log(error);
				that.isSendMailProgressing = false;
			})
			.finally(function () {
				that.isSendMailProgressing = false;
			});
		},
		confirmCode() {
			this.isProgressing = true;
			const parameters = {
				code: this.form.code,
				sid: this.sid,
				id: this.log_id
			};
			const that = this;
			axios.post('/api/NewCompany/Confirm', parameters, {'Content-Type': 'application/json'})
			.then((response) => {
				if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
					if(response.data == 1) {
						this.activeStep = 1;
						this.memberStatus = -2;
						this.isProgressing = false;
						this.isCountDownActive = false;
						clearInterval(this.countdownInterval);
						this.getCountryList();
					}
				}
			})
			.catch(function (error) {
				console.log(error);
				that.isProgressing = false;
			})
			.finally(function () {
				that.isProgressing = false;
			});
		},
		onNextStep() {
			this.sendLog();
			this.activeStep++;
		},
		onBackStep() {
			this.activeStep--;
			if(this.activeStep == 1) {
				this.form.is_gdpr_confirmed = this.form.is_gdpr_confirmed == 1 ? true : false;
				this.form.is_privacy_policy_confirmed = this.form.is_privacy_policy_confirmed == 1 ? true : false;
				this.form.terms_condition_confirmation = this.form.terms_condition_confirmation == 1 ? true : false;
				this.form.membership_aggrement_confirmed = this.form.membership_aggrement_confirmed == 1 ? true : false;
			}
		},
		sendLog() {
			this.isProgressing = true;
			const parameters = {
				sid: this.sid,
				is_gdpr_confirmed: this.form.is_gdpr_confirmed == true ? 1 : 0,
				is_privacy_policy_confirmed: this.form.is_privacy_policy_confirmed == true ? 1 : 0,
				terms_condition_confirmation: this.form.terms_condition_confirmation == true ? 1 : 0,
				membership_aggrement_confirmed: this.form.membership_aggrement_confirmed == true ? 1 : 0,
				gender: this.form.gender,
				name_surname: this.form.name_surname,
				phone: this.form.phone,
				phone_country_code: this.form.phone_country_code,
				company_country_code: this.form.company_country_code,
				company_type_id: this.form.company_type_id,
				company_member_type: this.form.company_member_type,
				company_name: this.form.company_name,
				company_vkn: this.form.company_vkn,
				primary_person: this.form.primary_person,
				company_address: this.form.company_address,
				company_city: this.form.company_city,
				company_postcode: this.form.company_postcode,
				company_region: this.form.company_region,
				company_district: this.form.company_district,
				x_coordinate: this.form.x_coordinate,
				y_coordinate: this.form.y_coordinate,
				uprn: this.form.uprn,
				udprn: this.form.udprn,
				last_confirmation_statement: this.form.last_confirmation_statement,
				next_confirmation_statement: this.form.next_confirmation_statement,
				next_confirmation_due_date: this.form.next_confirmation_due_date,
				last_account_date: this.form.last_account_date,
				next_account_date: this.form.next_account_date,
				next_account_due_date: this.form.next_account_due_date,
				official_company_type: this.form.official_company_type,
				incorporated_at: this.form.incorporated_at,
				statu: this.activeStep
			};
			const that = this;
			axios.post('/api/NewCompany/SendLog', parameters, {'Content-Type': 'application/json'})
			.then((response) => {
				if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
					this.isProgressing = false;
				}
			})
			.catch(function (error) {
				that.isProgressing = false;
				console.log(error);
			})			
			.finally(function () {
				that.isProgressing = false;
			});
		},
		createMember() {
		this.isProgressing = true;
		const parameters = {
			sid: this.sid,
			company_address: this.form.company_address,
			company_city: this.form.company_city,
			company_postcode: this.form.company_postcode,
			company_district: this.form.company_district,
			x_coordinate: this.form.x_coordinate,
			y_coordinate: this.form.y_coordinate,
			uprn: this.form.uprn,
			udprn: this.form.udprn,
			company_region: null,
			last_confirmation_statement: this.form.last_confirmation_statement,
			next_confirmation_statement: this.form.next_confirmation_statement,
			next_confirmation_due_date: this.form.next_confirmation_due_date,
			last_account_date: this.form.last_account_date,
			next_account_date: this.form.next_account_date,
			next_account_due_date: this.form.next_account_due_date,
			official_company_type: this.form.official_company_type,
			incorporated_at: this.form.incorporated_at,
		};
		const that = this;
		axios.post('/api/NewCompany/CreateMember', parameters, {'Content-Type': 'application/json'})
		.then((response) => {
			if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
				this.sendActivationLink();
				this.isProgressing = false;
			}
		})
		.catch(function (error) {
			console.log(error);
			that.isProgressing = false;
		})
		.finally(function () {
			that.isProgressing = false;
		});
		},	
		sendActivationLink(isSendAgain) {
			const parameters = {
				session_id: this.sid,			
				user_mail: this.form.user_mail
			};
			axios.post('/api/NewCompany/SendActivationLink', parameters, {'Content-Type': 'application/json'})
			.then((response) => {
				if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
					if(isSendAgain) {
						this.$swal("Success", "Verification link has been successfully sent", 'success');
					} else  {
						this.activeStep++;
					}
				}
			})
			.catch(function (error) {
			console.log(error);
			});
		},
		getCountryList() {
			axios.post('/api/NewCompany/GetLists', null, {'Content-Type': 'application/json'}).then((response) => {
				this.countryList = response.data;
				if (response.data.length == 1) {
					this.form.company_country_code = response.data[0].id;
					this.onChangeCountry(response.data[0]);
				}else {
					this.countryList.unshift({id: '-1', text: 'Select an option'});
				}
			});
		},
		onChangeCountry(event) {
			this.ControllerId = event.controller_id;
			this.AddressInquirySubType = event.address_inquiry_sub_type;
			this.form.phone_country_code = event.phone_country_code;
			const parameters = {
				country_code: this.form.company_country_code
			}
			axios.post('api/NewCompany/GetAggrementContents', parameters, {'Content-Type': 'application/json'})
			.then((response) => { 
				this.aggrementContents = response.data;						
				this.aggrementContents.forEach(element => {
					if(element.menu_selection == '/GeneralDataProtectionHelppage') {
						element.modelName = 'is_gdpr_confirmed';
						this.form.is_gdpr_required = element.is_required == 1 ? true : false;
					}
					if(element.menu_selection == '/PrivacyPolicyHelppage') {
						element.modelName = 'is_privacy_policy_confirmed';
						this.form.is_privacy_policy_required = element.is_required == 1 ? true : false;
					}
					if(element.menu_selection == '/TermsConditionsHelppage') {
						element.modelName = 'terms_condition_confirmation';
						this.form.terms_condition_required = element.is_required == 1 ? true : false;
					}
					if(element.menu_selection == '/MembershipAgreementHelppage') {
						element.modelName = 'membership_aggrement_confirmed';
						this.form.membership_aggrement_required = element.is_required == 1 ? true : false;
					}
				});
			})
			.catch(function (error) {
				console.log(error);
			});
		},
		handleOnChange(value) {
			this.form.code = value;
		},
		handleOnComplete(value) {
			this.form.code = value;
		},
		clearInput() {
        this.$refs.otpInput.clearInput();
		},
		startTimer() {
			const that = this;
			var timer = 180, minutes, seconds;
			const countdownInterval = setInterval(function () {
				minutes = parseInt(timer / 60, 10);
				seconds = parseInt(timer % 60, 10);

				minutes = minutes < 10 ? "0" + minutes : minutes;
				seconds = seconds < 10 ? "0" + seconds : seconds;

				var display = document.querySelector('#interval_timer_text');
				if(display.textContent !== null && display.textContent !== undefined && display.textContent !== ''){			
				display.textContent = '('+ minutes + ":" + seconds + ')';
				}
				if (--timer < 0) {
					timer = 0;
					display.textContent = '(03:00)';
					that.isCountDownActive = false;
					clearInterval(countdownInterval);
				}
			}, 1000);
			this.countdownInterval = countdownInterval;
		},
		onSearchCompanyByNo(){
			this.isCompanyInfoProgressing = true;
			const that = this;
			const parameters = {
				cn: this.form.company_vkn,
				cid: this.ControllerId
			}
			axios.post('api/NewCompany/SearchByNo', parameters, {'Content-Type': 'application/json'})
			.then((response) => { 
                if(response.data.error == null || response.data.error == '' || response.data.error == undefined){
				this.form.company_name = response.data.name;
				this.form.company_vkn = response.data.number;
				this.form.primary_person = response.data.contactName;
				this.form.company_postcode = response.data.postalCode;
				this.form.company_address = response.data.addressLine2 == null ? response.data.addressLine1 : response.data.addressLine1 + ' '+ response.data.addressLine2; 
				//this.form.country = response.data.country; Çıktı England şeklinde?
				// this.form.company_state = response.data.State;
				this.form.company_city = response.data.locality;
				this.form.company_town = response.data.Town;
				this.form.company_region = response.data.Region;
				this.form.last_confirmation_statement = response.data.last_confirmation_statement;
				this.form.next_confirmation_statement = response.data.next_confirmation_statement;
				this.form.next_confirmation_due_date = response.data.next_confirmation_due_date;
				this.form.last_account_date = response.data.last_account_date;
				this.form.next_account_date = response.data.next_account_date;
				this.form.next_account_due_date = response.data.next_account_due_date;
				this.form.official_company_type = response.data.official_company_type;
				this.form.incorporated_at = response.data.corporatedAt;
				this.companySearchResult = [];
                }
            })
            .catch(function (error) {
                console.log(error);
				that.isCompanyInfoProgressing = false;
                that.$swal("Error", "No records found..!", 'error');
			})			
			.finally(function () {
				that.isCompanyInfoProgressing = false;
			});
		},
		onSearchCompanyByName(){
			this.isCompanySearchInProgress = true;
			this.companySearchResult = [];
			this.searchBar = '';
			const parameters = {
				cn: this.form.company_name,
				cid: this.ControllerId
			}
			axios.post('api/NewCompany/SearchByName', parameters, {'Content-Type': 'application/json'})
			.then((response) => {
				if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){  
					this.companySearchResult = response.data;
					this.isCompanySearchInProgress = false;
				} else {
					setTimeout(() => {
						this.$refs.closeModal.click(); 
					}, 500);
				}
			})
			.catch(errors => {
				console.error(errors); 
				this.isCompanySearchInProgress = false;
			});
		},
		onSelectSearchResult(selectedCompanyNumber){
			this.form.company_vkn = selectedCompanyNumber;
			this.onSearchCompanyByNo();
			this.$refs.closeModal.click(); 
		},
		onSelectAddressSearchResult(item){
			this.form.company_address = item.ADDRESS;
			this.form.company_city = item.POST_TOWN;
			this.form.company_district = item.LOCAL_CUSTODIAN_CODE_DESCRIPTION;
			this.form.x_coordinate = item.X_COORDINATE,
			this.form.y_coordinate = item.Y_COORDINATE,
			this.form.uprn = item.UPRN,
			this.form.udprn = item.UDPRN,
            this.form.company_postcode = item.POSTCODE;
		},		
        onSelectAddress(item) {
            if(item.id == '-1') return;
            this.form.company_address = item.fullAddress;
            this.form.company_city = item.town_or_city;
            this.form.company_district = item.district;
            this.form.company_postcode = item.postcode;
        },
		onFindAddressByPostCode(){
			this.isSearchingByPostCode = true;
			this.form.address_id = '-1';
			this.addressList = [{id: '-1', text: 'Select an address from the list after the postcode query'}];
			const that = this;
			const parameters = {
				pc: this.form.company_postcode,
				cid: this.ControllerId
			};
			axios.post('api/NewCompany/FindAddressByPostCode', parameters, {'Content-Type': 'application/json'})
			.then(function(response) {
				if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
					if(response.data.results) {
						response.data.results.forEach(value => {
							value.DPA.id = value.DPA.UPRN;
							value.DPA.text = value.DPA.ADDRESS;
							that.addressList.push(value.DPA);
						});
					}
				}
			})
			.finally(function () { 
				that.isSearchingByPostCode = false;
			});  
		},
		onFindAddressByAddressCode(){
			this.isSearchingByAddressCode = true;
			this.form.address_id = '-1';
			this.addressList = [{id: '-1', text: 'Select an address from the list after the postcode query'}];
			const that = this;
			const parameters = {
				pc: this.form.uprn,
				cid: this.ControllerId
			};
			axios.post('api/NewCompany/FindAddressByAddressCode', parameters, {'Content-Type': 'application/json'})
			.then(function(response) {
				if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
					if(response.data.results) {
						response.data.results.forEach(value => {
							value.DPA.id = value.DPA.UPRN;
							value.DPA.text = value.DPA.ADDRESS;
							that.addressList.push(value.DPA);
						});
					}
				}
			})
			.finally(function () { 
				that.isSearchingByAddressCode = false;
			});  
		},
		onSubmitContactUs(){
			const that = this;
			const parameters = {
				name: this.contactUsform.name,
				email: this.contactUsform.mail,
				description: this.contactUsform.description
			};
			axios.post('api/NewCompany/ContactRequest', parameters, {'Content-Type': 'application/json'})
			.then(function(response) { 
				if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
					new Swal( { icon: 'success', title: 'Saved', text: 'Your request has been received.' });
					that.$refs.closeContactModal.click();
				}
			});
		},
		clearContactUsForm(){
			this.contactUsform = {
				name: null,
				mail: null,
				description: null
			};
		},
		exportToPDF(item, index) {
			html2pdf(this.$refs.documentToDownload[index], {
				margin: 1,
				filename: item.name + '.pdf',
				image: { type: 'jpeg', quality: 0.98 },
				html2canvas: { dpi: 192, letterRendering: true },
				jsPDF: { unit: 'in', format: 'letter', orientation: 'landscape' }
			});
		}
	},
	created() {
		this.getData();
	}
};
</script>

<style>
.otp-input.is-complete {
    border: 1px solid #b5b5c3   !important;
}
</style>