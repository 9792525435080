<template>
    <div class="modal-dialog modal-xxl">
        <div class="modal-content modal-rounded">
            <div class="modal-header bg-primary py-7 d-flex justify-content-between">
                <h2 class="text-white">Update Account</h2>
                <div class="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal" aria-label="Close" ref="closeUpdateAccount" >
                    <span class="svg-icon svg-icon-1 text-white text-hover-dark">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                            <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                        </svg>
                    </span>
                </div>
            </div>
            <div class="modal-body scroll-y" :class="{'overlay overlay-block': this.isAccountSaving == true}">
                <div class="d-flex flex-column">
                    <form id="updateAccount" @submit.prevent="" class="fv-plugins-bootstrap5 fv-plugins-framework" novalidate="novalidate">                        
                        <div class="d-flex flex-row row">
                            <div class="d-flex flex-column flex-row-auto col-lg-6">
                                <div class="d-flex flex-column-fluid card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                    <div class="card-body">
                                        <div class="fv-row mb-7 row">
                                            <div class="col-lg-12">
                                                <label class="fs-6 fw-bold form-label mt-3" v-tag="'name'"> 
                                                    {{ $t('Name / Company Name') }} 
                                                </label>
                                                <div class="input-group">
                                                    <input type="text" class="form-control" v-custom name="name" v-model="form.name">
                                                    <button type="button" v-if="userData.is_company_inquiry_active == 1 && this.$isCredentialActive(10,'CI')"
                                                            class="input-group-text m-0 text-hover-success cursor-pointer" 
                                                            id="basic-addon2" 
                                                            @click="onSearchCompanyByName()" 
                                                            data-bs-toggle="modal" 
                                                            data-bs-target="#kt_modal_search_company" 
                                                            data-kt-menu-trigger="click" 
                                                            data-kt-menu-placement="bottom-end"
                                                            :disabled="form.name == null || form.name == ''">
                                                        <i class="fas fa-search fs-4 me-3"></i> {{ $t('Search by Name') }}
                                                    </button>
                                                </div>
                                            </div>    
                                            <div class="col-lg-12">
                                                <div class="fv-row">
                                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'member_type'">
                                                        <span>{{ $t('Member Type') }}</span>
                                                    </label> 
                                                    <Select2 name="member_type" v-model="form.company_account_type" :options="companyAccountTypeList" v-custom :settings="{ width: '100%', dropdownParent: '#kt_modal_update_account .modal-content' }" />
                                                </div>
                                            </div>
                                            <div class="col-lg-12" v-if="form.company_account_type == 1">
                                                <label class="fs-6 fw-bold form-label mt-3"  v-tag="'registration_number'">
                                                    {{ $t('Registration Number') }}
                                                </label>
                                                <div class="input-group">
                                                    <input type="text" class="form-control" name="registration_number" v-custom
                                                        v-model="form.account_number">
                                                    <button v-if="userData.is_company_inquiry_active == 1 && this.$isCredentialActive(10,'CI')" type="button" class="input-group-text m-0 cursor-pointer text-hover-primary" id="basic-addon2" @click="onSearchCompanyByNo()"
                                                            :disabled="form.account_number == null || form.account_number == ''">
                                                        <i class="fas fa-search fs-4 me-3"></i> {{ $t('Search by No') }}
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="col-lg-12" v-if="form.company_account_type == 2">
                                                <label class="fs-6 fw-bold form-label mt-3" v-tag="'personal_id'">
                                                    {{ $t('Personal ID') }}
                                                </label>
                                                <input type="text" class="form-control" v-model="form.personal_id" name="personal_id" v-custom>
                                            </div>
                                            <div class="col-lg-12">
                                                <label class="fs-6 fw-bold form-label mt-3" v-tag="'contact_name'">{{ $t('Contact Name') }}</label>
                                                <input type="text" class="form-control" name="contact_name" v-model="form.contact_name" v-custom>
                                            </div>
                                            <div class="col-lg-12">
                                                <input class="form-check-input mt-6" type="checkbox" v-model="form.is_update_locked" :disabled="!this.$isCredentialActive(10,'CUL')" />
                                                <label class="fs-6 fw-bold form-label mt-7 ms-4" v-tag="'is_update_locked'">
                                                    <i class="bi bi-lock me-1"></i>
                                                    {{ $t('Lock Update') }}
                                                </label>
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex flex-column flex-row-auto col-lg-6">
                                <div class="d-flex flex-column-fluid card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                    <div class="card-body">
                                        <div class="fv-row mb-7 row">
                                            <div class="col-lg-12">
                                                <div class="fv-row fv-plugins-icon-container">
                                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'email'">{{ $t('Email') }} 
                                                    </label>
                                                    <input type="email" class="form-control" name="email" v-model="form.email" :class="{'border border-danger': isValidEmail == false}" v-custom>
                                                    <small class="text-danger" v-if="!isValidEmail && form.email !== null && form.email !== '' ">{{ $t('Email is not valid') }}</small>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="fv-row">
                                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'mobile_phone'">{{ $t('Mobile Phone') }}  
                                                    </label>
                                                    <div class="input-group">
                                                        <Select2 v-model="form.phone_country_code" :options="phoneMaskList" :settings="{ width: '100%', dropdownParent: '#kt_modal_update_account .modal-content' }"/>
                                                        <input type="text" class="form-control" name="mobile_phone" v-model="form.mobile_phone" v-custom>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="fv-row">
                                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'phone'">
                                                        <span>{{ $t('Phone') }}</span>
                                                    </label>
                                                    <div class="input-group">
                                                        <Select2 v-model="form.land_phone_country_code" :options="phoneMaskList" :settings="{ width: '100%', dropdownParent: '#kt_modal_update_account .modal-content' }"/>
                                                        <input type="text" class="form-control" name="phone" v-model="form.phone" v-custom>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="fv-row">
                                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'customer_type'">
                                                        <span>{{ $t('Customer Type') }}</span>
                                                    </label> 
                                                    <Select2 v-model="form.customer_type_id" :options="customerTypeList" v-custom name="customer_type" :settings="{ width: '100%', dropdownParent: '#kt_modal_update_account .modal-content' }"/>  
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="fv-row">
                                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'sales_channel'">
                                                        <span>{{ $t('Sales Channel') }}</span>
                                                    </label>
                                                    <Select2 v-model="form.sales_channel_id" :options="salesChannelList" v-custom name="sales_channel" :settings="{ width: '100%', dropdownParent: '#kt_modal_update_account .modal-content' }"/> 
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="fv-row">
                                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'payment_value'">
                                                        <span>{{ $t('Payment Value') }}</span>
                                                        <small class="fs-8"> ({{ $t('Day') }}) </small>
                                                    </label>
                                                    <input type="text" class="form-control" name="payment_value" v-model="form.payment_value" v-custom>
                                                </div>
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex flex-column flex-row-auto col-lg-12">
                                <div class="d-flex flex-column-fluid card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                    <div class="card-body">
                                        <div class="fv-row mb-6 row">
                                            <GetAddress @address-selected="onSelectAddress" :account-type="1" v-if="userData.is_address_inquiry_active == 1 && userData.address_inquiry_sub_type == 2 && this.$isCredentialActive(10,'AI')"/>
                                            <div class="col-lg-6">
                                                <div class="fv-row">
                                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'post_code'">{{ $t('Post Code') }}                                                                                                             
                                                    </label>
                                                    <div class="input-group">
                                                        <input type="text" class="form-control" name="post_code" v-model="form.postcode" v-custom>
                                                        <button v-if="userData.is_address_inquiry_active == 1 && userData.address_inquiry_sub_type == 1 && this.$isCredentialActive(10,'AI')" type="button" class="input-group-text m-0 cursor-pointer text-hover-info" 
                                                                id="basic-addon2" 
                                                                @click="this.onFindAddressByPostCode()" 
                                                                data-bs-toggle="modal" 
                                                                data-bs-target="#kt_modal_search_address" 
                                                                data-kt-menu-trigger="click" 
                                                                data-kt-menu-placement="bottom-end"
                                                                :disabled="this.form.postcode == null || this.form.postcode == ''">
                                                            <i class="fas fa-search fs-4 me-3"></i> {{ $t('Search by Post Code') }}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="fv-row">
                                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'address_code'">{{ $t('Address Code') }}
                                                    </label>
                                                    <div class="input-group">
                                                        <input type="text" class="form-control" name="address_code" v-model="form.uprn" v-custom>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="fv-row">
                                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'address'">{{ $t('Address') }}
                                                    </label>
                                                    <textarea class="form-control" name="address" v-model="form.address" v-custom></textarea>
                                                </div>
                                            </div>
                                            <div class="col-lg-4">
                                                <div class="fv-row">
                                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'city'">{{ $t('City') }}
                                                    </label>
                                                    <input type="text" class="form-control" name="city" v-model="form.city" v-custom>
                                                </div>
                                            </div>
                                            <div class="col-lg-4">
                                                <div class="fv-row">
                                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'district'">{{ $t('District') }}                                                    
                                                    </label>
                                                    <input type="text" class="form-control" name="district" v-model="form.district" v-custom>
                                                </div>
                                            </div>
                                            <div class="col-lg-4">
                                                <div class="fv-row">
                                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'country'">
                                                        {{ $t('Country') }}
                                                    </label>
                                                    <Select2 name="country" v-model="form.country" :options="countryList" :settings="{ width: '100%', dropdownParent: '#kt_modal_update_account .modal-content' }" @select="onChangeCountry($event)" v-custom/>
                                                </div>
                                            </div> 
                                        </div> 
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex flex-column flex-row-auto col-lg-6">
                                <div class="d-flex flex-column-fluid card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                    <div class="card-body">
                                        <div class="fv-row row">
                                            <div class="col-lg-6">
                                                <label class="fs-6 fw-bold form-label mt-3" v-tag="'is_vat_account'">{{ $t('VAT Account?') }}</label> 
                                                <br>
                                                <div class="form-check form-check-inline mt-5">
                                                    <input class="form-check-input" type="radio" name="is_vat_account" id="inlineRadio1" value="false"
                                                        v-model="form.isVATNumberExist" v-custom>
                                                    <label class="form-check-label" for="inlineRadio1">{{ $t('No') }}</label>
                                                </div>
                                                <div class="form-check form-check-inline">
                                                    <input class="form-check-input" type="radio" name="is_vat_account" id="inlineRadio2" value="true"
                                                    v-model="form.isVATNumberExist" v-custom>
                                                    <label class="form-check-label" for="inlineRadio2">{{ $t('Yes') }}</label>
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="fv-row mb-7">
                                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'vat_number'" :class="{'required' : this.form.isVATNumberExist == 'true'}">
                                                        {{ $t('VAT Number') }}                                                            
                                                    </label>
                                                    <input type="text" class="form-control" name="vat_number" v-model="form.vat_number" :disabled="form.isVATNumberExist == 'false'" v-custom>
                                                </div>
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex flex-column flex-row-auto col-lg-6">
                                <div class="d-flex flex-column-fluid card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                    <div class="card-body">
                                        <div class="fv-row mb-7">
                                            <label class="fs-6 fw-bold form-label mt-3" v-tag="'notes'">
                                                <span>{{ $t('Notes') }}</span>
                                            </label>
                                            <textarea class="form-control" name="notes" v-model="form.notes" v-custom></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="overlay-layer bg-dark bg-opacity-5" v-if="this.isAccountSaving == true">
                    <div class="spinner-border text-primary" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div>
            <div class="modal-footer row mx-0">
                <div class="d-grid gap-2">
                    <button type="submit" class="btn btn-lg btn-block btn-primary" @click="onUpdateAccount()">
                        <span class="svg-icon svg-icon-2">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                            </svg>
                        </span>
                        {{ $t('Update') }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import axios from "axios";
import moment from 'moment';
export default {
    name: "UpdateAccount",
    props:{
        accountid: String
    },
    data() {
        return {
            userData : this.$getUser(),
            assetTypeList: [],
            companyAccountTypeList: [
                {id: 1, text: 'Company'},
                {id: 2, text: 'Person'},
            ],
            lists:[],
            branchList: [],
            customerTypeList: [],
            salesChannelList:[],
            countryList: [],
            cityList: [],
            addressList: [],
            phoneMaskList:[],
            form: {
                id: null,
                name: null,
                company_account_type: 2,
                account_type: null,
                account_id: null,
                notes: null, 
                account_number: null,
                personal_id: null,
                contact_name: null,
                email: null,
                mobile_phone: null,
                phone: null,
                postcode: null,
                address: null,
                address_id: '-1',
                country: null,
                city: null,
                vat_number: null,
                payment_value: null,
                isVATNumberExist: 'false',
                sales_channel_id: '-1',
                customer_type_id: '-1',
                customer_type_name: null,
                phone_country_code: null,
                land_phone_country_code: null,
                district: null,
                uprn: null,
                is_update_locked: false,
                is_main_group: 0
            },
            isAccountSaving: false           
        };
    },
    computed: {
		isValidEmail() {
			return (this.form.email == null || this.form.email == '' || this.form.email == undefined) ? null : /^[^@]+@\w+(\.\w+)+\w$/.test(this.form.email);
		},
        previewDate(){
            return (value)=>{
            if (value) {
                return moment(new Date(value)).format('DD.MM.YYYY')
                }
            }
        },
	},
    methods: {
        getData(item) {
            this.$Progress.start();
            this.addressList = [];
            this.addressList.unshift({ id: '-1', text: 'Select an option'}); 
            const params = {
                id: item.id,
                session_id: this.$getUser().session_id
            };
            axios.post('api/CompanyAccounts/GetAccountById', params, {'Content-Type': 'application/json'})
            .then((response) => { 
                this.form.id = response.data.id;
                this.form.name = this.$unlock(response.data.name);
                this.form.account_id = this.$unlock(response.data.account_id);
                this.form.account_number = this.$unlock(response.data.customer_account_number);
                this.form.personal_id = this.$unlock(response.data.customer_account_number);
                this.form.contact_name = this.$unlock(response.data.customer_contact_name);
                this.form.email = this.$unlock(response.data.customer_email);
                this.form.mobile_phone = this.$unlock(response.data.customer_mobile_phone);
                this.form.phone = this.$unlock(response.data.customer_phone);
                this.form.postcode = this.$unlock(response.data.customer_postcode);
                this.form.address = this.$unlock(response.data.customer_address);
                this.form.country = this.$unlock(response.data.customer_country);
                if(this.form.country == '' || this.form.country == null) this.form.country = '-1';
                this.form.city = this.$unlock(response.data.customer_city);
                this.form.district = this.$unlock(response.data.customer_district);
                this.form.uprn = this.$unlock(response.data.customer_uprn);
                this.form.company_account_type = response.data.customer_company_account_type;
                this.form.vat_number = this.$unlock(response.data.customer_vat_number);
                this.form.payment_value = response.data.customer_payment_value;
                this.form.phone_country_code = this.$unlock(response.data.customer_phone_country_code);
                this.form.land_phone_country_code = this.$unlock(response.data.customer_land_phone_country_code);
                this.form.is_main_group = response.data.is_main_group;
                this.form.sales_channel_id = (response.data.customer_sales_channel_id == '' || response.data.customer_sales_channel_id == null) ? '-1': response.data.customer_sales_channel_id;
                this.form.customer_type_id = (response.data.customer_customer_type_id == '' || response.data.customer_customer_type_id == null) ? '-1': response.data.customer_customer_type_id;
                this.form.is_update_locked = response.data.is_update_locked == 1 ? true : false;
                this.form.account_type = response.data.account_type;
                this.form.notes = response.data.notes;
                if(this.form.vat_number !== null && this.form.vat_number !== ''){
                    this.form.isVATNumberExist =  'true';
                } 
                this.getLists();
             })
            .catch(function () {
                 this.$swal("Failed", "Data could not be saved..Please refresh the page and try again..!"); 
            });  
    },
    getLists() {
        const parameters = {
            session_id: this.$getUser().session_id,
            list_name: ["account_setting", "sales_channel", "country"],
            account_type: 1, // yalnızca account settings listesi çekilecek ise gerekli
            country_code: this.$getUser().company_country_code, // yalnızca Company Types listesi çekilecek ise gerekli
            list_type: 14, // Customer Type: 14            
            get_access_status: 0,
            type_id: 1
        };  
        const requestOne =  axios.post('/api/Helper/GeneralLists', parameters, {'Content-Type': 'application/json'});
        const requestTwo =  axios.post('/api/Helper/GetLists', parameters, {'Content-Type': 'application/json'}); 
        const requestThree =  axios.post('/api/Helper/GetPhoneMasks', parameters, {'Content-Type': 'application/json'}); 
        axios.all([requestOne, requestTwo, requestThree]).then(axios.spread((...responses) => {
            this.salesChannelList = responses[0].data.sales_channel;
            this.customerTypeList = responses[1].data;
            this.phoneMaskList = responses[2].data;
            this.countryList = responses[0].data.country;
            this.salesChannelList.unshift({ id: '-1', text: 'Select an option'}); 
            this.customerTypeList.unshift({ id: '-1', text: 'Select an option'});
            this.countryList.unshift({ id: '-1', text: 'Select an option'});
            this.customerTypeList.forEach(element => { if (element.id == this.form.customer_type_id && element.id !== '-1') { this.form.customer_type_name = element.text; } });
            if(this.form.phone_country_code == null || this.form.phone_country_code == '' || this.form.phone_country_code == undefined) {
                this.phoneMaskList.forEach(element => {
                    if(element.code == this.$getUser().company_country_code) { this.form.phone_country_code = element.id; }
                });
            }
            if(this.form.land_phone_country_code == null || this.form.land_phone_country_code == '' || this.form.land_phone_country_code == undefined) {
                this.phoneMaskList.forEach(element => {
                    if(element.code == this.$getUser().company_country_code) { this.form.land_phone_country_code = element.id; }
                });
            }
            this.$Progress.finish();   
        }))
        .catch(errors => { 
            console.error(errors); 
            this.$Progress.finish(); 
        });
    },
    onUpdateAccount(){
        this.$checkSelect2Validation('updateAccount');
        if(!document.forms['updateAccount'].reportValidity()){
            return;
        }
        this.isAccountSaving = true;
        const params = this.form;    
        const that = this;
        params.session_id = this.$getUser().session_id;
        params.tmp_session_id = this.$getSessionStorage('tmp_session_id');
        if(params.country == '-1'){ params.country = null }
        if(params.sales_channel_id == '-1'){ params.sales_channel_id = null }
        if(params.customer_type_id == '-1'){ params.customer_type_id = null }
        if(params.company_account_type == 2) {params.account_number = params.personal_id;}
        params.is_update_locked = params.is_update_locked == true ? 1 : 0;
        axios.post('api/CompanyAccounts/UpdateAccount', params, {'Content-Type': 'application/json'})
        .then((response) => { 
            if(response.data != null && response.data.length == 36){
                this.$emit('refresh');
                this.$refs.closeUpdateAccount.click();
                this.$swal("Success", "Data saved succesfully..!", 'success');
            }
            if(this.form.country == null) {this.form.country = '-1';}
            if(this.form.sales_channel_id == null) {this.form.sales_channel_id = '-1';}
            if(this.form.customer_type_id == null) {this.form.customer_type_id = '-1';}
            this.form.is_update_locked = this.form.is_update_locked == 1 ? true : false;
            this.getLists();
        })
        .catch((error) => {
            console.log(error);
            this.$swal({
                title:"Data could not be saved",
                icon:"danger",
                text:"Data could not be saved..Please refresh the page and try again..!"
            });
        })
        .finally(function () { 
            setTimeout(() => {
                that.isAccountSaving = false;
            }, 500);
        });
    },  
    onChangeCountry(event) {
        this.form.phone_country_code = event.phone_country_code;
        this.form.land_phone_country_code = event.phone_country_code;
    },
  }
};
</script>