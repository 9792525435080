<template> 
    <div>
        <div class="d-flex flex-stack flex-wrap">
            <div class="page-title d-flex flex-column me-3 mt-3"> 
                <h1 class="d-flex fw-bolder my-1 fs-3 text-gray-800">{{ $t('Update Customer Account') }}</h1>  
                <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
                    <li class="breadcrumb-item text-gray-600">
                        <a href="#" class="text-gray-600 text-hover-primary">
                            <router-link :to="{ name: 'Home' }">{{$t('Home')}}</router-link>
                        </a>
                    </li> 
                    <li class="breadcrumb-item text-primary">{{ $t('Accounts') }}</li>     
                    <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'CustomerList' }">{{ $t('Customer') }}</router-link></li>     
                    <li class="breadcrumb-item text-gray-600">{{ $t('Update Customer Account') }}
                    <HelpPageIcon v-if="this.iconView == true" :menuSelection="this.menuSelection"></HelpPageIcon>
                    </li> 
                </ul> 
            </div>  
            <div class="d-flex align-items-center py-1">
                <router-link to="/CustomerList">
                    <button type="button" class="btn btn-light shadow-sm btn-arrow-left border border-gray-400 me-3">
                    <span class="ms-2 fs-7"> {{ $t('Return back to List') }}</span>
                    </button>
                </router-link> 
                &nbsp;
                <div class="dropdown" v-if="this.$isCredentialActive(10,'D')">
                    <button class="btn btn-light-info border border-info dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="true" id="customerActions" type="button">
                    <span class="svg-icon svg-icon-muted"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path opacity="0.3" d="M22.1 11.5V12.6C22.1 13.2 21.7 13.6 21.2 13.7L19.9 13.9C19.7 14.7 19.4 15.5 18.9 16.2L19.7 17.2999C20 17.6999 20 18.3999 19.6 18.7999L18.8 19.6C18.4 20 17.8 20 17.3 19.7L16.2 18.9C15.5 19.3 14.7 19.7 13.9 19.9L13.7 21.2C13.6 21.7 13.1 22.1 12.6 22.1H11.5C10.9 22.1 10.5 21.7 10.4 21.2L10.2 19.9C9.4 19.7 8.6 19.4 7.9 18.9L6.8 19.7C6.4 20 5.7 20 5.3 19.6L4.5 18.7999C4.1 18.3999 4.1 17.7999 4.4 17.2999L5.2 16.2C4.8 15.5 4.4 14.7 4.2 13.9L2.9 13.7C2.4 13.6 2 13.1 2 12.6V11.5C2 10.9 2.4 10.5 2.9 10.4L4.2 10.2C4.4 9.39995 4.7 8.60002 5.2 7.90002L4.4 6.79993C4.1 6.39993 4.1 5.69993 4.5 5.29993L5.3 4.5C5.7 4.1 6.3 4.10002 6.8 4.40002L7.9 5.19995C8.6 4.79995 9.4 4.39995 10.2 4.19995L10.4 2.90002C10.5 2.40002 11 2 11.5 2H12.6C13.2 2 13.6 2.40002 13.7 2.90002L13.9 4.19995C14.7 4.39995 15.5 4.69995 16.2 5.19995L17.3 4.40002C17.7 4.10002 18.4 4.1 18.8 4.5L19.6 5.29993C20 5.69993 20 6.29993 19.7 6.79993L18.9 7.90002C19.3 8.60002 19.7 9.39995 19.9 10.2L21.2 10.4C21.7 10.5 22.1 11 22.1 11.5ZM12.1 8.59998C10.2 8.59998 8.6 10.2 8.6 12.1C8.6 14 10.2 15.6 12.1 15.6C14 15.6 15.6 14 15.6 12.1C15.6 10.2 14 8.59998 12.1 8.59998Z" fill="currentColor"/>
                    <path d="M17.1 12.1C17.1 14.9 14.9 17.1 12.1 17.1C9.30001 17.1 7.10001 14.9 7.10001 12.1C7.10001 9.29998 9.30001 7.09998 12.1 7.09998C14.9 7.09998 17.1 9.29998 17.1 12.1ZM12.1 10.1C11 10.1 10.1 11 10.1 12.1C10.1 13.2 11 14.1 12.1 14.1C13.2 14.1 14.1 13.2 14.1 12.1C14.1 11 13.2 10.1 12.1 10.1Z" fill="currentColor"/>
                    </svg></span>
                        {{ $t('Actions') }}
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="customerActions"> 
                        <li>
                            <a class="dropdown-item fs-4 text-danger" href="#" @click="deleteItem()"><span class="svg-icon svg-icon-muted svg-icon-2 text-danger"><svg width="24" height="24" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="currentColor"/>
                            <path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="currentColor"/>
                            <path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="currentColor"/>
                            </svg>
                            </span>{{ $t('Delete') }} </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div> 
        <div class="content flex-row-fluid mt-4" id="kt_content">
            <StatsBar :form-account="this.form"></StatsBar> 
            <div class="row">
                <div class="card-body">
                    <ul class="nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-6">
                        <li class="nav-item">
                            <a class="nav-link" :class="{'active': this.activeTab == 'Account'}" data-bs-toggle="tab" href="#kt_accounting_1" target="_self"
                            @click="this.activeTab = 'Account'">
                                <h4> {{ $t('Customer Account') }} </h4>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" :class="{'active': this.activeTab == 'AccountGroup'}" data-bs-toggle="tab" href="#kt_accounting_2" target="_self"
                            @click="this.activeTab = 'AccountGroup'">
                                <h4> {{ $t('Account Group') }} </h4>
                            </a>
                        </li>
                        <li class="nav-item" v-if="this.$isModuleActive(3)">
                            <a class="nav-link" :class="{'active': this.activeTab == 'Documents'}" data-bs-toggle="tab" href="#kt_accounting_4" target="_self"
                            @click="activateDocumentsSection()">
                                <h4> {{ $t('Documents') }} </h4>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" :class="{'active': this.activeTab == 'Factors'}" data-bs-toggle="tab" href="#kt_accounting_5" target="_self"
                            @click="this.activeTab = 'Factors'">
                                <h4> {{ $t('Factors') }} </h4>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" :class="{'active': this.activeTab == 'Invoices'}" data-bs-toggle="tab" href="#kt_accounting_6" target="_self"
                            @click="this.activeTab = 'Invoices', onListInvoices()">
                                <h4> {{ $t('Invoices') }} </h4>
                            </a>
                        </li>
                    </ul>
                </div>
                <RequiredField></RequiredField>
                <div class="tab-content" id="myTabContent">
                    <div class="card card-xxl-stretch tab-pane fade show active" id="kt_accounting_1" role="tabpanel" v-if="this.activeTab == 'Account'">
                        <form id="poliform" @submit.prevent="" class="form fv-plugins-bootstrap5 fv-plugins-framework">
                            <div class="d-flex flex-row row p-10"> 
                                <div class="d-flex flex-column flex-row-auto col-lg-6">
                                    <div class="d-flex flex-column-fluid card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                        <div class="card-body"> 
                                                <div class="fv-row mb-7 row">
                                                    <div class="col-lg-12" v-if="isViewAccountId">
                                                        <label class="fs-6 fw-bold form-label mt-3" :class="{'required': isViewAccountId}">
                                                            {{ $t('Account ID') }}
                                                        </label>
                                                        <input type="text" class="form-control" name="accountID" v-model="form.account_id">
                                                    </div>
                                                    <div class="col-lg-12">
                                                        <label class="fs-6 fw-bold form-label mt-3" v-tag="'name'"> 
                                                            {{ $t('Name / Company Name') }} 
                                                        </label>
                                                        <div class="input-group">
                                                            <input type="text" class="form-control" v-custom name="name" v-model="form.name">
                                                            <button type="button" v-if="userData.is_company_inquiry_active == 1 && this.$isCredentialActive(10,'CI')"
                                                                    class="input-group-text m-0 text-hover-success cursor-pointer" 
                                                                    id="basic-addon2" 
                                                                    @click="onSearchCompanyByName()" 
                                                                    data-bs-toggle="modal" 
                                                                    data-bs-target="#kt_modal_search_company" 
                                                                    data-kt-menu-trigger="click" 
                                                                    data-kt-menu-placement="bottom-end"
                                                                    :disabled="form.name == null || form.name == ''">
                                                                <i class="fas fa-search fs-4 me-3"></i> {{ $t('Search by Name') }}
                                                            </button>
                                                        </div>
                                                    </div>    
                                                    <div class="col-lg-12">
                                                        <div class="fv-row">
                                                            <label class="fs-6 fw-bold form-label mt-3" v-tag="'member_type'">
                                                                <span>{{ $t('Member Type') }}</span>
                                                            </label> 
                                                            <Select2 name="member_type" v-model="form.company_account_type" :options="companyAccountTypeList" v-custom :settings="{ width: '100%'}"/>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12" v-if="form.company_account_type == 1">
                                                        <label class="fs-6 fw-bold form-label mt-3"  v-tag="'registration_number'">
                                                            {{ $t('Registration Number') }}
                                                        </label>
                                                        <div class="input-group">
                                                            <input type="text" class="form-control" name="registration_number" v-custom
                                                                v-model="form.account_number">
                                                            <button v-if="userData.is_company_inquiry_active == 1 && this.$isCredentialActive(10,'CI')" type="button" class="input-group-text m-0 cursor-pointer text-hover-primary" id="basic-addon2" @click="onSearchCompanyByNo()"
                                                                    :disabled="form.account_number == null || form.account_number == ''">
                                                                <i class="fas fa-search fs-4 me-3"></i> {{ $t('Search by No') }}
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12" v-if="form.company_account_type == 2">
                                                        <label class="fs-6 fw-bold form-label mt-3" v-tag="'personal_id'">
                                                            {{ $t('Personal ID') }}
                                                        </label>
                                                        <input type="text" class="form-control" v-model="form.personal_id" name="personal_id" v-custom>
                                                    </div>
                                                    <div class="col-lg-12">
                                                        <label class="fs-6 fw-bold form-label mt-3" v-tag="'contact_name'">{{ $t('Contact Name') }}</label>
                                                        <input type="text" class="form-control" name="contact_name" v-model="form.contact_name" v-custom>
                                                    </div>
                                                    <div class="col-lg-12">
                                                        <input class="form-check-input mt-6" type="checkbox" v-model="form.is_update_locked" :disabled="!this.$isCredentialActive(10,'CUL')" />
                                                        <label class="fs-6 fw-bold form-label mt-7 ms-4" v-tag="'is_update_locked'">
                                                            <i class="bi bi-lock me-1"></i>
                                                            {{ $t('Lock Update') }}
                                                        </label>
                                                    </div>
                                                </div> 
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex flex-column flex-row-auto col-lg-6">
                                    <div class="d-flex flex-column-fluid card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                        <div class="card-body">                             
                                                <div class="fv-row mb-7 row">
                                                    <div class="col-lg-12">
                                                        <div class="fv-row fv-plugins-icon-container">
                                                            <label class="fs-6 fw-bold form-label mt-3" v-tag="'email'">{{ $t('Email') }} 
                                                            </label>
                                                            <input type="email" class="form-control" name="email" v-model="form.email" :class="{'border border-danger': isValidEmail == false}" v-custom>
                                                            <small class="text-danger" v-if="!isValidEmail && form.email !== null && form.email !== '' ">{{ $t('Email is not valid') }}</small>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12">
                                                        <div class="fv-row">
                                                            <label class="fs-6 fw-bold form-label mt-3" v-tag="'mobile_phone'">{{ $t('Mobile Phone') }}  
                                                            </label>
                                                            <div class="input-group">
                                                                <Select2 v-model="form.phone_country_code" :options="phoneMaskList" :settings="{width: '120px'}"/>
                                                                <input type="text" class="form-control" name="mobile_phone" v-model="form.mobile_phone" v-custom>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12">
                                                        <div class="fv-row">
                                                            <label class="fs-6 fw-bold form-label mt-3" v-tag="'phone'">
                                                                <span>{{ $t('Phone') }}</span>
                                                            </label>
                                                            <div class="input-group">
                                                                <Select2 v-model="form.land_phone_country_code" :options="phoneMaskList" :settings="{width: '120px'}"/>
                                                                <input type="text" class="form-control" name="phone" v-model="form.phone" v-custom>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12">
                                                        <div class="fv-row">
                                                            <label class="fs-6 fw-bold form-label mt-3" v-tag="'customer_type'">
                                                                <span>{{ $t('Customer Type') }}</span>
                                                            </label> 
                                                            <Select2 v-model="form.customer_type_id" :options="customerTypeList" v-custom name="customer_type" :settings="{ width: '100%'}"/>  
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <div class="fv-row">
                                                            <label class="fs-6 fw-bold form-label mt-3" v-tag="'sales_channel'">
                                                                <span>{{ $t('Sales Channel') }}</span>
                                                            </label>
                                                            <Select2 v-model="form.sales_channel_id" :options="salesChannelList" v-custom name="sales_channel" :settings="{ width: '100%'}"/> 
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <div class="fv-row">
                                                            <label class="fs-6 fw-bold form-label mt-3" v-tag="'payment_value'">
                                                                <span>{{ $t('Payment Value') }}</span>
                                                                <small class="fs-8"> ({{ $t('Day') }}) </small>
                                                            </label>
                                                            <input type="text" class="form-control" name="payment_value" v-model="form.payment_value" v-custom>
                                                        </div>
                                                    </div>
                                                </div> 
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                        <div class="card-body pb-0">                          
                                                <div class="fv-row mb-6 row">
                                                    <GetAddress @address-selected="onSelectAddress" :account-type="1" v-if="userData.is_address_inquiry_active == 1 && userData.address_inquiry_sub_type == 2 && this.$isCredentialActive(10,'AI')"/>
                                                    <div class="col-lg-6">
                                                        <div class="fv-row">
                                                            <label class="fs-6 fw-bold form-label mt-3" v-tag="'post_code'">{{ $t('Post Code') }}                                                                                                             
                                                            </label>
                                                            <div class="input-group">
                                                                <input type="text" class="form-control" name="post_code" v-model="form.postcode" v-custom>
                                                                <button v-if="userData.is_address_inquiry_active == 1 && userData.address_inquiry_sub_type == 1 && this.$isCredentialActive(10,'AI')" type="button" class="input-group-text m-0 cursor-pointer text-hover-info" 
                                                                        id="basic-addon2" 
                                                                        @click="this.onFindAddressByPostCode()" 
                                                                        data-bs-toggle="modal" 
                                                                        data-bs-target="#kt_modal_search_address" 
                                                                        data-kt-menu-trigger="click" 
                                                                        data-kt-menu-placement="bottom-end"
                                                                        :disabled="this.form.postcode == null || this.form.postcode == ''">
                                                                    <i class="fas fa-search fs-4 me-3"></i> {{ $t('Search by Post Code') }}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <div class="fv-row">
                                                            <label class="fs-6 fw-bold form-label mt-3" v-tag="'address_code'">{{ $t('Address Code') }}
                                                            </label>
                                                            <div class="input-group">
                                                                <input type="text" class="form-control" name="address_code" v-model="form.uprn" v-custom>
                                                                <button v-if="userData.is_address_inquiry_active == 1 && userData.address_inquiry_sub_type == 1 && this.$isCredentialActive(10,'AI')" type="button" class="input-group-text m-0 cursor-pointer text-hover-info" 
                                                                        id="basic-addon2" 
                                                                        @click="this.onFindAddressByAddressCode()" 
                                                                        data-bs-toggle="modal" 
                                                                        data-bs-target="#kt_modal_search_address_by_code" 
                                                                        data-kt-menu-trigger="click" 
                                                                        data-kt-menu-placement="bottom-end"
                                                                        :disabled="this.form.uprn == null || this.form.uprn == ''">
                                                                    <i class="fas fa-search fs-4 me-3"></i> {{ $t('Search by Address Code') }}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12">
                                                        <div class="fv-row">
                                                            <label class="fs-6 fw-bold form-label mt-3" v-tag="'address'">{{ $t('Address') }}
                                                            </label>
                                                            <textarea class="form-control" name="address" v-model="form.address" v-custom></textarea>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-4">
                                                        <div class="fv-row">
                                                            <label class="fs-6 fw-bold form-label mt-3" v-tag="'city'">{{ $t('City') }}
                                                            </label>
                                                            <input type="text" class="form-control" name="city" v-model="form.city" v-custom>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-4">
                                                        <div class="fv-row">
                                                            <label class="fs-6 fw-bold form-label mt-3" v-tag="'district'">{{ $t('District') }}                                                    
                                                            </label>
                                                            <input type="text" class="form-control" name="district" v-model="form.district" v-custom>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-4">
                                                        <div class="fv-row">
                                                            <label class="fs-6 fw-bold form-label mt-3" v-tag="'country'">
                                                                {{ $t('Country') }}
                                                            </label>
                                                            <Select2 name="country" v-model="form.country" :options="countryList" :settings="{width: '100%'}" @select="onChangeCountry($event)" v-custom/>
                                                        </div>
                                                    </div> 
                                                </div> 
                                        </div>
                                    </div>                    
                                </div>
                                <div class="col-lg-12">
                                    <div class="card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                        <div class="card-body pb-0">                              
                                                <div class="fv-row row">
                                                    <div class="col-lg-6">
                                                        <label class="fs-6 fw-bold form-label mt-3" v-tag="'is_vat_account'">{{ $t('VAT Account?') }}</label> 
                                                        <br>
                                                        <div class="form-check form-check-inline mt-5">
                                                            <input class="form-check-input" type="radio" name="is_vat_account" id="inlineRadio1" value="false"
                                                                v-model="form.isVATNumberExist" v-custom>
                                                            <label class="form-check-label" for="inlineRadio1">{{ $t('No') }}</label>
                                                        </div>
                                                        <div class="form-check form-check-inline">
                                                            <input class="form-check-input" type="radio" name="is_vat_account" id="inlineRadio2" value="true"
                                                            v-model="form.isVATNumberExist" v-custom>
                                                            <label class="form-check-label" for="inlineRadio2">{{ $t('Yes') }}</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <div class="fv-row mb-7">
                                                            <label class="fs-6 fw-bold form-label mt-3" v-tag="'vat_number'" :class="{'required' : this.form.isVATNumberExist == 'true'}">
                                                                {{ $t('VAT Number') }}                                                            
                                                            </label>
                                                            <input type="text" class="form-control" name="vat_number" v-model="form.vat_number" :disabled="form.isVATNumberExist == 'false'" v-custom>
                                                        </div>
                                                    </div>
                                                </div> 
                                        </div>
                                    </div>                    
                                </div>
                                <div class="col-lg-12">
                                    <div class="card card-xxl-stretch mb-6 bg-lighten border border-gray-300" id="kt_accounting_1" role="tabpanel">
                                        <div class="card-header">
                                            <div class="card-title">
                                                {{ $t('Add Default Vendor') }}
                                            </div>
                                        </div>
                                        <div class="card-body"> 
                                            <form id="kt_ecommerce_settings_general_form" @submit.prevent="" class="form fv-plugins-bootstrap5 fv-plugins-framework">  
                                                <div class="fv-row mb-6 row">   
                                                    <div class="col-lg-4">
                                                        <div class="fv-row">
                                                            <label class="fs-6 fw-bold form-label mt-3" v-tag="'main_branch_id'">
                                                                {{ $t('Vendor') }}
                                                            </label> 
                                                            <Select2 v-model="vendorForm.vendor_id" :options="vendorSelectionList" name="vendor" v-custom :settings="{width: '100%'}"/> 
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-4">
                                                        <div class="fv-row">
                                                            <label class="fs-6 fw-bold form-label mt-3" v-tag="'main_branch_id'">
                                                                {{ $t('Main Branch') }}
                                                            </label> 
                                                            <Select2 v-model="vendorForm.main_branch_id" :options="mainBranchList" :settings="{width: '100%'}" @select="getPolicyParentList(vendorForm.main_branch_id)" v-custom name="main_branch_id"/>  
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-4">
                                                        <div class="fv-row">
                                                            <label class="fs-6 fw-bold form-label mt-3" v-tag="'policy_branch_id'">
                                                                {{ $t('Policy Branch') }}
                                                            </label> 
                                                            <Select2 v-model="vendorForm.policy_branch_id" :options="policyBranchList" :settings="{width: '100%'}" v-custom name="policy_branch_id"/>  
                                                        </div>
                                                    </div>
                                                </div> 
                                            </form>
                                            <div class="separator my-10"></div>
                                            <div class="d-flex flex-stack flex-wrap my-5">
                                                <div class="d-flex justify-content-end" data-kt-docs-table-toolbar="base">
                                                    <button type="button" class="btn btn-sm btn-light btn-active-success me-3" @click="addVendorItem()">
                                                        <span class="svg-icon svg-icon-2">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                                viewBox="0 0 24 24" fill="none">
                                                                <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1"
                                                                    transform="rotate(-90 11.364 20.364)" fill="currentColor"></rect>
                                                                <rect x="4.36396" y="11.364" width="16" height="2" rx="1"
                                                                    fill="currentColor">
                                                                </rect>
                                                            </svg>
                                                        </span>
                                                        {{ $t('Add') }}
                                                    </button>
                                                    <button type="button" class="btn btn-sm btn-light btn-active-warning me-3" @click="deleteVendorItem()" 
                                                        :disabled="isButtonDisabled == null || isButtonDisabled == undefined">
                                                        <span class="svg-icon svg-icon-2">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"/>
                                                            </svg>
                                                        </span>
                                                        {{ $t('Remove') }}
                                                    </button>
                                                    <button type="button" class="btn btn-sm btn btn-light btn-active-danger me-3" @click="deleteAllVendors()" :disabled="this.vendorFilteredList.length == 0">
                                                        <span class="svg-icon svg-icon-2">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"/>
                                                            </svg>
                                                        </span>
                                                        {{ $t('Remove All (!)') }}
                                                    </button>
                                                </div>
                                            </div>
                                            <div id="kt_datatable_example_1_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                                                <div class="table-responsive">
                                                    <DxDataGrid id="gridContainer" :show-borders="true" 
                                                        key-expr="id" 
                                                        :data-source="vendorList" 
                                                        :allow-column-reordering="true"  
                                                        :allow-column-resizing="true" 
                                                        :column-auto-width="true" 
                                                        :row-alternatin-enabled="true"
                                                        :hover-state-enabled="true" 
                                                        :show-row-lines="true" 
                                                        @row-updating="onRowUpdating"
                                                        @edit-canceled="getTempVendor"> 

                                                        <DxFilterRow :visible="true"/>
                                                        <DxHeaderFilter :visible="true"/>
                                                        <DxGroupPanel :visible="false"/>
                                                        <DxPaging :page-size="1000"/>
                                                        <DxPager :visible="false" :allowed-page-sizes="pageSizes" :show-page-size-selector="true" :show-info="true" :show-navigation-buttons="true"/> 
                                                        <DxScrolling
                                                            mode="virtual"
                                                            row-rendering-mode="virtual"
                                                            
                                                        />                                                                
                                                        <DxEditing :allow-updating="this.vendorFilteredList.length > 0 ? true : false" mode="row" />
                                                        <DxColumn data-field="isSelected" caption="#" :width="100" :allow-filtering="false" alignment="center" cell-template="is-selected-cell-template" :allowEditing="false"/>
                                                        <DxColumn data-field="account_id" :caption="$t('Code')" cell-template="account_id-cell-template" :allowEditing="false"/>
                                                        <DxColumn data-field="vendor_name" :caption="$t('Name')" cell-template="vendor_name-cell-template" :allowEditing="false"/> 
                                                        <DxColumn data-field="main_branch_name" :caption="$t('Main Branch')" cell-template="main_branch_name-cell-template" :allowEditing="true" :set-cell-value="setMainBranch">
                                                            <DxLookup :data-source="mainBranchList" value-expr="id" display-expr="text" v-model="vendorForm.main_branch_id"/>
                                                        </DxColumn> 
                                                        <DxColumn data-field="policy_branch_name" :caption="$t('Policy Branch')" cell-template="policy_branch_name-cell-template" :allowEditing="true"  :set-cell-value="setPolicyBranch">
                                                            <DxLookup :data-source="policyBranchList" value-expr="id" display-expr="text" v-model="vendorForm.policy_branch_id"/>
                                                        </DxColumn> 
                                                        <DxColumn data-field="vendor_type_name" :caption="$t('Type')" cell-template="vendor_type_name-cell-template" :allowEditing="false"/> 

                                                        <template #is-selected-cell-template="{ data }"> 
                                                            <span>
                                                                <div class="form-check form-check-sm form-check-custom form-check-solid justify-content-center">
                                                                    <input class="form-check-input" type="checkbox" v-model="data.data.isSelected" @change="this.onSelectItem(data.data)" />
                                                                </div>
                                                            </span>
                                                        </template>
                                                        <template #account_id-cell-template="{ data }"> 
                                                            <span>
                                                                {{ data.data.account_id }}
                                                            </span>
                                                        </template>
                                                        <template #vendor_name-cell-template="{ data }"> 
                                                            <span>
                                                                {{ data.data.vendor_name }}
                                                            </span>
                                                        </template>
                                                        <template #main_branch_name-cell-template="{ data }"> 
                                                            <span>
                                                                {{ data.data.main_branch_name }}
                                                            </span>
                                                        </template>
                                                        <template #policy_branch_name-cell-template="{ data }"> 
                                                            <span>
                                                                {{ data.data.policy_branch_name }}
                                                            </span>
                                                        </template>
                                                        <template #vendor_type_name-cell-template="{ data }"> 
                                                            <span>
                                                                {{ data.data.vendor_type_name }}
                                                            </span>
                                                        </template>

                                                        <DxSummary>
                                                            <DxTotalItem column="id" summary-type="count" display-format="Record: {0}" show-in-column="isSelected"/>
                                                        </DxSummary>
                                                    </DxDataGrid>
                                                </div>
                                            </div>
                                        </div>
                                    </div> 
                                </div>
                                <div class="col-lg-12">
                                    <div class="card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                        <div class="card-body pb-0">
                                            <div class="fv-row mb-7">
                                                <label class="fs-6 fw-bold form-label mt-3" v-tag="'notes'">
                                                    <span>{{ $t('Notes') }}</span>
                                                </label>
                                                <textarea class="form-control" name="notes" v-model="form.notes" v-custom></textarea>
                                            </div>
                                        </div>
                                    </div>                    
                                </div>
                                <div class="col-lg-12 mt-0" v-if="this.$isCredentialActive(10,'U')">
                                    <div class="d-grid gap-2"> 
                                        <button type="button" class="btn btn-lg btn-primary mb-0" @click="this.saveData()" data-kt-indicator="on" :disabled="isValidEmail == false || !this.$isCredentialActive(10,'ULR')">
                                            <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                            <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                            </svg>
                                            </span>
                                            <strong v-if="!this.isProgressing"> {{ $t('Update Customer') }} </strong> 
                                                <span class="indicator-progress" v-if="this.isProgressing">
                                                    {{ $t('Saving Data...') }} <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                </span> 
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="card card-xxl-stretch tab-pane fade show active" id="kt_accounting_4" role="tabpanel" v-if="this.activeTab == 'Documents'">
                        <div class="row p-10">
                            <FileManager :location="1" :locationtype="1" :upload_source="4" ref="FileManagerComponent"></FileManager>
                        </div>
                    </div>
                    <div class="tab-pane fade show active" id="kt_accounting_2" role="tabpanel" v-if="this.activeTab == 'AccountGroup'">
                        <AccountGroupTab ref="AccountGroupTab" :account-info="accountInfo"></AccountGroupTab>
                    </div>
                    <div class="tab-pane fade show active"  id="kt_accounting_5" role="tabpanel" v-if="this.activeTab == 'Factors'" >
                        <AccountFactors ref="AccountFactors"></AccountFactors>
                    </div>
                    <div class="tab-pane fade show active"  id="kt_accounting_6" role="tabpanel" v-if="this.activeTab == 'Invoices'" >
                        <InvoiceList ref="InvoiceList" />
                    </div>
                </div> 
            </div>
        </div>

        <!-- SEARCH COMPANY BY NAME RESULT -->
        <div class="modal fade" id="kt_modal_search_company" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
            <SearchCompanyByName ref="SearchCompanyByName" :account-name="this.form.name" :account-type="1"></SearchCompanyByName>
        </div>

        <!-- SEARCH ADDRESS BY POSTCODE RESULT -->
        <div class="modal fade" id="kt_modal_search_address" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
            <FindAddressByPostCode ref="FindAddressByPostCode" :post-code="this.form.postcode" :account-type="1"></FindAddressByPostCode>
        </div>
        <!-- SEARCH ADDRESS BY ADDRESS CODE RESULT -->
        <div class="modal fade" id="kt_modal_search_address_by_code" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
            <FindAddressByAddressCode ref="FindAddressByAddressCode" :address-code="this.form.uprn" :account-type="1"></FindAddressByAddressCode>
        </div>

    </div>
</template>


<script>
import axios from "axios"; 
import Swal from "sweetalert2";
import moment from 'moment';
import SearchCompanyByName from '../../Accounts/Modals/SearchCompanyByName.vue';
import FindAddressByPostCode from '../../Accounts/Modals/FindAddressByPostCode.vue';
import AccountGroupTab from '../../Accounts/Modals/AccountGroupTab.vue';
import FindAddressByAddressCode from '../../Accounts/Modals/FindAddressByAddressCode.vue';
import AccountFactors from '../Modals/AccountFactors.vue';
import { DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxPaging, DxPager, DxColumn, DxTotalItem, DxSummary, DxScrolling, DxEditing, DxLookup } from 'devextreme-vue/data-grid';
import InvoiceList from '../../Transaction/Invoice/List.vue';

export default {
  name: "CustomerUpdate",
  components:{
    SearchCompanyByName,
    FindAddressByPostCode,
    AccountGroupTab,
    DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxPaging, DxPager, DxColumn, DxTotalItem, DxSummary, FindAddressByAddressCode, DxScrolling, DxEditing, DxLookup, AccountFactors, InvoiceList
  },
  data() {
    return { 
        pageIndex: 0,
        pageSize: 10,
        userData: this.$getUser(),
        activeTab: 'Account',
        lists: [],
        companyAccountTypeList: [
            {id: 1, text: 'Company'},
            {id: 2, text: 'Person'},
        ],
        customerTypeList: [],
        salesChannelList:[],
        countryList: [],
        cityList: [],
        addressList: [],
        criteriaList: [
            // { id: '1', text: 'ALL'},
            { id: '2', text: 'Financial Year'},
            { id: '3', text: 'Accounting Period'}
        ],
        yearList: [
            { id: '1', text: 'ALL'},
            { id: '2', text: '2020'},
            { id: '3', text: '2021'},
        ],
        phoneMaskList: [],
        isProgressing: false,
        form: {
            id: null,
            name: null,
            company_account_type: 2,
            company_type_id: null,
            customer_company_type_id: null,
            account_type: null,
            account_id: null,
            qr_code: null,
            notes: null, 
            account_number: null,
            personal_id: null,
            contact_name: null,
            email: null,
            mobile_phone: null,
            phone: null,
            postcode: null,
            address: null,
            address_id: '-1',
            country: null,
            city: null,
            vat_number: null,
            payment_value: null,
            isVATNumberExist: 'false',
            accounting_plan: null,
            sales_channel_id: '-1',
            customer_type_id: '-1',
            customer_type_name: null,
            criteria: '2',
            year_selection: '2',
            start_date: moment(),
            end_date: moment(),
            phone_country_code: null,
            land_phone_country_code: null,
            district: null,
            x_coordinate: 0,
            y_coordinate: 0,
            uprn: null,
            udprn: null,
            is_update_locked: false,
            is_main_group: 0
        },
        isViewAccountId: false,
        menuSelection: '/CustomerHelppage',
        iconView : false,
        vendorForm: {
            id: null,
            vendor_id: '-1',
            main_branch_id: '-1',
            policy_branch_id: '-1'
        },
        vendorSelectionList: [],
        vendorList: [],
        policyBranchList: [{ id: '-1', text: 'ALL'}],
        mainBranchList: [{ id: '-1', text: 'ALL'}],
        isItemSaving: false,
        accountInfo: {},
        selectedVendorId: null
    };
  }, 
  computed: {
		isValidEmail() {
			return (this.form.email == null || this.form.email == '' || this.form.email == undefined) ? null : /^[^@]+@\w+(\.\w+)+\w$/.test(this.form.email);
		},
        previewDate(){
            return (value)=>{
            if (value) {
                return moment(new Date(value)).format('DD.MM.YYYY')
                }
            }
        },
        isButtonDisabled() {
            return this.vendorList.find(item => item.isSelected == true && item.id !== '' );
        },
        vendorFilteredList(){
            return this.vendorList.filter(p => { return p.id !== ''; });
        },
	},
  methods: {
    getData() {
        this.$Progress.start();
        this.$setSessionStorage('tmp_session_id', null);
        this.addressList = [];
        this.addressList.unshift({ id: '-1', text: 'Select an option'}); 

        const params = {
            id: this.$getSessionStorage('record_id'),
            session_id: this.$getUser().session_id
        };
        axios.post('api/CompanyAccounts/GetAccountById', params, {'Content-Type': 'application/json'})
        .then((response) => { 
            this.form.id = response.data.id;
            this.form.name = this.$unlock(response.data.name);
            this.form.account_id = this.$unlock(response.data.account_id);
            this.form.qr_code = this.$unlock(response.data.qr_code);
            this.form.account_number = this.$unlock(response.data.customer_account_number);
            this.form.personal_id = this.$unlock(response.data.customer_account_number);
            this.form.contact_name = this.$unlock(response.data.customer_contact_name);
            this.form.email = this.$unlock(response.data.customer_email);
            this.form.mobile_phone = this.$unlock(response.data.customer_mobile_phone);
            this.form.phone = this.$unlock(response.data.customer_phone);
            this.form.postcode = this.$unlock(response.data.customer_postcode);
            this.form.address = this.$unlock(response.data.customer_address);
            this.form.country = this.$unlock(response.data.customer_country);
            if(this.form.country == '' || this.form.country == null) this.form.country = '-1';
            this.form.city = this.$unlock(response.data.customer_city);
            this.form.district = this.$unlock(response.data.customer_district);
            this.form.x_coordinate = this.$unlock(response.data.customer_x_coordinate);
            this.form.y_coordinate = this.$unlock(response.data.customer_y_coordinate);
            this.form.x_coordinate = (this.form.x_coordinate == null || this.form.x_coordinate == '') ? 0 : Math.abs(parseFloat(this.form.x_coordinate.replace(',', '.')));
            this.form.y_coordinate = (this.form.y_coordinate == null || this.form.y_coordinate == '') ? 0 : Math.abs(parseFloat(this.form.y_coordinate.replace(',', '.')));
            this.form.uprn = this.$unlock(response.data.customer_uprn);
            this.form.udprn = this.$unlock(response.data.customer_udprn);
            this.form.company_account_type = response.data.customer_company_account_type;
            this.form.vat_number = this.$unlock(response.data.customer_vat_number);
            this.form.payment_value = response.data.customer_payment_value;
            this.form.phone_country_code = this.$unlock(response.data.customer_phone_country_code);
            this.form.land_phone_country_code = this.$unlock(response.data.customer_land_phone_country_code);
            this.form.is_main_group = response.data.is_main_group;
            this.form.sales_channel_id = (response.data.customer_sales_channel_id == '' || response.data.customer_sales_channel_id == null) ? '-1': response.data.customer_sales_channel_id;
            this.form.customer_type_id = (response.data.customer_customer_type_id == '' || response.data.customer_customer_type_id == null) ? '-1': response.data.customer_customer_type_id;
            this.form.is_update_locked = response.data.is_update_locked == 1 ? true : false;
            this.form.account_type = response.data.account_type;
            this.form.notes = response.data.notes;
            if(this.form.vat_number !== null && this.form.vat_number !== ''){
                this.form.isVATNumberExist =  'true';
            } 
            this.accountInfo = {
                id: this.form.id,
                account_type: this.form.account_type,
                is_main_group: this.form.is_main_group
            };
            this.getLists();
        })
        .catch(function (error) {
            console.log(error);
            this.$swal("Failed", "Data could not be saved..Please refresh the page and try again..!");   
        });  
    },
    getLists() {
        const parameters = {
            session_id: this.$getUser().session_id,
            list_name: ["account_setting", "sales_channel", "country"],
            account_type: 1, // yalnızca account settings listesi çekilecek ise gerekli
            country_code: this.$getUser().company_country_code, // yalnızca Company Types listesi çekilecek ise gerekli
            list_type: 14, // Customer Type: 14            
            get_access_status: 0,
            type_id: 1
        };  
        const parameters2 = {
            account_id: this.$getSessionStorage('record_id'),
            session_id: this.$getUser().session_id,
            supplier_account_type: -1, // ALL ( 2:Insurance Vendors and 3: Sales Vendors)
        };  
        const parameters3 = {
            session_id: this.$getUser().session_id,
            list_type: 1 /* Main Branch */
        };
        
        const requestOne =  axios.post('/api/Helper/GeneralLists', parameters, {'Content-Type': 'application/json'});
        const requestTwo =  axios.post('/api/Helper/GetLists', parameters, {'Content-Type': 'application/json'}); 
        const requestThree =  axios.post('/api/Helper/GetPhoneMasks', parameters, {'Content-Type': 'application/json'}); 
        const requestFour=  axios.post('/api/GetCompanyAccounts/GetCustomerVendorsByAccountId', parameters2, {'Content-Type': 'application/json'}); 
        const requestFive=  axios.post('/api/GetCompanyAccounts/GetPolicyAccounts', parameters2, {'Content-Type': 'application/json'});
        const requestSix =  axios.post('/api/Helper/GetPolicyLists', parameters3, {'Content-Type': 'application/json'});
        axios.all([requestOne, requestTwo, requestThree, requestFour, requestFive, requestSix]).then(axios.spread((...responses) => {
                this.salesChannelList = responses[0].data.sales_channel;
                this.customerTypeList = responses[1].data;
                this.phoneMaskList = responses[2].data;
                this.countryList = responses[0].data.country; 
                if (responses[0].data.account_setting.is_default == false) { this.isViewAccountId = true; }
                this.salesChannelList.unshift({ id: '-1', text: 'Select an option'}); 
                this.customerTypeList.unshift({ id: '-1', text: 'Select an option'});
                this.countryList.unshift({ id: '-1', text: 'Select an option'});
                this.vendorList = responses[3].data;
                this.vendorSelectionList = responses[4].data;
                this.mainBranchList = responses[5].data;
                this.customerTypeList.forEach(element => { if (element.id == this.form.customer_type_id && element.id !== '-1') { this.form.customer_type_name = element.text; } });
                if(this.vendorList.length > 0){ this.$setSessionStorage('tmp_session_id', responses[3].data[0].tmp_session_id); }
                this.vendorSelectionList.unshift({ id: '-1', text: 'Select an option'});
                this.mainBranchList.unshift({ id: '-1', text: 'ALL'});
                
                if(this.form.phone_country_code == null || this.form.phone_country_code == '' || this.form.phone_country_code == undefined) {
                    this.phoneMaskList.forEach(element => {
                        if(element.code == this.$getUser().company_country_code) { this.form.phone_country_code = element.id; }
                    });
                }
                if(this.form.land_phone_country_code == null || this.form.land_phone_country_code == '' || this.form.land_phone_country_code == undefined) {
                    this.phoneMaskList.forEach(element => {
                        if(element.code == this.$getUser().company_country_code) { this.form.land_phone_country_code = element.id; }
                    });
                }
                this.$Progress.finish();   
            })
          )
          .catch(errors => { 
            console.error(errors); 
            this.$Progress.finish(); 
        });
    },
    saveData() {
        this.$checkSelect2Validation('poliform');
        if(!document.forms['poliform'].reportValidity()){
            return;
        }

        try{
            var data = sessionStorage.getItem('frm_val'); 
            var vendorTableValidation = JSON.parse(data).find((item) => item.field == "vendor_table");
            if(vendorTableValidation !== null && vendorTableValidation !== undefined && vendorTableValidation !== ''){
                if(vendorTableValidation.is_required){
                    if(this.vendorList.length == 0){
                        this.$swal("Failed", "Vendor Table entry is required..!", 'error');
                        return;
                    }
                } 
            }
        }
        catch(Err){
            console.error(Err);
        }

        this.isProgressing = true;
        const params = this.form;    
        const that = this;
        params.session_id = this.$getUser().session_id;
        params.tmp_session_id = this.$getSessionStorage('tmp_session_id');
        if(params.country == '-1'){ params.country = null }
        if(params.sales_channel_id == '-1'){ params.sales_channel_id = null }
        if(params.customer_type_id == '-1'){ params.customer_type_id = null }
        if(params.company_account_type == 2) {params.account_number = params.personal_id;}
        params.is_update_locked = params.is_update_locked == true ? 1 : 0;
        axios.post('api/CompanyAccounts/UpdateAccount', params, {'Content-Type': 'application/json'})
        .then((response) => { 
            if(response.data != null && response.data.length == 36){  
                this.$swal("Success", "Data saved succesfully..!", 'success'); 
            }
            if(this.form.country == null) {this.form.country = '-1';}
            if(this.form.sales_channel_id == null) {this.form.sales_channel_id = '-1';}
            if(this.form.customer_type_id == null) {this.form.customer_type_id = '-1';}
            this.form.is_update_locked = this.form.is_update_locked == 1 ? true : false;
            this.getLists();
        })
        .catch(function () { 
            new Swal( { icon: 'error', title: 'Error...', text: 'Data could not be saved..Please refresh the page and try again..!' });
        })
        .finally(function () { 
            setTimeout(() => {
                that.isProgressing = false;
            }, 500);
        }); 
    },
    deleteItem(){
        this.$swal.fire({
            title: 'Are you sure you want to delete this data?',
            confirmButtonColor: '#f1416c',
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: 'Yes',
            icon: 'warning'
          }).then((result) => { 
            if (result.isConfirmed) {
                const params = {
                    session_id: this.$getUser().session_id,
                    id: this.form.id
                }; 
                axios.post('api/CompanyAccounts/DeleteAccount', params, {'Content-Type': 'application/json'})
                .then((response) => { 
                    if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){ 
                        this.$router.push({ name: 'CustomerList' });
                    }
                })
                .catch(function () { 
                    new Swal( { icon: 'error', title: 'Error...', text: 'Data could not be deleted..Please refresh the page and try again..!' });   
                }); 
            }
        })   
    },
    async helpPageViewIcon()
    {
        const queryparameter = {
            session_id : this.$getUser().session_id,
            menu_selection : '/CustomerHelppage'
        };
        axios.post('api/HelpPage/HelpPagesContent', queryparameter, {'Content-Type': 'application/json'})
        .then((response) => { 
            if(!response.data.error){ 
                this.iconView = response.data;
            }
        })
    }, 
    getAccountingYears(){
        this.isAccountingYearLoading = true;
        this.companyAccountingPeriods = [];
        const queryParameter = {
            session_id: this.$getUser().session_id
        };
        axios.post('/api/Auth/GetCompanyAccountingYears', queryParameter, {'Content-Type': 'application/json'})
        .then((response) => {   
                this.accountingYears = response.data; 
                this.accountingYears.forEach((period) => {
                    if(period.id == this.$getSessionStorage('activeYear')){
                        this.accountingYearSelection = period;
                    } 
                });  
                setTimeout(() => {
                    if(this.accountingYearSelection == null){ 
                        this.accountingYears.forEach((period) => {
                            if(period.id == this.userData.period_id){
                                this.accountingYearSelection = period;
                            } 
                        }); 
                    }
                }, 300); 
                setTimeout(() => {
                    this.isAccountingYearLoading = false;
                }, 1000); 
        })
        .catch(function (error) {
            this.isAccountingYearLoading = false;
            console.log(error);
        }); 
    },
    activateDocumentsSection(){
        this.activeTab = 'Documents'; 
    }, 
    onChangeCountry(event) {
        this.form.phone_country_code = event.phone_country_code;
        this.form.land_phone_country_code = event.phone_country_code;
    },
    onSearchCompanyByNo(selectedCompanyNumber){
        if(selectedCompanyNumber) {
            this.form.account_number = selectedCompanyNumber;
        }
        const parameters = {
            CompanyNumber: this.form.account_number,
            SessionId: this.$getUser().session_id,
            SourceType: 1
        }
        const that = this;
        axios.post('api/GetCompanyAccounts/SearchByNo', parameters, {'Content-Type': 'application/json'})
        .then((response) => { 
            if(response.data.error == null || response.data.error == '' || response.data.error == undefined){
                this.form.name = response.data.name;
                this.form.account_number = response.data.number;
                this.form.contact_name = response.data.contactName;
                this.form.postcode = response.data.postalCode;
                this.form.address = response.data.addressLine2 == null ? response.data.addressLine1 : response.data.addressLine1 + ' '+ response.data.addressLine2; 
                //this.form.country = response.data.country; Çıktı England şeklinde?
                this.form.city = response.data.locality;
                this.companySearchResult = [];

                if(this.form.account_number !== null && this.form.account_number !== '' && this.form.account_number !== undefined) {
                    this.form.company_account_type = 1;
                }
            }
        })
        .catch(function (error) {
            console.log(error);
            that.$swal("Error", "No records found..!", 'error');
        });
    },
    onSearchCompanyByName(){
        this.$refs.SearchCompanyByName.getData();
    },
    onFindAddressByPostCode(){  
        this.$refs.FindAddressByPostCode.getData();
    },
    onFindAddressByAddressCode(){  
        this.$refs.FindAddressByAddressCode.getData();
    },
    onSelectAddressSearchResult(item){
        this.form.address = item.ADDRESS;
        this.form.city = item.POST_TOWN;
        this.form.district = item.LOCAL_CUSTODIAN_CODE_DESCRIPTION;
        this.form.x_coordinate = item.X_COORDINATE;
        this.form.y_coordinate = item.Y_COORDINATE;
        this.form.uprn = item.UPRN;
        this.form.udprn = item.UDPRN;
        this.form.postcode = item.POSTCODE;
    },
    getPolicyParentList(parentId, isChanged) {
        if(!isChanged) this.vendorForm.policy_branch_id = '-1';
        const params = {
            session_id: this.$getUser().session_id,
            list_type: 2, // Policy Branch: 2 
            parent_id: parentId
        };
        axios.post("/api/Helper/GetPolicyParentList", params, {'Content-Type': 'application/json'}).then((response) => { 
            this.policyBranchList = response.data;
            this.policyBranchList.unshift({ id: '-1', text: 'ALL'});
        })
        .catch(function (error) {
            console.log(error);
        });
    },
    addVendorItem(){
        if (this.vendorForm.vendor_id == '-1') {
            this.$swal("Failed", "Vendor must be selected..!", 'warning' );
            return;
        }
        this.isItemSaving = true;
        const parameters = {
            session_id: this.$getUser().session_id,
            tmp_session_id: this.$getSessionStorage('tmp_session_id'),
            accountList: [this.vendorForm.vendor_id],
            main_branch_id: this.vendorForm.main_branch_id == '-1' ? null : this.vendorForm.main_branch_id,
            policy_branch_id: this.vendorForm.policy_branch_id == '-1' ? null : this.vendorForm.policy_branch_id
        };
        const that = this;
        axios.post('/api/GetCompanyAccounts/AddVendorItem', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == '' || response.data.error == undefined){
                this.vendorList = response.data;
                this.$setSessionStorage('tmp_session_id', response.data[0].tmp_session_id);
                this.vendorForm = {
                    id: null,
                    vendor_id: '-1',
                    main_branch_id: '-1',
                    policy_branch_id: '-1'
                };
            }            
            setTimeout(() => {
                that.isItemSaving = false;
            }, 500);
        }) 
        .catch(function () {
            setTimeout(() => {
                that.isItemSaving = false;
            }, 500);
        })
        .finally(function () { 
            setTimeout(() => {
                that.isItemSaving = false;
            }, 500);
        });
    },
    deleteVendorItem(){
        this.$swal.fire({
            title: "Are you sure you want to remove this item?",
            confirmButtonColor: "#f1416c",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "DELETE",
            icon: 'info'
        })
        .then((result) => {
            if (result.isConfirmed) {
                const parameters = {
                    session_id: this.$getUser().session_id,
                    tmp_session_id: this.$getSessionStorage('tmp_session_id'),
                    vendor_id: this.selectedVendorId,
                    is_delete_all: 0
                }; 
                axios.post('/api/GetCompanyAccounts/DeleteVendorItem', parameters, {'Content-Type': 'application/json'})
                .then((response) => {
                    this.vendorList = response.data;
                }) 
                .catch(function (error) {
                    console.log(error);
                    this.Progress.finish();
                });
            }
        });
    },
    deleteAllVendors(){ 
        this.$swal.fire({
            title: "Are you sure you want to remove all items?",
            confirmButtonColor: "#f1416c",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "DELETE",
            icon: 'info'
        })
        .then((result) => {
            if (result.isConfirmed) { 
                const parameters = {
                    session_id: this.$getUser().session_id,
                    tmp_session_id: this.$getSessionStorage('tmp_session_id'),
                    is_delete_all: 1,
                    vendor_id: null
                }; 
                axios.post('/api/GetCompanyAccounts/DeleteVendorItem', parameters, {'Content-Type': 'application/json'})
                .then((response) => {
                    this.vendorList = response.data;
                }) 
                .catch(function (error) {
                    console.log(error);
                    this.Progress.finish();
                });
            }
        });
    },    
    onSelectItem(item) { 
        this.selectedVendorId = item.id;
        this.vendorList.forEach((value) => {
            if (item.id !== value.id) { 
                value.isSelected = false;
            }
        });
    },
    onChangeIsMainGroup(isMainGroup) {
        this.form.is_main_group = isMainGroup;
        this.accountInfo.is_main_group = isMainGroup;
    },
    getTempVendor(){
        this.policyBranchList = null;
    },
    onRowUpdating(e){
        this.isItemSaving = true;
        const parameters = {
            id: e.oldData.id,
            session_id: this.$getUser().session_id,
            tmp_session_id: this.$getSessionStorage('tmp_session_id'),
            main_branch_id: e.newData.main_branch_id ? e.newData.main_branch_id : e.oldData.main_branch_id,
            policy_branch_id: e.newData.policy_branch_id ? e.newData.policy_branch_id  : e.oldData.policy_branch_id
      
        };
        const that = this;
        if(parameters.main_branch_id == '-1' || parameters.main_branch_id == '') { parameters.main_branch_id = null; parameters.policy_branch_id = null ;}
        if(parameters.policy_branch_id == '-1' || parameters.policy_branch_id == '') parameters.policy_branch_id = null ;
        axios.post('/api/GetCompanyAccounts/EditVendorItem', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == '' || response.data.error == undefined){
                this.vendorList = response.data;
                this.$setSessionStorage('tmp_session_id', response.data[0].tmp_session_id);
            }            
            setTimeout(() => {
                that.isItemSaving = false;
            }, 500);
        }) 
        .catch(function () {
            setTimeout(() => {
                that.isItemSaving = false;
            }, 500);
        })
        .finally(function () { 
            setTimeout(() => {
                that.isItemSaving = false;
            }, 500);
        });
    },
    setMainBranch(newData, value ){
        newData.main_branch_name = value;
        newData.main_branch_id = value;
        newData.policy_branch_name = '-1';
        newData.policy_branch_id = '-1';
        this.getPolicyParentList(value, true);
    },
    setPolicyBranch(newData, value){
        newData.policy_branch_name = value;
        newData.policy_branch_id = value;
    },
    onSelectAddress(item) {
        if(item.id == '-1') return;
        this.form.address = item.fullAddress;
        this.form.city = item.town_or_city;
        this.form.district = item.district;
        this.form.postcode = item.postcode;
    },
    onListInvoices() {
        setTimeout(() => {
            this.$refs.InvoiceList.setCriteriasToDefault(this.form.id);
            this.$refs.InvoiceList.isAccountIdRequired = true;
        }, 500);
    }
  },
  created() {
    this.$removeSessionStorage('tmp_session_id');
    this.helpPageViewIcon();
    this.getData(); 
  },
}; 
</script>