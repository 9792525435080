<template> 
    <div>
        <div class="d-flex flex-stack flex-wrap">
            <div class="page-title d-flex flex-column me-3 mt-3"> 
                <h1 class="d-flex fw-bolder my-1 fs-3 text-gray-800">{{ $t('Update Bank Account') }}</h1>  
                <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
                    <li class="breadcrumb-item text-gray-600">
                        <a href="#" class="text-gray-600 text-hover-primary">
                            <router-link :to="{ name: 'Home' }">{{ $t('Home') }}</router-link>
                        </a>
                    </li> 
                    <li class="breadcrumb-item text-primary">{{ $t('Accounts') }}</li>     
                    <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'BankList' }">{{ $t('Bank') }}</router-link></li>     
                    <li class="breadcrumb-item text-gray-600">{{ $t('Update Bank Account') }}
                        <HelpPageIcon v-if="this.iconView == true" :menuSelection="this.menuSelection"></HelpPageIcon>
                    </li> 
                </ul> 
            </div>  
            <div class="d-flex align-items-center py-1">
                <router-link to="/BankList">
                    <button type="button" class="btn btn-light shadow-sm btn-arrow-left border border-gray-400 me-3">
                    <span class="ms-2 fs-7"> {{ $t('Return back to List') }}</span>
                    </button>
                </router-link> 
                &nbsp;
                <div class="dropdown" v-if="this.$isCredentialActive(13,'D')">
                    <button class="btn btn-light-info border border-info dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="true" id="bankActions" type="button">
                    <span class="svg-icon svg-icon-muted"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path opacity="0.3" d="M22.1 11.5V12.6C22.1 13.2 21.7 13.6 21.2 13.7L19.9 13.9C19.7 14.7 19.4 15.5 18.9 16.2L19.7 17.2999C20 17.6999 20 18.3999 19.6 18.7999L18.8 19.6C18.4 20 17.8 20 17.3 19.7L16.2 18.9C15.5 19.3 14.7 19.7 13.9 19.9L13.7 21.2C13.6 21.7 13.1 22.1 12.6 22.1H11.5C10.9 22.1 10.5 21.7 10.4 21.2L10.2 19.9C9.4 19.7 8.6 19.4 7.9 18.9L6.8 19.7C6.4 20 5.7 20 5.3 19.6L4.5 18.7999C4.1 18.3999 4.1 17.7999 4.4 17.2999L5.2 16.2C4.8 15.5 4.4 14.7 4.2 13.9L2.9 13.7C2.4 13.6 2 13.1 2 12.6V11.5C2 10.9 2.4 10.5 2.9 10.4L4.2 10.2C4.4 9.39995 4.7 8.60002 5.2 7.90002L4.4 6.79993C4.1 6.39993 4.1 5.69993 4.5 5.29993L5.3 4.5C5.7 4.1 6.3 4.10002 6.8 4.40002L7.9 5.19995C8.6 4.79995 9.4 4.39995 10.2 4.19995L10.4 2.90002C10.5 2.40002 11 2 11.5 2H12.6C13.2 2 13.6 2.40002 13.7 2.90002L13.9 4.19995C14.7 4.39995 15.5 4.69995 16.2 5.19995L17.3 4.40002C17.7 4.10002 18.4 4.1 18.8 4.5L19.6 5.29993C20 5.69993 20 6.29993 19.7 6.79993L18.9 7.90002C19.3 8.60002 19.7 9.39995 19.9 10.2L21.2 10.4C21.7 10.5 22.1 11 22.1 11.5ZM12.1 8.59998C10.2 8.59998 8.6 10.2 8.6 12.1C8.6 14 10.2 15.6 12.1 15.6C14 15.6 15.6 14 15.6 12.1C15.6 10.2 14 8.59998 12.1 8.59998Z" fill="currentColor"/>
                    <path d="M17.1 12.1C17.1 14.9 14.9 17.1 12.1 17.1C9.30001 17.1 7.10001 14.9 7.10001 12.1C7.10001 9.29998 9.30001 7.09998 12.1 7.09998C14.9 7.09998 17.1 9.29998 17.1 12.1ZM12.1 10.1C11 10.1 10.1 11 10.1 12.1C10.1 13.2 11 14.1 12.1 14.1C13.2 14.1 14.1 13.2 14.1 12.1C14.1 11 13.2 10.1 12.1 10.1Z" fill="currentColor"/>
                    </svg></span>
                        {{ $t('Actions') }}
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="bankActions"> 
                        <li>
                            <a class="dropdown-item fs-4 text-danger" href="#" @click="deleteItem()">
                                <span class="svg-icon svg-icon-muted svg-icon-2 text-danger"><svg width="24" height="24" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="currentColor"/>
                                    <path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="currentColor"/>
                                    <path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="currentColor"/>
                                    </svg>
                                </span>
                                {{ $t('Delete') }}
                            </a>
                        </li>
                    </ul>
                </div>  
                <!-- <div class="dropdown ms-3">
                    <button class="btn btn-light-warning border border-warning dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="true" id="bankPrintActions" type="button">
                        <i class="fa fa-print"></i>
                        {{ $t('Print Actions') }}
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="bankPrintActions"> 
                        <li>
                            <a class="dropdown-item fs-4 text-primary py-4" href="#" @click="printStatement()" 
                                data-bs-toggle="modal" data-bs-target="#kt_modal_current_statement">
                            <i class="fa fa-print text-primary fs-5"></i>  {{ $t('Current Statement') }}
                            </a>
                        </li>
                    </ul>
                </div>   -->
            </div>
        </div> 
        <div class="content flex-row-fluid mt-4" id="kt_content"> 
            <StatsBar :form-account="this.form"></StatsBar>  

            <div class="card-body">
                <ul class="nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-6">
                    <li class="nav-item">
                        <a class="nav-link" :class="{'active': this.activeTab == 'Account'}" data-bs-toggle="tab" href="#kt_accounting_1" target="_self"
                        @click="this.activeTab = 'Account'">
                            <h4> {{ $t('Bank Account') }} </h4>
                        </a>
                    </li>
                    <li class="nav-item" v-if="this.$isModuleActive(3)">
                        <a class="nav-link" :class="{'active': this.activeTab == 'Documents'}" data-bs-toggle="tab" href="#kt_accounting_4" target="_self"
                        @click="activateDocumentsSection()">
                            <h4> {{ $t('Documents') }} </h4>
                        </a>
                    </li>
                </ul>
            </div>
            <RequiredField></RequiredField>
            <div class="tab-content" id="myTabContent">
                <div class="card card-xxl-stretch tab-pane fade show active" id="kt_accounting_1" role="tabpanel">
                    <form id="poliform" @submit.prevent="" class="form fv-plugins-bootstrap5 fv-plugins-framework">
                        <div class="row p-10">
                                <div class="d-flex flex-row row">
                                    <div class="d-flex flex-column flex-row-auto col-lg-6">
                                        <div class="d-flex flex-column-fluid card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                            <div class="card-body pb-0" style="border-radius: 0.95rem; height: 286px;"> 
                                                    <div class="fv-row mb-7 row">
                                                        <div class="col-lg-12" v-if="isViewAccountId">
                                                        <label class="fs-6 fw-bold form-label mt-3" :class="{'required': isViewAccountId}">
                                                            {{ $t('Account ID') }}
                                                        </label>
                                                        <input type="text" class="form-control" name="accountID" v-model="form.account_id">
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <label class="fs-6 fw-bold form-label mt-3" v-tag="'name'">{{ $t('Name') }}</label>
                                                            <input type="text" class="form-control" name="name" v-model="form.name" v-custom>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <label class="fs-6 fw-bold form-label mt-3" v-tag="'account_type'">{{ $t('Account Type') }}
                                                            </label>
                                                            <Select2 v-model="form.account_type_id" :options="accountTypeList" name="account_type" v-custom :settings="{ width: '100%'}"/> 
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <label class="fs-6 fw-bold form-label mt-3" v-tag="'currency'">
                                                                <span>{{ $t('Currency') }}</span>
                                                            </label>
                                                            <Select2 v-model="form.currency" :options="currencyList" name="currency" v-custom :settings="{ width: '100%'}"/> 
                                                        </div>
                                                    </div> 
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex flex-column flex-row-auto col-lg-6">
                                        <div class="d-flex flex-column-fluid card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                            <div class="card-body pb-0"> 
                                                        <div class="fv-row mb-8 row">
                                                            <div class="col-lg-12">
                                                            <label class="fs-6 fw-bold form-label mt-3" v-tag="'bank_account_detail'">
                                                                <span>{{ $t('Bank Account Detail') }}</span>
                                                            </label>
                                                            <Select2 v-model="form.bank_detail_id" :options="bankDetailList" name="bank_account_detail" v-custom :settings="{ width: '100%'}"/> 
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <label class="fs-6 fw-bold form-label mt-3" v-tag="'branch'">
                                                                <span>{{ $t('Branch') }}</span>
                                                            </label>
                                                            <Select2 v-model="form.branch_id" :options="branchList" name="branch" v-custom :settings="{ width: '100%'}"/> 
                                                        </div>
                                                    </div> 
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                            <div class="card-body pb-0"> 
                                                    <div class="fv-row">
                                                        <div class="col-lg-12">
                                                            <label class="fs-6 fw-bold form-label mt-3" v-tag="'general_bank_list'">{{ $t('General Bank List') }}
                                                            </label>
                                                            <Select2 v-model="form.bank_bank_id" :options="bankList" name="general_bank_list" v-custom :settings="{ width: '100%'}"/> 
                                                        </div>
                                                    </div>                                               
                                                    <div class="fv-row row mb-7">
                                                        <div class="col-lg-6">
                                                            <label class="fs-6 fw-bold form-label mt-3" v-tag="'short_code'">{{ $t('Short Code') }}
                                                            </label>
                                                            <input type="text" class="form-control" name="short_code" v-model="form.bank_short_code" v-custom>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <label class="fs-6 fw-bold form-label mt-3" v-tag="'account_number'">{{ $t('Account Number') }}
                                                            </label>
                                                            <input type="text" class="form-control" name="account_number" v-model="form.account_number" v-custom>
                                                        </div>
                                                    </div> 
                                            </div>
                                        </div>                    
                                    </div>

                                    <!-- <div class="col-lg-6" v-if="form.account_type_id == '2'">
                                        <div class="card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                            <div class="card-body pb-0"> 
                                                    <div class="fv-row mb-7 row">
                                                        <div class="col-lg-8">
                                                            <label class="fs-6 fw-bold form-label mt-3" v-tag="'credit_card_no'">
                                                                <span>Credit Card No</span>
                                                            </label>
                                                            <input type="text" class="form-control" name="credit_card_no" v-model="form.credit_card_no" v-custom>
                                                        </div>
                                                        <div class="col-lg-4">
                                                            <label class="fs-6 fw-bold form-label mt-3" v-tag="'cvv'">
                                                                <span>CVV</span>
                                                            </label>
                                                            <input type="text" class="form-control" name="cvv" v-model="form.cvv" v-custom>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <label class="fs-6 fw-bold form-label mt-3" v-tag="'credit_card_name'">
                                                                <span>Name</span>
                                                            </label>
                                                            <input type="text" class="form-control" name="credit_card_name" v-model="form.cardholder_name" v-custom>
                                                        </div>
                                                    </div> 
                                            </div>
                                        </div>                    
                                    </div>
                                    <div class="col-lg-6" v-if="form.account_type_id == '2'">
                                        <div class="card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                            <div class="card-body pb-0"> 
                                                    <div class="fv-row mb-7 row">
                                                        <div class="col-lg-12">
                                                            <label class="fs-6 fw-bold form-label mt-3" v-tag="'valid_date'">
                                                                <span>Valid Date</span>
                                                            </label>
                                                            <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.period_start" inputClassName="form-control" format="dd.MM.yyyy HH:mm" autoApply :clearable="false" weekNumbers :monthChangeOnScroll="'inverse'"></Datepicker>  
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <label class="fs-6 fw-bold form-label mt-3" v-tag="'expiry_date'">
                                                                <span>Expiry Date</span>
                                                            </label>
                                                            <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.period_start" inputClassName="form-control" format="dd.MM.yyyy HH:mm" autoApply :clearable="false" weekNumbers :monthChangeOnScroll="'inverse'"></Datepicker>  
                                                        </div>
                                                    </div>
                                            </div>
                                        </div>                    
                                    </div>

                                    <div class="col-lg-6" v-if="form.account_type_id == '3'">
                                        <div class="card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                            <div class="card-body pb-0"> 
                                                    <div class="fv-row mb-7 row">
                                                        <div class="col-lg-6">
                                                            <label class="fs-6 fw-bold form-label mt-3">
                                                                <span>Loan Amount</span>
                                                            </label>
                                                            <input type="text" class="form-control" name="company_name" v-model="form.loan_amount">
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <label class="fs-6 fw-bold form-label mt-3">
                                                                <span>Loan Rate</span>
                                                            </label>
                                                            <input type="text" class="form-control" name="company_name" v-model="form.loan_rate">
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <label class="fs-6 fw-bold form-label mt-3">
                                                                <span>Loan Term</span>
                                                            </label>
                                                            <input type="text" class="form-control" name="company_name" v-model="form.loan_term">
                                                        </div>
                                                    </div> 
                                            </div>
                                        </div>                    
                                    </div>
                                    <div class="col-lg-6" v-if="form.account_type_id == '3'">
                                        <div class="card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                            <div class="card-body pb-0"> 
                                                    <div class="fv-row mb-7 row">
                                                        <div class="col-lg-12">
                                                            <label class="fs-6 fw-bold form-label mt-3">
                                                                <span>Total Refund</span>  <small> Toplam Geri Ödeme </small>
                                                            </label>
                                                            <input type="number" class="form-control" name="company_name" v-model="form.loan_total_refund">
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <label class="fs-6 fw-bold form-label mt-3">
                                                                <span>Monthly Payment Amount </span> <small> Aylık Ödeme Tutarı </small>
                                                            </label>
                                                            <input type="number" class="form-control" name="company_name" v-model="form.loan_monthly_payment_amount">
                                                        </div>
                                                    </div> 
                                            </div>
                                        </div>                    
                                    </div>

                                    <div class="col-lg-6" v-if="form.account_type_id == '4'">
                                        <div class="card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                            <div class="card-body pb-0"> 
                                                    <div class="fv-row mb-7 row">
                                                        <div class="col-lg-6">
                                                            <label class="fs-6 fw-bold form-label mt-3">
                                                                <span>Lease Amount</span>
                                                            </label>
                                                            <input type="text" class="form-control" name="company_name" v-model="form.lease_amount">
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <label class="fs-6 fw-bold form-label mt-3">
                                                                <span>Lease Rate</span>
                                                            </label>
                                                            <input type="text" class="form-control" name="company_name" v-model="form.lease_rate">
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <label class="fs-6 fw-bold form-label mt-3">
                                                                <span>Lease Term</span>
                                                            </label>
                                                            <input type="text" class="form-control" name="company_name" v-model="form.lease_term">
                                                        </div>
                                                    </div> 
                                            </div>
                                        </div>                    
                                    </div>
                                    <div class="col-lg-6" v-if="form.account_type_id == '4'">
                                        <div class="card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                            <div class="card-body pb-0"> 
                                                    <div class="fv-row mb-7 row">
                                                        <div class="col-lg-12">
                                                            <label class="fs-6 fw-bold form-label mt-3">
                                                                <span>Total Refund</span>  <small> Toplam Geri Ödeme </small>
                                                            </label>
                                                            <input type="number" class="form-control" name="company_name" v-model="form.lease_total_refund">
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <label class="fs-6 fw-bold form-label mt-3">
                                                                <span>Monthly Payment Amount </span> <small> Aylık Ödeme Tutarı </small>
                                                            </label>
                                                            <input type="number" class="form-control" name="company_name" v-model="form.lease_monthly_payment_amount">
                                                        </div>
                                                    </div> 
                                            </div>
                                        </div>
                                    </div> -->

                                    <div class="col-lg-12">
                                        <div class="card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                            <div class="card-body pb-0"> 
                                                    <div class="fv-row mb-7">
                                                        <label class="fs-6 fw-bold form-label mt-3" v-tag="'notes'">
                                                            <span>{{ $t('Notes') }}</span>
                                                        </label>
                                                        <textarea class="form-control" name="notes" v-model="form.notes" v-custom></textarea>
                                                    </div> 
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 mt-0" v-if="this.$isCredentialActive(13,'U')">
                                        <div class="d-grid gap-2"> 
                                            <button type="button" class="btn btn-lg btn-primary mb-0" @click="this.saveData()" data-kt-indicator="on">
                                                <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                                <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                                </svg>
                                                </span> 
                                                <strong v-if="!this.isProgressing"> {{ $t('Update Bank') }} </strong> 
                                                    <span class="indicator-progress" v-if="this.isProgressing">
                                                        {{ $t('Saving Data...') }} <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                    </span> 
                                            </button>
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </form>
                </div>
                <div class="card card-xxl-stretch tab-pane fade" id="kt_accounting_4" role="tabpanel">
                    <div class="row p-10" v-if="this.activeTab == 'Documents'">
                        <FileManager :location="1" :locationtype="4" :upload_source="4" ref="FileManagerComponent"></FileManager>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios"; 
import Swal from "sweetalert2";

export default {
  name: "BankUpdate",
  data() {
    return { 
      userData: this.$getUser(),
      currencyList: [],
      bankList: [],
      accountTypeList: [],
      bankAccountTypeList: [],
      branchList: [],
      isProgressing: false,
      bankDetailList: [],
      form: {
        id: null,
        name: null,
        company_id: null,
        account_type: null,
        account_id: null,
        qr_code: null,
        notes: null,
        account_number: null,
        account_type_id: '1',
        bank_account_type_id: null,
        bank_bank_id: null,
        currency: null,
        bank_short_code: null,
        branch_id: '-1',
        accounting_plan: null,
        bank_detail_id: '-1'
      },
      test: {
        test1: null
      },
      isViewAccountId: false,
      activeTab: 'Account',
      menuSelection: '/BankHelppage',
      iconView : false,
    };
  },
  methods: {
    getData() {
        this.bankDetailList = [];
        this.$Progress.start();
        const params = {
            id: this.$getSessionStorage('record_id'),
            session_id: this.$getUser().session_id
        };
        axios.post('api/CompanyAccounts/GetAccountById', params, {'Content-Type': 'application/json'})
        .then((response) => {
            this.form.id = response.data.id;
            this.form.name = this.$unlock(response.data.name);
            this.form.account_type = response.data.account_type;
            this.form.account_id = this.$unlock(response.data.account_id);
            if(this.form.account_id == '' || this.form.account_id == null || this.form.account_id == undefined) this.form.account_id = '-1';
            this.form.qr_code = this.$unlock(response.data.qr_code);
            this.form.notes = response.data.notes;
            this.form.bank_short_code = this.$unlock(response.data.bank_short_code);
            this.form.currency = (response.data.bank_currency == '' || response.data.bank_currency == null) ? '-1': response.data.bank_currency;
            this.form.bank_bank_id = (response.data.bank_bank_id == '' || response.data.bank_bank_id == null) ? '-1': response.data.bank_bank_id;  
            this.form.account_type_id = response.data.bank_account_type_id; 
            this.form.account_number = this.$unlock(response.data.bank_account_number);
            this.form.branch_id = (response.data.branch_id == '' || response.data.branch_id == null) ? '-1' : response.data.branch_id;
            this.form.bank_detail_id = (response.data.bank_detail_id == '' || response.data.bank_detail_id == null) ? '-1' : response.data.bank_detail_id;
            this.getLists();
        })
        .catch(function (error) {
          console.log(error);
          this.$swal(
            "Failed",
            "Data could not get..Please refresh the page and try again..!"
          );
        });
    },
    getLists() {
        
        this.bankAccountTypeList = [];
        this.bankList = [];

        const parameters = {
            session_id: this.$getUser().session_id,
            list_types: [
                {
                    list_type: 2,
                    list_name: "bank_list"
                }
            ],
            list_name: ["branch", "account_setting", "currency"],
            account_type: 4 // yalnızca account settings listesi çekilecek ise gerekli
        };
        const parameters2 = {
            session_id: this.$getUser().session_id,
            list_type: 1, // Bank Detail List: 1
            list_name: ["bank_detail_list"],
            get_access_status: 0,
            tip: 9
        };
        const requestOne =  axios.post('/api/Helper/GetMultiLists', parameters, {'Content-Type': 'application/json'});
        const requestTwo =  axios.post('/api/Helper/GeneralLists', parameters, {'Content-Type': 'application/json'}); 
        const requestThree =  axios.post('/api/Helper/GetLists', parameters2, {'Content-Type': 'application/json'});
        const requestFour =  axios.post('/api/Helper/GetConst', parameters2, {'Content-Type': 'application/json'});

        axios.all([requestOne, requestTwo, requestThree, requestFour]).then(axios.spread((...responses) => {
                this.bankList = responses[0].data.bank_list;
                this.branchList = responses[1].data.branch;
                this.currencyList = responses[1].data.currency;
                this.bankDetailList = responses[2].data;
                this.accountTypeList = responses[3].data;
                this.bankList.unshift({ id: '-1', text: 'Select an option'});
                this.bankDetailList.unshift({ id: '-1', text: 'Select an option'});

                if (responses[1].data.account_setting.is_default == false) {
                    this.isViewAccountId = true;
                }                
                if (this.form.currency == null || this.form.currency == '' || this.form.currency == undefined) {
                    this.form.currency = this.$getUser().currency_code; 
                }
                this.$Progress.finish();      
            })
          )
          .catch(errors => { 
            console.error(errors); 
            this.$Progress.finish(); 
        });  
    },
    async helpPageViewIcon()
    {
        const queryparameter = {
            session_id : this.$getUser().session_id,
            menu_selection : '/BankHelppage'
        };
        axios.post('api/HelpPage/HelpPagesContent', queryparameter, {'Content-Type': 'application/json'})
        .then((response) => { 
            if(!response.data.error){ 
                this.iconView = response.data;
            }
        })
    }, 
    saveData() {
     this.$checkSelect2Validation('poliform');
     if(!document.forms['poliform'].reportValidity()){
        return;
      }
      this.isProgressing = true;
      const params = { 
        id:this.form.id,
        name:this.form.name,
        company_id:this.form.company_id,
        account_type:this.form.account_type,
        account_id:this.form.account_id == '-1' ? null : this.form.account_id,
        qr_code:this.form.qr_code,
        notes:this.form.notes,
        account_number:this.form.account_number,
        bank_account_type_id: this.form.account_type_id == '' || this.form.account_type_id == null ? '1' : this.form.account_type_id,
        bank_bank_id:this.form.bank_bank_id == '-1' ? null : this.form.bank_bank_id,
        currency:this.form.currency == '-1' ? null : this.form.currency,
        bank_short_code:this.form.bank_short_code,
        branch_id:this.form.branch_id == '-1' ? null : this.form.branch_id,
        accounting_plan:this.form.accounting_plan,
        bank_detail_id: this.form.bank_detail_id == '-1' ? null : this.form.bank_detail_id,
        };
      params.session_id = this.$getUser().session_id;
      const that = this;
      axios.post('api/CompanyAccounts/UpdateAccount', params, {'Content-Type': 'application/json'})
        .then((response) => {
          if (response.data != null && response.data.length == 36) {
            this.$swal("Success", "Data saved succesfully..!", 'success');  
          }
        })
        .catch(function () { 
            new Swal( { icon: 'error', title: 'Error...', text: 'Data could not be saved..Please refresh the page and try again..!' });
        })
        .finally(function () { 
            setTimeout(() => {
                that.isProgressing = false;
            }, 500);
        }); 
    },
    deleteItem() {
      this.$swal
        .fire({
          title: "Are you sure you want to delete this data?",
          confirmButtonColor: "#E53935",
          showDenyButton: false,
          showCancelButton: true,
          confirmButtonText: "Yes",
          icon: 'warning'
        })
        .then((result) => {
          if (result.isConfirmed) {
            const params = {
              session_id: this.$getUser().session_id,
              id: this.form.id,
            };
            axios.post("api/CompanyAccounts/DeleteAccount", params, { "Content-Type": "application/json", })
              .then((response) => { 
                if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){ 
                    this.$router.push({ name: "BankList" });
                }
              })
              .catch(function () { 
                new Swal( { icon: 'error', title: 'Error...', text: 'Data could not be deleted..Please refresh the page and try again..!' });   
              }); 
          }
        });
    },
    activateDocumentsSection(){
        this.activeTab = 'Documents'; 
    }
  },
  created() {
    this.helpPageViewIcon();
    this.getData();
  },
};
</script>