<template>
  <div>  
    <div class="d-flex flex-stack flex-wrap">
        <div class="page-title d-flex flex-column me-3 mt-3"> 
            <h1 class="d-flex fw-bolder my-1 fs-3 text-gray-800">
                {{ $t('Policy Branch Factors List') }}
            </h1>  
            <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
                <li class="breadcrumb-item text-gray-600">
                    <a href="#" class="text-gray-600 text-hover-primary"><router-link :to="{ name: 'Home' }">{{ $t('Home') }}</router-link></a>
                </li> 
                <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'Settings' }">{{ $t('Settings') }}</router-link></li>
                <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'ListTypes' }">{{ $t('List Types') }}</router-link></li>     
                <li class="breadcrumb-item text-gray-600">{{ $t('Policy Branch Factors List') }}
                        <HelpPageIcon v-if="this.iconView == true" :menuSelection="this.menuSelection"></HelpPageIcon>
                </li> 
            </ul> 
        </div>  
            <div class="d-flex align-items-center py-1">
            <router-link to="/ListTypes">
                <button type="button" class="btn btn-light shadow-sm btn-arrow-left border border-gray-400 me-3">
                    <span class="ms-2 fs-7"> {{ $t('Return back to List Types') }} </span>
                </button>
                </router-link>
            </div> 
        </div>  
        <div class="content flex-row-fluid mt-4" id="kt_content">
            <div class="card">
                <div class="card-header border-0 pt-6">
                    <div class="card-title">
                    </div>
                    <div class="card-toolbar">
                        <div class="d-flex justify-content-end" data-kt-customer-table-toolbar="base"> 
                            <button type="button" class="btn btn-light-primary border border-primary" @click="getData()" data-kt-indicator="on">
                                <span class="svg-icon svg-icon-muted"><svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect y="6" width="16" height="3" rx="1.5" fill="currentColor"/>
                                <rect opacity="0.3" y="12" width="8" height="3" rx="1.5" fill="currentColor"/>
                                <rect opacity="0.3" width="12" height="3" rx="1.5" fill="currentColor"/>
                                </svg>
                                </span>
                                <span v-if="!this.isListProgressing"> {{ $t('List Policy Branch Factors') }} </span> 
                                <span class="indicator-progress" v-if="this.isListProgressing">
                                    {{ $t('Getting Data...') }} <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="separator my-4 mt-0 border-gray-300" style="margin-top: -10px !important;"></div>
                    <div class="dataTables_wrapper dt-bootstrap4 no-footer">
                        <div class="table-responsive min-h-300px">
                            <DxDataGrid id="gridContainer" :show-borders="true" 
                                key-expr="id" 
                                :data-source="lists" 
                                :allow-column-reordering="true"  
                                :allow-column-resizing="true" 
                                :column-auto-width="true" 
                                :row-alternatin-enabled="true"
                                :hover-state-enabled="true" 
                                :show-row-lines="true" 
                                @exporting="this.$onExporting($event , 'PolicyBranch-Factors')"> 

                                <DxFilterRow :visible="true"/>
                                <DxHeaderFilter :visible="true"/>
                                <DxGroupPanel :visible="false"/>
                                <DxExport :enabled="this.$getUser().is_allow_export" :formats="['xlsx','pdf']" :allow-export-selected-data="false"/>
                                <DxPaging :page-size="1000"/>
                                <DxPager :visible="false" :allowed-page-sizes="pageSizes" :show-page-size-selector="true" :show-info="true" :show-navigation-buttons="true"/> 
                                <DxScrolling
                                    mode="virtual"
                                    row-rendering-mode="virtual"
                                />
                                <DxColumn data-field="parent_name" :caption="$t('Policy Branch')" cell-template="parent_name-cell-template"/>
                                <DxColumn data-field="factor_type_name" :caption="$t('Factor Type')" alignment="left" cell-template="factor_type_name-cell-template"/> 
                                <DxColumn data-field="code" :caption="$t('Factor Detail Code')" cell-template="code-cell-template"/>
                                <DxColumn data-field="name" :caption="$t('Factor Detail Name')" alignment="left" cell-template="name-cell-template"/> 
                                <DxColumn data-field="is_required" caption="#" :width="100" :allow-filtering="false" alignment="center" cell-template="is-required-cell-template"/>
                                
                                <!-- Cell Templates -->
                                <template #is-required-cell-template="{ data }"> 
                                    <span>
                                        <div class="form-check form-check-sm form-check-custom form-check-solid justify-content-center">
                                            <input class="form-check-input" type="checkbox" v-model="data.data.is_required" @change="onChangeIsRequired(data.data)" />
                                        </div>
                                    </span>
                                </template>
                                <template #parent_name-cell-template="{ data }"> 
                                    <span>
                                        {{ data.data.parent_name }}
                                    </span>
                                </template>
                                <template #code-cell-template="{ data }"> 
                                    <span>
                                        {{ data.data.code }}
                                    </span>
                                </template>
                                <template #name-cell-template="{ data }"> 
                                    <span>
                                        {{ data.data.name }}
                                    </span>
                                </template>
                                <template #factor_type_name-cell-template="{ data }"> 
                                    <span>
                                        {{ data.data.factor_type_name }}
                                    </span>
                                </template>
                            </DxDataGrid>
                        </div>
                    </div> 
              </div>
          </div> 
      </div> 
  </div> 
</template>

<style scoped>
.mh-300 {
    min-height: 300px;
}
</style>

<script>
import axios from 'axios';  
import { DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxPaging, DxPager, DxColumn, DxScrolling } from 'devextreme-vue/data-grid';
 
export default {
  name: "PolicyBranchFactors",
  components:{
        DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxPaging, DxPager, DxColumn, DxScrolling
  },
  data() {
    return {
        lists: [],
        searchbar: '',
        isListProgressing: false,
        menuSelection: '/PolicyBranchFactorsHelppage',
        iconView : false,
        pageSize: 10
    };
  },
  computed: {
    showPageSizeSelector() {
        return this.lists.length > this.pageSize;
    },
    pageSizes() {
        let arr = [];
        let x = 1;
        let loop = (this.lists.length / this.pageSize);
        for (let i = 1; i <= loop && i <= 5; i++) {
            arr.push(this.pageSize * x);
            x++;
        }
        if(loop > 5) arr.push(100);
        if(loop > 1) arr.push('all');
        return arr;
    }
  },
  methods: {
    getData() {
        this.$Progress.start();
        this.lists = [];
        this.isListProgressing = true;
        const params = {
            session_id: this.$getUser().session_id,
            sub_type: 2
        };
        axios.post('/api/Helper/GetPolicySubLists', params, {'Content-Type': 'application/json'})
        .then((response) => { 
            this.lists = response.data; 
            this.$Progress.finish();
            setTimeout(() => {
                this.isListProgressing = false;
            }, 500);
        })
        .catch(function (error) {
            console.log(error); 
            this.$Progress.finish();
            setTimeout(() => {
                this.isListProgressing = false;
            }, 500);
        });
    },
    onChangeIsRequired(data) {
        this.$swal.fire({
            title: data.is_required == true ? 'Are you sure to set this item as required?' : 'Are you sure to set this item as optional?',
            confirmButtonColor: '#f1416c',
            showDenyButton: false,
            showCancelButton: true,
            icon: 'warning',
            confirmButtonText: 'Yes'
        }).then((result) => { 
            if (result.isConfirmed) {
                const params = {
                  session_id: this.$getUser().session_id,
                  is_required: data.is_required ? 1 : 0,
                  sub_list_id: data.sub_list_id,
                  parent_id: data.parent_id
                };
                axios.post('/api/Helper/UpdatePolicyBranchFactor', params, {'Content-Type': 'application/json'})
                .then((response) => { 
                    if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){  
                        this.getData();
                    } 
                })
                .catch(function (error) {
                    console.log(error);
                    this.$swal("Failed", "Data could not be saved..Please refresh the page and try again..!");   
                });
            }
            else{
                data.is_required = !data.is_required;
            }
        });  
    },
    async helpPageViewIcon()
    {
        const queryparameter = {
            session_id : this.$getUser().session_id,
            menu_selection : '/PolicyBranchFactorsHelppage'
        };
        axios.post('api/HelpPage/HelpPagesContent', queryparameter, {'Content-Type': 'application/json'})
        .then((response) => { 
            if(!response.data.error){ 
                this.iconView = response.data;
            }
        })
    }, 
  },
  created() { 
    this.helpPageViewIcon();
    this.getData();
  }
}; 
</script>