import { createWebHistory, createRouter } from "vue-router"; 
import SecureLS from "secure-ls";  
const ls = new SecureLS({ isCompression: false });

import Home from "@/components/Home.vue";
import Login from "@/components/Login.vue";
import Logout from "@/components/Logout.vue";
import ScreenLock from "@/components/ScreenLock.vue";
import ResetPassword from "@/components/ResetPassword.vue";
import ForgotPassword from "@/components/ForgotPassword.vue";
import NewCompany from "@/components/NewCompany.vue";
import VerifyAccount from "@/components/VerifyAccount.vue";


import CustomerList from "@/components/Accounts/Customer/List.vue";  
import CustomerCreate from "@/components/Accounts/Customer/Create.vue";  
import CustomerUpdate from "@/components/Accounts/Customer/Update.vue";  


import BankList from "@/components/Accounts/Bank/List.vue";  
import BankCreate from "@/components/Accounts/Bank/Create.vue";  
import BankUpdate from "@/components/Accounts/Bank/Update.vue";


import SupplierList from "@/components/Accounts/Supplier/List.vue";   
import SupplierCreate from "@/components/Accounts/Supplier/Create.vue";  
import SupplierUpdate from "@/components/Accounts/Supplier/Update.vue";  


import CashList from "@/components/Accounts/Cash/List.vue";  
import CashCreate from "@/components/Accounts/Cash/Create.vue";  
import CashUpdate from "@/components/Accounts/Cash/Update.vue";  

import PersonnelList from "@/components/Accounts/Personnel/List.vue";   
import PersonnelCreate from "@/components/Accounts/Personnel/Create.vue";  
import PersonnelUpdate from "@/components/Accounts/Personnel/Update.vue";  

import ExpenseList from "@/components/Accounts/Expense/List.vue";  
import ExpenseCreate from "@/components/Accounts/Expense/Create.vue";  
import ExpenseUpdate from "@/components/Accounts/Expense/Update.vue";  

import StockList from "@/components/Accounts/Stock/List.vue";  
import StockCreate from "@/components/Accounts/Stock/Create.vue";  
import StockUpdate from "@/components/Accounts/Stock/Update.vue";  

import FixedAssetList from "@/components/Accounts/FixedAsset/List.vue";  
import FixedAssetCreate from "@/components/Accounts/FixedAsset/Create.vue";  
import FixedAssetUpdate from "@/components/Accounts/FixedAsset/Update.vue";

import OtherAccountList from "@/components/Accounts/OtherAccount/List.vue";  
import OtherAccountCreate from "@/components/Accounts/OtherAccount/Create.vue";  
import OtherAccountUpdate from "@/components/Accounts/OtherAccount/Update.vue";


import Settings from "@/components/Settings/Settings.vue";

import BranchList from "@/components/Settings/ListTypes/Branch/List.vue";
import BranchCreate from "@/components/Settings/ListTypes/Branch/Create.vue";  
import BranchUpdate from "@/components/Settings/ListTypes/Branch/Update.vue";  


import ExpenseCenterList from "@/components/Settings/ListTypes/ExpenseCenter/List.vue";
import ExpenseCenterCreate from "@/components/Settings/ListTypes/ExpenseCenter/Create.vue";  
import ExpenseCenterUpdate from "@/components/Settings/ListTypes/ExpenseCenter/Update.vue";  

import AccountNumeratorSettings from "@/components/Settings/AccountSettings/List.vue";  
import AccountNumeratorCreate from "@/components/Settings/AccountSettings/Create.vue";  
import AccountNumeratorUpdate from "@/components/Settings/AccountSettings/Update.vue";  

import SalesChannelList from "@/components/Settings/ListTypes/SalesChannel/List.vue";
import SalesChannelCreate from "@/components/Settings/ListTypes/SalesChannel/Create.vue";  
import SalesChannelUpdate from "@/components/Settings/ListTypes/SalesChannel/Update.vue";

import CampaignList from "@/components/Settings/Campaign/List.vue";
import CampaignCreate from "@/components/Settings/Campaign/Create.vue";  
import CampaignUpdate from "@/components/Settings/Campaign/Update.vue"; 

import InvoiceList from "@/components/Transaction/Invoice/List.vue";  
import InvoiceCreate from "@/components/Transaction/Invoice/Create.vue";  
import InvoiceUpdate from "@/components/Transaction/Invoice/Update.vue";

import CashTransactionList from "@/components/Transaction/Cash/List.vue";  
import CashTransactionCreate from "@/components/Transaction/Cash/Create.vue";  
import CashTransactionUpdate from "@/components/Transaction/Cash/Update.vue";

import BankTransactionList from "@/components/Transaction/Bank/List.vue";  
import BankTransactionCreate from "@/components/Transaction/Bank/Create.vue";  
import BankTransactionUpdate from "@/components/Transaction/Bank/Update.vue";

import NumeratorSettingsList from "@/components/Settings/NumeratorSettings/List.vue";  
import NumeratorSettingsCreate from "@/components/Settings/NumeratorSettings/Create.vue";  
import NumeratorSettingsUpdate from "@/components/Settings/NumeratorSettings/Update.vue";

import DataImportSettings from "@/components/Settings/DataImportSettings/Create.vue";

import ReCosting from "@/components/Settings/ReCosting/Create.vue";  

import DataTransferList from "@/components/Settings/DataTransfer/List.vue";  

import TransactionList from "@/components/Reports/Transaction/List.vue/"; 
import RatesList from "@/components/Reports/Rates/List.vue/"; 

import AccountingVoucherList from "@/components/Accounting/AccountingTransaction/List.vue";  
import AccountingVoucherCreate from "@/components/Accounting/AccountingTransaction/Create.vue";  
import AccountingVoucherUpdate from "@/components/Accounting/AccountingTransaction/Update.vue";


import PaymentTypeList from "@/components/Settings/ListTypes/PaymentType/List.vue";  
import PaymentTypeCreate from "@/components/Settings/ListTypes/PaymentType/Create.vue";  
import PaymentTypeUpdate from "@/components/Settings/ListTypes/PaymentType/Update.vue";  

import ShippingTypeList from "@/components/Settings/ListTypes/ShippingType/List.vue";  
import ShippingTypeCreate from "@/components/Settings/ListTypes/ShippingType/Create.vue";  
import ShippingTypeUpdate from "@/components/Settings/ListTypes/ShippingType/Update.vue";

import StockTrackingGroupList from "@/components/Settings/ListTypes/StockTrackingGroup/StockTrackingGroupList.vue";  
import StockTrackingGroupCreate from "@/components/Settings/ListTypes/StockTrackingGroup/StockTrackingGroupCreate.vue";  
import StockTrackingGroupUpdate from "@/components/Settings/ListTypes/StockTrackingGroup/StockTrackingGroupUpdate.vue";

import ExpenseTransactionList from "@/components/Transaction/Expense/List.vue";  
import ExpenseTransactionCreate from "@/components/Transaction/Expense/Create.vue";  
import ExpenseTransactionUpdate from "@/components/Transaction/Expense/Update.vue";

import ChequeTransactionList from "@/components/Transaction/Cheque/List.vue";  
import ChequeTransactionCreate from "@/components/Transaction/Cheque/Create.vue";  
import ChequeTransactionUpdate from "@/components/Transaction/Cheque/Update.vue";


import ChequeOperationsList from "@/components/Transaction/ChequeOperations/List.vue";  
import ChequeOperationsCreate from "@/components/Transaction/ChequeOperations/Create.vue";  
import ChequeOperationsUpdate from "@/components/Transaction/ChequeOperations/Update.vue";

import BalanceSheet from "@/components/Reports/BalanceSheet/DXList.vue";
import ComparativeBalanceSheet from "@/components/Reports/ComparativeBalanceSheet/DXList.vue";
import AccountTransactionList from "@/components/Reports/Transaction/DXList.vue/"; 
import ConsolidationList from "@/components/Reports/Transaction/DXConsolidationList.vue/"; 
import GeneralAccountReport from "@/components/Reports/Transaction/GeneralAccountReport.vue/"; 
import Documents from "@/components/Documents/Documents.vue/"; 
import StockTransactionList from "@/components/Reports/StockTransaction/DXList.vue/"; 
import GeneralStockTransactionList from "@/components/Reports/StockTransaction/GeneralList.vue/"; 

import CollectionList from "@/components/Reports/BalanceClosing/CollectionList.vue/"; 
import CollectedList from "@/components/Reports/BalanceClosing/CollectedList.vue/"; 
import PaymentList from "@/components/Reports/BalanceClosing/PaymentList.vue/"; 
import PaidList from "@/components/Reports/BalanceClosing/PaidList.vue/"; 

import StockCostList from "@/components/Reports/StockTransaction/StockCostList.vue/"; 
import GeneralPolicyReport from "@/components/Reports/Policy/GeneralPolicyReport.vue/"; 
 
import TurnoverDiffList from "@/components/Settings/TurnoverDiff/List.vue";  
import TurnoverDiffCreate from "@/components/Settings/TurnoverDiff/Add.vue";  
import TurnoverDiffUpdate from "@/components/Settings/TurnoverDiff/Update.vue";  

import ProductTypeList from "@/components/Settings/ListTypes/ProductType/ProductTypeList.vue";
import ProductTypeCreate from "@/components/Settings/ListTypes/ProductType/ProductTypeCreate.vue";
import ProductTypeUpdate from "@/components/Settings/ListTypes/ProductType/ProductTypeUpdate.vue";

import ProductDetailList from "@/components/Settings/ListTypes/ProductDetail/ProductDetailList.vue";
import ProductDetailCreate from "@/components/Settings/ListTypes/ProductDetail/ProductDetailCreate.vue";
import ProductDetailUpdate from "@/components/Settings/ListTypes/ProductDetail/ProductDetailUpdate.vue";

import ProductGroupList from "@/components/Settings/ListTypes/ProductGroup/ProductGroupList.vue";
import ProductGroupCreate from "@/components/Settings/ListTypes/ProductGroup/ProductGroupCreate.vue";
import ProductGroupUpdate from "@/components/Settings/ListTypes/ProductGroup/ProductGroupUpdate.vue";

import OffsettingList from "@/components/Transaction/Offsetting/List.vue";  
import OffsettingCreate from "@/components/Transaction/Offsetting/Create.vue";  
import OffsettingUpdate from "@/components/Transaction/Offsetting/Update.vue";

import ListTypes from "@/components/Settings/ListTypes.vue";
import RecalculationOperations from "@/components/Settings/RecalculationOperations.vue";
import MultiDeleteOperations from "@/components/Settings/MultiDeleteOperations.vue";
import UserManagement from "@/components/Settings/UserManagement.vue";
import SystemLogs from "@/components/Settings/SystemLogs.vue";

import CostSettings from "@/components/Settings/Costs/Create.vue";
import DocumentSettings from "@/components/Settings/DocumentSettings/Create.vue";
import BalanceClosingParameters from "@/components/Settings/BalanceClosingParameters/Create.vue";
import AccountingSettings from "@/components/Settings/AccountingSettings/Create.vue";
import CompanySettings from "@/components/Settings/CompanySettings/Create.vue";
import CompanyInfos from "@/components/Settings/CompanyInfos/Create.vue";

import UserList from "@/components/Settings/UserManagement/Users/UserList.vue";
import UserCreate from "@/components/Settings/UserManagement/Users/UserCreate.vue";
import UserUpdate from "@/components/Settings/UserManagement/Users/UserUpdate.vue";
import UserProfile from "@/components/Settings/UserManagement/Users/UserProfile.vue";

import OtherAccountTypeList from "@/components/Settings/ListTypes/OtherAccountType/List.vue";
import OtherAccountTypeCreate from "@/components/Settings/ListTypes/OtherAccountType/Create.vue";
import OtherAccountTypeUpdate from "@/components/Settings/ListTypes/OtherAccountType/Update.vue";

import OpeningOperationsList from "@/components/Accounting/OpeningOperations/List.vue";
import ClosingOperationsList from "@/components/Accounting/ClosingOperations/List.vue";
import ProfitAndLossOperationsList from "@/components/Accounting/ProfitAndLossOperations/List.vue";
import VATOperationsList from "@/components/Accounting/VATOperations/List.vue";
import AccountingIntegrationList from "@/components/Accounting/AccountingIntegration/List.vue";

import GeneralProfitAndLossList from "@/components/Accounting/ProfitAndLossStatements/GeneralProfitAndLossList.vue";
import ComparativeProfitAndLossList from "@/components/Accounting/ProfitAndLossStatements/ComparativeProfitAndLossList.vue";

import BankAccountTypeList from "@/components/Settings/ListTypes/BankAccountType/BankAccountTypeList.vue";
import BankAccountTypeCreate from "@/components/Settings/ListTypes/BankAccountType/BankAccountTypeCreate.vue";
import BankAccountTypeUpdate from "@/components/Settings/ListTypes/BankAccountType/BankAccountTypeUpdate.vue";

import CustomerTypeList from "@/components/Settings/ListTypes/CustomerType/CustomerTypeList.vue";
import CustomerTypeCreate from "@/components/Settings/ListTypes/CustomerType/CustomerTypeCreate.vue";
import CustomerTypeUpdate from "@/components/Settings/ListTypes/CustomerType/CustomerTypeUpdate.vue";

import ExpenseTypeList from "@/components/Settings/ListTypes/ExpenseType/ExpenseTypeList.vue";
import ExpenseTypeCreate from "@/components/Settings/ListTypes/ExpenseType/ExpenseTypeCreate.vue";
import ExpenseTypeUpdate from "@/components/Settings/ListTypes/ExpenseType/ExpenseTypeUpdate.vue";

import PriceTypeList from "@/components/Settings/ListTypes/PriceType/PriceTypeList.vue";
import PriceTypeCreate from "@/components/Settings/ListTypes/PriceType/PriceTypeCreate.vue";
import PriceTypeUpdate from "@/components/Settings/ListTypes/PriceType/PriceTypeUpdate.vue";

import UnitTypeList from "@/components/Settings/ListTypes/UnitType/UnitTypeList.vue";
import UnitTypeCreate from "@/components/Settings/ListTypes/UnitType/UnitTypeCreate.vue";
import UnitTypeUpdate from "@/components/Settings/ListTypes/UnitType/UnitTypeUpdate.vue";

import UnitType2List from "@/components/Settings/ListTypes/UnitType2/UnitType2List.vue";
import UnitType2Create from "@/components/Settings/ListTypes/UnitType2/UnitType2Create.vue";
import UnitType2Update from "@/components/Settings/ListTypes/UnitType2/UnitType2Update.vue";

import SourceCodeList from "@/components/Settings/ListTypes/SourceCode/List.vue";
import SourceCodeCreate from "@/components/Settings/ListTypes/SourceCode/Create.vue";
import SourceCodeUpdate from "@/components/Settings/ListTypes/SourceCode/Update.vue";

import SourceCode2List from "@/components/Settings/ListTypes/SourceCode2/List.vue";
import SourceCode2Create from "@/components/Settings/ListTypes/SourceCode2/Create.vue";
import SourceCode2Update from "@/components/Settings/ListTypes/SourceCode2/Update.vue";

import OfferList from "@/components/Transaction/Offer/List.vue";  
import OfferCreate from "@/components/Transaction/Offer/Create.vue";  
import OfferUpdate from "@/components/Transaction/Offer/Update.vue";

import PolicyBranchFactors from "@/components/Settings/ListTypes/PolicyBranchFactors/List.vue";
import AccessAuthorization from "@/components/Settings/AccessAuthorization.vue"; 
import FileManagerList from "@/components/Settings/FileManager/List.vue";  
import Liabilities from "@/components/Liabilities/Liabilities.vue"; 
import HelpPage from "@/HelpPage/HelpPage.vue";
import ChequeAccountList from "@/components/Accounts/ChequeAccount/List.vue"; 
import MailSettingsList from "@/components/Settings/MailSettings/List.vue";  
import MailSettingsCreate from "@/components/Settings/MailSettings/Create.vue";  
import MailSettingsUpdate from "@/components/Settings/MailSettings/Update.vue";
import PermissionDenied from "@/components/PermissionDenied.vue";
import LicenceExpired from "@/components/LicenceExpired.vue";
import PageNotFound from "@/components/PageNotFound.vue";
import UpgradeSuccess from "@/components/UpgradeSuccess.vue";
import UpgradeFailed from "@/components/UpgradeFailed.vue";
import PolicyDataImport from "@/components/DataImport/PolicyDataImport.vue"; 
import PolicyList from "@/components/Policies/List.vue"; 
import PolicyCreate from "@/components/Policies/PolicyCreate.vue"; 
import PolicyUpdate from "@/components/Policies/PolicyUpdate.vue"; 
import MultiAccountDelete from "@/components/Settings/MultiDeleteOperations/MultiAccountDelete.vue";
import MultiPolicyDelete from "@/components/Settings/MultiDeleteOperations/MultiPolicyDelete.vue";
import BalanceClosingOperations from "@/components/Transaction/BalanceClosingOperations/Create.vue";
import UserGroupList from "@/components/Settings/UserManagement/UserGroup/List.vue";  
import UserGroupCreate from "@/components/Settings/UserManagement/UserGroup/Create.vue";  
import UserGroupUpdate from "@/components/Settings/UserManagement/UserGroup/Update.vue";
import SessionManagement from "@/components/Settings/UserManagement/SessionManagement/Create.vue";

// import axios from "axios"; 
 
const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
        meta: {category:'Home', requiresAuth: true, quickmode: true },
        props: true
    },
    {
        path: "/Login",
        name: "Login",
        component: Login,
        meta: {category:'Login', requiresAuth: false, quickmode: true, fullwidth: true },
        props: true
    },
    {
        path: "/Logout",
        name: "Logout",
        component: Logout,
        meta: {category:'Logout', requiresAuth: false, quickmode: true, fullwidth: true },
        props: true
    },
    {
        path: "/ScreenLock",
        name: "ScreenLock",
        component: ScreenLock,
        meta: {category:'ScreenLock', requiresAuth: false, quickmode: true, fullwidth: true },
        props: true
    },
    {
        path: "/ResetPassword",
        name: "ResetPassword",
        component: ResetPassword,
        meta: {category:'ResetPassword', requiresAuth: false, quickmode: true, fullwidth: true },
        props: true
    },
    {
        path: "/ForgotPassword",
        name: "ForgotPassword",
        component: ForgotPassword,
        meta: {category:'ForgotPassword', requiresAuth: false, quickmode: true, fullwidth: true },
        props: true
    },
    {
        path: "/VerifyAccount",
        name: "VerifyAccount",
        component: VerifyAccount,
        meta: {category:'VerifyAccount', requiresAuth: false, quickmode: true, fullwidth: true },
        props: true
    },
    {
        path: "/NewCompany",
        name: "NewCompany",
        component: NewCompany,
        meta: {category:'NewCompany', requiresAuth: false, quickmode: true, fullwidth: true },
        props: true
    },
    {
        path: "/CustomerList",
        name: "CustomerList",
        component: CustomerList,
        meta: {category:'Customer Account', requiresAuth: true, fullwidth: true },
        props: true
    },    
    {
        path: "/CustomerCreate",
        name: "CustomerCreate",
        component: CustomerCreate,
        meta: {category:'Customer Account', requiresAuth: true },
        props: true
    },
    {
        path: "/CustomerUpdate",
        name: "CustomerUpdate",
        component: CustomerUpdate,
        meta: {category:'Customer Account', requiresAuth: true },
        props: true
    },
    {
        path: "/BankList",
        name: "BankList",
        component: BankList,
        meta: {category:'Bank Account', requiresAuth: true, fullwidth: true },
        props: true
    },
    {
        path: "/BankCreate",
        name: "BankCreate",
        component: BankCreate,
        meta: {category:'Bank Account', requiresAuth: true },
        props: true
    },
    {
        path: "/BankUpdate",
        name: "BankUpdate",
        component: BankUpdate,
        meta: {category:'Bank Account', requiresAuth: true },
        props: true
    },
    {
        path: "/SupplierList",
        name: "SupplierList",
        component: SupplierList,
        meta: {category:'Supplier Account', requiresAuth: true, fullwidth: true },
        props: true
    },
    {
        path: "/SupplierCreate",
        name: "SupplierCreate",
        component: SupplierCreate,
        meta: {category:'Supplier Account', requiresAuth: true },
        props: true
    },
    {
        path: "/SupplierUpdate",
        name: "SupplierUpdate",
        component: SupplierUpdate,
        meta: {category:'Supplier Account', requiresAuth: true },
        props: true
    },
    {
        path: "/CashList",
        name: "CashList",
        component: CashList,
        meta: {category:'Cash Account', requiresAuth: true, fullwidth: true },
        props: true
    },
    {
        path: "/CashCreate",
        name: "CashCreate",
        component: CashCreate,
        meta: {category:'Cash Account', requiresAuth: true },
        props: true
    },
    {
        path: "/CashUpdate",
        name: "CashUpdate",
        component: CashUpdate,
        meta: {category:'Cash Account', requiresAuth: true },
        props: true
    },
    {
        path: "/PersonnelList",
        name: "PersonnelList",
        component: PersonnelList,
        meta: {category:'Personnel Account', requiresAuth: true, fullwidth: true },
        props: true
    },
    {
        path: "/PersonnelCreate",
        name: "PersonnelCreate",
        component: PersonnelCreate,
        meta: {category:'Personnel Account', requiresAuth: true },
        props: true
    },
    {
        path: "/PersonnelUpdate",
        name: "PersonnelUpdate",
        component: PersonnelUpdate,
        meta: {category:'Personnel Account', requiresAuth: true },
        props: true
    },
    
    {
        path: "/ExpenseList",
        name: "ExpenseList",
        component: ExpenseList,
        meta: {category:'Expense Account', requiresAuth: true, fullwidth: true },
        props: true
    },
    {
        path: "/ExpenseCreate",
        name: "ExpenseCreate",
        component: ExpenseCreate,
        meta: {category:'Expense Account', requiresAuth: true },
        props: true
    },
    {
        path: "/ExpenseUpdate",
        name: "ExpenseUpdate",
        component: ExpenseUpdate,
        meta: {category:'Expense Account', requiresAuth: true },
        props: true
    },
    {
        path: "/StockList",
        name: "StockList",
        component: StockList,
        meta: {category:'Stock Account', requiresAuth: true, fullwidth: true },
        props: true
    },
    {
        path: "/StockCreate",
        name: "StockCreate",
        component: StockCreate,
        meta: {category:'Stock Account', requiresAuth: true },
        props: true
    },
    {
        path: "/StockUpdate",
        name: "StockUpdate",
        component: StockUpdate,
        meta: {category:'Stock Account', requiresAuth: true },
        props: true
    },
    {
        path: "/FixedAssetList",
        name: "FixedAssetList",
        component: FixedAssetList,
        meta: {category:'FixedAsset Account', requiresAuth: true, fullwidth: true },
        props: true
    },
    {
        path: "/FixedAssetCreate",
        name: "FixedAssetCreate",
        component: FixedAssetCreate,
        meta: {category:'FixedAsset Account', requiresAuth: true },
        props: true
    },
    {
        path: "/FixedAssetUpdate",
        name: "FixedAssetUpdate",
        component: FixedAssetUpdate,
        meta: {category:'FixedAsset Account', requiresAuth: true },
        props: true
    },    
    {
        path: "/BranchList",
        name: "BranchList",
        component: BranchList,
        meta: {category:'Branch Settings', requiresAuth: true, requiresAdmin: true, fullwidth: true },
        props: true
    },
    {
        path: "/BranchCreate",
        name: "BranchCreate",
        component: BranchCreate,
        meta: {category:'Branch Settings', requiresAuth: true, requiresAdmin: true },
        props: true
    },
    {
        path: "/BranchUpdate",
        name: "BranchUpdate",
        component: BranchUpdate,
        meta: {category:'Branch Settings', requiresAuth: true, requiresAdmin: true },
        props: true
    },
    {
        path: "/ExpenseCenterList",
        name: "ExpenseCenterList",
        component: ExpenseCenterList,
        meta: {category:'ExpenseCenter Settings', requiresAuth: true, requiresAdmin: true, fullwidth: true },
        props: true
    },
    {
        path: "/ExpenseCenterCreate",
        name: "ExpenseCenterCreate",
        component: ExpenseCenterCreate,
        meta: {category:'ExpenseCenter Settings', requiresAuth: true, requiresAdmin: true },
        props: true
    },
    {
        path: "/ExpenseCenterUpdate",
        name: "ExpenseCenterUpdate",
        component: ExpenseCenterUpdate,
        meta: {category:'ExpenseCenter Settings', requiresAuth: true, requiresAdmin: true },
        props: true
    },
    {
        path: "/AccountNumeratorSettings",
        name: "AccountNumeratorSettings",
        component: AccountNumeratorSettings,
        meta: {category:'Account Settings', requiresAuth: true, requiresAdmin: true, fullwidth: true },
        props: true
    },
    {
        path: "/ReCosting",
        name: "ReCosting",
        component: ReCosting,
        meta: {category:'ReCosting', requiresAuth: true, requiresAdmin: true },
        props: true
    },
    {
        path: "/AccountNumeratorCreate",
        name: "AccountNumeratorCreate",
        component: AccountNumeratorCreate,
        meta: {category:'Account Settings', requiresAuth: true, requiresAdmin: true },
        props: true
    },
    {
        path: "/AccountNumeratorUpdate",
        name: "AccountNumeratorUpdate",
        component: AccountNumeratorUpdate,
        meta: {category:'Account Settings', requiresAuth: true, requiresAdmin: true },
        props: true
    }, 
    {
        path: "/InvoiceList",
        name: "InvoiceList",
        component: InvoiceList,
        meta: {category:'Invoice Settings', requiresAuth: true, fullwidth: true },
        props: true
    },
    {
        path: "/InvoiceCreate",
        name: "InvoiceCreate",
        component: InvoiceCreate,
        meta: {category:'Invoice Settings', requiresAuth: true, fullwidth: true },
        props: true
    },
    {
        path: "/InvoiceUpdate",
        name: "InvoiceUpdate",
        component: InvoiceUpdate,
        meta: { category:'Invoice Settings',requiresAuth: true, fullwidth: true },
        props: true
    },
    {
        path: "/SalesChannelList",
        name: "SalesChannelList",
        component: SalesChannelList,
        meta: {category:'Sales Channel Settings', requiresAuth: true, requiresAdmin: true, fullwidth: true },
        props: true
    },
    {
        path: "/SalesChannelCreate",
        name: "SalesChannelCreate",
        component: SalesChannelCreate,
        meta: {category:'Sales Channel Settings', requiresAuth: true, requiresAdmin: true },
        props: true
    },
    {
        path: "/SalesChannelUpdate",
        name: "SalesChannelUpdate",
        component: SalesChannelUpdate,
        meta: {category:'Sales Channel Settings', requiresAuth: true, requiresAdmin: true },
        props: true
    },
    {
        path: "/ProductTypeList",
        name: "ProductTypeList",
        component: ProductTypeList,
        meta: {category:'Product Type Settings', requiresAuth: true, requiresAdmin: true, fullwidth: true },
        props: true
    },
    {
        path: "/ProductTypeCreate",
        name: "ProductTypeCreate",
        component: ProductTypeCreate,
        meta: {category:'Product Type Settings', requiresAuth: true, requiresAdmin: true },
        props: true
    },
    {
        path: "/ProductTypeUpdate",
        name: "ProductTypeUpdate",
        component: ProductTypeUpdate,
        meta: {category:'Product Type Settings', requiresAuth: true, requiresAdmin: true },
        props: true
    },
    {
        path: "/ProductDetailList",
        name: "ProductDetailList",
        component: ProductDetailList,
        meta: {category:'Product Detail Settings', requiresAuth: true, requiresAdmin: true, fullwidth: true },
        props: true
    },
    {
        path: "/ProductDetailCreate",
        name: "ProductDetailCreate",
        component: ProductDetailCreate,
        meta: {category:'Product Detail Settings', requiresAuth: true, requiresAdmin: true },
        props: true
    },
    {
        path: "/ProductDetailUpdate",
        name: "ProductDetailUpdate",
        component: ProductDetailUpdate,
        meta: {category:'Product Detail Settings', requiresAuth: true, requiresAdmin: true },
        props: true
    },
    {
        path: "/ProductGroupList",
        name: "ProductGroupList",
        component: ProductGroupList,
        meta: {category:'Product Group Settings', requiresAuth: true, requiresAdmin: true, fullwidth: true },
        props: true
    },
    {
        path: "/ProductGroupCreate",
        name: "ProductGroupCreate",
        component: ProductGroupCreate,
        meta: {category:'Product Group Settings', requiresAuth: true, requiresAdmin: true },
        props: true
    },
    {
        path: "/ProductGroupUpdate",
        name: "ProductGroupUpdate",
        component: ProductGroupUpdate,
        meta: {category:'Product Group Settings', requiresAuth: true, requiresAdmin: true },
        props: true
    },
    {
        path: "/CampaignList",
        name: "CampaignList",
        component: CampaignList,
        meta: {category:'Campaign Settings', requiresAuth: true, requiresAdmin: true, fullwidth: true },
        props: true
    },
    {
        path: "/CampaignCreate",
        name: "CampaignCreate",
        component: CampaignCreate,
        meta: {category:'Campaign Settings', requiresAuth: true, requiresAdmin: true },
        props: true
    },
    {
        path: "/CampaignUpdate",
        name: "CampaignUpdate",
        component: CampaignUpdate,
        meta: {category:'Campaign Settings', requiresAuth: true, requiresAdmin: true },
        props: true
    },
    {
        path: "/CashTransactionList",
        name: "CashTransactionList",
        component: CashTransactionList,
        meta: {category:'/Cash Transaction', requiresAuth: true, fullwidth: true },
        props: true
    },
    {
        path: "/CashTransactionCreate",
        name: "CashTransactionCreate",
        component: CashTransactionCreate,
        meta: {category:'/Cash Transaction', requiresAuth: true, fullwidth: true },
        props: true
    },
    {
        path: "/CashTransactionUpdate",
        name: "CashTransactionUpdate",
        component: CashTransactionUpdate,
        meta: {category:'/Cash Transaction', requiresAuth: true, fullwidth: true },
        props: true
    },
    {
        path: "/BankTransactionList",
        name: "BankTransactionList",
        component: BankTransactionList,
        meta: {category:'Bank Transaction', requiresAuth: true, fullwidth: true },
        props: true
    },
    {
        path: "/BankTransactionCreate",
        name: "BankTransactionCreate",
        component: BankTransactionCreate,
        meta: {category:'Bank Transaction', requiresAuth: true, fullwidth: true },
        props: true
    },
    {
        path: "/BankTransactionUpdate",
        name: "BankTransactionUpdate",
        component: BankTransactionUpdate,
        meta: {category:'Bank Transaction', requiresAuth: true, fullwidth: true },
        props: true
    },
    {
        path:"/OfferCreate",
        name:"OfferCreate",
        component:OfferCreate,
        meta:{category :"Offer Settings",requiresAuth:true,fullwidth:true},
        props:true
    },
    {
        path:"/OfferList",
        name:"OfferList",
        component:OfferList,
        meta:{category :"Offer Settings",requiresAuth:true,fullwidth:true},
        props:true
    },
    {
        path:"/OfferUpdate",
        name:"OfferUpdate",
        component:OfferUpdate,
        meta:{category :"Offer Settings",requiresAuth:true,fullwidth:true},
        props:true
    },
    {
        path: "/BalanceClosingOperations",
        name: "BalanceClosingOperations",
        component: BalanceClosingOperations,
        meta: {category:'/Balance Closing', requiresAuth: true, fullwidth: true },
        props: true
    },
    {
        path: "/NumeratorSettingsList",
        name: "NumeratorSettingsList",
        component: NumeratorSettingsList,
        meta: {category:'Numerator Settings', requiresAuth: true, requiresAdmin: true, fullwidth: true },
        props: true
    },
    {
        path: "/NumeratorSettingsCreate",
        name: "NumeratorSettingsCreate",
        component: NumeratorSettingsCreate,
        meta: {category:'Numerator Settings', requiresAuth: true, requiresAdmin: true },
        props: true
    },
    {
        path: "/NumeratorSettingsUpdate",
        name: "NumeratorSettingsUpdate",
        component: NumeratorSettingsUpdate,
        meta: {category:'Numerator Settings', requiresAuth: true, requiresAdmin: true },
        props: true
    }, 
    {
        path: "/DataImportSettings",
        name: "DataImportSettings",
        component: DataImportSettings,
        meta: {category:'Data Import Settings', requiresAuth: true, requiresAdmin: true },
        props: true
    }, 
    {
        path: "/DataTransferList",
        name: "DataTransferList",
        component: DataTransferList,
        meta: {category:'DataTransfer', requiresAuth: true, requiresAdmin: true, fullwidth: true },
        props: true
    },
    {
        path: "/TransactionList",
        name: "TransactionList",
        component: TransactionList,
        meta: {category:'Transaction', requiresAuth: true, fullwidth: true },
        props: true
    }, 
    {
        path: "/RatesList",
        name: "RatesList",
        component: RatesList,
        meta: {category:'Rates', requiresAuth: true, fullwidth: true },
        props: true
    },
    {
        path: "/AccountingVoucherList",
        name: "AccountingVoucherList",
        component: AccountingVoucherList,
        meta: {category:'Accounting Transaction', requiresAuth: true, fullwidth: true, requiresAccountingLicence: true },
        props: true
    },
    {
        path: "/AccountingVoucherCreate",
        name: "AccountingVoucherCreate",
        component: AccountingVoucherCreate,
        meta: {category:'Accounting Transaction', requiresAuth: true, fullwidth: true, requiresAccountingLicence: true },
        props: true
    },
    {
        path: "/AccountingVoucherUpdate",
        name: "AccountingVoucherUpdate",
        component: AccountingVoucherUpdate,
        meta: {category:'Accounting Transaction', requiresAuth: true, fullwidth: true, requiresAccountingLicence: true },
        props: true
    },
    {
        path: "/Settings",
        name: "Settings",
        component: Settings,
        meta: {category:'Settings', requiresAuth: true, requiresAdmin: true },
        props: true
    },
    {
        path: "/PaymentTypeList",
        name: "PaymentTypeList",
        component: PaymentTypeList,
        meta: {category:'PaymentType Settings', requiresAuth: true, requiresAdmin: true, fullwidth: true },
        props: true
    },
    {
        path: "/PaymentTypeCreate",
        name: "PaymentTypeCreate",
        component: PaymentTypeCreate,
        meta: {category:'PaymentType Settings', requiresAuth: true, requiresAdmin: true },
        props: true
    },
    {
        path: "/PaymentTypeUpdate",
        name: "PaymentTypeUpdate",
        component: PaymentTypeUpdate,
        meta: {category:'PaymentType Settings', requiresAuth: true, requiresAdmin: true },
        props: true
    }, 
    {
        path: "/ShippingTypeList",
        name: "ShippingTypeList",
        component: ShippingTypeList,
        meta: {category:'ShippingType Settings', requiresAuth: true, requiresAdmin: true, fullwidth: true },
        props: true
    },
    {
        path: "/ShippingTypeCreate",
        name: "ShippingTypeCreate",
        component: ShippingTypeCreate,
        meta: {category:'ShippingType Settings', requiresAuth: true, requiresAdmin: true },
        props: true
    },
    {
        path: "/ShippingTypeUpdate",
        name: "ShippingTypeUpdate",
        component: ShippingTypeUpdate,
        meta: {category:'ShippingType Settings', requiresAuth: true, requiresAdmin: true },
        props: true
    },
    {
        path: "/StockTrackingGroupList",
        name: "StockTrackingGroupList",
        component: StockTrackingGroupList,
        meta: {category:'StockTrackingGroup Settings', requiresAuth: true, requiresAdmin: true, fullwidth: true },
        props: true
    },
    {
        path: "/StockTrackingGroupCreate",
        name: "StockTrackingGroupCreate",
        component: StockTrackingGroupCreate,
        meta: {category:'StockTrackingGroup Settings', requiresAuth: true, requiresAdmin: true },
        props: true
    },
    {
        path: "/StockTrackingGroupUpdate",
        name: "StockTrackingGroupUpdate",
        component: StockTrackingGroupUpdate,
        meta: {category:'StockTrackingGroup Settings', requiresAuth: true, requiresAdmin: true },
        props: true
    }, 
    {
        path: "/ExpenseTransactionList",
        name: "ExpenseTransactionList",
        component: ExpenseTransactionList,
        meta: {category:'Expense Transaction', requiresAuth: true, fullwidth: true },
        props: true
    },
    {
        path: "/ExpenseTransactionCreate",
        name: "ExpenseTransactionCreate",
        component: ExpenseTransactionCreate,
        meta: {category:'Expense Transaction', requiresAuth: true, fullwidth: true },
        props: true
    },
    {
        path: "/ExpenseTransactionUpdate",
        name: "ExpenseTransactionUpdate",
        component: ExpenseTransactionUpdate,
        meta: {category:'Expense Transaction', requiresAuth: true, fullwidth: true },
        props: true
    }, 
    {
        path: "/BalanceSheet",
        name: "BalanceSheet",
        component: BalanceSheet,
        meta: {category:'BalanceSheet', requiresAuth: true, fullwidth: true, requiresAccountingLicence: true },
        props: true
    },  
    {
        path: "/ComparativeBalanceSheet",
        name: "ComparativeBalanceSheet",
        component: ComparativeBalanceSheet,
        meta: {category:'ComparativeBalanceSheet', requiresAuth: true, fullwidth: true, requiresAccountingLicence: true },
        props: true
    }, 
    {
        path: "/ChequeTransactionList",
        name: "ChequeTransactionList",
        component: ChequeTransactionList,
        meta: {category:'Cheque Transaction', requiresAuth: true, fullwidth: true },
        props: true
    },
    {
        path: "/ChequeTransactionCreate",
        name: "ChequeTransactionCreate",
        component: ChequeTransactionCreate,
        meta: {category:'Cheque Transaction', requiresAuth: true, fullwidth: true },
        props: true
    },
    {
        path: "/ChequeTransactionUpdate",
        name: "ChequeTransactionUpdate",
        component: ChequeTransactionUpdate,
        meta: {category:'Cheque Transaction', requiresAuth: true, fullwidth: true },
        props: true
    },
    {
        path: "/ChequeOperationsList",
        name: "ChequeOperationsList",
        component: ChequeOperationsList,
        meta: {category:'Cheque Transaction', requiresAuth: true, fullwidth: true },
        props: true
    },
    {
        path: "/ChequeOperationsCreate",
        name: "ChequeOperationsCreate",
        component: ChequeOperationsCreate,
        meta: {category:'Cheque Transaction', requiresAuth: true, fullwidth: true },
        props: true
    },
    {
        path: "/ChequeOperationsUpdate",
        name: "ChequeOperationsUpdate",
        component: ChequeOperationsUpdate,
        meta: {category:'Cheque Transaction', requiresAuth: true, fullwidth: true },
        props: true
    },
    {
        path: "/AccountTransactionList",
        name: "AccountTransactionList",
        component: AccountTransactionList,
        meta: {category:'AccountTransactionList', requiresAuth: true, fullwidth: true },
        props: true
    },
    {
        path: "/ConsolidationList",
        name: "ConsolidationList",
        component: ConsolidationList,
        meta: {category:'ConsolidationList', requiresAuth: true, fullwidth: true },
        props: true
    },
    {
        path: "/GeneralAccountReport",
        name: "GeneralAccountReport",
        component: GeneralAccountReport,
        meta: {category:'GeneralAccount Report', requiresAuth: true, fullwidth: true },
        props: true
    }, 
    {
        path: "/Documents",
        name: "Documents",
        component: Documents,
        meta: {category:'Documents', requiresAuth: true, fullwidth: false, requiresDocumentLicence: true, quickmode: true },
        props: true
    },
    {
        path: "/StockTransactionList",
        name: "StockTransactionList",
        component: StockTransactionList,
        meta: {category:'StockTransactionList', requiresAuth: true, fullwidth: true },
        props: true
    }, 
    {
        path: "/StockCostList",
        name: "StockCostList",
        component: StockCostList,
        meta: {category:'StockCostList', requiresAuth: true, fullwidth: true },
        props: true
    }, 
    {
        path: "/GeneralPolicyReport",
        name: "GeneralPolicyReport",
        component: GeneralPolicyReport,
        meta: {category:'GeneralPolicyReport', requiresAuth: true, requiresInsuranceLicence: true, fullwidth: true },
        props: true
    }, 
    {
        path: "/GeneralStockTransactionList",
        name: "GeneralStockTransactionList",
        component: GeneralStockTransactionList,
        meta: {category:'GeneralStockTransactionList', requiresAuth: true, fullwidth: true },
        props: true
    },
    {
        path: "/CollectionList",
        name: "CollectionList",
        component: CollectionList,
        meta: {category:'CollectionList', requiresAuth: true, fullwidth: true },
        props: true
    },
    {
        path: "/CollectedList",
        name: "CollectedList",
        component: CollectedList,
        meta: {category:'CollectedList', requiresAuth: true, fullwidth: true },
        props: true
    }, 
    {
        path: "/PaymentList",
        name: "PaymentList",
        component: PaymentList,
        meta: {category:'PaymentList', requiresAuth: true, fullwidth: true },
        props: true
    }, 
    {
        path: "/PaidList",
        name: "PaidList",
        component: PaidList,
        meta: {category:'PaidList', requiresAuth: true, fullwidth: true },
        props: true
    }, 
    {
        path: "/TurnoverDiffList",
        name: "TurnoverDiffList",
        component: TurnoverDiffList,
        meta: {category:'TurnoverDiff', requiresAuth: true, requiresAdmin: true, fullwidth: true },
        props: true
    }, 
    {
        path: "/TurnoverDiffCreate",
        name: "TurnoverDiffCreate",
        component: TurnoverDiffCreate,
        meta: {category:'TurnoverDiff', requiresAuth: true, requiresAdmin: true, fullwidth: true },
        props: true
    },  
    {
        path: "/TurnoverDiffUpdate",
        name: "TurnoverDiffUpdate",
        component: TurnoverDiffUpdate,
        meta: {category:'TurnoverDiff', requiresAuth: true, requiresAdmin: true, fullwidth: true },
        props: true
    },    
    {
        path: "/OffsettingList",
        name: "OffsettingList",
        component: OffsettingList,
        meta: {category:'Offsetting', requiresAuth: true, fullwidth: true },
        props: true
    },
    {
        path: "/OffsettingCreate",
        name: "OffsettingCreate",
        component: OffsettingCreate,
        meta: {category:'Offsetting', requiresAuth: true, fullwidth: true },
        props: true
    },
    {
        path: "/OffsettingUpdate",
        name: "OffsettingUpdate",
        component: OffsettingUpdate,
        meta: {category:'Offsetting', requiresAuth: true, fullwidth: true },
        props: true
    },
    {
        path: "/ListTypes",
        name: "ListTypes",
        component: ListTypes,
        meta: {category:'ListTypes', requiresAuth: true, requiresAdmin: true },
        props: true
    },
    {
        path: "/RecalculationOperations",
        name: "RecalculationOperations",
        component: RecalculationOperations,
        meta: {category:'RecalculationOperations', requiresAuth: true, requiresAdmin: true },
        props: true
    },
    {
        path: "/CostSettings",
        name: "CostSettings",
        component: CostSettings,
        meta: {category:'CostSettings', requiresAuth: true, requiresAdmin: true },
        props: true
    },
    {
        path: "/UserList",
        name: "UserList",
        component: UserList,
        meta: {category:'UserSettings', requiresAuth: true, requiresAdmin: true, fullwidth: true },
        props: true
    },
    {
        path: "/UserCreate",
        name: "UserCreate",
        component: UserCreate,
        meta: {category:'UserSettings', requiresAuth: true, requiresAdmin: true },
        props: true
    },
    {
        path: "/UserUpdate",
        name: "UserUpdate",
        component: UserUpdate,
        meta: {category:'UserSettings', requiresAuth: true, requiresAdmin: true },
        props: true
    },
    {
        path: "/UserProfile",
        name: "UserProfile",
        component: UserProfile,
        meta: {category:'UserSettings', requiresAuth: true, quickmode: true },
        props: true
    },
    {
        path: "/OtherAccountList",
        name: "OtherAccountList",
        component: OtherAccountList,
        meta: {category:'Other Account', requiresAuth: true, fullwidth: true },
        props: true
    },
    {
        path: "/OtherAccountCreate",
        name: "OtherAccountCreate",
        component: OtherAccountCreate,
        meta: {category:'Other Account', requiresAuth: true },
        props: true
    },
    {
        path: "/OtherAccountUpdate",
        name: "OtherAccountUpdate",
        component: OtherAccountUpdate,
        meta: {category:'Other Account', requiresAuth: true },
        props: true
    },
    {
        path: "/OtherAccountTypeList",
        name: "OtherAccountTypeList",
        component: OtherAccountTypeList,
        meta: {category:'Other Account Type', requiresAuth: true, requiresAdmin: true, fullwidth: true },
        props: true
    },
    {
        path: "/OtherAccountTypeCreate",
        name: "OtherAccountTypeCreate",
        component: OtherAccountTypeCreate,
        meta: {category:'Other Account Type', requiresAuth: true, requiresAdmin: true },
        props: true
    },
    {
        path: "/OtherAccountTypeUpdate",
        name: "OtherAccountTypeUpdate",
        component: OtherAccountTypeUpdate,
        meta: {category:'Other Account Type', requiresAuth: true, requiresAdmin: true },
        props: true
    },
    {
        path: "/OpeningOperationsList",
        name: "OpeningOperationsList",
        component: OpeningOperationsList,
        meta: {category:'OpeningOperationsList', requiresAuth: true, fullwidth: true, requiresAccountingLicence: true },
        props: true
    },
    {
        path: "/ClosingOperationsList",
        name: "ClosingOperationsList",
        component: ClosingOperationsList,
        meta: {category:'ClosingOperationsList', requiresAuth: true, fullwidth: true, requiresAccountingLicence: true },
        props: true
    },
    {
        path: "/ProfitAndLossOperationsList",
        name: "ProfitAndLossOperationsList",
        component: ProfitAndLossOperationsList,
        meta: {category:'ProfitAndLossOperationsList', requiresAuth: true, fullwidth: true, requiresAccountingLicence: true },
        props: true
    },
    {
        path: "/AccountingIntegrationList",
        name: "AccountingIntegrationList",
        component: AccountingIntegrationList,
        meta: {category:'AccountingIntegrationList', requiresAuth: true, fullwidth: true, requiresAccountingLicence: true },
        props: true
    },
    {
        path: "/VATOperationsList",
        name: "VATOperationsList",
        component: VATOperationsList,
        meta: {category:'VATOperationsList', requiresAuth: true, fullwidth: true, requiresAccountingLicence: true },
        props: true
    },
    {
        path: "/GeneralProfitAndLossList",
        name: "GeneralProfitAndLossList",
        component: GeneralProfitAndLossList,
        meta: {category:'GeneralProfitAndLossList',  requiresAuth: true, fullwidth: true, requiresAccountingLicence: true },
        props: true
    },
    {
        path: "/ComparativeProfitAndLossList",
        name: "ComparativeProfitAndLossList",
        component: ComparativeProfitAndLossList,
        meta: {category:'ComparativeProfitAndLossList',  requiresAuth: true, fullwidth: true, requiresAccountingLicence: true },
        props: true
    },    
    {
        path: "/BankAccountTypeList",
        name: "BankAccountTypeList",
        component: BankAccountTypeList,
        meta: {category:'Bank AccountType',  requiresAuth: true, requiresAdmin: true, fullwidth: true },
        props: true
    },
    {
        path: "/BankAccountTypeCreate",
        name: "BankAccountTypeCreate",
        component: BankAccountTypeCreate,
        meta: {category:'Bank AccountType',  requiresAuth: true, requiresAdmin: true },
        props: true
    },
    {
        path: "/BankAccountTypeUpdate",
        name: "BankAccountTypeUpdate",
        component: BankAccountTypeUpdate,
        meta: {category:'Bank AccountType',  requiresAuth: true, requiresAdmin: true },
        props: true
    }, 
    {
        path: "/CustomerTypeList",
        name: "CustomerTypeList",
        component: CustomerTypeList,
        meta: {category:'Customer AccountType', requiresAuth: true, requiresAdmin: true, fullwidth: true },
        props: true
    },
    {
        path: "/CustomerTypeCreate",
        name: "CustomerTypeCreate",
        component: CustomerTypeCreate,
        meta: {category:'Customer AccountType', requiresAuth: true, requiresAdmin: true },
        props: true
    },
    {
        path: "/CustomerTypeUpdate",
        name: "CustomerTypeUpdate",
        component: CustomerTypeUpdate,
        meta: {category:'Customer AccountType', requiresAuth: true, requiresAdmin: true },
        props: true
    },
    {
        path: "/ExpenseTypeList",
        name: "ExpenseTypeList",
        component: ExpenseTypeList,
        meta: {category:'Expense AccountType', requiresAuth: true, requiresAdmin: true, fullwidth: true },
        props: true
    },
    {
        path: "/ExpenseTypeCreate",
        name: "ExpenseTypeCreate",
        component: ExpenseTypeCreate,
        meta: {category:'Expense AccountType', requiresAuth: true, requiresAdmin: true },
        props: true
    },
    {
        path: "/ExpenseTypeUpdate",
        name: "ExpenseTypeUpdate",
        component: ExpenseTypeUpdate,
        meta: {category:'Expense AccountType', requiresAuth: true, requiresAdmin: true },
        props: true
    }, 
    {
        path: "/PriceTypeList",
        name: "PriceTypeList",
        component: PriceTypeList,
        meta: {category:'Price AccountType', requiresAuth: true, requiresAdmin: true, fullwidth: true },
        props: true
    },
    {
        path: "/PriceTypeCreate",
        name: "PriceTypeCreate",
        component: PriceTypeCreate,
        meta: {category:'Price AccountType', requiresAuth: true, requiresAdmin: true },
        props: true
    },
    {
        path: "/PriceTypeUpdate",
        name: "PriceTypeUpdate",
        component: PriceTypeUpdate,
        meta: {category:'Price AccountType', requiresAuth: true, requiresAdmin: true },
        props: true
    },
    {
        path: "/UnitTypeList",
        name: "UnitTypeList",
        component: UnitTypeList,
        meta: {category:'Unit AccountType', requiresAuth: true, requiresAdmin: true, fullwidth: true },
        props: true
    },
    {
        path: "/UnitTypeCreate",
        name: "UnitTypeCreate",
        component: UnitTypeCreate,
        meta: {category:'Unit AccountType', requiresAuth: true, requiresAdmin: true },
        props: true
    },
    {
        path: "/UnitTypeUpdate",
        name: "UnitTypeUpdate",
        component: UnitTypeUpdate,
        meta: {category:'Unit AccountType', requiresAuth: true, requiresAdmin: true },
        props: true
    }, 
    {
        path: "/UnitType2List",
        name: "UnitType2List",
        component: UnitType2List,
        meta: {category:'Unit2 AccountType', requiresAuth: true, requiresAdmin: true, fullwidth: true },
        props: true
    },
    {
        path: "/UnitType2Create",
        name: "UnitType2Create",
        component: UnitType2Create,
        meta: {category:'Unit2 AccountType', requiresAuth: true, requiresAdmin: true },
        props: true
    },
    {
        path: "/UnitType2Update",
        name: "UnitType2Update",
        component: UnitType2Update,
        meta: {category:'Unit2 AccountType', requiresAuth: true, requiresAdmin: true },
        props: true
    },
    {
        path: "/AccessAuthorization",
        name: "AccessAuthorization",
        component: AccessAuthorization,
        meta: {category:'AccessAuthorization', requiresAuth: true, requiresAdmin: true },
        props: true
    },
    {
        path: "/DocumentSettings",
        name: "DocumentSettings",
        component: DocumentSettings,
        meta: {category:'DocumentSettings', requiresAuth: true, requiresAdmin: true },
        props: true
    },
    {
        path: "/BalanceClosingParameters",
        name: "BalanceClosingParameters",
        component: BalanceClosingParameters,
        meta: {category:'BalanceClosingParameters', requiresAuth: true, requiresAdmin: true },
        props: true
    },
    {
        path: "/AccountingSettings",
        name: "AccountingSettings",
        component: AccountingSettings,
        meta: {category:'AccountingSettings', requiresAuth: true, requiresAdmin: true, requiresAccountingLicence: true },
        props: true
    },
    {
        path: "/CompanySettings",
        name: "CompanySettings",
        component: CompanySettings,
        meta: {category:'CompanySettings', requiresAuth: true, requiresAdmin: true },
        props: true
    },
    {
        path: "/CompanyInfos",
        name: "CompanyInfos",
        component: CompanyInfos,
        meta: {category:'CompanyInfos', requiresAuth: true, requiresAdmin: true, fullwidth: true },
        props: true
    },
    {
        path: "/FileManagerList",
        name: "FileManagerList",
        component: FileManagerList,
        meta: { category:'FileManager', requiresAuth: true },
        props: true
    },
    {
        path: "/Liabilities",
        name: "Liabilities",
        component: Liabilities,
        meta: {category:'Liabilities', requiresAuth: true, requiresLiabilityLicence: true, quickmode: true },
        props: true
    },
    {
        path: "/PolicyDataImport",
        name: "PolicyDataImport",
        component: PolicyDataImport,
        meta: {category:'PolicyDataImport', requiresAuth: true, requiresInsuranceLicence: true, fullwidth: true },
        props: true
    },
    {
        path: "/PolicyList",
        name: "PolicyList",
        component: PolicyList,
        meta: {category:'PolicyList', requiresAuth: true, requiresInsuranceLicence: true, fullwidth: true },
        props: true
    },
    {
        path: "/PolicyCreate",
        name: "PolicyCreate",
        component: PolicyCreate,
        meta: {category:'PolicyCreate', requiresAuth: true, requiresInsuranceLicence: true, fullwidth: true },
        props: true
    },
    {
        path: "/PolicyUpdate",
        name: "PolicyUpdate",
        component: PolicyUpdate,
        meta: {category:'PolicyUpdate', requiresAuth: true, requiresInsuranceLicence: true, fullwidth: true },
        props: true
    },
    {
        path: "/:pageUrl",
        name: "HelpPage",
        component: HelpPage,
        meta: {category:'HelpPage', requiresAuth: false, fullwidth: false},
        props: true
    },
    {
        path: "/ChequeAccountList",
        name: "ChequeAccountList",
        component: ChequeAccountList,
        meta: {category:'Cheque Account', requiresAuth: true, fullwidth: true },
        props: true
    },
    {
        path: "/MailSettingsList",
        name: "MailSettingsList",
        component: MailSettingsList,
        meta: {category:'MailSettingsList', requiresAuth: true, requiresAdmin: true },
        props: true
    },
    {
        path: "/MailSettingsCreate",
        name: "MailSettingsCreate",
        component: MailSettingsCreate,
        meta: {category:'MailSettingsCreate', requiresAuth: true, requiresAdmin: true },
        props: true
    },
    {
        path: "/MailSettingsUpdate",
        name: "MailSettingsUpdate",
        component: MailSettingsUpdate,
        meta: {category:'MailSettingsUpdate', requiresAuth: true, requiresAdmin: true },
        props: true
    },
    {
        path: "/PermissionDenied",
        name: "PermissionDenied",
        component: PermissionDenied,
        meta: {category:'PermissionDenied', requiresAuth: true, quickmode: true  },
        props: true
    },
    {
        path: "/LicenceExpired",
        name: "LicenceExpired",
        component: LicenceExpired,
        meta: {category:'LicenceExpired', fullwidth: true },
        props: true
    },
    {
        path: "/:pathMatch(.*)*",
        name: "PageNotFound",
        component: PageNotFound,
        meta: {category:'PageNotFound', requiresAuth: true  },
        props: true
    },
    {
        path: "/UpgradeSuccess",
        name: "UpgradeSuccess",
        component: UpgradeSuccess,
        meta: {category:'UpgradeSuccess', requiresAuth: false, quickmode: true  },
        props: true
    },
    {
        path: "/UpgradeFailed",
        name: "UpgradeFailed",
        component: UpgradeFailed,
        meta: {category:'UpgradeFailed', requiresAuth: false, quickmode: true  },
        props: true
    },
    {
        path: "/SourceCodeList",
        name: "SourceCodeList",
        component: SourceCodeList,
        meta: {category:'SourceCode', requiresAuth: true, requiresAdmin: true, fullwidth: true },
        props: true
    },
    {
        path: "/SourceCodeCreate",
        name: "SourceCodeCreate",
        component: SourceCodeCreate,
        meta: {category:'SourceCode', requiresAuth: true, requiresAdmin: true },
        props: true
    },
    {
        path: "/SourceCodeUpdate",
        name: "SourceCodeUpdate",
        component: SourceCodeUpdate,
        meta: {category:'SourceCode', requiresAuth: true, requiresAdmin: true },
        props: true
    },
    {
        path: "/SourceCode2List",
        name: "SourceCode2List",
        component: SourceCode2List,
        meta: {category:'SourceCode2', requiresAuth: true, requiresAdmin: true, fullwidth: true },
        props: true
    },
    {
        path: "/SourceCode2Create",
        name: "SourceCode2Create",
        component: SourceCode2Create,
        meta: {category:'SourceCode2', requiresAuth: true, requiresAdmin: true },
        props: true
    },
    {
        path: "/SourceCode2Update",
        name: "SourceCode2Update",
        component: SourceCode2Update,
        meta: {category:'SourceCode2', requiresAuth: true, requiresAdmin: true },
        props: true
    },
    {
        path: "/PolicyBranchFactors",
        name: "PolicyBranchFactors",
        component: PolicyBranchFactors,
        meta: {category:'Policy Branch Factors', requiresAuth: true, requiresAdmin: true },
        props: true
    },
    {
        path: "/MultiDeleteOperations",
        name: "MultiDeleteOperations",
        component: MultiDeleteOperations,
        meta: {category:'MultiDeleteOperations', requiresAuth: true, requiresAdmin: true },
        props: true
    },
    {
        path: "/SystemLogs",
        name: "SystemLogs",
        component: SystemLogs,
        meta: {category:'SystemLogs', requiresAuth: true, requiresAdmin: true, fullwidth: true },
        props: true
    },
    {
        path: "/UserManagement",
        name: "UserManagement",
        component: UserManagement,
        meta: {category:'UserManagement', requiresAuth: true, requiresAdmin: true },
        props: true
    },
    {
        path: "/MultiAccountDelete",
        name: "MultiAccountDelete",
        component: MultiAccountDelete,
        meta: {category:'MultiAccountDelete', requiresAuth: true, requiresAdmin: true },
        props: true
    },
    {
        path: "/MultiPolicyDelete",
        name: "MultiPolicyDelete",
        component: MultiPolicyDelete,
        meta: {category:'MultiPolicyDelete', requiresAuth: true, requiresInsuranceLicence: true, requiresAdmin: true , fullwidth: true },
        props: true
    },
    {
        path: "/UserGroupList",
        name: "UserGroupList",
        component: UserGroupList,
        meta: {category:'UserGroup', requiresAuth: true, requiresAdmin: true },
        props: true
    },
    {
        path: "/UserGroupCreate",
        name: "UserGroupCreate",
        component: UserGroupCreate,
        meta: {category:'UserGroup', requiresAuth: true, requiresAdmin: true },
        props: true
    },
    {
        path: "/UserGroupUpdate",
        name: "UserGroupUpdate",
        component: UserGroupUpdate,
        meta: {category:'UserGroup', requiresAuth: true, requiresAdmin: true, fullwidth: true },
        props: true
    },
    {
        path: "/SessionManagement",
        name: "SessionManagement",
        component: SessionManagement,
        meta: {category:'SessionManagement', requiresAuth: true, requiresAdmin: true },
        props: true
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
}); 

router.beforeEach((to, from, next) => {
    if (to.meta.category != from.meta.category){
        sessionStorage.setItem('refreshList', false);
    }

    if(to.name == 'HelpPage') {
        if(to.path.includes('Helppage') == false) {
            router.push({ name: "PageNotFound" });
        }
    }

    /**
     * Kurallar
     * 1. Eğer "s" query stringi ile gelindiyse bunu SS'de tutuyoruz.
     * 2. Route çizelgesinde Login gerektiren ekranlarda mutlaka Authentication kontrolü yapıyoruz.
     * 3. Deneme yanılmalara ve Route'un fail olma ihtimaline karşı kullanıcıyı her hatada /Login'e atmalıyız.
     */  
        var GetAuthStorage =  ls.get('user_' + sessionStorage.getItem('sid'));

        //Home + Düz Login
        if(to.query === null || to.query.s === null || to.query === undefined || to.query.s === undefined || to.query === '' || to.query.s === ''){
            if (to.matched.some((record) => record.meta.requiresAuth)) {
                try{
                    if (GetAuthStorage !== null && GetAuthStorage !== '' && GetAuthStorage !== undefined) {
                        if(GetAuthStorage.period_end <= 0 && GetAuthStorage.is_quick_mode !== 1) {
                            next("/LicenceExpired");
                            return;
                        } else {
                            if (to.matched.some((record) => record.meta.requiresAdmin)) {
                                if(GetAuthStorage.permission_type == 1) {
                                    to.matched.some((record) => {
                                        if((record.meta.requiresAccountingLicence && (!isModuleActive(5) || GetAuthStorage.is_accounting_info_required)) 
                                        || (record.meta.requiresLiabilityLicence && !isModuleActive(35)) 
                                        || (record.meta.requiresDocumentLicence && !isModuleActive(3)) 
                                        || (record.meta.requiresInsuranceLicence && !this.$isModuleActive(28)) 
                                        || (to.name == 'AccountingIntegrationList' && GetAuthStorage.is_manual_integration_active !== 1 && isModuleActive(5))
                                        || (GetAuthStorage.is_quick_mode == 1 && !record.meta.quickmode)) {
                                            next("/PermissionDenied");
                                        } else {
                                            next();
                                            return;
                                        }
                                    });
                                }
                                else{
                                    next("/PermissionDenied");
                                }
                            } else {
                                to.matched.some((record) => {
                                    if((record.meta.requiresAccountingLicence && (!isModuleActive(5) || GetAuthStorage.is_accounting_info_required)) 
                                    || (record.meta.requiresLiabilityLicence && !isModuleActive(35)) 
                                    || (record.meta.requiresDocumentLicence && !isModuleActive(3)) 
                                    || (record.meta.requiresInsuranceLicence && !this.$isModuleActive(28)) 
                                    || (to.name == 'AccountingIntegrationList' && GetAuthStorage.is_manual_integration_active !== 1 && isModuleActive(5))
                                    || (GetAuthStorage.is_quick_mode == 1 && !record.meta.quickmode)) {
                                        next("/PermissionDenied");
                                    } else {
                                        next();
                                        return;
                                    }
                                });
                            }
                        }
                    }
                    else{
                        sessionStorage.clear();
                        router.push({ name: "Login" });
                    }
                }
                catch(Err){
                    console.error(Err);
                    sessionStorage.clear();
                    router.push({ name: "Login" });
                }
            } 
            else {
                to.matched.some((record) => {
                    if((record.meta.requiresAccountingLicence && (!isModuleActive(5) || GetAuthStorage.is_accounting_info_required)) 
                    || (record.meta.requiresLiabilityLicence && !isModuleActive(35)) 
                    || (record.meta.requiresDocumentLicence && !isModuleActive(3)) 
                    || (record.meta.requiresInsuranceLicence && !this.$isModuleActive(28)) 
                    || (to.name == 'AccountingIntegrationList' && GetAuthStorage.is_manual_integration_active !== 1 && isModuleActive(5))
                    || (GetAuthStorage.is_quick_mode == 1 && !record.meta.quickmode)) {
                        next("/PermissionDenied");
                    } else {
                        next();
                    }
                });
            } 
        }
        //Home + Instant Login
        if(to.name == 'Home' && to.query !== null && to.query.s !== null && to.query !== undefined && to.query.s !== undefined && to.query !== '' && to.query.s !== ''){
            if(GetAuthStorage == null || GetAuthStorage == '' || GetAuthStorage == undefined){
                sessionStorage.setItem('sid', to.query.s);
                next();
                return;
            }
            else{
                // Security Check
                if (to.matched.some((record) => record.meta.requiresAuth)) {
                    if(GetAuthStorage.period_end <= 0 && GetAuthStorage.is_quick_mode !== 1) {
                        next("/LicenceExpired");
                    } else {
                        try{
                            if (GetAuthStorage !== null && GetAuthStorage !== '' && GetAuthStorage !== undefined) {
                                if (to.matched.some((record) => record.meta.requiresAdmin)) {
                                    if(GetAuthStorage.permission_type == 1) {
                                        to.matched.some((record) => {
                                            if((record.meta.requiresAccountingLicence && (!isModuleActive(5) || GetAuthStorage.is_accounting_info_required)) 
                                            || (record.meta.requiresLiabilityLicence && !isModuleActive(35)) 
                                            || (record.meta.requiresDocumentLicence && !isModuleActive(3)) 
                                            || (record.meta.requiresInsuranceLicence && !this.$isModuleActive(28)) 
                                            || (to.name == 'AccountingIntegrationList' && GetAuthStorage.is_manual_integration_active !== 1 && isModuleActive(5))
                                            || (GetAuthStorage.is_quick_mode == 1 && !record.meta.quickmode)) {
                                                next("/PermissionDenied");
                                            } else {
                                                next();
                                                return;
                                            }
                                        });
                                    }
                                    else{
                                        next("/PermissionDenied");
                                    }
                                } else {
                                    to.matched.some((record) => {
                                        if((record.meta.requiresAccountingLicence && (!isModuleActive(5) || GetAuthStorage.is_accounting_info_required)) 
                                        || (record.meta.requiresLiabilityLicence && !isModuleActive(35)) 
                                        || (record.meta.requiresDocumentLicence && !isModuleActive(3)) 
                                        || (record.meta.requiresInsuranceLicence && !this.$isModuleActive(28)) 
                                        || (to.name == 'AccountingIntegrationList' && GetAuthStorage.is_manual_integration_active !== 1 && isModuleActive(5))
                                        || (GetAuthStorage.is_quick_mode == 1 && !record.meta.quickmode)) {
                                            next("/PermissionDenied");
                                        } else {
                                            next();
                                            return;
                                        }
                                    });
                                }
                            }
                            else{
                                sessionStorage.clear();
                                router.push({ name: "Login" });
                            }
                        }
                        catch(Err){
                            console.error(Err);
                            sessionStorage.clear();
                            router.push({ name: "Login" });
                        }
                    }
                } 
                else {
                    to.matched.some((record) => {
                        if((record.meta.requiresAccountingLicence && (!isModuleActive(5) || GetAuthStorage.is_accounting_info_required)) 
                        || (record.meta.requiresLiabilityLicence && !isModuleActive(35))
                        || (record.meta.requiresDocumentLicence && !isModuleActive(3)) 
                        || (record.meta.requiresInsuranceLicence && !this.$isModuleActive(28)) 
                        || (to.name == 'AccountingIntegrationList' && GetAuthStorage.is_manual_integration_active !== 1 && isModuleActive(5))
                        || (GetAuthStorage.is_quick_mode == 1 && !record.meta.quickmode)) {
                            next("/PermissionDenied");
                        } else {
                            next();
                        }
                    });
                }
             }
        }   

        // Google Tag Assistant eklentisine bilgi gönderme amaçlı
        let user_id;
        
            if (GetAuthStorage && GetAuthStorage.user_mail) {
                user_id = GetAuthStorage.user_mail;
            } else {
                user_id = null; 
            }

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event : 'userData',
                userId : user_id,
            });

        next();
});

router.beforeResolve((to) => {
    if(to.name !== 'Home' && to.name !== '' && to.name !== undefined && to.name !== null && to.matched.some((record) => record.meta.requiresAuth)){
        try{ 
            var frmValues = JSON.parse(sessionStorage.getItem('frm_val_list'));
            if(frmValues){
                var frmVal = frmValues.filter((s) => s.route === to.name);
                if(frmVal.length > 0){
                    sessionStorage.setItem('frm_val', JSON.stringify(frmVal));
                }
                else{
                    sessionStorage.removeItem('frm_val');
                }
            } 
        }
        catch(Err){
            console.log(Err);
        } 
    } 
});

function isModuleActive(module) {
    let moduleStatus = false;
    var GetAuthStorage =  ls.get('user_' + sessionStorage.getItem('sid'));
    if(GetAuthStorage !== null && GetAuthStorage !== '' && GetAuthStorage !== undefined)
    {
        const modules = GetAuthStorage.modules;
        if(modules !== null && modules !== '' && modules !== undefined) {
        moduleStatus = modules.includes('#'+ module + '#');
        }
    }
    return moduleStatus;
}

export default router;