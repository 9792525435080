<template>
    <div>
        <div class="d-flex flex-stack flex-wrap">
            <div class="page-title d-flex flex-column me-3 mt-3"> 
                <h1 class="d-flex fw-bolder my-1 fs-3 text-gray-800">{{ $t('Create Sales Role / Campaign') }} </h1>  
                <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
                    <li class="breadcrumb-item text-gray-600">
                        <a href="#" class="text-gray-600 text-hover-primary">
                            <router-link :to="{ name: 'Home' }">{{ $t('Home') }}</router-link>
                        </a>
                    </li> 
                    <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'Settings' }">{{ $t('Settings') }}</router-link></li>     
                    <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'CampaignList' }">{{ $t('Sales Role / Campaign') }}</router-link></li>     
                    <li class="breadcrumb-item text-gray-600">{{ $t('Create Sales Role / Campaign') }}
                    <HelpPageIcon v-if="this.iconView == true" :menuSelection="this.menuSelection"></HelpPageIcon>
                    </li> 
                </ul> 
            </div>  
            <div class="d-flex align-items-center py-1">
                <router-link to="/CampaignList">
                    <button type="button" class="btn btn-light shadow-sm btn-arrow-left border border-gray-400 me-3">
                     <span class="ms-2 fs-7"> {{ $t('Return back to List') }}</span>
                    </button>
                </router-link> 
            </div>
        </div>
        <div class="content flex-row-fluid mt-4" id="kt_content">            
            <RequiredField></RequiredField>
            <div class="card card-xxl-stretch">
                <div class="row p-10">
                    <div class="col-lg-12 mt-0"> 
                        <div class="d-flex flex-row row">
                            <div class="d-flex flex-column flex-row-auto col-lg-6"> 
                                <div class="d-flex flex-column-fluid card card-xxl-stretch mb-xxl-6 bg-lighten border border-gray-300">
                                    <div class="card-body pb-0">
                                        <div class="row">
                                            <form id="kt_ecommerce_settings_general_form"
                                                class="form fv-plugins-bootstrap5 fv-plugins-framework">                                
                                                <div class="fv-row mb-7 row">
                                                    <div class="col-lg-12">
                                                        <label class="fs-6 fw-bold form-label mt-3 required">
                                                            {{ $t('Code') }}
                                                        </label>
                                                        <input type="text" class="form-control" name="code" v-model="form.code">
                                                    </div>
                                                    <div class="col-lg-12">
                                                        <label class="fs-6 fw-bold form-label mt-3 required">
                                                            {{ $t('Name') }}
                                                        </label>
                                                        <input type="text" class="form-control" name="name" v-model="form.name">
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <label class="fs-6 fw-bold form-label mt-3" for="isActive">
                                                            {{ $t('Active') }}
                                                        </label>
                                                        <div class="form-check mt-3 mb-3">
                                                            <input class="form-check-input" type="checkbox" value="" id="isActive" v-model="form.is_active">
                                                        </div> 
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <label class="fs-6 fw-bold form-label mt-3" for="isDefault">
                                                            {{ $t('Default') }}
                                                        </label>
                                                        <div class="form-check mt-3 mb-3">
                                                            <input class="form-check-input" type="checkbox" value="" id="isDefault" v-model="form.is_default">
                                                        </div> 
                                                    </div>
                                                </div>
                                            </form>
                                        </div> 
                                    </div>
                                </div> 
                            </div>
                            <div class="d-flex flex-column flex-row-auto col-lg-6">
                                <div class="d-flex flex-column-fluid card card-xxl-stretch mb-xxl-6 bg-lighten border border-gray-300">
                                    <div class="card-header min-h-45px">
                                        <div class="card-title">
                                            {{ $t('Invoice Defaults') }}
                                        </div>
                                    </div>
                                    <div class="card-body pb-0">
                                        <div class="row">
                                            <form id="kt_ecommerce_settings_general_form"
                                                class="form fv-plugins-bootstrap5 fv-plugins-framework">
                                                <div class="fv-row mb-7 row">
                                                    <div class="col-lg-4">
                                                        <label class="fs-6 fw-bold form-label mt-3">
                                                            <span>{{ $t('Payment Method') }}</span>
                                                        </label>
                                                        <Select2 v-model="form.payment_type_id" :options="paymentTypeList" :settings="{ width: '100%'}"/> 
                                                    </div>
                                                    <div class="col-lg-4">
                                                        <label class="fs-6 fw-bold form-label mt-3">
                                                            <span>{{ $t('Shipping Type') }}</span>
                                                        </label>
                                                        <Select2 v-model="form.shipping_type_id" :options="shippingTypeList" :settings="{ width: '100%'}"/> 
                                                    </div>
                                                    <div class="col-lg-4">
                                                        <label class="fs-6 fw-bold form-label mt-3">
                                                            <span>{{ $t('Sales Channel') }}</span>
                                                        </label>
                                                        <Select2 v-model="form.sales_channel_id" :options="salesChannelList" :settings="{ width: '100%'}"/> 
                                                    </div>
                                                </div>
                                                <div class="separator"></div>
                                                <div class="fv-row mb-7 row">
                                                    <div class="col-lg-12">
                                                        <label class="fs-6 fw-bold form-label mt-3" for="isAlways">
                                                            {{ $t('Always Apply') }}
                                                        </label>
                                                        <div class="form-check mt-3 mb-3">
                                                            <input class="form-check-input" type="checkbox" value="" id="isAlways" v-model="form.is_always">
                                                        </div> 
                                                    </div>
                                                    <div class="col-lg-4">
                                                        <label class="fs-6 fw-bold form-label mt-3">
                                                            <span>{{ $t('Payment Value') }}</span>
                                                            <small class="fs-8"> ({{ $t('Day') }}) </small>
                                                        </label>
                                                        <CurrencyInput type="text" class="form-control" name="payment_value" v-model="this.form.payment_value" 
                                                        :precision="0" @focus="$event.target.select()" :min="0" @change="onChangePaymentValue()" />
                                                    </div>
                                                    <div class="col-lg-4">
                                                        <label class="fs-6 fw-bold form-label mt-3">
                                                            <span>{{ $t('Campaign Start Date') }}</span>
                                                        </label>
                                                        <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.start_date" @update:modelValue="onChangePaymentValue()" :disabled="this.form.is_always"
                                                            inputClassName="form-control" format="dd.MM.yyyy" autoApply :clearable="false" weekNumbers :monthChangeOnScroll="'inverse'"></Datepicker>  
                                                    </div>
                                                    <div class="col-lg-4">
                                                        <label class="fs-6 fw-bold form-label mt-3">
                                                            <span>{{ $t('Campaign End Date') }}</span>
                                                        </label>
                                                        <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.end_date" :disabled="this.form.is_always" inputClassName="form-control" format="dd.MM.yyyy" autoApply :clearable="false" weekNumbers :monthChangeOnScroll="'inverse'"></Datepicker>  
                                                    </div>
                                                </div>
                                            </form>
                                        </div> 
                                    </div>
                                </div> 
                            </div>
                        </div> 
                    </div>
                    <div class="col-lg-12">
                        <div class="card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                            <div class="card-header min-h-45px">
                                <div class="card-title">
                                    {{ $t('Item Defaults') }}
                                </div>
                            </div>
                            <div class="card-body">
                                <div class="d-flex flex-stack flex-wrap mb-5">
                                    <div class="d-flex justify-content-end" data-kt-docs-table-toolbar="base">
                                        <button type="button" class="btn btn-sm btn-light btn-active-success me-3" data-bs-toggle="modal" ref="btnAddItem"
                                            data-bs-target="#kt_modal_add_item" @click="openItemModal('addItem')"
                                            data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                                            <span class="svg-icon svg-icon-2">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                    viewBox="0 0 24 24" fill="none">
                                                    <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1"
                                                        transform="rotate(-90 11.364 20.364)" fill="currentColor"></rect>
                                                    <rect x="4.36396" y="11.364" width="16" height="2" rx="1"
                                                        fill="currentColor">
                                                    </rect>
                                                </svg>
                                            </span>
                                            {{ $t('Add Item') }}
                                        </button>
                                        <button type="button" class="btn btn-sm btn-light btn-active-primary me-3" data-bs-toggle="modal" ref="btnEditItem"
                                            data-bs-target="#kt_modal_add_item" @click="openItemModal('editItem')" :disabled="isItemButtonDisabled == null || isItemButtonDisabled == undefined"
                                            data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                                            <span class="svg-icon svg-icon-2">
                                                <span class="svg-icon svg-icon-2">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                        <path opacity="0.3" d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z" fill="currentColor"/>
                                                        <path d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z" fill="currentColor"/>
                                                    </svg>
                                                </span>
                                            </span>
                                            {{ $t('Edit') }}
                                        </button>
                                        <button type="button" class="btn btn-sm btn-light btn-active-warning me-3" @click="deleteCampaignItem()" :disabled="isItemButtonDisabled == null || isItemButtonDisabled == undefined">
                                            <span class="svg-icon svg-icon-2">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"/>
                                                </svg>
                                            </span>
                                            {{ $t('Remove') }}
                                        </button>
                                        <button type="button" class="btn btn-sm btn btn-light btn-active-danger me-3" @click="deleteAllCampaignItems()">
                                            <span class="svg-icon svg-icon-2">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"/>
                                                </svg>
                                            </span>
                                            {{ $t('Remove All (!)') }}
                                        </button>
                                    </div>
                                </div>
                                <div class="dataTables_wrapper dt-bootstrap4 no-footer mt-3">
                                    <div class="table-responsive min-h-300px">
                                        <DxDataGrid id="gridContainer" :show-borders="true" key-expr="id" :data-source="campaignItemList" :allow-column-reordering="true"  :allow-column-resizing="true" :column-auto-width="true" :row-alternatin-enabled="true"
                                                    :hover-state-enabled="true" :show-row-lines="true" @exporting="this.$onExporting($event , 'Campaign-items')" @row-updating="onRowUpdating"> 
                                                <DxFilterRow :visible="true"/>
                                                <DxHeaderFilter :visible="true"/>
                                                <DxGroupPanel :visible="true"/>      
                                                <DxExport :enabled="this.$getUser().is_allow_export" :formats="['xlsx','pdf']" :allow-export-selected-data="false"/>
                                                <DxPaging :page-size="10"/>
                                                <DxPager :visible="false" :allowed-page-sizes="pageSizes" :show-page-size-selector="showPageSizeSelector" :show-info="true" :show-navigation-buttons="true"/>
                                                <DxSearchPanel :visible="true"/>
                                                <DxScrolling
                                                    mode="virtual"
                                                    row-rendering-mode="virtual"
                                                />

                                                <DxColumn data-field="isSelected" caption="#" :allow-filtering="false" alignment="center" cell-template="is-selected-cell-template" :allow-editing="false" :allow-exporting="false"/> 
                                                <DxColumn data-field="product_group_name" :caption="$t('Product Group')" header-cell-template="product-group-header" alignment="left" cell-template="product_group-cell-template" :set-cell-value="setProductType"/>
                                                <DxColumn data-field="product_type_name" :caption="$t('Product Type')" header-cell-template="product-type-header" alignment="left" cell-template="product_type-cell-template"/>
                                                <DxColumn data-field="product_detail_name" :caption="$t('Product Detail')" header-cell-template="product-detail-header" alignment="left" cell-template="product_detail-cell-template"/>
                                                <DxColumn data-field="price_type_name" :caption="$t('Price Type')" header-cell-template="price-type-header" alignment="left" cell-template="price_type-cell-template"/>
                                                <DxColumn data-field="discount" :sorting-method="this.$numericSort" :caption="$t('Discount %')" alignment="right" cell-template="discount-cell-template"/>

                                                <template #product-group-header>
                                                        <div>{{ $t('Product') }}</div><div>{{ $t('Group') }}</div>  
                                                </template>
                                                <template #product-type-header>
                                                        <div>{{$t('Product')}}</div><div>{{ $t('Type') }}</div>
                                                </template>
                                                <template #product-detail-header>
                                                        <div>{{$t('Product')}}</div><div>{{ $t('Detail') }}</div>
                                                </template>
                                                <template #price-type-header>
                                                        <div>{{ $t('Price') }}</div><div>{{ $t('Type') }}</div>
                                                </template>

                                                <template #is-selected-cell-template="{ data }"> 
                                                    <span>
                                                        <div class="form-check form-check-sm form-check-custom form-check-solid justify-content-center" v-if="data.data.id !== null && data.data.id !== ''">
                                                            <input class="form-check-input" type="checkbox" v-model="data.data.isSelected" @change="this.onSelectItem(data.data)" />
                                                        </div>
                                                    </span>
                                                </template>
                                                <template #product_group-cell-template="{ data }"> 
                                                    <span> 
                                                        {{ data.data.product_group_name }}
                                                    </span>
                                                </template>
                                                <template #product_type-cell-template="{ data }"> 
                                                    <span> 
                                                        {{ data.data.product_type_name }}
                                                    </span>
                                                </template>
                                                <template #product_detail-cell-template="{ data }"> 
                                                    <span> 
                                                        {{ data.data.product_detail_name }}
                                                    </span>
                                                </template>
                                                <template #price_type-cell-template="{ data }"> 
                                                    <span> 
                                                        {{ data.data.price_type_name }}
                                                    </span>
                                                </template>
                                                <template #discount-cell-template="{ data }"> 
                                                    <span> 
                                                        {{ data.data.discount }}
                                                    </span>
                                                </template>
                                        </DxDataGrid>
                                    </div>
                                </div>
                            </div>
                        </div>                    
                    </div>
                    <div class="col-lg-12">
                        <div class="card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                            <div class="card-body pb-0">
                                <form id="kt_ecommerce_settings_general_form"
                                    class="form fv-plugins-bootstrap5 fv-plugins-framework">                                            
                                    <div class="fv-row mb-7">
                                        <label class="fs-6 fw-bold form-label mt-3">
                                            <span>{{ $t('Description') }}</span>
                                        </label>
                                        <textarea class="form-control" name="description" v-model="form.description"></textarea>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="d-grid gap-2">
                            <button type="button" class="btn btn-success" @click="this.saveData()" data-kt-indicator="on" :disabled="campaignItemList.length == 0">
                                <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                </svg>
                                </span> 
                                <strong v-if="!this.isProgressing"> {{ $t('Save Sales Role / Campaign') }}</strong> 
                                    <span class="indicator-progress" v-if="this.isProgressing">
                                        {{ $t('Saving Data...') }} <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                            </button>
                        </div>                   
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="kt_modal_add_item" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content"  >
                    <div class="modal-header bg-primary" id="kt_modal_add_user_header">
                        <h2 class="modal-title fw-bolder col-md-11 text-white">
                            <span v-if="this.modalType == 'addItem'"> {{ $t('Add') }} </span>
                            <span v-if="this.modalType == 'editItem'"> {{ $t('Edit') }} </span> {{ $t('Item') }}                      
                        </h2>
                        <div class="btn btn-icon btn-sm btn-active-icon-primary">
                            <i class="bi bi-gear fs-2 mt-1 cursor-pointer text-white text-hover-dark"></i>  
                        </div>
                        <div class="btn btn-icon btn-sm btn-active-icon-primary"
                            data-bs-dismiss="modal" aria-label="Close" ref="closeModal">
                            <span class="svg-icon svg-icon-1 text-white text-hover-dark">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none">
                                    <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                        transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                    <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                        transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                                </svg>
                            </span>
                        </div>
                    </div>
                    <div class="modal-body pt-0" :class="{'overlay overlay-block': this.isItemSaving == true}">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="card card-flush">
                                    <div class="card-body pt-0 pb-0">
                                        <form class="form">
                                            <div class="card-body border-top">
                                                <div class="row mb-2">
                                                    <label class="col-lg-4 col-form-label fw-bold fs-6">
                                                        <span>{{ $t('Product Group') }}</span>
                                                    </label>
                                                    <div class="col-lg-8 fv-row">
                                                        <Select2 v-model="campaignForm.product_group_id" :options="productGroupList" @select="onChangeProductGroup(true)" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_item .modal-content'}"/> 
                                                    </div>
                                                </div>
                                                <div class="row mb-2">
                                                    <label class="col-lg-4 col-form-label fw-bold fs-6">
                                                        <span>{{ $t('Product Type') }}</span>
                                                    </label>
                                                    <div class="col-lg-8 fv-row">
                                                        <Select2 v-model="campaignForm.product_type_id" :options="productTypeList" @select="onChangeProductType(true)" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_item .modal-content'}"/> 
                                                    </div>
                                                </div>
                                                <div class="row mb-2">
                                                    <label class="col-lg-4 col-form-label fw-bold fs-6">
                                                        <span>{{ $t('Product Detail') }}</span>
                                                    </label>
                                                    <div class="col-lg-8 fv-row">
                                                        <Select2 v-model="campaignForm.product_detail_id" :options="productDetailList" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_item .modal-content'}"/> 
                                                    </div>
                                                </div>
                                                <div class="row mb-2">
                                                    <label class="col-lg-4 col-form-label fw-bold fs-6">
                                                        <span>{{ $t('Price Type') }}</span>
                                                    </label>
                                                    <div class="col-lg-8 fv-row">
                                                        <Select2 v-model="campaignForm.price_type_id" :options="priceTypeList" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_item .modal-content'}"/> 
                                                    </div>
                                                </div>
                                                <div class="row mb-2">
                                                    <label class="col-lg-4 col-form-label fw-bold fs-6">
                                                        <span>{{ $t('Discount') }} %</span>
                                                    </label>
                                                    <div class="col-lg-8 fv-row">
                                                        <CurrencyInput type="text" class="form-control text-end" name="discount" v-model="this.campaignForm.discount" :precision="0" @focus="$event.target.select()" :min="0" />
                                                    </div>
                                                </div> 
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="card card-flush mt-4" id="kt_contacts_list">
                                    <div class="d-grid gap-2"> 
                                        <button type="button" class="btn btn-success btn-lg" v-if="this.modalType == 'addItem'"
                                            @click="this.createCampaignItem()" :disabled="this.isModalButtonDisabled">
                                            <span class="indicator-label">{{ $t('Add Item') }}</span> 
                                        </button>
                                        <button type="button" class="btn btn-primary btn-lg" v-if="this.modalType == 'editItem'"
                                            @click="this.editCampaignItem()" :disabled="this.isModalButtonDisabled">
                                            <span class="indicator-label">{{ $t('Update') }}</span> 
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="overlay-layer bg-dark bg-opacity-5" v-if="this.isItemSaving == true">
                            <div class="spinner-border text-primary" role="status">
                                <span class="visually-hidden">{{ $t('Loading...') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script> 
import axios from 'axios' 
  
import Swal from "sweetalert2";
import moment from 'moment';
import { DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxScrolling  } from 'devextreme-vue/data-grid';
 
export default {
  name: "CampaignList",
  components:{
        DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxScrolling 
  },
  data() {
    return {
      campaignItemList: [],
      salesChannelList:[],
      productGroupList: [],
      productTypeList: [],
      productDetailList: [],
      priceTypeList: [],
      searchbar: '',
      isProgressing: false,
      form:{
        code: null,
        name: null,
        description: null,
        is_default: false,
        is_always: true,
        is_active: true,
        accounting_plan: null,
        start_date: moment(),
        end_date: moment().add(1, 'days'),
        payment_value: 0,
        sales_channel_id: '-1',
        payment_type_id: '-1',
        shipping_type_id: '-1'
      },
      campaignForm: {
        id: null,
        product_detail_id: '-1',
        product_type_id: '-1',
        product_group_id: '-1',
        discount: 0.00,
        tmp_session_id: null,
        price_type_id: '-1'
      },
      modalType: 'addItem',
      isItemSaving: false,
      isModalButtonDisabled: false,
      menuSelection: '/SalesRoleHelppage',
      iconView : false,
      pageSize: 10
    };
  }, 
  computed: {
   isItemButtonDisabled() {
       return this.campaignItemList.find(item =>item.isSelected == true );
    },
    showPageSizeSelector() {
        return this.campaignItemList.length > this.pageSize;
    },
    pageSizes() {
        let arr = [];
        let x = 1;
        let loop = (this.campaignItemList.length / this.pageSize);
        for (let i = 1; i <= loop && i <= 5; i++) {
            arr.push(this.pageSize * x);
            x++;
        }
        if(loop > 5) arr.push(100);
        if(loop > 1) arr.push('all');
        return arr;
    }
     
  },
  methods: {
        getData() { 
            this.$removeSessionStorage('tmp_session_id');
            this.$setSessionStorage('refreshList', true);
            const parameters = { 
                session_id: this.$getUser().session_id,
                list_name: ["sales_channel", "product_group", "product_type"],
                account_type: 6 // yalnızca account settings listesi çekilecek ise gerekli
            }; 
            axios.post('/api/Helper/GeneralLists', parameters, {'Content-Type': 'application/json'}).then((response) => {
                this.salesChannelList = response.data.sales_channel;
                this.productGroupList = response.data.product_group;
                this.productGroupList.unshift({ id: '-1', text: 'ALL'});
                this.salesChannelList.unshift({ id: '-1', text: 'Select an option'});
                this.productGroupList.forEach((value) => {
                        if(value.is_default) {
                            this.campaignForm.product_group_id = value.id;
                        }
                    });

                const params = {
                    session_id: this.$getUser().session_id,
                    list_types: [
                        {
                            list_type: 7,
                            list_name: "product_group"
                        },
                        {
                            list_type: 11,
                            list_name: "payment_type"
                        },
                        {
                            list_type: 12,
                            list_name: "shipping"
                        },
                        {
                            list_type: 13,
                            list_name: "price_type"
                        }
                    ],
                };
                axios.post("/api/Helper/GetMultiLists", params, {'Content-Type': 'application/json'}).then((listResponse) => {
                    this.priceTypeList = listResponse.data.price_type;  
                    this.paymentTypeList = listResponse.data.payment_type;
                    this.shippingTypeList = listResponse.data.shipping;
                    this.priceTypeList.unshift({ id: '-1', text: 'ALL'});
                    this.paymentTypeList.unshift({ id: '-1', text: 'Select an option'});
                    this.shippingTypeList.unshift({ id: '-1', text: 'Select an option'});
                    this.priceTypeList.forEach((value) => {
                        if(value.is_default) {
                            this.campaignForm.price_type_id = value.id;
                        }
                    });
                    this.paymentTypeList.forEach((value) => {
                        if(value.is_default) {
                            this.form.payment_type_id = value.id;
                        }
                    });
                    this.shippingTypeList.forEach((value) => {
                        if(value.is_default) {
                            this.form.shipping_type_id = value.id;
                        }
                    });
                    this.onChangeProductGroup(false);
                    this.onChangeProductType(false);
                    this.$Progress.finish();
                })
                .catch(function (error) {
                    console.log(error); 
                    this.$Progress.finish();
                });
            })
            .catch(function (error) {
                console.log(error); 
                this.$Progress.finish();
            });           
        },   
        onChangeProductGroup(isSetDefault) {
            const params = {
                session_id: this.$getUser().session_id,
                parent_id: this.campaignForm.product_group_id
            };
            axios.post("/api/Helper/GetProductTypeParentList", params, {'Content-Type': 'application/json'}).then((response) => { 
                this.productTypeList = response.data;
                this.productTypeList.unshift({ id: '-1', text: 'ALL'}); 
                this.campaignForm.product_type_id = '-1';
                if(isSetDefault) {
                    this.productTypeList.forEach((value) => {
                        if(value.is_default) {
                            this.campaignForm.product_type_id = value.id;
                        }
                    });
                }
            })
            .catch(function (error) {
                console.log(error); 
            });  
        },
        onChangeProductType(isSetDefault) {
            const params = {
                session_id: this.$getUser().session_id,
                parent_id: this.campaignForm.product_type_id
            };
            axios.post("/api/Helper/GetProductDetailParentList", params, {'Content-Type': 'application/json'}).then((response) => { 
                this.productDetailList = response.data;
                this.productDetailList.unshift({ id: '-1', text: 'ALL'}); 
                this.campaignForm.product_detail_id = '-1';
                if(isSetDefault) {
                    this.productDetailList.forEach((value) => {
                        if(value.is_default) {
                            this.campaignForm.product_detail_id = value.id;
                        }
                    });
                }
            })
            .catch(function (error) {
                console.log(error); 
            });  
        },
        openItemModal(value) {
            try {
                this.$refs.btnAddItem.blur();
                this.$refs.btnEditItem.blur();
            } catch(Err){ console.log(Err);}
            
            this.modalType = value;
            this.isItemSaving = false;
            this.isModalButtonDisabled = false;
            this.productTypeList = [{ id: '-1', text: 'ALL'}];
            this.productDetailList = [{ id: '-1', text: 'ALL'}];
            this.campaignForm.product_group_id = '-1';
            this.campaignForm.product_type_id = '-1';
            this.campaignForm.product_detail_id = '-1';
            this.campaignForm.price_type_id = '-1';
            this.campaignForm.discount = 0.00;

            if (this.modalType == 'editItem') {
                this.campaignItemList.forEach((item) => {
                    if (item.isSelected) {
                        this.campaignForm.id = item.id;
                        this.campaignForm.tmp_session_id = item.tmp_session_id;
                        this.campaignForm.product_group_id = (item.product_group_id == null || item.product_group_id == '') ? '-1' : item.product_group_id;
                        this.campaignForm.product_type_id = (item.product_type_id == null || item.product_type_id == '') ? '-1' : item.product_type_id;
                        this.campaignForm.product_detail_id = (item.product_detail_id == null || item.product_detail_id == '') ? '-1' : item.product_detail_id;
                        this.campaignForm.price_type_id = (item.price_type_id == null || item.price_type_id == '') ? '-1' : item.price_type_id;
                        this.campaignForm.discount = item.discount.replace(/,/g, '');
                        this.onChangeProductGroup(false);
                        this.onChangeProductType(false);
                    }
                }); 
            } else {
                this.salesChannelList.forEach((value) => {
                    if(value.is_default){
                        this.campaignForm.sales_channel_id = value.id;
                    }
                });  
            }
        },
        onSelectItem(item) {
            this.campaignItemList.forEach((value) => { 
                if (item.id !== value.id) {
                    value.isSelected = false;
                }
            });  
        },
        saveData(){
            this.isProgressing = true;
            const params = this.form; 
            const that = this;
            params.session_id = this.$getUser().session_id; 
            params.branch_id = params.branch_id == '-1' ? null: params.branch_id;
            params.is_default = params.is_default == true ? 1: 0;
            params.is_always = params.is_always == true ? 1: 0;
            params.is_active = params.is_active == true ? 1: 0;  
            if(params.is_always == 1){
                params.start_date = null;
                params.end_date = null;
            }
            else{
                params.start_date = moment(params.start_date).format('YYYY-MM-DD[T]HH:mm:ss');
                params.end_date = moment(params.end_date).format('YYYY-MM-DD[T]HH:mm:ss');  
            }
            params.tmp_session_id = this.$getSessionStorage('tmp_session_id'); 
            params.sales_channel_id = params.sales_channel_id == '-1'?  null : params.sales_channel_id;
            params.payment_type_id = params.payment_type_id == '-1'?  null : params.payment_type_id;
            params.shipping_type_id = params.shipping_type_id == '-1'?  null : params.shipping_type_id;
            axios.post('/api/Campaign/CreateCampaign', params, {'Content-Type': 'application/json'})
            .then((response) => { 
                if(response.data != null && response.data.length == 36){  
                    this.$setSessionStorage('record_id', response.data);
                    this.$setSessionStorage('refreshList', true);
                    this.$swal("Success", "Data saved succesfully..!", 'success');
                    this.$router.push({ name: 'CampaignUpdate' });
                } 
            })
            .catch(function () { 
                new Swal( { icon: 'error', title: 'Error...', text: 'Data could not be saved..Please refresh the page and try again..!' });
            })
            .finally(function () { 
                setTimeout(() => {
                    that.isProgressing = false;
                }, 500);
            });
        },
        createCampaignItem(){ 
            const params = this.campaignForm; 
            this.isModalButtonDisabled = true;
            this.isItemSaving = true;
            const that = this;
            params.session_id = this.$getUser().session_id; 
            params.tmp_session_id = this.$getSessionStorage('tmp_session_id'); 
            params.product_group_id = params.product_group_id == '-1'?  null : params.product_group_id;
            params.product_type_id = params.product_type_id == '-1'?  null : params.product_type_id;
            params.product_detail_id = params.product_detail_id == '-1'?  null : params.product_detail_id;
            params.price_type_id = params.price_type_id == '-1'?  null : params.price_type_id;
            
            axios.post('/api/Campaign/AddCampaignItem', params, {'Content-Type': 'application/json'})
            .then((response) => { 
                if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){  
                    this.campaignItemList = response.data;
                    this.$setSessionStorage('tmp_session_id', response.data[0].tmp_session_id);
                    this.$refs.closeModal.click();
                } else {
                    this.campaignForm.product_group_id = this.campaignForm.product_group_id == null ? '-1' : this.campaignForm.product_group_id;
                    this.campaignForm.product_type_id = this.campaignForm.product_type_id == null ? '-1' : this.campaignForm.product_type_id;
                    this.campaignForm.product_detail_id = this.campaignForm.product_detail_id == null ? '-1' : this.campaignForm.product_detail_id;
                    this.campaignForm.price_type_id = this.campaignForm.price_type_id == null ? '-1' : this.campaignForm.price_type_id;
                }
                this.isItemSaving = false;
            })
            .catch(function () { 
                this.isItemSaving = false;
                new Swal( { icon: 'error', title: 'Error...', text: 'Data could not be saved..Please refresh the page and try again..!' });
            })
            .finally(function () { 
                setTimeout(() => {
                    that.isItemSaving = false;
                    that.isModalButtonDisabled = false;
                }, 500);
            });
        },
        editCampaignItem(){  
            const params = this.campaignForm; 
            this.isModalButtonDisabled = true;
            this.isItemSaving = true;
            const that = this;
            params.session_id = this.$getUser().session_id; 
            params.session_id = this.$getUser().session_id; 
            params.tmp_session_id = this.$getSessionStorage('tmp_session_id'); 
            params.product_group_id = params.product_group_id == '-1'?  null : params.product_group_id;
            params.product_type_id = params.product_type_id == '-1'?  null : params.product_type_id;
            params.product_detail_id = params.product_detail_id == '-1'?  null : params.product_detail_id;
            params.price_type_id = params.price_type_id == '-1'?  null : params.price_type_id;
    
            axios.post('/api/Campaign/UpdateCampaignItem', params, {'Content-Type': 'application/json'})
            .then((response) => { 
                if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                    this.campaignItemList = response.data;
                    this.$setSessionStorage('tmp_session_id', response.data[0].tmp_session_id);
                    this.$refs.closeModal.click();
                } else {
                    this.campaignForm.product_group_id = this.campaignForm.product_group_id == null ? '-1' : this.campaignForm.product_group_id;
                    this.campaignForm.product_type_id = this.campaignForm.product_type_id == null ? '-1' : this.campaignForm.product_type_id;
                    this.campaignForm.product_detail_id = this.campaignForm.product_detail_id == null ? '-1' : this.campaignForm.product_detail_id;
                    this.campaignForm.price_type_id = this.campaignForm.price_type_id == null ? '-1' : this.campaignForm.price_type_id;
                }
                this.isItemSaving = false;
            })
            .catch(function () { 
                this.isItemSaving = false;
                new Swal( { icon: 'error', title: 'Error...', text: 'Data could not be saved..Please refresh the page and try again..!' });
            })
            .finally(function () { 
                setTimeout(() => {
                    that.isItemSaving = false;
                    that.isModalButtonDisabled = false;
                }, 500);
            });
        },
        deleteCampaignItem(){ 
            this.$swal.fire({
                title: "Are you sure you want to delete this data?",
                confirmButtonColor: "#E53935",
                showDenyButton: false,
                showCancelButton: true,
                confirmButtonText: "Yes",
                icon: 'warning'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.campaignItemList.forEach((item) => {
                    if(item.isSelected) {
                        const params = {
                            id: item.id,
                            session_id: this.$getUser().session_id,
                            tmp_session_id: this.$getSessionStorage('tmp_session_id')
                        };
                        axios.post('/api/Campaign/DeleteCampaignItem', params, {'Content-Type': 'application/json'})
                        .then((response) => { 
                            if(response.data != null){  
                                this.campaignItemList = response.data;
                                this.$setSessionStorage('tmp_session_id', response.data[0].tmp_session_id);
                                this.$swal("Deleted", "Selected item deleted..!", 'warning');    
                                this.$refs.closeModal.click(); 
                            } 
                        })
                        .catch(function () { 
                            
                        }); 
                    }
                  });
                }
            }); 
        },
        deleteAllCampaignItems(){
            this.$swal.fire({
                title: "Are you sure you want to delete all items?",
                confirmButtonColor: "#E53935",
                showDenyButton: false,
                showCancelButton: true,
                confirmButtonText: "Yes",
                icon: 'warning'
            }).then((result) => {
                if (result.isConfirmed) { 
                    const params = {
                        session_id: this.$getUser().session_id,
                        tmp_session_id: this.$getSessionStorage('tmp_session_id')
                    };
                    axios.post('/api/Campaign/DeleteAllCampaignItems', params, {'Content-Type': 'application/json'})
                    .then((response) => { 
                        if(response.data != null){  
                            this.campaignItemList = [];
                            this.$swal("Deleted", "All items deleted..!", 'warning');    
                            this.$refs.closeModal.click(); 
                        } 
                    })
                    .catch(function () { 
                        
                    });  
                } 
            }); 
        },
        onChangePaymentValue() {
            if (parseInt(this.form.payment_value) > 0) {
                this.form.end_date = moment(this.form.start_date, "YYYY-MM-DD").add(parseInt(this.form.payment_value), 'days');
            }
        },
        async helpPageViewIcon(){
            const queryparameter = {
                session_id : this.$getUser().session_id,
                menu_selection : '/SalesRoleHelppage'
            };
            axios.post('api/HelpPage/HelpPagesContent', queryparameter, {'Content-Type': 'application/json'})
            .then((response) => { 
                if(!response.data.error){ 
                    this.iconView = response.data;
                }
            })
        },
    }, 
    created() {
        this.helpPageViewIcon();
        this.$setSessionStorage('refreshList', true);
        this.getData(); 
    }
}; 
</script>