<template> 
    <div> 
        <div class="d-flex flex-stack flex-wrap">
            <div class="page-title d-flex flex-column me-3 mt-3"> 
                <h1 class="d-flex fw-bolder my-1 fs-3 text-gray-800">{{ $t('Update Supplier Account') }}</h1>  
                <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
                    <li class="breadcrumb-item text-gray-600">
                        <a href="#" class="text-gray-600 text-hover-primary">
                            <router-link :to="{ name: 'Home' }">{{ $t('Home') }}</router-link>
                        </a>
                    </li> 
                    <li class="breadcrumb-item text-primary">{{ $t('Accounts') }}</li>     
                    <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'SupplierList' }">{{$t('Supplier')}}</router-link></li>     
                    <li class="breadcrumb-item text-gray-600">{{ $t('Update Supplier Account') }}
                    <HelpPageIcon v-if="this.iconView == true" :menuSelection="this.menuSelection"></HelpPageIcon>
                    </li> 
                </ul> 
            </div>  
            <div class="d-flex align-items-center py-1">
                <router-link to="/SupplierList">
                    <button type="button" class="btn btn-light shadow-sm btn-arrow-left border border-gray-400 me-3">
                    <span class="ms-2 fs-7"> {{ $t('Return back to List') }}</span>
                    </button>
                </router-link> 
                &nbsp;
                <div class="dropdown" v-if="this.$isCredentialActive(11,'D')">
                    <button class="btn btn-light-info border border-info dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="true" id="supplierActions" type="button">
                    <span class="svg-icon svg-icon-muted"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path opacity="0.3" d="M22.1 11.5V12.6C22.1 13.2 21.7 13.6 21.2 13.7L19.9 13.9C19.7 14.7 19.4 15.5 18.9 16.2L19.7 17.2999C20 17.6999 20 18.3999 19.6 18.7999L18.8 19.6C18.4 20 17.8 20 17.3 19.7L16.2 18.9C15.5 19.3 14.7 19.7 13.9 19.9L13.7 21.2C13.6 21.7 13.1 22.1 12.6 22.1H11.5C10.9 22.1 10.5 21.7 10.4 21.2L10.2 19.9C9.4 19.7 8.6 19.4 7.9 18.9L6.8 19.7C6.4 20 5.7 20 5.3 19.6L4.5 18.7999C4.1 18.3999 4.1 17.7999 4.4 17.2999L5.2 16.2C4.8 15.5 4.4 14.7 4.2 13.9L2.9 13.7C2.4 13.6 2 13.1 2 12.6V11.5C2 10.9 2.4 10.5 2.9 10.4L4.2 10.2C4.4 9.39995 4.7 8.60002 5.2 7.90002L4.4 6.79993C4.1 6.39993 4.1 5.69993 4.5 5.29993L5.3 4.5C5.7 4.1 6.3 4.10002 6.8 4.40002L7.9 5.19995C8.6 4.79995 9.4 4.39995 10.2 4.19995L10.4 2.90002C10.5 2.40002 11 2 11.5 2H12.6C13.2 2 13.6 2.40002 13.7 2.90002L13.9 4.19995C14.7 4.39995 15.5 4.69995 16.2 5.19995L17.3 4.40002C17.7 4.10002 18.4 4.1 18.8 4.5L19.6 5.29993C20 5.69993 20 6.29993 19.7 6.79993L18.9 7.90002C19.3 8.60002 19.7 9.39995 19.9 10.2L21.2 10.4C21.7 10.5 22.1 11 22.1 11.5ZM12.1 8.59998C10.2 8.59998 8.6 10.2 8.6 12.1C8.6 14 10.2 15.6 12.1 15.6C14 15.6 15.6 14 15.6 12.1C15.6 10.2 14 8.59998 12.1 8.59998Z" fill="currentColor"/>
                    <path d="M17.1 12.1C17.1 14.9 14.9 17.1 12.1 17.1C9.30001 17.1 7.10001 14.9 7.10001 12.1C7.10001 9.29998 9.30001 7.09998 12.1 7.09998C14.9 7.09998 17.1 9.29998 17.1 12.1ZM12.1 10.1C11 10.1 10.1 11 10.1 12.1C10.1 13.2 11 14.1 12.1 14.1C13.2 14.1 14.1 13.2 14.1 12.1C14.1 11 13.2 10.1 12.1 10.1Z" fill="currentColor"/>
                    </svg></span>
                        {{ $t('Actions') }}
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="supplierActions"> 
                        <li>
                            <a class="dropdown-item fs-4 text-danger" href="#" @click="deleteItem()"><span class="svg-icon svg-icon-muted svg-icon-2 text-danger"><svg width="24" height="24" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="currentColor"/>
                            <path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="currentColor"/>
                            <path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="currentColor"/>
                            </svg>
                            </span>{{ $t('Delete') }} </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="content flex-row-fluid mt-4" id="kt_content">
            <StatsBar :form-account="this.form"></StatsBar>
            <div class="row">
                <div class="card-body">
                    <ul class="nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-6">
                        <li class="nav-item">
                            <a class="nav-link" :class="{'active': this.activeTab == 'Account'}" data-bs-toggle="tab" href="#kt_accounting_1" target="_self"
                            @click="this.activeTab = 'Account'">
                                <h4 v-if="form.supplier_account_type == 0"> {{ $t('Supplier') }} </h4>
                                <h4 v-if="form.supplier_account_type == 1"> {{ $t('Insurance Producer') }} </h4>
                                <h4 v-if="form.supplier_account_type == 2"> {{ $t('Insurance Vendor') }} </h4>
                                <h4 v-if="form.supplier_account_type == 3"> {{ $t('Sales Vendor') }} </h4>
                            </a>
                        </li>
                        <li class="nav-item" v-if="form.supplier_account_type == 1">
                            <a class="nav-link" :class="{'active': this.activeTab == 'ProducerSettings'}" data-bs-toggle="tab" href="#kt_accounting_2" target="_self"
                            @click="this.activeTab = 'ProducerSettings', getProducerSettings()">
                                <h4> {{ $t('Insurance Producer Setting') }} </h4>
                            </a>
                        </li>
                        <li class="nav-item" v-if="form.supplier_account_type == 2">
                            <a class="nav-link" :class="{'active': this.activeTab == 'InsuranceVendorSettings'}" data-bs-toggle="tab" href="#kt_accounting_3" target="_self"
                            @click="this.activeTab = 'InsuranceVendorSettings', getVendorSettings()">
                                <h4> {{ $t('Insurance Vendor Setting') }} </h4>
                            </a>
                        </li>
                        <li class="nav-item" v-if="form.supplier_account_type == 3">
                            <a class="nav-link" :class="{'active': this.activeTab == 'SalesVendorSettings'}" data-bs-toggle="tab" href="#kt_accounting_4" target="_self"
                            @click="this.activeTab = 'SalesVendorSettings', getVendorSettings()">
                                <h4> {{ $t('Sales Vendor Setting') }} </h4>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" :class="{'active': this.activeTab == 'AccountGroup'}" data-bs-toggle="tab" href="#kt_accounting_5" target="_self"
                            @click="this.activeTab = 'AccountGroup'">
                                <h4> {{ $t('Account Group') }} </h4>
                            </a>
                        </li>
                        <li class="nav-item" v-if="this.$isModuleActive(3)">
                            <a class="nav-link" :class="{'active': this.activeTab == 'Documents'}" data-bs-toggle="tab" href="#kt_accounting_6" target="_self"
                            @click="activateDocumentsSection()">
                                <h4> {{ $t('Documents') }} </h4>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" :class="{'active': this.activeTab == 'Invoices'}" data-bs-toggle="tab" href="#kt_accounting_7" target="_self"
                            @click="this.activeTab = 'Invoices', onListInvoices()">
                                <h4> {{ $t('Invoices') }} </h4>
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="tab-content" id="myTabContent">
                    <RequiredField></RequiredField>
                    <div class="card card-xxl-stretch"> 
                        <form id="poliform" @submit.prevent="" class="form fv-plugins-bootstrap5 fv-plugins-framework">
                            <div class="d-flex flex-row row p-10 pb-0 tab-pane fade show active" id="kt_accounting_1" role="tabpanel" v-if="this.activeTab == 'Account'">
                                <div class="d-flex flex-column flex-row-auto col-lg-6">
                                    <div class="d-flex flex-column-fluid card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                        <div class="card-body">
                                            <form id="kt_ecommerce_settings_general_form" @submit.prevent=""
                                                class="form fv-plugins-bootstrap5 fv-plugins-framework">                                
                                                <div class="fv-row mb-7 row">
                                                    <div class="col-lg-12" v-if="isViewAccountId">
                                                        <label class="fs-6 fw-bold form-label mt-3" :class="{'required': isViewAccountId}">
                                                            {{ $t('Account ID') }}
                                                        </label>
                                                        <input type="text" class="form-control" name="accountID" v-model="form.account_id">
                                                    </div>
                                                    <div class="col-lg-12">
                                                        <label class="fs-6 fw-bold form-label mt-3" v-tag="'name'"> 
                                                            {{ $t('Name / Company Name') }}
                                                        </label>
                                                        <div class="input-group">
                                                            <input type="text" class="form-control" name="name" v-custom v-model="form.name">
                                                            <button v-if="userData.is_company_inquiry_active == 1 && this.$isCredentialActive(11,'CI')" type="button" 
                                                                    class="input-group-text m-0 text-hover-success cursor-pointer" 
                                                                    id="basic-addon2" 
                                                                    @click="onSearchCompanyByName()" 
                                                                    data-bs-toggle="modal" 
                                                                    data-bs-target="#kt_modal_search_company" 
                                                                    data-kt-menu-trigger="click" 
                                                                    data-kt-menu-placement="bottom-end"
                                                                    :disabled="form.name == null || form.name == ''">
                                                                <i class="fas fa-search fs-4 me-3"></i> {{ $t('Search by Name') }}
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12">
                                                        <div class="fv-row">
                                                            <label class="fs-6 fw-bold form-label mt-3" v-tag="'member_type'">
                                                                <span>{{ $t('Member Type') }}</span>
                                                            </label> 
                                                            <Select2 v-model="form.company_account_type" :options="companyAccountTypeFilteredList" name="member_type" v-custom :settings="{ width: '100%'}"/>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12" v-if="form.company_account_type == 1">
                                                        <label class="fs-6 fw-bold form-label mt-3" v-tag="'registration_number'">
                                                            {{ $t('Registration Number') }}
                                                        </label>
                                                        <div class="input-group">
                                                            <input type="text" class="form-control" name="registration_number" v-custom v-model="form.account_number">
                                                            <button v-if="userData.is_company_inquiry_active == 1 && this.$isCredentialActive(11,'CI')" type="button" class="input-group-text m-0 cursor-pointer text-hover-primary" id="basic-addon2" @click="onSearchCompanyByNo()"
                                                                    :disabled="form.account_number == null || form.account_number == ''">
                                                                <i class="fas fa-search fs-4 me-3"></i> {{ $t('Search by No') }}
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12" v-if="form.company_account_type == 2">
                                                        <label class="fs-6 fw-bold form-label mt-3" v-tag="'personal_id'">
                                                            {{ $t('Personal ID') }}
                                                        </label>
                                                        <input type="text" class="form-control" v-model="form.personal_id" name="personal_id" v-custom>
                                                    </div>
                                                    <div class="col-lg-12">
                                                        <label class="fs-6 fw-bold form-label mt-3" v-tag="'contact_name'">
                                                            {{ $t('Contact Name') }}
                                                        </label>
                                                        <input type="text" class="form-control" name="contact_name" v-model="form.contact_name" v-custom>
                                                    </div>
                                                    <div class="col-lg-12">
                                                        <label class="fs-6 fw-bold form-label mt-3" v-tag="'supplier_account_type'">
                                                            {{ $t('Account Type') }}
                                                        </label>
                                                        <Select2 v-model="form.supplier_account_type" :options="supplierAccountTypeList" name="supplier_account_type" v-custom :disabled="!this.$isAdmin()" :settings="{ width: '100%'}"/> 
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex flex-column flex-row-auto col-lg-6">
                                    <div class="d-flex flex-column-fluid card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                        <div class="card-body">
                                            <form id="kt_ecommerce_settings_general_form" @submit.prevent=""
                                                class="form fv-plugins-bootstrap5 fv-plugins-framework">                                
                                                <div class="fv-row mb-7 row">
                                                    <div class="col-lg-12">
                                                        <div class="fv-row fv-plugins-icon-container">
                                                            <label class="fs-6 fw-bold form-label mt-3" v-tag="'email'">
                                                                {{ $t('Email') }}
                                                            </label>
                                                            <input type="email" class="form-control" name="email" v-model="form.email" :class="{'border border-danger': isValidEmail == false}"  v-custom>
                                                            <small class="text-danger" v-if="!isValidEmail && form.email !== null && form.email !== '' ">{{ $t('Email is not valid') }}</small>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12">
                                                        <div class="fv-row">
                                                            <label class="fs-6 fw-bold form-label mt-3" v-tag="'mobile_phone'">
                                                                {{ $t('Mobile Phone') }}
                                                            </label>
                                                            <div class="input-group">
                                                                <Select2 v-model="form.phone_country_code" :options="phoneMaskList" :settings="{width: '120px'}" />
                                                                <input type="text" class="form-control" name="mobile_phone" v-model="form.mobile_phone"  v-custom>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12">
                                                        <div class="fv-row">
                                                            <label class="fs-6 fw-bold form-label mt-3" v-tag="'phone'">
                                                                <span>{{ $t('Phone') }}</span>
                                                            </label>
                                                            <div class="input-group">
                                                                <Select2 v-model="form.land_phone_country_code" :options="phoneMaskList" :settings="{width: '120px'}"/>
                                                                <input type="text" class="form-control" name="phone" v-model="form.phone" v-custom>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12">
                                                        <div class="fv-row">
                                                            <label class="fs-6 fw-bold form-label mt-3" v-tag="'supplier_type'">
                                                                <span>{{ $t('Supplier Type') }}</span>
                                                            </label> 
                                                            <Select2 v-model="form.customer_type_id" :options="customerTypeList" name="supplier_type" v-custom :settings="{ width: '100%'}"/>  
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <div class="fv-row">
                                                            <label class="fs-6 fw-bold form-label mt-3" v-tag="'sales_channel'">
                                                                <span>{{ $t('Sales Channel') }}</span>
                                                            </label>
                                                            <Select2 v-model="form.sales_channel_id" :options="salesChannelList" name="sales_channel" v-custom :settings="{ width: '100%'}"/> 
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <div class="fv-row">
                                                            <label class="fs-6 fw-bold form-label mt-3" v-tag="'payment_value'">
                                                                <span>{{ $t('Payment Value') }}</span>
                                                                <small class="fs-8"> ({{ $t('Day') }}) </small>
                                                            </label>
                                                            <input type="text" class="form-control" name="payment_value" v-model="form.payment_value" v-custom/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                        <div class="card-body pb-0">
                                            <form id="kt_ecommerce_settings_general_form" @submit.prevent=""
                                                class="form fv-plugins-bootstrap5 fv-plugins-framework">                                
                                                <div class="fv-row mb-6 row">
                                                    <GetAddress @address-selected="onSelectAddress" :account-type="2" v-if="userData.is_address_inquiry_active == 1 && userData.address_inquiry_sub_type == 2 && this.$isCredentialActive(11,'AI')"/>
                                                    <div class="col-lg-6">
                                                        <div class="fv-row">
                                                            <label class="fs-6 fw-bold form-label mt-3" v-tag="'post_code'">{{ $t('Post Code') }}                                                                                                             
                                                            </label>
                                                            <div class="input-group">
                                                                <input type="text" class="form-control" name="post_code" v-model="form.postcode" v-custom>
                                                                <button v-if="userData.is_address_inquiry_active == 1 && userData.address_inquiry_sub_type == 1  && this.$isCredentialActive(11,'AI')" type="button" class="input-group-text m-0 cursor-pointer text-hover-info" 
                                                                        id="basic-addon2" 
                                                                        @click="this.onFindAddressByPostCode()" 
                                                                        data-bs-toggle="modal" 
                                                                        data-bs-target="#kt_modal_search_address" 
                                                                        data-kt-menu-trigger="click" 
                                                                        data-kt-menu-placement="bottom-end"
                                                                        :disabled="this.form.postcode == null || this.form.postcode == ''">
                                                                    <i class="fas fa-search fs-4 me-3"></i> {{ $t('Search by Post Code') }}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <div class="fv-row">
                                                            <label class="fs-6 fw-bold form-label mt-3" v-tag="'address_code'">{{ $t('Address Code') }}
                                                            </label>
                                                            <div class="input-group">
                                                                <input type="text" class="form-control" name="address_code" v-model="form.uprn" v-custom>
                                                                <button v-if="userData.is_address_inquiry_active == 1 && userData.address_inquiry_sub_type == 1  && this.$isCredentialActive(11,'AI')" type="button" class="input-group-text m-0 cursor-pointer text-hover-info" 
                                                                        id="basic-addon2" 
                                                                        @click="this.onFindAddressByAddressCode()" 
                                                                        data-bs-toggle="modal" 
                                                                        data-bs-target="#kt_modal_search_address_by_code" 
                                                                        data-kt-menu-trigger="click" 
                                                                        data-kt-menu-placement="bottom-end"
                                                                        :disabled="this.form.uprn == null || this.form.uprn == ''">
                                                                    <i class="fas fa-search fs-4 me-3"></i> {{ $t('Search by Address Code') }}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12">
                                                        <div class="fv-row">
                                                            <label class="fs-6 fw-bold form-label mt-3" v-tag="'address'">{{ $t('Address') }}
                                                            </label>
                                                            <textarea class="form-control" name="address" v-model="form.address" v-custom></textarea>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-4">
                                                        <div class="fv-row">
                                                            <label class="fs-6 fw-bold form-label mt-3" v-tag="'city'">{{ $t('City') }}
                                                            </label>
                                                            <input type="text" class="form-control" name="city" v-model="form.city" v-custom>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-4">
                                                        <div class="fv-row">
                                                            <label class="fs-6 fw-bold form-label mt-3" v-tag="'district'">{{ $t('District') }}                                                    
                                                            </label>
                                                            <input type="text" class="form-control" name="city" v-model="form.district" v-custom>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-4">
                                                        <div class="fv-row">
                                                            <label class="fs-6 fw-bold form-label mt-3" v-tag="'country'">
                                                                {{ $t('Country') }}
                                                            </label>
                                                            <Select2 v-model="form.country" :options="countryList" name="country" v-custom :settings="{ width: '100%'}"/>
                                                        </div>
                                                    </div> 
                                                </div>
                                            </form>
                                        </div>
                                    </div>                    
                                </div>
                                <div class="col-lg-12">
                                    <div class="card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                        <div class="card-body pb-0">
                                            <form id="kt_ecommerce_settings_general_form" @submit.prevent=""
                                                class="form fv-plugins-bootstrap5 fv-plugins-framework">                                
                                                <div class="fv-row row">
                                                    <div class="col-lg-6">
                                                        <label class="fs-6 fw-bold form-label mt-3" v-tag="'is_vat_account'">{{ $t('VAT Account?') }}</label> 
                                                        <br>
                                                        <div class="form-check form-check-inline mt-5">
                                                            <input class="form-check-input" type="radio" name="is_vat_account" id="inlineRadio1" value="false" v-model="form.isVATNumberExist" v-custom>
                                                            <label class="form-check-label" for="inlineRadio1">{{ $t('No') }}</label>
                                                        </div>
                                                        <div class="form-check form-check-inline">
                                                            <input class="form-check-input" type="radio" name="is_vat_account" id="inlineRadio2" value="true" v-model="form.isVATNumberExist" v-custom>
                                                            <label class="form-check-label" for="inlineRadio2">{{ $t('Yes') }}</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <div class="fv-row mb-7">
                                                            <label class="fs-6 fw-bold form-label mt-3" :class="{'required' : this.form.isVATNumberExist == 'true'}" v-tag="'vat_number'">
                                                                {{ $t('VAT Number') }}
                                                            </label>
                                                            <input type="text" class="form-control" name="vat_number" v-model="form.vat_number" :disabled="form.isVATNumberExist == 'false'" v-custom>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>                    
                                </div>
                                <div class="col-lg-12">
                                    <div class="card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                        <div class="card-body pb-0">
                                            <div class="fv-row mb-7">
                                                <label class="fs-6 fw-bold form-label mt-3" v-tag="'notes'">
                                                    <span>{{ $t('Notes') }}</span>
                                                </label>
                                                <textarea class="form-control" name="notes" v-model="form.notes" v-custom></textarea>
                                            </div>
                                        </div>
                                    </div>                    
                                </div>
                            </div>
                            
                            <div class="d-flex flex-row row p-10 tab-pane fade show active" id="kt_accounting_2" role="tabpanel" v-if="this.activeTab == 'ProducerSettings'">
                                <ul class="nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-6">
                                    <li class="nav-item">
                                        <a class="nav-link" :class="{'active': this.activeProducerTab == 'GeneralSettings'}" data-bs-toggle="tab" href="#kt_general_settings" target="_self"
                                        @click="this.activeProducerTab = 'GeneralSettings', getProducerSettings()">
                                            <h4> {{ $t('General Settings') }} </h4>
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" :class="{'active': this.activeProducerTab == 'DataImportSettings'}" data-bs-toggle="tab" href="#kt_vehicle_settings" target="_self"
                                        @click="this.activeProducerTab = 'DataImportSettings', getDataImportParameters()">
                                            <h4> {{ $t('Data Import Settings') }} </h4>
                                        </a>
                                    </li>
                                </ul>

                                <div class="d-flex flex-row row tab-pane fade show active" id="kt_general_settings" role="tabpanel" v-if="this.activeProducerTab == 'GeneralSettings'">
                                    <div class="col-lg-12">
                                        <div class="card card-xxl-stretch border border-gray-300">
                                            <div class="card-header">
                                                <div class="card-title">
                                                    {{ $t('Producer Policy Types') }}
                                                </div>
                                            </div>
                                            <div class="card-body">
                                                <div class="d-flex flex-stack flex-wrap mb-5">
                                                    <div class="d-flex justify-content-end" data-kt-docs-table-toolbar="base">
                                                        <button type="button" class="btn btn-sm btn-light btn-active-success me-3" data-bs-toggle="modal" ref="btnAddItem"
                                                            data-bs-target="#kt_modal_add_producer" @click="openProducerModal('add')"
                                                            data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                                                            <span class="svg-icon svg-icon-2">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                                    viewBox="0 0 24 24" fill="none">
                                                                    <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1"
                                                                        transform="rotate(-90 11.364 20.364)" fill="currentColor"></rect>
                                                                    <rect x="4.36396" y="11.364" width="16" height="2" rx="1"
                                                                        fill="currentColor">
                                                                    </rect>
                                                                </svg>
                                                            </span>
                                                            {{ $t('Add') }}
                                                        </button>
                                                        <button type="button" class="btn btn-sm btn-light btn-active-warning me-3" @click="deleteProducerItem()"
                                                            :disabled="isButtonDisabled(producerSettingList) == null || isButtonDisabled(producerSettingList) == undefined">
                                                            <span class="svg-icon svg-icon-2">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                    <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"/>
                                                                </svg>
                                                            </span>
                                                            {{ $t('Remove') }}
                                                        </button>
                                                        <button type="button" class="btn btn-sm btn btn-light btn-active-danger me-3" @click="deleteAllProducers()" 
                                                            :disabled="this.producerSettingList.length == 0">
                                                            <span class="svg-icon svg-icon-2">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                    <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"/>
                                                                </svg>
                                                            </span>
                                                            {{ $t('Remove All (!)') }}
                                                        </button>
                                                    </div>
                                                </div>
                                                <DxDataGrid id="gridContainer" :show-borders="true" 
                                                    key-expr="id" 
                                                    :data-source="producerSettingList" 
                                                    :allow-column-reordering="true"  
                                                    :allow-column-resizing="true" 
                                                    :column-auto-width="true" 
                                                    :row-alternatin-enabled="true"
                                                    :hover-state-enabled="true" 
                                                    :show-row-lines="true" 
                                                    @exporting="this.$onExporting($event , 'Supplier-Account-List')" 
                                                    :ref="'dataGrid'"
                                                    :v-model:page-size="pageSize"
                                                    :v-model:page-index="pageIndex"
                                                    @row-updating="onProducerRowUpdating"
                                                    @editing-start="setProducerMainDefaultList"> 

                                                    <DxFilterRow :visible="true"/>
                                                    <DxHeaderFilter :visible="true"/>
                                                    <DxGroupPanel :visible="true"/>      
                                                    <DxExport :enabled="this.$getUser().is_allow_export" :formats="['xlsx', 'pdf']" :allow-export-selected-data="false"/>
                                                    <DxPaging :page-size="10"/>
                                                    <DxPager :visible="false" :allowed-page-sizes="pageSizes" :show-page-size-selector="showPageSizeSelector" :show-info="true" :show-navigation-buttons="true"/>  
                                                    <DxSearchPanel :visible="true"/>
                                                    <DxScrolling
                                                        mode="virtual"
                                                        row-rendering-mode="virtual"                                                        
                                                    />
                                                    <DxEditing :allow-updating="true" mode="row"/>
                                                    <DxColumn data-field="isSelected" caption="#" :width="100" :allow-filtering="false" alignment="center" cell-template="is-selected-cell-template" :allow-editing="false" :allow-exporting="false"/>
                                                    <DxColumn data-field="main_branch_name" caption="System Main Branch" header-cell-template="main_branch_id-header" cell-template="main_branch_id-cell-template" :set-cell-value="setProducerMainBranch">
                                                        <DxLookup :data-source="mainBranchList" value-expr="id" display-expr="text" v-model="producerForm.main_branch_id" />
                                                    </DxColumn> 
                                                    <DxColumn data-field="policy_branch_name" caption="System Policy Branch" header-cell-template="policy_branch_id-header" cell-template="policy_branch_id-cell-template" :set-cell-value="setProducerPolicyBranch">
                                                        <DxLookup :data-source="policyBranchList" value-expr="id" display-expr="text" v-model="producerForm.policy_branch_id" />
                                                    </DxColumn> 
                                                    <DxColumn data-field="policy_group_name" caption="System Policy Group" header-cell-template="policy_group_id-header" cell-template="policy_group_id-cell-template" :set-cell-value="setProducerPolicyGroup">
                                                        <DxLookup :data-source="policyGroupList" value-expr="id" display-expr="text" v-model="producerForm.policy_group_id"/>
                                                    </DxColumn> 
                                                    <DxColumn data-field="producer_policy_code" caption="Producer Policy Code" header-cell-template="producer_policy_code-header" cell-template="producer_policy_code-cell-template"/>  
                                                    <DxColumn data-field="producer_policy_name" caption="Producer Policy Name" header-cell-template="producer_policy_name-header" cell-template="producer_policy_name-cell-template"/>
                                                    <DxColumn :width="100" data-field="search_title" caption="Search Title" header-cell-template="search_title-header" cell-template="search_title-cell-template" />

                                                    <template #main_branch_id-header>
                                                        <div>System</div><div>Main Branch</div>
                                                    </template>
                                                    <template #policy_branch_id-header>
                                                        <div>System</div><div>Policy Branch</div>
                                                    </template>
                                                    <template #policy_group_id-header>
                                                        <div>System</div><div>Policy Group</div>
                                                    </template>
                                                    <template #producer_policy_code-header>
                                                        <div>Producer</div><div>Policy Code</div>
                                                    </template>
                                                    <template #producer_policy_name-header>
                                                        <div>Producer</div><div>Policy Name</div>
                                                    </template>
                                                    <template #search_title-header>
                                                        <div>Search</div><div>Title</div>
                                                    </template>
                    
                                                    <template #is-selected-cell-template="{ data }"> 
                                                        <span>
                                                            <div class="form-check form-check-sm form-check-custom form-check-solid justify-content-center">
                                                                <input class="form-check-input" type="checkbox" v-model="data.data.isSelected" @change="this.onSelectItem(producerSettingList, data.data)" />
                                                            </div>
                                                        </span>
                                                    </template>
                                                    <template #main_branch_id-cell-template="{ data }">
                                                        <a href="#" class="text-gray-800 text-hover-primary mb-1">
                                                            {{ data.data.main_branch_name }}
                                                        </a>
                                                    </template>
                                                    <template #policy_branch_id-cell-template="{ data }">
                                                        <a href="#" class="text-gray-800 text-hover-primary mb-1">
                                                            {{ data.data.policy_branch_name }}
                                                        </a>
                                                    </template> 
                                                    <template #policy_group_id-cell-template="{ data }">
                                                        <a href="#" class="text-gray-800 text-hover-primary mb-1">
                                                            {{ data.data.policy_group_name }}
                                                        </a>
                                                    </template> 
                                                    <template #producer_policy_code-cell-template="{ data }">
                                                        <a href="#" class="text-gray-800 text-hover-primary mb-1">
                                                            {{ data.data.producer_policy_code }}
                                                        </a>
                                                    </template> 
                                                    <template #producer_policy_name-cell-template="{ data }">
                                                        <a href="#" class="text-gray-800 text-hover-primary mb-1">
                                                            {{ data.data.producer_policy_name }}
                                                        </a>
                                                    </template>
                                                    <template #search_title-cell-template="{ data }">
                                                        <a href="#" class="text-gray-800 text-hover-primary mb-1">
                                                            {{ data.data.search_title }}
                                                        </a>
                                                    </template>
                                                </DxDataGrid>
                                            </div>
                                        </div>

                                        <div class="card card-xxl-stretch border border-gray-300 mt-5">
                                            <div class="card-header">
                                                <div class="card-title">
                                                    {{ $t('Producer Coverage Types') }}
                                                </div>
                                                <div class="card-toolbar">
                                                    <button type="button" class="btn btn-sm btn-light btn-active-success me-3" data-bs-toggle="modal" ref="btnAddItem" @click="onOpenCoverageParameterModal()"
                                                        data-bs-target="#kt_modal_coverage_parameters" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                                                        {{ $t('Coverage Parameters') }}
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="card-body">
                                                <div class="d-flex flex-stack flex-wrap mb-5">
                                                    <div class="d-flex justify-content-end" data-kt-docs-table-toolbar="base">
                                                        <button type="button" class="btn btn-sm btn-light btn-active-success me-3" data-bs-toggle="modal" ref="btnAddItem"
                                                            data-bs-target="#kt_modal_add_coverage" @click="openCoverageModal('add')"
                                                            data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                                                            <span class="svg-icon svg-icon-2">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                                    viewBox="0 0 24 24" fill="none">
                                                                    <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1"
                                                                        transform="rotate(-90 11.364 20.364)" fill="currentColor"></rect>
                                                                    <rect x="4.36396" y="11.364" width="16" height="2" rx="1"
                                                                        fill="currentColor">
                                                                    </rect>
                                                                </svg>
                                                            </span>
                                                            {{ $t('Add') }}
                                                        </button>
                                                        <button type="button" class="btn btn-sm btn-light btn-active-primary me-3" data-bs-toggle="modal"
                                                            data-bs-target="#kt_modal_add_coverage" @click="openCoverageModal('edit')"
                                                            data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end"
                                                            :disabled="isButtonDisabled(coverageSettingList) == null || isButtonDisabled(coverageSettingList) == undefined">
                                                            <span class="svg-icon svg-icon-2">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                        <path opacity="0.3" d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z" fill="currentColor"/>
                                                                        <path d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z" fill="currentColor"/>
                                                                    </svg>
                                                            </span>
                                                            {{ $t('Edit') }}
                                                        </button>
                                                        <button type="button" class="btn btn-sm btn-light btn-active-warning me-3" @click="deleteCoverageItem()"
                                                            :disabled="isButtonDisabled(coverageSettingList) == null || isButtonDisabled(coverageSettingList) == undefined">
                                                            <span class="svg-icon svg-icon-2">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                    <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"/>
                                                                </svg>
                                                            </span>
                                                            {{ $t('Remove') }}
                                                        </button>
                                                        <button type="button" class="btn btn-sm btn btn-light btn-active-danger me-3" @click="deleteAllCoverages()"
                                                            :disabled="this.coverageSettingList.length == 0">
                                                            <span class="svg-icon svg-icon-2">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                    <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"/>
                                                                </svg>
                                                            </span>
                                                            {{ $t('Remove All (!)') }}
                                                        </button>
                                                    </div>
                                                </div>
                                                <DxDataGrid id="gridContainer" :show-borders="true" 
                                                        key-expr="id" 
                                                        :data-source="coverageSettingList" 
                                                        :allow-column-reordering="true"  
                                                        :allow-column-resizing="true" 
                                                        :column-auto-width="true" 
                                                        :row-alternatin-enabled="true"
                                                        :hover-state-enabled="true" 
                                                        :show-row-lines="true" 
                                                        @exporting="this.$onExporting($event , 'Supplier-Account-List')"  
                                                        :ref="'dataGrid'"
                                                        :v-model:page-size="pageSize"
                                                        :v-model:page-index="pageIndex"> 

                                                    <DxFilterRow :visible="true"/>
                                                    <DxHeaderFilter :visible="true"/>
                                                    <DxGroupPanel :visible="true"/>      
                                                    <DxExport :enabled="this.$getUser().is_allow_export" :formats="['xlsx', 'pdf']" :allow-export-selected-data="false"/>
                                                    <DxPaging :page-size="10"/>
                                                    <DxPager :visible="false" :allowed-page-sizes="pageSizes" :show-page-size-selector="showPageSizeSelector" :show-info="true" :show-navigation-buttons="true"/>  
                                                    <DxSearchPanel :visible="true"/>
                                                    <DxScrolling
                                                        mode="virtual"
                                                        row-rendering-mode="virtual"
                                                    />
                                                    <DxColumn data-field="isSelected" caption="#" :width="100" :allow-filtering="false" alignment="center" cell-template="is-selected-cell-template" :allow-exporting="false"/>
                                                    <DxColumn data-field="coverage_main_name" caption="System Coverage Main" header-cell-template="coverage_main_id-header" cell-template="coverage_main_id-cell-template"/> 
                                                    <DxColumn data-field="coverage_detail_name" caption="System Coverage Detail" header-cell-template="coverage_detail_id-header" cell-template="coverage_detail_id-cell-template"/> 
                                                    <DxColumn data-field="producer_coverage_code" caption="Producer Coverage Code" header-cell-template="producer_coverage_code-header" cell-template="producer_coverage_code-cell-template"/>  
                                                    <DxColumn data-field="producer_coverage_name" caption="Producer Coverage Name" header-cell-template="producer_coverage_name-header" cell-template="producer_coverage_name-cell-template"/>  
                                                    <DxColumn data-field="producer_policy_type_name" caption="Producer Coverage Name" header-cell-template="producer_policy_type_name-header" cell-template="producer_policy_type_name-cell-template"/>  

                                                    <template #coverage_main_id-header>
                                                        <div>System</div><div>Coverage Main</div>
                                                    </template>
                                                    <template #coverage_detail_id-header>
                                                        <div>System</div><div>Coverage Detail</div>
                                                    </template>
                                                    <template #producer_coverage_code-header>
                                                        <div>Producer</div><div>Coverage Code</div>
                                                    </template>
                                                    <template #producer_coverage_name-header>
                                                        <div>Producer</div><div>Coverage Name</div>
                                                    </template>
                                                    <template #producer_policy_type_name-header>
                                                        <div>Producer</div><div>Policy Type</div>
                                                    </template>
                    
                                                    <template #is-selected-cell-template="{ data }"> 
                                                        <span>
                                                            <div class="form-check form-check-sm form-check-custom form-check-solid justify-content-center">
                                                                <input class="form-check-input" type="checkbox" v-model="data.data.isSelected" @change="this.onSelectItem(coverageSettingList, data.data)" />
                                                            </div>
                                                        </span>
                                                    </template>
                                                    <template #coverage_main_id-cell-template="{ data }">
                                                        <a href="#" class="text-gray-800 text-hover-primary mb-1">
                                                            {{ data.data.coverage_main_name }}
                                                        </a>
                                                    </template>
                                                    <template #coverage_detail_id-cell-template="{ data }">
                                                        <a href="#" class="text-gray-800 text-hover-primary mb-1">
                                                            {{ data.data.coverage_detail_name }}
                                                        </a>
                                                    </template> 
                                                    <template #producer_coverage_code-cell-template="{ data }">
                                                        <a href="#" class="text-gray-800 text-hover-primary mb-1">
                                                            {{ data.data.producer_coverage_code }}
                                                        </a>
                                                    </template> 
                                                    <template #producer_coverage_name-cell-template="{ data }">
                                                        <a href="#" class="text-gray-800 text-hover-primary mb-1">
                                                            {{ data.data.producer_coverage_name }}
                                                        </a>
                                                    </template> 
                                                    <template #producer_policy_type_name-cell-template="{ data }">
                                                        <a href="#" class="text-gray-800 text-hover-primary mb-1">
                                                            {{ data.data.producer_policy_type_name }}
                                                        </a>
                                                    </template> 
                                                </DxDataGrid>
                                            </div>
                                        </div>

                                        <div class="card card-xxl-stretch border border-gray-300 mt-5">
                                            <div class="card-header">
                                                <div class="card-title">
                                                    {{ $t('Tax Payment Method') }}
                                                </div>
                                            </div>
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col-lg-6">
                                                        <label class="fs-6 fw-bold form-label">
                                                            {{ $t('Tax') }} 
                                                        </label>
                                                        <Select2 v-model="form.tax_method" :options="taxMethodList" :settings="{ width: '100%' }" />
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <label class="fs-6 fw-bold form-label">
                                                            {{ $t('Sub Tax') }} 1
                                                        </label>
                                                        <Select2 v-model="form.sub_tax_1_method" :options="taxMethodList" :settings="{ width: '100%' }" />
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <label class="fs-6 fw-bold form-label mt-3">
                                                            {{ $t('Sub Tax') }} 2
                                                        </label>
                                                        <Select2 v-model="form.sub_tax_2_method" :options="taxMethodList" :settings="{ width: '100%' }" />
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <label class="fs-6 fw-bold form-label mt-3">
                                                            {{ $t('Sub Tax') }} 3
                                                        </label>
                                                        <Select2 v-model="form.sub_tax_3_method" :options="taxMethodList" :settings="{ width: '100%' }" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card card-xxl-stretch border border-gray-300 mt-5">
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col-lg-6">
                                                        <label class="fs-6 fw-bold form-label">
                                                            {{ $t('Policy Commission Account') }}
                                                        </label>
                                                        <Select2 v-model="form.policy_commission_account" :options="policyCommissionAccounts" :settings="{ width: '100%' }" />
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <label class="fs-6 fw-bold form-label">
                                                            {{ $t('Policy Taxes Account') }}
                                                        </label>
                                                        <Select2 v-model="form.policy_taxes_account" :options="policyTaxesAccounts" :settings="{ width: '100%' }" />
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <label class="fs-6 fw-bold form-label mt-3" v-tag="'payment_record_type'">
                                                            {{ $t('Payments Record Type') }}
                                                        </label> 
                                                        <Select2 v-model="form.payment_record_type" :options="paymentRecordTypeList" name="payment_record_type"  v-custom  :settings="{width: '100%'}"/>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <label class="fs-6 fw-bold form-label mt-3" v-tag="'is_duplicate_record_check_active'">
                                                            {{ $t('Duplicate Record Check') }}
                                                        </label>
                                                        <Select2 v-model="form.is_duplicate_record_check_active" :options="duplicateRecordCheckList" name="is_duplicate_record_check_active"  v-custom  :settings="{width: '100%'}"/>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <label class="fs-6 fw-bold form-label mt-3" v-tag="'is_main_policy_check_active'">
                                                            {{ $t('Main Policy Check') }}
                                                        </label>
                                                        <Select2 v-model="form.is_main_policy_check_active" :options="mainPolicyCheckList" name="is_main_policy_check_active"  v-custom  :settings="{width: '100%'}"/>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <label class="fs-6 fw-bold form-label mt-3" v-tag="'dll_template_id'">
                                                            {{ $t('DLL Template') }}
                                                        </label>
                                                        <Select2 v-model="form.dll_template_id" :options="dllTemplateList" name="dll_template_id" v-custom :disabled="!this.$isAdmin()" :settings="{ width: '100%'}"/> 
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <label class="fs-6 fw-bold form-label mt-3" v-tag="'policy_import_design'">
                                                            {{ $t('Policy Import Design') }} (1)
                                                        </label>
                                                        <Select2 v-model="form.policy_import_design" :options="policyImportDesignList" name="policy_import_design"  v-custom  :settings="{width: '100%'}"/>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <label class="fs-6 fw-bold form-label mt-3" v-tag="'policy_import_design_2'">
                                                            {{ $t('Policy Import Design') }} (2)
                                                        </label>
                                                        <Select2 v-model="form.policy_import_design_2" :options="policyImportDesignList" name="policy_import_design_2"  v-custom  :settings="{width: '100%'}"/>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <label class="fs-6 fw-bold form-label mt-3" v-tag="'collection_import_design'">
                                                            {{ $t('Collection Import Design') }}
                                                        </label>
                                                        <Select2 v-model="form.collection_import_design" :options="policyCollectionDesignList" name="collection_import_design"  v-custom  :settings="{width: '100%'}"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex flex-row row tab-pane fade show active" id="kt_general_settings" role="tabpanel" v-if="this.activeProducerTab == 'DataImportSettings'">
                                    <div class="card card-xxl-stretch border border-gray-300">
                                        <div class="card-header">
                                            <div class="card-title">
                                                {{ $t('Data Import Parameters') }}
                                            </div>
                                        </div>
                                        <div class="card-body">
                                            <div class="d-flex flex-stack flex-wrap mb-5">
                                                <div class="d-flex justify-content-end" data-kt-docs-table-toolbar="base">
                                                    <button type="button" class="btn btn-sm btn-light btn-active-success me-3" data-bs-toggle="modal" ref="btnAddItem"
                                                        data-bs-target="#kt_modal_add_import_parameters" @click="openDataImportParameterModal('add')"
                                                        data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                                                        <span class="svg-icon svg-icon-2">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                                viewBox="0 0 24 24" fill="none">
                                                                <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1"
                                                                    transform="rotate(-90 11.364 20.364)" fill="currentColor"></rect>
                                                                <rect x="4.36396" y="11.364" width="16" height="2" rx="1"
                                                                    fill="currentColor">
                                                                </rect>
                                                            </svg>
                                                        </span>
                                                        {{ $t('Add') }}
                                                    </button>
                                                    <button type="button" class="btn btn-sm btn-light btn-active-primary me-3" data-bs-toggle="modal"
                                                        data-bs-target="#kt_modal_add_import_parameters" @click="openDataImportParameterModal('edit')"
                                                        data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end"
                                                        :disabled="isButtonDisabled(dataImportSettingsList) == null || isButtonDisabled(dataImportSettingsList) == undefined">
                                                        <span class="svg-icon svg-icon-2">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                    <path opacity="0.3" d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z" fill="currentColor"/>
                                                                    <path d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z" fill="currentColor"/>
                                                                </svg>
                                                        </span>
                                                        {{ $t('Edit') }}
                                                    </button>
                                                    <button type="button" class="btn btn-sm btn-light btn-active-warning me-3" @click="deleteImportParameterItem()"
                                                        :disabled="isButtonDisabled(dataImportSettingsList) == null || isButtonDisabled(dataImportSettingsList) == undefined">
                                                        <span class="svg-icon svg-icon-2">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"/>
                                                            </svg>
                                                        </span>
                                                        {{ $t('Remove') }}
                                                    </button>
                                                    <button type="button" class="btn btn-sm btn btn-light btn-active-danger me-3" @click="deleteAllImportParameters()" 
                                                        :disabled="this.dataImportSettingsList.length == 0">
                                                        <span class="svg-icon svg-icon-2">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"/>
                                                            </svg>
                                                        </span>
                                                        {{ $t('Remove All (!)') }}
                                                    </button>
                                                </div>
                                            </div>
                                            <DxDataGrid id="gridContainer" :show-borders="true" 
                                                    key-expr="id" 
                                                    :data-source="dataImportSettingsList" 
                                                    :allow-column-reordering="true"  
                                                    :allow-column-resizing="true" 
                                                    :column-auto-width="true" 
                                                    :row-alternatin-enabled="true"
                                                    :hover-state-enabled="true" 
                                                    :show-row-lines="true" 
                                                    @exporting="this.$onExporting($event , 'Supplier-Account-List')"  
                                                    :ref="'dataGrid'"
                                                    :v-model:page-size="pageSize"
                                                    :v-model:page-index="pageIndex"> 

                                                <DxFilterRow :visible="true"/>
                                                <DxHeaderFilter :visible="true"/>
                                                <DxGroupPanel :visible="true"/>      
                                                <DxExport :enabled="this.$getUser().is_allow_export" :formats="['xlsx', 'pdf']" :allow-export-selected-data="false"/>
                                                <DxPaging :page-size="100"/>
                                                <DxPager :visible="false" :allowed-page-sizes="pageSizes" :show-page-size-selector="showPageSizeSelector" :show-info="true" :show-navigation-buttons="true"/>  
                                                <DxSearchPanel :visible="true"/>
                                                <DxScrolling
                                                    mode="virtual"
                                                    row-rendering-mode="virtual"
                                                />
                                                <DxColumn data-field="isSelected" caption="#" :width="100" :allow-filtering="false" alignment="center" cell-template="is-selected-cell-template" :allow-exporting="false"/>
                                                <DxColumn data-field="data_type_name" :caption="$t('Data Type')" header-cell-template="data_type-header" cell-template="data_type-cell-template"/> 
                                                <DxColumn data-field="data_code" :caption="$t('Data Code')" header-cell-template="data_code-header" cell-template="data_code-cell-template"/> 
                                                <DxColumn data-field="data_name" :caption="$t('Data Name')" header-cell-template="data_name-header" cell-template="data_name-cell-template"/>  
                                                <DxColumn data-field="sub_list_name" :caption="$t('Data Type List Name')" header-cell-template="sub_list_name-header" cell-template="sub_list_name-cell-template"/>  

                                                <template #data_code-header>
                                                    <div>{{ $t('Data') }}</div><div>{{ $t('Code') }}</div>
                                                </template>
                                                <template #data_type-header>
                                                    <div>{{ $t('Data') }}</div><div>{{ $t('Type') }}</div>
                                                </template>
                                                <template #sub_list_name-header>
                                                    <div>{{ $t('Data Type') }}</div><div>{{ $t('List Name') }}</div>
                                                </template>
                                                <template #data_name-header>
                                                    <div>{{ $t('Date') }}</div><div>{{ $t('Name') }}</div>
                                                </template>
                
                                                <template #is-selected-cell-template="{ data }"> 
                                                    <span>
                                                        <div class="form-check form-check-sm form-check-custom form-check-solid justify-content-center">
                                                            <input class="form-check-input" type="checkbox" v-model="data.data.isSelected" @change="this.onSelectItem(dataImportSettingsList, data.data)" />
                                                        </div>
                                                    </span>
                                                </template>
                                                <template #data_code-cell-template="{ data }">
                                                    <span class="text-gray-800 text-hover-primary mb-1">
                                                        {{ data.data.data_code }}
                                                    </span>
                                                </template>
                                                <template #data_type-cell-template="{ data }">
                                                    <span class="text-gray-800 text-hover-primary mb-1">
                                                        {{ data.data.data_type_name }}
                                                    </span>
                                                </template> 
                                                <template #data_name-cell-template="{ data }">
                                                    <span class="text-gray-800 text-hover-primary mb-1">
                                                        {{ data.data.data_name }}
                                                    </span>
                                                </template> 
                                                <template #sub_list_name-cell-template="{ data }">
                                                    <span class="text-gray-800 text-hover-primary mb-1">
                                                        {{ data.data.sub_list_name }}
                                                    </span>
                                                </template> 
                                            </DxDataGrid>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex flex-row row p-10 tab-pane fade show active" id="kt_accounting_3" role="tabpanel" v-if="this.activeTab == 'InsuranceVendorSettings'">
                                <div class="col-lg-12">
                                    <div class="card card-xxl-stretch border border-gray-300">
                                        <div class="card-header">
                                            <div class="card-title">
                                                {{ $t('Vendor Details') }}
                                            </div>
                                        </div>
                                        <div class="card-body">
                                            <div class="d-flex flex-stack flex-wrap mb-5">
                                                <div class="d-flex justify-content-end" data-kt-docs-table-toolbar="base">
                                                    <button type="button" class="btn btn-sm btn-light btn-active-success me-3" data-bs-toggle="modal" ref="btnAddItem"
                                                        data-bs-target="#kt_modal_add_vendor_detail" @click="openVendorModal('add')"
                                                        data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                                                        <span class="svg-icon svg-icon-2">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                                viewBox="0 0 24 24" fill="none">
                                                                <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1"
                                                                    transform="rotate(-90 11.364 20.364)" fill="currentColor"></rect>
                                                                <rect x="4.36396" y="11.364" width="16" height="2" rx="1"
                                                                    fill="currentColor">
                                                                </rect>
                                                            </svg>
                                                        </span>
                                                        {{ $t('Add') }}
                                                    </button>
                                                    <button type="button" class="btn btn-sm btn-light btn-active-warning me-3" @click="deleteVendorItem()"
                                                        :disabled="isButtonDisabled(vendorSettingList) == null || isButtonDisabled(vendorSettingList) == undefined">
                                                        <span class="svg-icon svg-icon-2">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"/>
                                                            </svg>
                                                        </span>
                                                        {{ $t('Remove') }}
                                                    </button>
                                                    <button type="button" class="btn btn-sm btn btn-light btn-active-danger me-3" @click="deleteAllVendors()"
                                                        :disabled="this.vendorSettingList.length == 0">
                                                        <span class="svg-icon svg-icon-2">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"/>
                                                            </svg>
                                                        </span>
                                                        {{ $t('Remove All (!)') }}
                                                    </button>
                                                </div>
                                            </div>
                                            <DxDataGrid id="gridContainer" :show-borders="true" 
                                                key-expr="id" 
                                                :data-source="vendorSettingList" 
                                                :allow-column-reordering="true"  
                                                :allow-column-resizing="true" 
                                                :column-auto-width="true" 
                                                :row-alternatin-enabled="true"
                                                :hover-state-enabled="true" 
                                                :show-row-lines="true" 
                                                @exporting="this.$onExporting($event , 'Supplier-insurance-vendor-list')"  
                                                :ref="'dataGrid'"
                                                :v-model:page-size="pageSize"
                                                :v-model:page-index="pageIndex"
                                                @row-updating="onVendorRowUpdating"
                                                @editing-start="setMainDefaultList" >                                                    

                                                <DxFilterRow :visible="true"/>
                                                <DxHeaderFilter :visible="true"/>
                                                <DxGroupPanel :visible="true"/>      
                                                <DxExport :enabled="this.$getUser().is_allow_export" :formats="['xlsx', 'pdf']" :allow-export-selected-data="false"/>
                                                <DxPaging :page-size="10"/>
                                                <DxPager :visible="false" :allowed-page-sizes="pageSizes" :show-page-size-selector="showPageSizeSelector" :show-info="true" :show-navigation-buttons="true"/>  
                                                <DxSearchPanel :visible="true"/>
                                                <DxScrolling
                                                    mode="virtual"
                                                    row-rendering-mode="virtual"                                                    
                                                />

                                                <DxEditing :allow-updating="true" mode="row" />
                                                <DxColumn data-field="isSelected" caption="#" :width="100" :allow-filtering="false" alignment="center" cell-template="is-selected-cell-template" :allow-editing="false" :allow-exporting="false"/>
                                                <DxColumn data-field="main_branch_name" :caption="$t('System Main Branch')" header-cell-template="main_branch_id-header" cell-template="main_branch_id-cell-template" :set-cell-value="setMainBranch">
                                                    <DxLookup :data-source="mainBranchList" value-expr="id" display-expr="text" v-model="vendorForm.main_branch_id"/>
                                                </DxColumn> 
                                                <DxColumn data-field="policy_branch_name" :caption="$t('System Policy Branch')" header-cell-template="policy_branch_id-header" cell-template="policy_branch_id-cell-template" :set-cell-value="setPolicyBranch"> 
                                                    <DxLookup :data-source="policyBranchList" value-expr="id" display-expr="text" v-model="vendorForm.policy_branch_id"/>
                                                </DxColumn>
                                                <DxColumn data-field="commission_type_name" :caption="$t('Commission Type')" header-cell-template="commission_type-header" cell-template="commission_type-cell-template" :set-cell-value="setCommissionTypeList">
                                                    <DxLookup :data-source="commissionTypeList" value-expr="id" display-expr="text" v-model="vendorForm.commission_type"/> 
                                                </DxColumn>  
                                                <DxColumn data-field="commission_rate" :sorting-method="this.$numericSort" :caption="$t('Commission Rate')" alignment="right" 
                                                :editor-options="columnEditorOptions" :format="decimalFormat" data-type="number" header-cell-template="commission_rate-header" cell-template="commission_rate-cell-template">                                                
                                                    <DxFormat type="fixedPoint" :precision="2"/>
                                                </DxColumn>

                                                <template #main_branch_id-header>
                                                    <div>{{ $t('System') }}</div><div>{{ $t('Main Branch') }}</div>
                                                </template>
                                                <template #policy_branch_id-header>
                                                    <div>{{ $t('System') }}</div><div>{{ $t('Policy Branch') }}</div>
                                                </template>
                                                <template #commission_type-header>
                                                    <div>{{ $t('Commission') }}</div><div>{{ $t('Type') }}</div>
                                                </template>
                                                <template #commission_rate-header>
                                                    <div>{{ $t('Commission') }}</div><div>{{ $t('Rate') }}</div>
                                                </template>        
                
                                                <template #is-selected-cell-template="{ data }"> 
                                                    <span>
                                                        <div class="form-check form-check-sm form-check-custom form-check-solid justify-content-center">
                                                            <input class="form-check-input" type="checkbox" v-model="data.data.isSelected" @change="this.onSelectItem(vendorSettingList, data.data)" />
                                                        </div>
                                                    </span>
                                                </template>
                                                <template #main_branch_id-cell-template="{ data }">
                                                    <span class="text-gray-800 text-hover-primary mb-1">
                                                        {{ data.data.main_branch_name }}
                                                    </span>
                                                </template>
                                                <template #policy_branch_id-cell-template="{ data }">
                                                    <span class="text-gray-800 text-hover-primary mb-1">
                                                        {{ data.data.policy_branch_name }}
                                                    </span>
                                                </template> 
                                                <template #commission_type-cell-template="{ data }">
                                                    <span class="text-gray-800 text-hover-primary mb-1">
                                                        {{ data.data.commission_type_name }}
                                                    </span>
                                                </template> 
                                                <template #commission_rate-cell-template="{ data }">
                                                    <span class="text-gray-800 text-hover-primary mb-1">
                                                        {{ data.data.commission_rate }}
                                                    </span>
                                                </template> 
                                            </DxDataGrid>
                                        </div>
                                    </div>
                                    <div class="card card-xxl-stretch border border-gray-300 mt-5">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-lg-6">
                                                    <label class="fs-6 fw-bold form-label mt-3">
                                                        {{ $t('Vendor Commission Account') }}
                                                    </label>
                                                    <Select2 v-model="form.vendor_commission_account" :options="vendorCommissionAccounts" :settings="{ width: '100%' }" />
                                                </div>
                                                <div class="col-lg-6">
                                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'branch'">
                                                        <span>{{ $t('Branch') }}</span>
                                                    </label>
                                                    <Select2 v-model="form.branch_id" :options="branchList" name="branch" v-custom :settings="{ width: '100%'}"/> 
                                                </div>  
                                                <div class="col-lg-6">
                                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'campaign'">
                                                        <span>{{ $t('Sales Role / Campaign') }}</span>
                                                    </label>
                                                    <Select2 v-model="form.policy_campaign_id" :options="campaignList" name="campaign" v-custom :settings="{ width: '100%'}"/> 
                                                </div>  
                                                <div class="col-lg-6">
                                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'sourceCode'">
                                                        <span>{{ $t('Source Code') }}</span>
                                                    </label>
                                                    <Select2 v-model="form.policy_source_code_id" :options="sourceCodeList" v-custom :settings="{ width: '100%'}"/> 
                                                </div>  
                                                <div class="col-lg-6">
                                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'sourceCode2'">
                                                        <span>{{ $t('Source Code 2') }}</span>
                                                    </label>
                                                    <Select2 v-model="form.policy_source_code_2_id" :options="sourceCode2List" v-custom :settings="{ width: '100%'}"/> 
                                                </div>   
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex flex-row row p-10 tab-pane fade show active" id="kt_accounting_4" role="tabpanel" v-if="this.activeTab == 'SalesVendorSettings'">
                                <div class="col-lg-12">
                                    <div class="card card-xxl-stretch border border-gray-300">
                                        <div class="card-header">
                                            <div class="card-title">
                                                {{ $t('Vendor Details') }}
                                            </div>
                                        </div>
                                        <div class="card-body">
                                            <div class="d-flex flex-stack flex-wrap mb-5">
                                                <div class="d-flex justify-content-end" data-kt-docs-table-toolbar="base">
                                                    <button type="button" class="btn btn-sm btn-light btn-active-success me-3" data-bs-toggle="modal" ref="btnAddItem"
                                                        data-bs-target="#kt_modal_add_vendor_detail" @click="openVendorModal('add')"
                                                        data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                                                        <span class="svg-icon svg-icon-2">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                                viewBox="0 0 24 24" fill="none">
                                                                <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1"
                                                                    transform="rotate(-90 11.364 20.364)" fill="currentColor"></rect>
                                                                <rect x="4.36396" y="11.364" width="16" height="2" rx="1"
                                                                    fill="currentColor">
                                                                </rect>
                                                            </svg>
                                                        </span>
                                                        {{ $t('Add') }}
                                                    </button>
                                                    <button type="button" class="btn btn-sm btn-light btn-active-warning me-3" @click="deleteVendorItem()"
                                                        :disabled="isButtonDisabled(vendorSettingList) == null || isButtonDisabled(vendorSettingList) == undefined">
                                                        <span class="svg-icon svg-icon-2">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"/>
                                                            </svg>
                                                        </span>
                                                        {{ $t('Remove') }}
                                                    </button>
                                                    <button type="button" class="btn btn-sm btn btn-light btn-active-danger me-3" @click="deleteAllVendors()"
                                                        :disabled="this.vendorSettingList.length == 0">
                                                        <span class="svg-icon svg-icon-2">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"/>
                                                            </svg>
                                                        </span>
                                                        {{ $t('Remove All (!)') }}
                                                    </button>
                                                </div>
                                            </div>
                                            <DxDataGrid id="gridContainer" :show-borders="true" 
                                                key-expr="id" 
                                                :data-source="vendorSettingList" 
                                                :allow-column-reordering="true"  
                                                :allow-column-resizing="true" 
                                                :column-auto-width="true" 
                                                :row-alternatin-enabled="true"
                                                :hover-state-enabled="true" 
                                                :show-row-lines="true" 
                                                @exporting="this.$onExporting($event , 'Supplier-sales-vendor-list')"  
                                                :ref="'dataGrid'"
                                                :v-model:page-size="pageSize"
                                                :v-model:page-index="pageIndex"
                                                @row-updating="onVendorRowUpdating"
                                                @editing-start="setMainDefaultList" >                                                    

                                                <DxFilterRow :visible="true"/>
                                                <DxHeaderFilter :visible="true"/>
                                                <DxGroupPanel :visible="true"/>      
                                                <DxExport :enabled="this.$getUser().is_allow_export" :formats="['xlsx', 'pdf']" :allow-export-selected-data="false"/>
                                                <DxPaging :page-size="10"/>
                                                <DxPager :visible="false" :allowed-page-sizes="pageSizes" :show-page-size-selector="showPageSizeSelector" :show-info="true" :show-navigation-buttons="true"/>  
                                                <DxSearchPanel :visible="true"/>
                                                <DxScrolling
                                                    mode="virtual"
                                                    row-rendering-mode="virtual"                                                    
                                                />

                                                <DxEditing :allow-updating="true" mode="row" />
                                                <DxColumn data-field="isSelected" caption="#" :width="100" :allow-filtering="false" alignment="center" cell-template="is-selected-cell-template" :allow-editing="false" :allow-exporting="false"/>
                                                <DxColumn data-field="main_branch_name" :caption="$t('Product Group')" header-cell-template="main_branch_id-header" cell-template="main_branch_id-cell-template" :set-cell-value="setProductGroup">
                                                    <DxLookup :data-source="productGroupList" value-expr="id" display-expr="text" v-model="vendorForm.main_branch_id"/>
                                                </DxColumn>
                                                <DxColumn data-field="policy_branch_name" :caption="$t('Product Type')" header-cell-template="policy_branch_id-header" cell-template="policy_branch_id-cell-template" :set-cell-value="setProductType"> 
                                                    <DxLookup :data-source="productTypeList" value-expr="id" display-expr="text" v-model="vendorForm.policy_branch_id"/>
                                                </DxColumn>
                                                <DxColumn data-field="sub_list_name" :caption="$t('Product Type')" header-cell-template="sub_list_id-header" cell-template="sub_list_id-cell-template"
                                                :set-cell-value="setProductDetail"> 
                                                    <DxLookup :data-source="productDetailList" value-expr="id" display-expr="text" v-model="vendorForm.sub_list_id"/>
                                                </DxColumn>
                                                <DxColumn data-field="commission_type_name" :caption="$t('Commission Type')" header-cell-template="commission_type-header" cell-template="commission_type-cell-template" />
                                                <DxColumn data-field="commission_rate" :sorting-method="this.$numericSort" :caption="$t('Commission Rate')" alignment="right" 
                                                :editor-options="columnEditorOptions" :format="decimalFormat" data-type="number" header-cell-template="commission_rate-header" cell-template="commission_rate-cell-template">                                                
                                                    <DxFormat type="fixedPoint" :precision="2"/>
                                                </DxColumn>

                                                <template #main_branch_id-header>
                                                    <div>{{ $t('Product') }}</div><div>{{ $t('Group') }}</div>
                                                </template>
                                                <template #policy_branch_id-header>
                                                    <div>{{ $t('Product') }}</div><div>{{ $t('Type') }}</div>
                                                </template>
                                                <template #sub_list_id-header>
                                                    <div>{{ $t('Product') }}</div><div>{{ $t('Detail') }}</div>
                                                </template>
                                                <template #commission_type-header>
                                                    <div>{{ $t('Commission') }}</div><div>{{ $t('Type') }}</div>
                                                </template>
                                                <template #commission_rate-header>
                                                    <div>{{ $t('Commission') }}</div><div>{{ $t('Rate') }}</div>
                                                </template>        
                
                                                <template #is-selected-cell-template="{ data }"> 
                                                    <span>
                                                        <div class="form-check form-check-sm form-check-custom form-check-solid justify-content-center">
                                                            <input class="form-check-input" type="checkbox" v-model="data.data.isSelected" @change="this.onSelectItem(vendorSettingList, data.data)" />
                                                        </div>
                                                    </span>
                                                </template>
                                                <template #main_branch_id-cell-template="{ data }">
                                                    <span class="text-gray-800 text-hover-primary mb-1">
                                                        {{ data.data.main_branch_name }}
                                                    </span>
                                                </template>
                                                <template #policy_branch_id-cell-template="{ data }">
                                                    <span class="text-gray-800 text-hover-primary mb-1">
                                                        {{ data.data.policy_branch_name }}
                                                    </span>
                                                </template> 
                                                <template #sub_list_id-cell-template="{ data }">
                                                    <span class="text-gray-800 text-hover-primary mb-1">
                                                        {{ data.data.sub_list_name }}
                                                    </span>
                                                </template> 
                                                <template #commission_type-cell-template="{ data }">
                                                    <span class="text-gray-800 text-hover-primary mb-1">
                                                        {{ data.data.commission_type_name }}
                                                    </span>
                                                </template> 
                                                <template #commission_rate-cell-template="{ data }">
                                                    <span class="text-gray-800 text-hover-primary mb-1">
                                                        {{ data.data.commission_rate }}
                                                    </span>
                                                </template> 
                                            </DxDataGrid>
                                        </div>
                                    </div>
                                    <div class="card card-xxl-stretch border border-gray-300 mt-5">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-lg-6">
                                                    <label class="fs-6 fw-bold form-label mt-3">
                                                        {{ $t('Vendor Commission Account') }}
                                                    </label>
                                                    <Select2 v-model="form.vendor_commission_account" :options="vendorCommissionAccounts" :settings="{ width: '100%' }" />
                                                </div>
                                                <div class="col-lg-6">
                                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'branch'">
                                                        <span>{{ $t('Branch') }}</span>
                                                    </label>
                                                    <Select2 v-model="form.branch_id" :options="branchList" name="branch" v-custom :settings="{ width: '100%'}"/> 
                                                </div>  
                                                <div class="col-lg-6">
                                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'campaign'">
                                                        <span>{{ $t('Sales Role / Campaign') }}</span>
                                                    </label>
                                                    <Select2 v-model="form.policy_campaign_id" :options="campaignList" name="campaign" v-custom :settings="{ width: '100%'}"/> 
                                                </div>  
                                                <div class="col-lg-6">
                                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'sourceCode'">
                                                        <span>{{ $t('Source Code') }}</span>
                                                    </label>
                                                    <Select2 v-model="form.policy_source_code_id" :options="sourceCodeList" v-custom :settings="{ width: '100%'}"/> 
                                                </div>  
                                                <div class="col-lg-6">
                                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'sourceCode2'">
                                                        <span>{{ $t('Source Code 2') }}</span>
                                                    </label>
                                                    <Select2 v-model="form.policy_source_code_2_id" :options="sourceCode2List" v-custom :settings="{ width: '100%'}"/> 
                                                </div>   
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Save button -->
                            <div v-if="this.activeTab !== 'Documents' && this.activeTab !== 'AccountGroup' && this.activeTab !== 'Invoices'" class="d-flex flex-row row px-10">
                                <div class="col-lg-12" v-if="this.$isCredentialActive(11,'U')">
                                    <div class="d-grid gap-2"> 
                                        <button type="button" class="btn btn-lg btn-primary mb-4" @click="this.saveData()" data-kt-indicator="on" :disabled="isValidEmail == false">
                                            <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                            <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                            </svg>
                                            </span>
                                                <strong v-if="!this.isProgressing"> {{ $t('Update Supplier') }} </strong> 
                                                <span class="indicator-progress" v-if="this.isProgressing">
                                                    {{ $t('Saving Data...') }} <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                </span> 
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div class="tab-pane fade show active" id="kt_accounting_5" role="tabpanel" v-if="this.activeTab == 'AccountGroup'">
                        <AccountGroupTab ref="AccountGroupTab" :account-info="accountInfo"></AccountGroupTab>
                    </div>

                    <div v-if="this.activeTab == 'Documents'" class="row p-10 tab-pane fade show active" id="kt_accounting_6" role="tabpanel">
                        <FileManager :location="1" :locationtype="2" :upload_source="4" ref="FileManagerComponent"></FileManager>
                    </div>
                    <div class="tab-pane fade show active"  id="kt_accounting_7" role="tabpanel" v-if="this.activeTab == 'Invoices'" >
                        <InvoiceList ref="InvoiceList" />
                    </div>
                </div>
            </div>
        </div>
        <!-- SEARCH COMPANY BY NAME RESULT -->
        <div class="modal fade" id="kt_modal_search_company" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
            <SearchCompanyByName ref="SearchCompanyByName" :account-name="this.form.name" :account-type="2"></SearchCompanyByName>
        </div>

        <!-- SEARCH ADDRESS BY POSTCODE RESULT -->
        <div class="modal fade" id="kt_modal_search_address" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
            <FindAddressByPostCode ref="FindAddressByPostCode" :post-code="this.form.postcode" :account-type="2"></FindAddressByPostCode>
        </div>
        <!-- SEARCH ADDRESS BY ADDRESS CODE RESULT -->
        <div class="modal fade" id="kt_modal_search_address_by_code" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
            <FindAddressByAddressCode ref="FindAddressByAddressCode" :address-code="this.form.uprn" :account-type="2"></FindAddressByAddressCode>
        </div>
        
        <!-- Producer Policy Type -->
        <div class="modal fade" id="kt_modal_add_producer" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content"  >
                    <div class="modal-header">
                        <h2 class="modal-title fw-bolder col-md-10">
                            <span v-if="modalType.producer == 'add'">{{ $t('Add') }}</span>
                            <span v-if="modalType.producer == 'edit'">{{ $t('Edit') }}</span>
                            {{ $t('Producer Policy Type') }}
                        </h2>  
                        <div class="btn btn-icon btn-sm btn-active-icon-primary"
                            data-bs-dismiss="modal" aria-label="Close" ref="closeProducerModal">
                            <span class="svg-icon svg-icon-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none">
                                    <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                        transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                    <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                        transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                                </svg>
                            </span>
                        </div>
                    </div>
                    <div class="modal-body pt-0" :class="{'overlay overlay-block': this.isItemSaving == true}">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="card">
                                    <div class="card-body">
                                        <form class="row">
                                            <div class="col-lg-12">
                                                <label class="fs-6 fw-bold form-label mt-3" v-tag="'main_branch_id'">
                                                    {{ $t('System Main Branch') }}
                                                </label> 
                                                <Select2 v-model="producerForm.main_branch_id" :options="mainBranchList" @select="getParentLists(2, producerForm.main_branch_id, true)" name="main_branch_id"  v-custom  :settings="{width: '100%', dropdownParent: '#kt_modal_add_producer .modal-content'}"/>
                                            </div>
                                            <div class="col-lg-6">
                                                <label class="fs-6 fw-bold form-label mt-3" v-tag="'policy_branch_id'">
                                                    {{ $t('System Policy Branch') }}
                                                </label> 
                                                <Select2 v-model="producerForm.policy_branch_id" :options="policyBranchList" name="policy_branch_id" @select="getPolicyGroupList(true)"  v-custom  :settings="{width: '100%', dropdownParent: '#kt_modal_add_producer .modal-content'}"/>
                                            </div>
                                            <div class="col-lg-6">
                                                <label class="fs-6 fw-bold form-label mt-3" v-tag="'policy_group_id'">
                                                    {{ $t('System Policy Group') }}
                                                </label> 
                                                <Select2 v-model="producerForm.policy_group_id" :options="policyGroupList" name="policy_group_id"  v-custom  :settings="{width: '100%', dropdownParent: '#kt_modal_add_producer .modal-content'}"/>
                                            </div>
                                            <div class="col-lg-12">
                                                <label class="fs-6 fw-bold form-label mt-3" v-tag="'producer_policy_code'">
                                                    {{ $t('Producer Policy Code') }}
                                                </label>
                                                <input type="text" class="form-control" name="producer_policy_code" v-model="producerForm.producer_policy_code" v-custom>
                                            </div>
                                            <div class="col-lg-12">
                                                <label class="fs-6 fw-bold form-label mt-3" v-tag="'producer_policy_name'">
                                                    {{ $t('Producer Policy Name') }}
                                                </label>
                                                <input type="text" class="form-control" name="producer_policy_name" v-model="producerForm.producer_policy_name" v-custom>
                                            </div>
                                            <div class="col-lg-12">
                                                <label class="fs-6 fw-bold form-label mt-3" v-tag="'search_title'">
                                                    {{ $t('Search Title') }}
                                                </label>
                                                <input type="text" class="form-control" name="search_title" v-model="producerForm.search_title" v-custom>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="card card-flush mt-4">
                                    <div class="d-grid gap-2"> 
                                        <button v-if="modalType.producer == 'add'" type="button" class="btn btn-light-success border border-success btn-lg"
                                            @click="addProducerSetting()">
                                            <span class="indicator-label">{{ $t('Save') }}</span> 
                                        </button>
                                        <button v-if="modalType.producer == 'edit'" type="button" class="btn btn-light-primary border border-primary btn-lg"
                                            @click="editProducerSetting()">
                                            <span class="indicator-label">{{ $t('Edit') }}</span> 
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="overlay-layer bg-dark bg-opacity-5" v-if="this.isItemSaving == true">
                            <div class="spinner-border text-primary" role="status">
                                <span class="visually-hidden">{{ $t('Loading...') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <!-- Producer Coverage Type -->
        <div class="modal fade" id="kt_modal_add_coverage" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content"  >
                    <div class="modal-header">
                        <h2 class="modal-title fw-bolder col-md-10">
                            <span v-if="modalType.coverage == 'add'">{{ $t('Add') }}</span>
                            <span v-if="modalType.coverage == 'edit'">{{ $t('Edit') }}</span>
                            {{ $t('Producer Coverage Type') }}
                        </h2>  
                        <div class="btn btn-icon btn-sm btn-active-icon-primary"
                            data-bs-dismiss="modal" aria-label="Close" ref="closeCoverageModal">
                            <span class="svg-icon svg-icon-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none">
                                    <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                        transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                    <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                        transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                                </svg>
                            </span>
                        </div>
                    </div>
                    <div class="modal-body pt-0" :class="{'overlay overlay-block': this.isItemSaving == true}">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="card">
                                    <div class="card-body">
                                        <form class="row">
                                            <div class="col-lg-4">
                                                <label class="fs-6 fw-bold form-label mt-3" v-tag="'coverage_main_id'">
                                                    {{ $t('System Coverage Main') }}
                                                </label> 
                                                <Select2 v-model="coverageForm.coverage_main_id" :options="coverageMainList" name="coverage_main_id" @select="getParentLists(4, coverageForm.coverage_main_id, true)"  v-custom  :settings="{width: '100%', dropdownParent: '#kt_modal_add_coverage .modal-content'}"/>
                                            </div>
                                            <div class="col-lg-4">
                                                <label class="fs-6 fw-bold form-label mt-3" v-tag="'coverage_detail_id'">
                                                    {{ $t('System Coverage Detail') }}
                                                </label> 
                                                <Select2 v-model="coverageForm.coverage_detail_id" :options="coverageDetailList" name="coverage_detail_id"  v-custom  :settings="{width: '100%', dropdownParent: '#kt_modal_add_coverage .modal-content'}"/>
                                            </div>
                                            <div class="col-lg-4">
                                                <label class="fs-6 fw-bold form-label mt-3" v-tag="'producer_coverage_code'">
                                                    {{ $t('Producer Policy Type') }}
                                                </label>
                                                <Select2 v-model="coverageForm.producer_policy_type_id" :options="producerSettingList" name="coverage_detail_id"  v-custom  :settings="{width: '100%', dropdownParent: '#kt_modal_add_coverage .modal-content'}"/>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 mt-5">
                                <div class="card">
                                    <div class="card-body">
                                        <ul class="nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-6">
                                            <li class="nav-item">
                                                <a class="nav-link" :class="{'active': this.activeCoverageTab == 'Single'}" data-bs-toggle="tab" href="#kt_tab_single" target="_self"
                                                @click="this.activeCoverageTab = 'Single'">
                                                    <h4> {{ $t('Coverage Settings') }} </h4>
                                                </a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link" :class="{'active': this.activeCoverageTab == 'Multi'}" data-bs-toggle="tab" href="#kt_tab_multi" target="_self"
                                                @click="this.activeCoverageTab = 'Multi', getCoverageParameters()">
                                                    <h4> {{ $t('Multi Coverage Selection') }} </h4>
                                                </a>
                                            </li>
                                        </ul>
                                        <div class="tab-content">
                                            <div class="tab-pane fade" id="kt_tab_single" role="tabpanel" :class="{ 'show active': this.activeCoverageTab == 'Single' }">
                                                <div class="row">
                                                    <form class="form">
                                                        <div class="col-lg-12">
                                                            <label class="fs-6 fw-bold form-label mt-3" v-tag="'producer_coverage_code'">
                                                                {{ $t('Producer Coverage Code') }}
                                                            </label>
                                                            <input type="text" class="form-control" name="producer_coverage_code" v-model="coverageForm.producer_coverage_code" v-custom>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <label class="fs-6 fw-bold form-label mt-3" v-tag="'producer_coverage_name'">
                                                                {{ $t('Producer Coverage Name') }}
                                                            </label>
                                                            <input type="text" class="form-control" name="producer_coverage_name" v-model="coverageForm.producer_coverage_name" v-custom>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <label class="fs-6 fw-bold form-label mt-3" v-tag="'search_title'">
                                                                {{ $t('Search Title') }}
                                                            </label>
                                                            <input type="text" class="form-control" name="search_title" v-model="coverageForm.search_title" v-custom>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                            <div class="tab-pane fade" id="kt_tab_multi" role="tabpanel" :class="{ 'show active': this.activeCoverageTab == 'Multi' }">
                                                <div class="row mb-5">
                                                    <label class="col-lg-3 col-form-label fw-bold">{{ $t('Parameter Group') }}:</label>
                                                    <div class="col-lg-9">
                                                        <Select2 v-model="coverageForm.parameter_group" :options="parameterGroups" name="parameter_group" @select="getCoverageParametersByGroup($event)" v-custom  :settings="{width: '100%'}"/>
                                                    </div>
                                                </div>
                                                <div class="separator my-5 mt-0 border-gray-300"></div>
                                                <DxDataGrid id="gridContainer" :show-borders="true" 
                                                    key-expr="id" 
                                                    :data-source="this.coverageParametersList" 
                                                    :allow-column-reordering="true"  
                                                    :allow-column-resizing="true" 
                                                    :column-auto-width="true" 
                                                    :row-alternatin-enabled="true"
                                                    :hover-state-enabled="true" 
                                                    :show-row-lines="true" 
                                                    @exporting="this.$onExporting($event , 'Supplier-Account-List')" 
                                                    :ref="'dataGrid'"
                                                    :v-model:page-size="pageSize"
                                                    :v-model:page-index="pageIndex"> 

                                                    <DxFilterRow :visible="false"/>
                                                    <DxHeaderFilter :visible="false"/>
                                                    <DxGroupPanel :visible="false"/>      
                                                    <DxExport :enabled="false" :formats="['xlsx', 'pdf']" :allow-export-selected-data="false"/>
                                                    <DxPaging :page-size="10"/>
                                                    <DxPager :visible="false" :show-info="true" :show-navigation-buttons="true"/>  
                                                    <DxSearchPanel :visible="false"/>
                                                    <DxScrolling
                                                        mode="virtual"
                                                        row-rendering-mode="virtual"
                                                    />
                                                    <DxColumn data-field="isSelected" caption="#" :width="100" :allow-filtering="false" :allow-sorting="false" alignment="center" header-cell-template="is-selected-header" cell-template="is-selected-cell-template" :allow-exporting="false"/>
                                                    <DxColumn data-field="group_name" caption="Group" cell-template="group_name-cell-template"/>  
                                                    <DxColumn data-field="coverage_code" caption="Code" cell-template="coverage_code-cell-template"/>  
                                                    <DxColumn data-field="coverage_name" caption="Name" cell-template="coverage_name-cell-template"/>  

                                                    
                                                    <!-- Header Cell Templates -->
                                                    <template #is-selected-header>
                                                        <div class="form-check form-check-sm form-check-custom form-check-solid">
                                                            <input class="form-check-input" type="checkbox" v-model="this.coverageForm.isSelectedAll" @change="onSelectAll()"> 
                                                        </div>
                                                    </template>

                                                    <template #is-selected-cell-template="{ data }"> 
                                                        <span>
                                                            <div class="form-check form-check-sm form-check-custom form-check-solid justify-content-center">
                                                                <input class="form-check-input" type="checkbox" v-model="data.data.isSelected" />
                                                            </div>
                                                        </span>
                                                    </template>
                                                    <template #group_name-cell-template="{ data }">
                                                        <a href="#" class="text-gray-800 text-hover-primary mb-1">
                                                            {{ data.data.group_name }}
                                                        </a>
                                                    </template> 
                                                    <template #coverage_code-cell-template="{ data }">
                                                        <a href="#" class="text-gray-800 text-hover-primary mb-1">
                                                            {{ data.data.coverage_code }}
                                                        </a>
                                                    </template> 
                                                    <template #coverage_name-cell-template="{ data }">
                                                        <a href="#" class="text-gray-800 text-hover-primary mb-1">
                                                            {{ data.data.coverage_name }}
                                                        </a>
                                                    </template> 
                                                </DxDataGrid>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="card card-flush mt-4">
                                    <div class="d-grid gap-2"> 
                                        <button v-if="modalType.coverage == 'add' && this.activeCoverageTab == 'Single'" type="button" class="btn btn-light-success border border-success btn-lg"
                                            @click="this.addCoverageSetting()">
                                            <span class="indicator-label">{{ $t('Add') }}</span> 
                                        </button>
                                        <button v-if="modalType.coverage == 'add' && this.activeCoverageTab == 'Multi'" type="button" class="btn btn-light-success border border-success btn-lg"
                                            @click="this.addMultiCoverageSetting()">
                                            <span class="indicator-label">{{ $t('Add All') }}</span> 
                                        </button>
                                        <button v-if="modalType.coverage == 'edit'" type="button" class="btn btn-light-primary border border-primary btn-lg"
                                            @click="this.editCoverageSetting()">
                                            <span class="indicator-label">{{ $t('Edit') }}</span> 
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="overlay-layer bg-dark bg-opacity-5" v-if="this.isItemSaving == true">
                            <div class="spinner-border text-primary" role="status">
                                <span class="visually-hidden">{{ $t('Loading...') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <!-- Vendor Detail -->
        <div class="modal fade" id="kt_modal_add_vendor_detail" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content"  >
                    <div class="modal-header">
                        <h2 class="modal-title fw-bolder col-md-10">
                            <span v-if="modalType.vendor == 'add'">{{ $t('Add Vendor Detail') }}</span>
                            <span v-if="modalType.vendor == 'edit'">{{ $t('Edit Vendor Detail') }}</span>
                        </h2>  
                        <div class="btn btn-icon btn-sm btn-active-icon-primary"
                            data-bs-dismiss="modal" aria-label="Close" ref="closeVendorModal">
                            <span class="svg-icon svg-icon-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none">
                                    <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                        transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                    <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                        transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                                </svg>
                            </span>
                        </div>
                    </div>
                    <div class="modal-body pt-0" :class="{'overlay overlay-block': this.isItemSaving == true}">
                        <div class="row">
                            <div class="col-lg-12">                                
                                <div class="card">
                                    <div class="card-body">
                                        <form class="form">
                                            <!-- Sales Vendor -->
                                            <div class="col-lg-12" v-if="form.supplier_account_type == 3">
                                                <label class="fs-6 fw-bold form-label mt-3" v-tag="'main_branch_id'">
                                                    {{ $t('Product Group') }}
                                                </label>
                                                <Select2 v-model="vendorForm.main_branch_id" :options="productGroupList" name="main_branch_id" @select="onChangeProductGroup()" v-custom  :settings="{width: '100%', dropdownParent: '#kt_modal_add_vendor_detail .modal-content'}"/>
                                            </div>
                                            <div class="col-lg-12" v-if="form.supplier_account_type == 3">
                                                <label class="fs-6 fw-bold form-label mt-3" v-tag="'policy_branch_id'">
                                                    <span>{{ $t('Product Type') }}</span>
                                                </label> 
                                                <Select2 v-model="vendorForm.policy_branch_id" :options="productTypeList" name="policy_branch_id" @select="onChangeProductType()" v-custom  :settings="{width: '100%', dropdownParent: '#kt_modal_add_vendor_detail .modal-content'}"/>
                                            </div>
                                            <div class="col-lg-12" v-if="form.supplier_account_type == 3">
                                                <label class="fs-6 fw-bold form-label mt-3" v-tag="'sub_list_id'">
                                                    <span>{{ $t('Product Detail') }}</span>
                                                </label> 
                                                <Select2 v-model="vendorForm.sub_list_id" :options="productDetailList" name="sub_list_id"  v-custom  :settings="{width: '100%', dropdownParent: '#kt_modal_add_vendor_detail .modal-content'}"/>
                                            </div>

                                            <!-- Insurance Vendor -->
                                            <div class="col-lg-12" v-if="form.supplier_account_type == 2">
                                                <label class="fs-6 fw-bold form-label mt-3" v-tag="'main_branch_id'">
                                                    <span>{{ $t('System Main Branch') }}</span>
                                                </label> 
                                                <Select2 v-model="vendorForm.main_branch_id" :options="mainBranchList" name="main_branch_id" @select="getParentLists(2, vendorForm.main_branch_id, true)" v-custom  :settings="{width: '100%', dropdownParent: '#kt_modal_add_vendor_detail .modal-content'}"/>
                                            </div>
                                            <div class="col-lg-12" v-if="form.supplier_account_type == 2">
                                                <label class="fs-6 fw-bold form-label mt-3" v-tag="'policy_branch_id'">
                                                    <span>{{ $t('System Policy Branch') }}</span>
                                                </label> 
                                                <Select2 v-model="vendorForm.policy_branch_id" :options="policyBranchList" name="policy_branch_id"  v-custom  :settings="{width: '100%', dropdownParent: '#kt_modal_add_vendor_detail .modal-content'}"/>
                                            </div>
                                            <div class="col-lg-12">
                                                <label class="fs-6 fw-bold form-label mt-3" v-tag="'commission_type'">
                                                    {{ $t('Commission Type') }}
                                                </label>
                                                <Select2 v-model="vendorForm.commission_type" :options="commissionTypeList" name="commission_type"  v-custom  :settings="{width: '100%', dropdownParent: '#kt_modal_add_vendor_detail .modal-content'}" :disabled="form.supplier_account_type == 3"/>
                                            </div>
                                            <div class="col-lg-12">
                                                <label class="fs-6 fw-bold form-label mt-3" v-tag="'commission_rate'">
                                                    {{ $t('Commission Rate') }} (%)
                                                </label>
                                                <CurrencyInput type="text" step="any" class="form-control text-end" name="commission_rate" v-custom v-model="vendorForm.commission_rate" @focus="$event.target.select()"/>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="card card-flush mt-4">
                                    <div class="d-grid gap-2"> 
                                        <button v-if="modalType.vendor == 'add'" type="button" class="btn btn-light-success border border-success btn-lg"
                                            @click="this.addVendorSetting()">
                                            <span class="indicator-label">{{ $t('Add') }}</span> 
                                        </button>
                                        <button v-if="modalType.vendor == 'edit'" type="button" class="btn btn-light-primary border border-primary btn-lg"
                                            @click="this.editVendorSetting()">
                                            <span class="indicator-label">{{ $t('Edit') }}</span> 
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="overlay-layer bg-dark bg-opacity-5" v-if="this.isItemSaving == true">
                            <div class="spinner-border text-primary" role="status">
                                <span class="visually-hidden">{{ $t('Loading...') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <!-- Data Import Parameters -->
        <div class="modal fade" id="kt_modal_add_import_parameters" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content"  >
                    <div class="modal-header">
                        <h2 class="modal-title fw-bolder col-md-10">
                            <span v-if="modalType.data_import == 'add'">{{ $t('Add') }}</span>
                            <span v-if="modalType.data_import == 'edit'">{{ $t('Edit') }}</span>
                            {{ $t('Data Import Parameters') }}
                        </h2>  
                        <div class="btn btn-icon btn-sm btn-active-icon-primary"
                            data-bs-dismiss="modal" aria-label="Close" ref="closeDataImportModal">
                            <span class="svg-icon svg-icon-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none">
                                    <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                        transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                    <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                        transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                                </svg>
                            </span>
                        </div>
                    </div>
                    <div class="modal-body pt-0" :class="{'overlay overlay-block': this.isItemSaving == true}">
                        <div class="row">
                            <div class="col-lg-12">                                
                                <div class="card">
                                    <div class="card-body">
                                        <form class="form">
                                            <div class="col-lg-12">
                                                <label class="fs-6 fw-bold form-label mt-3" v-tag="'data_type'">
                                                    {{ $t('Data Type') }}
                                                </label> 
                                                <Select2 v-model="dataImportForm.data_type" :options="dataTypeList" name="data_type" @select="onChangeDataType($event, true)"  v-custom  :settings="{width: '100%', dropdownParent: '#kt_modal_add_import_parameters .modal-content' }"/>
                                            </div>
                                            <div class="col-lg-12" v-if="dataImportForm.data_type != 4 && dataImportForm.data_type != 12 && dataImportForm.data_type != 24 && dataImportForm.data_type != 19 && dataImportForm.data_type != 25 && dataImportForm.data_type != 16 && dataImportForm.data_type != 23 && dataImportForm.data_type != 14 && dataImportForm.data_type != 20 && dataImportForm.data_type != 21 && dataImportForm.data_type != 28">
                                                <label class="fs-6 fw-bold form-label mt-3" v-tag="'sub_list_id'">
                                                    {{dataImportForm.sub_list_name}}
                                                </label> 
                                                <Select2 v-model="dataImportForm.sub_list_id" :options="dataTypeSubLists" name="sub_list_id"  v-custom @select="onChangeSubType($event)" :settings="{width: '100%', dropdownParent: '#kt_modal_add_import_parameters .modal-content' }"/>
                                            </div>
                                            <div class="col-lg-12" v-if="dataImportForm.data_type == 4">
                                                <label class="fs-6 fw-bold form-label mt-3" v-tag="'sub_list_id'">
                                                    {{dataImportForm.sub_list_name}}
                                                </label> 
                                                <Select2 v-model="dataImportForm.sub_list_type" :options="residenceTypeList" name="sub_list_id"  v-custom  :settings="{width: '100%', dropdownParent: '#kt_modal_add_import_parameters .modal-content' }"/>
                                            </div>                                         
                                            <div class="col-lg-12" v-if="dataImportForm.data_type == 12">
                                                <label class="fs-6 fw-bold form-label mt-3" v-tag="'sub_list_id'">
                                                    {{dataImportForm.sub_list_name}}
                                                </label> 
                                                <Select2 v-model="dataImportForm.sub_list_type" :options="notRegisteredTypeList" name="sub_list_id"  v-custom  :settings="{width: '100%', dropdownParent: '#kt_modal_add_import_parameters .modal-content' }"/>
                                            </div>
                                            <div class="col-lg-12" v-if="dataImportForm.data_type == 14">
                                                <label class="fs-6 fw-bold form-label mt-3" v-tag="'sub_list_id'">
                                                    {{dataImportForm.sub_list_name}}
                                                </label> 
                                                <Select2 v-model="dataImportForm.sub_list_type" :options="companyAccountTypeList" name="sub_list_id"  v-custom  :settings="{width: '100%', dropdownParent: '#kt_modal_add_import_parameters .modal-content' }"/>
                                            </div>
                                            <div class="col-lg-12" v-if="dataImportForm.data_type == 20">
                                                <label class="fs-6 fw-bold form-label mt-3" v-tag="'sub_list_id'">
                                                    {{dataImportForm.sub_list_name}}
                                                </label> 
                                                <Select2 v-model="dataImportForm.sub_list_type" :options="policyTypeList" name="sub_list_id"  v-custom  :settings="{width: '100%', dropdownParent: '#kt_modal_add_import_parameters .modal-content' }"/>
                                            </div>
                                            <div class="col-lg-12" v-if="dataImportForm.data_type == 21">
                                                <label class="fs-6 fw-bold form-label mt-3" v-tag="'sub_list_id'">
                                                    {{dataImportForm.sub_list_name}}
                                                </label> 
                                                <Select2 v-model="dataImportForm.sub_list_type" :options="endorsementTypeList" name="sub_list_id"  v-custom  :settings="{width: '100%', dropdownParent: '#kt_modal_add_import_parameters .modal-content' }"/>
                                            </div> 
                                            <div class="col-lg-12" v-if="dataImportForm.data_type == 19">
                                                <label class="fs-6 fw-bold form-label mt-3" v-tag="'sub_list_id'">
                                                    {{dataImportForm.sub_list_name}}
                                                </label> 
                                                <Select2 v-model="dataImportForm.sub_list_id" :options="branchList" name="sub_list_id"  v-custom  :settings="{width: '100%', dropdownParent: '#kt_modal_add_import_parameters .modal-content' }"/>
                                            </div>
                                            <div class="col-lg-12" v-if="dataImportForm.data_type == 25">
                                                <label class="fs-6 fw-bold form-label mt-3" v-tag="'sub_list_id'">
                                                    {{dataImportForm.sub_list_name}}
                                                </label> 
                                                <Select2 v-model="dataImportForm.sub_list_id" :options="campaignList" name="sub_list_id"  v-custom  :settings="{width: '100%', dropdownParent: '#kt_modal_add_import_parameters .modal-content' }"/>
                                            </div>
                                            <div class="col-lg-12" v-if="dataImportForm.data_type == 24">
                                                <label class="fs-6 fw-bold form-label mt-3" v-tag="'data_value'">
                                                    {{dataImportForm.sub_list_name}}
                                                </label> 
                                                <Select2 v-model="dataImportForm.data_value" :options="currencyList" name="data_value"  v-custom  :settings="{width: '100%', dropdownParent: '#kt_modal_add_import_parameters .modal-content' }"/>
                                            </div>
                                            <div class="col-lg-12" v-if="dataImportForm.data_type == 28">
                                                <label class="fs-6 fw-bold form-label mt-3" v-tag="'sub_list_id'">
                                                    {{dataImportForm.sub_list_name}}
                                                </label> 
                                                <Select2 v-model="dataImportForm.sub_list_type" :options="genderList" name="sub_list_id"  v-custom  :settings="{width: '100%', dropdownParent: '#kt_modal_add_import_parameters .modal-content' }"/>
                                            </div> 
                                            <div class="col-lg-12" v-if="dataImportForm.data_type == 23 || dataImportForm.data_type == 16 ">
                                                <label class="fs-6 fw-bold form-label mt-3" v-tag="'sub_list_id'">
                                                    {{dataImportForm.sub_list_name}}
                                                </label> 
                                                <Select2 v-model="dataImportForm.sub_list_id" :options="salesChannelList" name="sub_list_id"  v-custom  :settings="{width: '100%', dropdownParent: '#kt_modal_add_import_parameters .modal-content' }"/>
                                            </div>
                                            <div class="col-lg-12">
                                                <label class="fs-6 fw-bold form-label mt-3" v-tag="'data_code'">
                                                    {{ $t('Data Code') }}
                                                </label> 
                                                <input type="text" class="form-control" name="data_code" v-model="dataImportForm.data_code" v-custom>
                                            </div>
                                            <div class="col-lg-12">
                                                <label class="fs-6 fw-bold form-label mt-3" v-tag="'data_name'">
                                                    {{ $t('Data Name') }}
                                                </label>
                                                <input type="text" class="form-control" name="data_name" v-model="dataImportForm.data_name" v-custom>
                                            </div> 
                                            <div class="col-lg-12" v-if="dataImportForm.data_type != 24">
                                                <label class="fs-6 fw-bold form-label mt-3" v-tag="'data_value'">
                                                    {{ $t('Data Value') }}
                                                </label> 
                                                <input type="text" class="form-control" name="data_value" v-model="dataImportForm.data_value" v-custom>
                                            </div> 
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="card card-flush mt-4">
                                    <div class="d-grid gap-2"> 
                                        <button v-if="modalType.data_import == 'add'" type="button" class="btn btn-light-success border border-success btn-lg" @click="addImportParameterItem()">
                                            <span class="indicator-label">{{ $t('Save') }}</span> 
                                        </button>
                                        <button v-if="modalType.data_import == 'edit'" type="button" class="btn btn-light-primary border border-primary btn-lg" @click="editImportParameterItem()">
                                            <span class="indicator-label">{{ $t('Edit') }}</span> 
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="overlay-layer bg-dark bg-opacity-5" v-if="this.isItemSaving == true">
                            <div class="spinner-border text-primary" role="status">
                                <span class="visually-hidden">{{ $t('Loading...') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Coverage Parameters -->
        <div class="modal fade" id="kt_modal_coverage_parameters" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content"  >
                    <div class="modal-header">
                        <h2 class="modal-title fw-bolder col-md-10">
                            {{ $t('Producer Coverage Parameters') }}
                        </h2>  
                        <div class="btn btn-icon btn-sm btn-active-icon-primary"
                            data-bs-dismiss="modal" aria-label="Close" ref="closeParameterModal">
                            <span class="svg-icon svg-icon-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none">
                                    <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                        transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                    <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                        transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                                </svg>
                            </span>
                        </div>
                    </div>
                    <div class="modal-body p-3">
                        <CoverageParameters ref="CoverageParameters"></CoverageParameters>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios"; 
import Swal from "sweetalert2"; 
import SearchCompanyByName from '../../Accounts/Modals/SearchCompanyByName.vue';
import FindAddressByPostCode from '../../Accounts/Modals/FindAddressByPostCode.vue';
import FindAddressByAddressCode from '../../Accounts/Modals/FindAddressByAddressCode.vue';
import { DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn,  DxSearchPanel, DxScrolling, DxEditing, DxLookup, DxFormat } from 'devextreme-vue/data-grid';
import CoverageParameters from '../Supplier/Modals/CoverageParameters.vue';
import AccountGroupTab from '../../Accounts/Modals/AccountGroupTab.vue';
import InvoiceList from '../../Transaction/Invoice/List.vue';
 
export default {
  name: "SupplierUpdate",
  components:{
    SearchCompanyByName, FindAddressByPostCode, FindAddressByAddressCode, AccountGroupTab,
    DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn,  DxSearchPanel, CoverageParameters, DxScrolling, DxEditing, DxLookup, DxFormat, InvoiceList
  },
  data() {
    return { 
        columnEditorOptions: { format: "#,##0.00", precision: 2 },
        decimalFormat: "#,##0.00",
        lists: [],
        producerSettingList: [],
        coverageSettingList: [],
        vendorSettingList: [],
        userData: this.$getUser(),
        companyAccountTypeList: [
            { id: 0, text: 'Select on option'},
            { id: 1, text: 'Company'},
            { id: 2, text: 'Person'},
        ],
        duplicateRecordCheckList: [
            {id: 1, text: 'Yes'},
            {id: 0, text: 'No'},
        ],
        mainPolicyCheckList: [
            {id: 0, text: 'No'},
            {id: 1, text: 'Yes'},
        ],
        paymentRecordTypeList: [
            {id: 0, text: 'Select an option'},
            {id: 1, text: 'Commission + Taxes (Out)'},
            {id: 2, text: 'Commission + Taxes (In)'},
        ],
        countryList: [],
        cityList: [],
        addressList: [],
        customerTypeList: [], 
        salesChannelList:[],
        supplierAccountTypeList:[
            {id: 0, text: 'Supplier'},
            {id: 1, text: 'Insurance Producer'},
            {id: 2, text: 'Insurance Vendor'},
            {id: 3, text: 'Sales Vendor'},
        ],
        phoneMaskList: [],
        isProgressing: false,
        currencyList: [],
        form: {
            id: null,
            name: null,
            company_account_type: 2,
            company_type_id: null,
            supplier_company_type_id: null,
            account_type: null,
            account_id: null,
            qr_code: null,
            notes: null, 
            account_number: null,
            personal_id: null,
            contact_name: null,
            email: null,
            mobile_phone: null,
            phone: null,
            postcode: null,
            address: null,
            address_id: '-1',
            country: '-1',
            city: null, 
            addressSelection: null,
            vat_number: null,
            payment_value: null,
            isVATNumberExist: 'false',
            accounting_plan: null,
            customer_type_id: '-1',
            sales_channel_id: '-1',
            supplier_sales_channel_id: null,
            phone_country_code: null,
            land_phone_country_code: null,
            district: null,
            x_coordinate: 0,
            y_coordinate: 0,
            uprn: null,
            udprn: null,
            supplier_account_type: 0,     
            tax_method: 1,
            sub_tax_1_method: 1,
            sub_tax_2_method: 1,
            sub_tax_3_method: 1, 
            vendor_commission_account: '-1',
            policy_commission_account: '-1',
            policy_taxes_account: '-1',
            payment_record_type: 0,
            is_duplicate_record_check_active: 1,
            is_main_policy_check_active: 1,
            policy_import_design: '-1',
            policy_import_design_2: '-1',
            collection_import_design: '-1',
            data_import_design: '-1',
            dll_template_id: '-1',
            branch_id: '-1',
            is_main_group: 0,
            policy_campaign_id:'-1',
            policy_source_code_id:'-1',
            policy_source_code_2_id:'-1',
        },
        isViewAccountId: false,
        activeTab: 'Account',
        menuSelection: '/SupplierHelppage',
        iconView : false,
        mainBranchList: [],
        coverageMainList: [],
        policyBranchList: [],
        campaignList: [],
        policyGroupList: [{ id: '-1', text: 'Select an option'}],
        dataImportDesignList: [{ id: '-1', text: 'Select an option'}],
        coverageDetailList: [{ id: '-1', text: 'Select an option'}],
        producerPolicyTypesList: [{ id: '-1', text: 'Select an option'}],
        commissionTypeList: [
            { id: 1, text: 'Policy Commission'},
            { id: 2, text: 'Net Amount'},
            { id: 3, text: 'Total Amount'},
        ],
        producerForm: {
            main_branch_id: '-1',
            policy_branch_id: '-1',
            policy_group_id: '-1',
            producer_policy_code: null,
            producer_policy_name: null,
            search_title: null
        },
        coverageForm: {
            coverage_main_id: '-1',
            coverage_detail_id: '-1',
            producer_coverage_code: null,
            producer_coverage_name: null,
            search_title: null,
            producer_policy_type_id: '-1',
            isSelectedAll: false,
            parameter_group: '-1'
        },
        vendorForm: {
            main_branch_id: '-1',
            policy_branch_id: '-1',
            commission_type: 1,
            commission_rate: 0.00
        },
        isItemSaving: false,
        modalType: {
            vendor: 'add',
            producer: 'add',
            coverage: 'add',
            data_import: 'add',
            coverage_parameter: 'add'
        },
        taxMethodList: [{id: 1, text: 'Producer'}, {id: 2, text: 'Insurer'}],
        producerSettingPolicyAccounts: [],
        vendorCommissionAccounts: [],
        dllTemplateList: [],
        activeProducerTab: 'GeneralSettings',        
        dataImportSettingsList: [],      
        dataImportForm: {
            data_type: 0,
            data_code: null,
            data_name: null,
            data_value: null,
            sub_list_id: '-1',
            sub_list_type: 0,
            sub_list_name: 'Data Type List Name'
        },
        policyTypeList: [
            { id: 0, text: 'Select an option'},
            { id: 1, text: 'Main Policy'},
            { id: 2, text: 'Endorsement Policy'}
        ],
        dataTypeList:[
            {id: 0, text: 'Select an option', list_type: 0},
            {id: 19, text: 'Branch', list_type: 0 },
            {id: 29, text: 'Coverage Main', list_type: 3 },
            {id: 30, text: 'Coverage Detail', list_type: 4 },
            {id: 24, text: 'Currency', list_type: 0},
            {id: 15, text: 'Customer Type', list_type: 14 },
            {id: 13, text: 'Endorsement Detail', list_type: 6},
            {id: 21, text: 'Endorsement Type', list_type: 0 },
            {id: 1, text: 'Factor Type Detail', list_type: 5},
            {id: 28, text: 'Gender', list_type: 0 },
            {id: 16, text: 'Insurer Sales Channel', list_type: 0 },
            {id: 14, text: 'Member Type', list_type: 0 },
            {id: 17, text: 'Payment Method', list_type: 11 },
            {id: 2, text: 'Person Position Type', list_type: 19},
            {id: 31, text: 'Person Type Detail', list_type: 5 },
            {id: 20, text: 'Policy Record Type', list_type: 0 },
            {id: 23, text: 'Policy Sales Channel', list_type: 0 },
            {id: 3, text: 'Property Construction Type', list_type: 17},
            {id: 4, text: 'Property Residence Type', list_type: 0},
            {id: 5, text: 'Property Type', list_type: 20},
            {id: 6, text: 'Property Usage Detail', list_type: 21},
            {id: 7, text: 'Property Usage Type', list_type: 22},
            {id: 18, text: 'Shipping Type', list_type: 12 },
            {id: 25, text: 'Sales Role/Campaign', list_type: 0 },
            {id: 26, text: 'Source Code 1', list_type: 7 },
            {id: 27, text: 'Source Code 2', list_type: 8 },
            {id: 8, text: 'Vehicle Group', list_type: 9},
            {id: 12, text: 'Vehicle Not Registered', list_type: 0},
            {id: 10, text: 'Vehicle Usage Type', list_type: 11},
            {id: 11, text: 'Vehicle Usage Detail', list_type: 12},
            {id: 9, text: 'Vehicle Type', list_type: 10}
        ],
        endorsementTypeList: [
            { id: 0, text: 'Select an option'},
            { id: 1, text: 'Main Policy'},
            { id: 2, text: 'Coverage Addition'},
            { id: 3, text: 'Coverage Reduction'},
            { id: 4, text: 'Coverage Cancellation'},
            { id: 5, text: 'Change of Content'},
        ],
        residenceTypeList: [
            {id: 0, text: 'Select an option'},
            {id: 1, text: 'Tenant'},
            {id: 2, text: 'Owner'},
        ],
        notRegisteredTypeList: [
            {id: 0, text: 'Not Registered'},
        ],
        activeCoverageTab: 'Single',
        coverageParametersList: [],
        parameterGroups: [],
        branchList: [],
        dataTypeSubLists: [{id: '-1', text: 'Select an option'}],
        accountInfo: {},        
        sourceCodeList: [],
        sourceCode2List: [],
        productGroupList: [],
        productTypeList: [],
        productDetailList: [],
        genderList: [
            {id: 0 , text: 'Select an option'},
            {id: 1, text: 'Male'},
            {id: 2, text: 'Female'},
            {id: 3, text: 'Other'},
        ],
    };
  },
  computed: {
		isValidEmail() {
			return (this.form.email == null || this.form.email == '' || this.form.email == undefined) ? null : /^[^@]+@\w+(\.\w+)+\w$/.test(this.form.email);
		},
        isButtonDisabled() {
            return (value)=>{
                return value.find(item => item.isSelected == true );
            }
        },
        policyCommissionAccounts() {
            return this.producerSettingPolicyAccounts.filter(p => { 
                return p.policy_account_type == 1 || p.id == '-1';
            });
        },
        policyTaxesAccounts() {
            return this.producerSettingPolicyAccounts.filter(p => { 
                return p.policy_account_type == 3 || p.id == '-1';
            });
        },
        companyAccountTypeFilteredList() {
            return this.companyAccountTypeList.filter(p => { 
                return p.id !== 0;
            });
        },
        policyCollectionDesignList() {
            return this.dataImportDesignList.filter(p => { 
                return p.category_id == 5 || p.id == '-1';
            });
        }
  },
  methods: {
    getData() {
        this.$setSessionStorage('tmp_session_id', null);
        this.addressList = [];
        this.addressList.unshift({ id: '-1', text: 'Select an option'});

        this.$Progress.start();
        const params = {
            id: this.$getSessionStorage('record_id'),
            session_id: this.$getUser().session_id
        };
        axios.post('api/CompanyAccounts/GetAccountById', params, {'Content-Type': 'application/json'})
        .then((response) => { 
            this.form.id = response.data.id;
            this.form.name = this.$unlock(response.data.name);
            this.form.account_id = this.$unlock(response.data.account_id);
            this.form.qr_code = this.$unlock(response.data.qr_code);
            this.form.notes = response.data.notes;
            this.form.account_type = response.data.account_type;
            this.form.account_number = this.$unlock(response.data.supplier_account_number);
            this.form.personal_id = this.$unlock(response.data.supplier_account_number);
            this.form.contact_name = this.$unlock(response.data.supplier_contact_name);
            this.form.email = this.$unlock(response.data.supplier_email);
            this.form.mobile_phone = this.$unlock(response.data.supplier_mobile_phone);
            this.form.phone = this.$unlock(response.data.supplier_phone);
            this.form.postcode = this.$unlock(response.data.supplier_postcode);
            this.form.address = this.$unlock(response.data.supplier_address);
            this.form.country = this.$unlock(response.data.supplier_country);
            if(this.form.country == '' || this.form.country == null) this.form.country = '-1';
            this.form.city = this.$unlock(response.data.supplier_city);
            this.form.district = this.$unlock(response.data.supplier_district);
            this.form.x_coordinate = this.$unlock(response.data.supplier_x_coordinate);
            this.form.y_coordinate = this.$unlock(response.data.supplier_y_coordinate);
            this.form.x_coordinate = (this.form.x_coordinate == null || this.form.x_coordinate == '') ? 0 : Math.abs(parseFloat(this.form.x_coordinate.replace(',', '.')));
            this.form.y_coordinate = (this.form.y_coordinate == null || this.form.y_coordinate == '') ? 0 : Math.abs(parseFloat(this.form.y_coordinate.replace(',', '.')));
            this.form.uprn = this.$unlock(response.data.supplier_uprn);
            this.form.udprn = this.$unlock(response.data.supplier_udprn);
            this.form.company_account_type = response.data.supplier_company_account_type;
            this.form.vat_number = this.$unlock(response.data.supplier_vat_number);
            this.form.payment_value = response.data.supplier_payment_value;
            this.form.phone_country_code = this.$unlock(response.data.supplier_phone_country_code);
            this.form.land_phone_country_code = this.$unlock(response.data.supplier_land_phone_country_code);
            this.form.sales_channel_id = (response.data.supplier_sales_channel_id == '' || response.data.supplier_sales_channel_id == null) ? '-1': response.data.supplier_sales_channel_id;
            this.form.customer_type_id = (response.data.supplier_customer_type_id == '' || response.data.supplier_customer_type_id == null) ? '-1': response.data.supplier_customer_type_id;
            if(this.form.vat_number !== null && this.form.vat_number !== ''){
                this.form.isVATNumberExist =  'true';
            } 
            this.form.supplier_account_type = response.data.supplier_account_type;
            this.form.dll_template_id = response.data.supplier_dll_template_id == null || response.data.supplier_dll_template_id == '' ? -1 : response.data.supplier_dll_template_id;
            this.form.tax_method = response.data.supplier_tax_method;
            this.form.sub_tax_1_method = response.data.supplier_sub_tax_1_method;
            this.form.sub_tax_2_method = response.data.supplier_sub_tax_2_method;
            this.form.sub_tax_3_method = response.data.supplier_sub_tax_3_method;
            this.form.policy_commission_account = (response.data.supplier_policy_commission_account == '' || response.data.supplier_policy_commission_account == null) ? '-1': response.data.supplier_policy_commission_account;
            this.form.policy_taxes_account = (response.data.supplier_policy_taxes_account == '' || response.data.supplier_policy_taxes_account == null) ? '-1': response.data.supplier_policy_taxes_account;
            this.form.vendor_commission_account = (response.data.supplier_vendor_commission_account == '' || response.data.supplier_vendor_commission_account == null) ? '-1': response.data.supplier_vendor_commission_account;
            this.form.payment_record_type = response.data.supplier_payment_record_type;
            this.form.is_duplicate_record_check_active = response.data.supplier_is_duplicate_record_check_active;
            this.form.is_main_policy_check_active = response.data.supplier_is_main_policy_check_active;            
            this.form.policy_import_design = (response.data.policy_import_design == '' || response.data.policy_import_design == null) ? '-1': response.data.policy_import_design;
            this.form.policy_import_design_2 = (response.data.policy_import_design_2 == '' || response.data.policy_import_design_2 == null) ? '-1': response.data.policy_import_design_2;
            this.form.collection_import_design = (response.data.collection_import_design == '' || response.data.collection_import_design == null) ? '-1': response.data.collection_import_design;
            this.form.branch_id = (response.data.branch_id == '' || response.data.branch_id == null) ? '-1': response.data.branch_id;
            this.form.policy_campaign_id = (response.data.supplier_policy_campaign_id == '' || response.data.supplier_policy_campaign_id == null) ? '-1' : response.data.supplier_policy_campaign_id;
            this.form.policy_source_code_id = (response.data.supplier_policy_source_code_id == '' || response.data.supplier_policy_source_code_id == null) ? '-1' : response.data.supplier_policy_source_code_id;
            this.form.policy_source_code_2_id = (response.data.supplier_policy_source_code_2_id == '' || response.data.supplier_policy_source_code_2_id == null) ? '-1' : response.data.supplier_policy_source_code_2_id;
            this.form.is_main_group = response.data.is_main_group;
            this.accountInfo = {
                id: this.form.id,
                account_type: this.form.account_type,
                is_main_group: this.form.is_main_group
            };
            this.getLists();
        })
        .catch(function (error) {
            console.log(error);
            this.$swal("Failed", "Data could not be saved..Please refresh the page and try again..!");   
        });  
    },
    getLists() {
        this.$Progress.start(); 
        const parameters = {
            session_id: this.$getUser().session_id,
            list_name: ["account_setting", "sales_channel", "country", "branch", "campaign"],
            account_type: 2, // yalnızca account settings listesi çekilecek ise gerekli
            country_code: this.$getUser().company_country_code, // yalnızca Company Types listesi çekilecek ise gerekli
            list_type: 14, // Customer Type: 14            
            get_access_status: 0,
            type_id: 2,
            category: 0
        }; 
        const parametersTwo = { 
                session_id: this.$getUser().session_id,
                list_type: 7,
                main_branch_id: null,
                policy_branch_id: null
        };  
        const parametersThree = { 
            session_id: this.$getUser().session_id,
            list_type: 8,
            main_branch_id: null,
            policy_branch_id: null
        };         
        const requestOne =  axios.post('/api/Helper/GeneralLists', parameters, {'Content-Type': 'application/json'});
        const requestTwo =  axios.post('/api/Helper/GetLists', parameters, {'Content-Type': 'application/json'}); 
        const requestThree =  axios.post('/api/Helper/GetPhoneMasks', parameters, {'Content-Type': 'application/json'}); 
        const requestFour =  axios.post('/api/DLLTemplates/GetDLLTemplate', parameters, {'Content-Type': 'application/json'});
        const requestFive =  axios.post('/api/DLLTemplates/GetDataImportTemplates', parameters, {'Content-Type': 'application/json'});
        const requestSix =  axios.post('/api/Helper/GetPolicyListBySubLists', parametersTwo, {'Content-Type': 'application/json'});
        const requestSeven =  axios.post('/api/Helper/GetPolicyListBySubLists', parametersThree, {'Content-Type': 'application/json'});

        axios.all([requestOne, requestTwo, requestThree, requestFour, requestFive, requestSix, requestSeven]).then(axios.spread((...responses) => {
                this.salesChannelList = responses[0].data.sales_channel;
                this.countryList = responses[0].data.country; 
                this.branchList = responses[0].data.branch;
                this.campaignList = responses[0].data.campaign; 
                this.customerTypeList = responses[1].data;
                this.phoneMaskList = responses[2].data;
                this.sourceCodeList = responses[5].data;
                this.sourceCode2List = responses[6].data;
                if (responses[0].data.account_setting.is_default == false) {
                    this.isViewAccountId = true;
                }
                this.salesChannelList.unshift({ id: '-1', text: 'Select an option'}); 
                this.countryList.unshift({ id: '-1', text: 'Select an option'});  
                this.customerTypeList.unshift({ id: '-1', text: 'Select an option'}); 

                this.dllTemplateList = responses[3].data;
                this.dllTemplateList.unshift({ id: '-1', text: 'Select an option'}); 

                this.policyImportDesignList = responses[4].data;
                this.policyImportDesignList.unshift({ id: '-1', text: 'Select an option'}); 

                this.campaignList.unshift({ id: '-1', text: 'Select an option'});
                this.sourceCodeList.unshift({ id: '-1', text: 'Select an option'});
                this.sourceCode2List.unshift({ id: '-1', text: 'Select an option'});

                this.$Progress.finish();
            })
          )
        .catch(function (error) {
            console.log(error);
            this.$Progress.finish();
        });
    },
    getProducerSettings() {
        const parameters = {
            account_id: this.$getSessionStorage('record_id'),
            session_id: this.$getUser().session_id,
            policy_account_type: -1
        };        
        const requestOne =  axios.post('/api/SupplierAccount/GetProducerPolicyTypes', parameters, {'Content-Type': 'application/json'});
        const requestTwo =  axios.post('/api/SupplierAccount/GetProducerCoverageTypes', parameters, {'Content-Type': 'application/json'}); 
        const requestThree =  axios.post('/api/SupplierAccount/GetOtherPolicyAccounts', parameters, {'Content-Type': 'application/json'}); 
        const requestFour =  axios.post('/api/SupplierAccount/GetDataImportTemplates', parameters, {'Content-Type': 'application/json'}); 
        
        axios.all([requestOne, requestTwo, requestThree, requestFour]).then(axios.spread((...responses) => {            
            this.producerSettingList = responses[0].data;
            this.coverageSettingList = responses[1].data;
            this.producerSettingPolicyAccounts = responses[2].data;
            this.dataImportDesignList = responses[3].data;
            this.producerSettingPolicyAccounts.unshift({ id: '-1', text: 'Select an option'}); 
            this.dataImportDesignList.unshift({ id: '-1', text: 'Select an option'}); 

            if(this.form.policy_commission_account == null || this.form.policy_commission_account == '' || this.form.policy_commission_account == '-1') {                
                this.producerSettingPolicyAccounts.forEach((value) => {
                    if(value.is_default == 1 && value.policy_account_type == 1){
                        this.form.policy_commission_account = value.id;
                    }
                    if(value.is_default == 1 && value.policy_account_type == 3){
                        this.form.policy_taxes_account = value.id;
                    }
                });
            }
        }))
        .catch(errors => { 
            console.error(errors); 
        });
    },
    getVendorSettings() {
        const parameters = {
            account_id: this.$getSessionStorage('record_id'),
            session_id: this.$getUser().session_id,
            policy_account_type: 2
        };        
        const requestOne =  axios.post('/api/SupplierAccount/GetSupplierVendorSettings', parameters, {'Content-Type': 'application/json'});
        const requestTwo =  axios.post('/api/SupplierAccount/GetOtherPolicyAccounts', parameters, {'Content-Type': 'application/json'}); 
        
        axios.all([requestOne, requestTwo]).then(axios.spread((...responses) => {            
            this.vendorSettingList = responses[0].data;
            if(this.vendorSettingList.length > 0){
                this.$setSessionStorage('tmp_session_id', responses[0].data[0].tmp_session_id);
            }
            this.vendorCommissionAccounts = responses[1].data;
            this.vendorCommissionAccounts.unshift({ id: '-1', text: 'Select an option'}); 
            this.vendorCommissionAccounts.forEach((value) => {
                if(value.is_default == 1 && value.policy_account_type == 2){
                    this.form.vendor_commission_account = value.id;
                }
            });
        }))
        .catch(errors => { 
            console.error(errors); 
        }); 
    },
    async helpPageViewIcon()
    {
        const queryparameter = {
            session_id : this.$getUser().session_id,
            menu_selection : '/SupplierHelppage'
        };
        axios.post('api/HelpPage/HelpPagesContent', queryparameter, {'Content-Type': 'application/json'})
        .then((response) => { 
            if(!response.data.error){ 
                this.iconView = response.data;
            }
        })
    }, 
    saveData() {
      this.$checkSelect2Validation('poliform');
      if(!document.forms['poliform'].reportValidity()){
        return;
      }
      this.isProgressing = true;
      const params = this.form;    
      const that = this;
      params.session_id = this.$getUser().session_id; 
      if(params.country =='-1'){ params.country = null;}
      if(params.branch_id =='-1'){ params.branch_id = null;}
      if(params.sales_channel_id =='-1'){ params.sales_channel_id = null;}
      if(params.customer_type_id == '-1'){ params.customer_type_id = null }
      if(params.policy_commission_account == '-1'){ params.policy_commission_account = null }
      if(params.policy_taxes_account == '-1'){ params.policy_taxes_account = null }
      if(params.vendor_commission_account == '-1'){ params.vendor_commission_account = null }
      if(params.policy_import_design == '-1'){ params.policy_import_design = null }
      if(params.policy_import_design_2 == '-1'){ params.policy_import_design_2 = null }
      if(params.collection_import_design == '-1'){ params.collection_import_design = null }
      if(params.company_account_type == 2) {params.account_number = params.personal_id;}

      if(params.policy_campaign_id == '-1') {params.policy_campaign_id = null }
      if(params.policy_source_code_id == '-1') {params.policy_source_code_id = null }
      if(params.policy_source_code_2_id == '-1') {params.policy_source_code_2_id = null }
      params.tmp_session_id = this.$getSessionStorage('tmp_session_id');
      params.dll_template_id = params.dll_template_id == -1 ? null : params.dll_template_id;
      axios.post('api/CompanyAccounts/UpdateAccount', params, {'Content-Type': 'application/json'})
        .then((response) => { 
            if(response.data != null && response.data.length == 36){  
                this.$swal("Success", "Data saved succesfully..!", 'success');  
            }
            if(this.form.country == null) {this.form.country = '-1';}
            if(this.form.branch_id == null) {this.form.branch_id = '-1';}
            if(this.form.sales_channel_id == null) {this.form.sales_channel_id = '-1';}
            if(this.form.customer_type_id == null) {this.form.customer_type_id = '-1';}
            if(this.form.policy_commission_account == null) {this.form.policy_commission_account = '-1';}
            if(this.form.vendor_commission_account == null) {this.form.vendor_commission_account = '-1';}
            if(this.form.policy_taxes_account == null) {this.form.policy_taxes_account = '-1';}
            if(this.form.policy_import_design == null) {this.form.policy_import_design = '-1';}
            if(this.form.policy_import_design_2 == null) {this.form.policy_import_design_2 = '-1';}
            if(this.form.collection_import_design == null) {this.form.collection_import_design = '-1';}

            if(this.form.policy_campaign_id == null) {this.form.policy_campaign_id = '-1';}
            if(this.form.policy_source_code_id == null) {this.form.policy_source_code_id = '-1';}
            if(this.form.policy_source_code_2_id == null) {this.form.policy_source_code_2_id = '-1';}
            
            if(this.activeTab == 'ProducerSettings') { this.getProducerSettings(); } 
            if(this.activeTab == 'InsuranceVendorSettings') { this.getVendorSettings(); }
        })
        .catch(function () { 
            new Swal( { icon: 'error', title: 'Error...', text: 'Data could not be saved..Please refresh the page and try again..!' });
        })
        .finally(function () { 
            setTimeout(() => {
                that.isProgressing = false;
            }, 500);
        }); 
    },
    deleteItem(){
        this.$swal.fire({
            title: 'Are you sure you want to delete this data?',
            confirmButtonColor: '#f1416c',
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: 'Yes',
            icon: 'warning'
          }).then((result) => { 
            if (result.isConfirmed) {
            const params = {
                session_id: this.$getUser().session_id,
                id: this.form.id
            }; 
            axios.post('api/CompanyAccounts/DeleteAccount', params, {'Content-Type': 'application/json'})
            .then((response) => { 
                if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){  
                    this.$router.push({ name: 'SupplierList' });
                }
            })
            .catch(function () { 
                new Swal( { icon: 'error', title: 'Error...', text: 'Data could not be deleted..Please refresh the page and try again..!' });   
            });  
            }
        })   
    },
    activateDocumentsSection(){
        this.activeTab = 'Documents'; 
    },
    onChangeCountry(event) {
        this.form.phone_country_code = event.phone_country_code;
        this.form.land_phone_country_code = event.phone_country_code;
    },
    onSearchCompanyByNo(selectedCompanyNumber){
        if(selectedCompanyNumber) {
            this.form.account_number = selectedCompanyNumber;
        }
        const parameters = {
            CompanyNumber: this.form.account_number,
            SessionId: this.$getUser().session_id,
            SourceType: 2
        }
        const that = this;
        axios.post('api/GetCompanyAccounts/SearchByNo', parameters, {'Content-Type': 'application/json'})
        .then((response) => { 
            if(response.data.error == null || response.data.error == '' || response.data.error == undefined){
                this.form.name = response.data.name;
                this.form.account_number = response.data.number;
                this.form.contact_name = response.data.contactName;
                this.form.postcode = response.data.postalCode;
                this.form.address = response.data.addressLine2 == null ? response.data.addressLine1 : response.data.addressLine1 + ' '+ response.data.addressLine2; 
                //this.form.country = response.data.country; Çıktı England şeklinde?
                this.form.city = response.data.locality;
                this.companySearchResult = [];

                if(this.form.account_number !== null && this.form.account_number !== '' && this.form.account_number !== undefined) {
                    this.form.company_account_type = 1;
                }
            }
        })
        .catch(function (error) {
            console.log(error);
            that.$swal("Error", "No records found..!", 'error');
        });
    },
    onSearchCompanyByName(){
        this.$refs.SearchCompanyByName.getData();
    },
    onFindAddressByPostCode(){  
        this.$refs.FindAddressByPostCode.getData();
    },
    onFindAddressByAddressCode(){  
        this.$refs.FindAddressByAddressCode.getData();
    },
    onSelectAddressSearchResult(item){
        this.form.address = item.ADDRESS;
        this.form.city = item.POST_TOWN;
        this.form.district = item.LOCAL_CUSTODIAN_CODE_DESCRIPTION;
        this.form.x_coordinate = item.X_COORDINATE;
        this.form.y_coordinate = item.Y_COORDINATE;
        this.form.uprn = item.UPRN;
        this.form.udprn = item.UDPRN;
        this.form.postcode = item.POSTCODE;
    },
    getParentLists(listType, parent_id, isChanged) {
        if(listType == 2 && isChanged) {
            this.producerForm.policy_branch_id = '-1';
            this.vendorForm.policy_branch_id = '-1';
        }
        if(listType == 4 && isChanged) this.coverageForm.coverage_detail_id = '-1';

        const params = {
            session_id: this.$getUser().session_id,
            list_type: listType,
            parent_id: parent_id
        };
        axios.post("/api/Helper/GetPolicyParentList", params, {'Content-Type': 'application/json'}).then((response) => { 
            if(listType == 2) {
                this.policyBranchList = response.data;
                this.policyBranchList.unshift({ id: '-1', text: (this.form.supplier_account_type == 2 ? 'ALL' : 'Select an option')});
                this.getPolicyGroupList();
            }
            if(listType == 4) {
                this.coverageDetailList = response.data;
                this.coverageDetailList.unshift({ id: '-1', text: 'Select an option'});
                this.coverageDetailList.forEach(element => {
                    if(element.id !== '-1') {
                        element.text = element.code + ' - ' + element.text;
                    }
                });            
            }
        })
        .catch(function (error) {
            console.log(error);
        });
    },
    getPolicyGroupList(isChanged) {
        if(isChanged) this.producerForm.policy_group_id = '-1';
        const params = {
            session_id: this.$getUser().session_id,
            list_type: 14,
            parent_id: this.producerForm.policy_branch_id
        };
        axios.post("/api/Helper/GetPolicyParentList", params, {'Content-Type': 'application/json'}).then((response) => { 
            this.policyGroupList = response.data;
            this.policyGroupList.unshift({ id: '-1', text: 'Select an option'});
        })
        .catch(function (error) {
            console.log(error);
        });
    },
    onSelectItem(array, item) { 
        array.forEach((value) => {
            if (item.id !== value.id) { 
                value.isSelected = false;
            }
        });
    },

    // Producer CRUD
    openProducerModal(modalType) {
        this.policyBranchList = [{ id: '-1', text: 'Select an option'}],
        this.modalType['producer'] = modalType;
        this.producerForm = {
            id: null,
            main_branch_id: '-1',
            policy_branch_id: '-1',
            policy_group_id: '-1',
            producer_policy_code: null,
            producer_policy_name: null,
            search_title: null
        };
        const parameters = {
            session_id: this.$getUser().session_id,
            list_types: [
                {
                    list_type: 1,
                    list_name: "main_branch"
                }
            ],
        };
        const requestOne =  axios.post('/api/Helper/GetMultiPolicyLists', parameters, {'Content-Type': 'application/json'});
        axios.all([requestOne]).then(axios.spread((...responses) => {
            this.mainBranchList= responses[0].data.main_branch;
            this.mainBranchList.unshift({ id: '-1', text: 'Select an option'});
        }))
        .catch(errors => { 
            console.error(errors); 
        });
    },
    addProducerSetting(){
        if (this.producerForm.policy_branch_id == '-1' || this.producerForm.policy_branch_id == null) {
            this.$swal("Failed", " Policy Branch must be selected..!", 'warning' );
            return;
        }
        this.isItemSaving = true;
        const parameters = {
            session_id: this.$getUser().session_id,
            parent_account_id: this.$getSessionStorage('record_id'),
            main_branch_id: this.producerForm.main_branch_id == '-1' ? null : this.producerForm.main_branch_id,
            policy_branch_id: this.producerForm.policy_branch_id == '-1' ? null : this.producerForm.policy_branch_id,
            producer_policy_code: this.producerForm.producer_policy_code,
            producer_policy_name: this.producerForm.producer_policy_name,
            policy_group_id: this.producerForm.policy_group_id == '-1' ? null : this.producerForm.policy_group_id,
            search_title: this.producerForm.search_title
        };
        const that = this;
        axios.post('/api/SupplierAccount/AddProducerItem', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == '' || response.data.error == undefined){
                this.producerSettingList = response.data;
                this.$refs.closeProducerModal.click();
            }            
            setTimeout(() => {
                that.isItemSaving = false;
            }, 500);
        }) 
        .catch(function () {
            setTimeout(() => {
                that.isItemSaving = false;
            }, 500);
        })
        .finally(function () { 
            setTimeout(() => {
                that.isItemSaving = false;
            }, 500);
        });
    },
    editProducerSetting(){
        if (this.producerForm.policy_branch_id == '-1' || this.producerForm.policy_branch_id == null) {
            this.$swal("Failed", " Policy Branch must be selected..!", 'warning' );
            return;
        }
        this.isItemSaving = true;
        const parameters = {
            id: this.producerForm.id,
            session_id: this.$getUser().session_id,
            parent_account_id: this.$getSessionStorage('record_id'),
            main_branch_id: this.producerForm.main_branch_id == '-1' ? null : this.producerForm.main_branch_id,
            policy_branch_id: this.producerForm.policy_branch_id == '-1' ? null : this.producerForm.policy_branch_id,
            policy_group_id: this.producerForm.policy_group_id == '-1' ? null : this.producerForm.policy_group_id,
            producer_policy_code: this.producerForm.producer_policy_code,
            producer_policy_name: this.producerForm.producer_policy_name,
            search_title: this.producerForm.search_title
        };
        const that = this;
        axios.post('/api/SupplierAccount/EditProducerItem', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == '' || response.data.error == undefined){
                this.producerSettingList = response.data;
                this.$refs.closeProducerModal.click();
            }            
            setTimeout(() => {
                that.isItemSaving = false;
            }, 500);
        }) 
        .catch(function () {
            setTimeout(() => {
                that.isItemSaving = false;
            }, 500);
        })
        .finally(function () { 
            setTimeout(() => {
                that.isItemSaving = false;
            }, 500);
        });
    },
    deleteProducerItem(){
        this.producerSettingList.forEach((item) => {
            if (item.isSelected) {
                this.producerForm.id = item.id;
            }
        });
        this.$swal.fire({
            title: "Are you sure you want to remove this item?",
            confirmButtonColor: "#f1416c",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "DELETE",
            icon: 'info'
        })
        .then((result) => {
            if (result.isConfirmed) {
                const parameters = {
                    session_id: this.$getUser().session_id,
                    parent_account_id: this.$getSessionStorage('record_id'),
                    id: this.producerForm.id,
                    is_delete_all: 0
                }; 
                axios.post('/api/SupplierAccount/DeleteProducerItem', parameters, {'Content-Type': 'application/json'})
                .then((response) => {
                    if(response.data.error == null || response.data.error == '' || response.data.error == undefined){
                        this.producerSettingList = response.data;
                    } 
                }) 
                .catch(function (error) {
                    console.log(error);
                    this.Progress.finish();
                });
            }
        });
    },
    deleteAllProducers(){ 
        this.$swal.fire({
            title: "Are you sure you want to remove all items?",
            confirmButtonColor: "#f1416c",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "DELETE",
            icon: 'info'
        })
        .then((result) => {
            if (result.isConfirmed) { 
                const parameters = {
                    session_id: this.$getUser().session_id,
                    parent_account_id: this.$getSessionStorage('record_id'),
                    is_delete_all: 1,
                    id: null
                }; 
                axios.post('/api/SupplierAccount/DeleteProducerItem', parameters, {'Content-Type': 'application/json'})
                .then((response) => {
                    if(response.data.error == null || response.data.error == '' || response.data.error == undefined){
                        this.producerSettingList = [];
                    } 
                }) 
                .catch(function (error) {
                    console.log(error);
                    this.Progress.finish();
                });
            }
        });
    },

    // Coverage CRUD
    openCoverageModal(modalType) {
        this.modalType['coverage'] = modalType;
        this.coverageForm = {
            id: null,
            coverage_main_id: '-1',
            coverage_detail_id: '-1',
            producer_coverage_code: null,
            producer_coverage_name: null,
            search_title: null,
            producer_policy_type_id: '-1',
            parameter_group: '-1',
            isSelectedAll: false,
        };
        this.activeCoverageTab = 'Single';
        this.parameterGroups = [];
        this.coverageParametersList = [];

        if(!this.producerSettingList.find(item => item.id == '-1')) {            
            this.producerSettingList.unshift({ id: '-1', text: 'Select an option'});
        }
        if(modalType == 'edit'){
            this.coverageSettingList.forEach(element => {
                if(element.isSelected) {
                    this.coverageForm = {
                        id: element.id,
                        coverage_main_id: (element.coverage_main_id == null || element.coverage_main_id == '') ? '-1' : element.coverage_main_id,
                        coverage_detail_id: (element.coverage_detail_id == null || element.coverage_detail_id == '') ? '-1' : element.coverage_detail_id,
                        producer_coverage_code: element.producer_coverage_code,
                        producer_coverage_name: element.producer_coverage_name,
                        producer_policy_type_id: (element.producer_policy_type_id == null || element.producer_policy_type_id == '') ? '-1' : element.producer_policy_type_id,
                        search_title: element.search_title,
                        parameter_group: '-1',
                        isSelectedAll: false
                    };
                }
            });
            this.getParentLists(4, this.coverageForm.coverage_main_id);
        }
        const parameters = {
            session_id: this.$getUser().session_id,
            list_types: [
                {
                    list_type: 3,
                    list_name: "coverage_main"
                }
            ],
        };
        const requestOne =  axios.post('/api/Helper/GetMultiPolicyLists', parameters, {'Content-Type': 'application/json'});
        axios.all([requestOne]).then(axios.spread((...responses) => {
            this.coverageMainList= responses[0].data.coverage_main;
            this.coverageMainList.unshift({ id: '-1', text: 'Select an option'});
            this.coverageMainList.forEach(element => {
                if(element.id !== '-1') {
                    element.text = element.code + ' - ' + element.text;
                }
            });
        }))
        .catch(errors => { 
            console.error(errors); 
        });
    },
    addCoverageSetting(){
        if (this.coverageForm.coverage_detail_id == '-1' || this.coverageForm.coverage_detail_id == '-1' || this.coverageForm.coverage_detail_id == null) {
            this.$swal("Failed", " Coverage Main and Coverage Detail must be selected..!", 'warning' );
            return;
        }
        if (this.coverageForm.producer_policy_type_id == '-1' || this.coverageForm.producer_policy_type_id == null) {
            this.$swal("Failed", " Producer Policy Type must be selected..!", 'warning' );
            return;
        }
        this.isItemSaving = true;
        const parameters = {
            session_id: this.$getUser().session_id,
            parent_account_id: this.$getSessionStorage('record_id'),
            coverage_main_id: this.coverageForm.coverage_main_id == '-1' ? null : this.coverageForm.coverage_main_id,
            coverage_detail_id: this.coverageForm.coverage_detail_id == '-1' ? null : this.coverageForm.coverage_detail_id,
            producer_coverage_code: this.coverageForm.producer_coverage_code,
            producer_coverage_name: this.coverageForm.producer_coverage_name,
            producer_policy_type_id: this.coverageForm.producer_policy_type_id == '-1' ? null : this.coverageForm.producer_policy_type_id,
            search_title: this.coverageForm.search_title,
        };
        const that = this;
        axios.post('/api/SupplierAccount/AddCoverageItem', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == '' || response.data.error == undefined){
                this.coverageSettingList = response.data;
                this.$refs.closeCoverageModal.click();
            }            
            setTimeout(() => {
                that.isItemSaving = false;
            }, 500);
        }) 
        .catch(function () {
            setTimeout(() => {
                that.isItemSaving = false;
            }, 500);
        })
        .finally(function () { 
            setTimeout(() => {
                that.isItemSaving = false;
            }, 500);
        });
    },
    addMultiCoverageSetting(){
        if (this.coverageForm.coverage_detail_id == '-1' || this.coverageForm.coverage_detail_id == '-1' || this.coverageForm.coverage_detail_id == null) {
            this.$swal("Failed", " Coverage Main and Coverage Detail must be selected..!", 'warning' );
            return;
        }
        if (this.coverageForm.producer_policy_type_id == '-1' || this.coverageForm.producer_policy_type_id == null) {
            this.$swal("Failed", " Producer Policy Type must be selected..!", 'warning' );
            return;
        }
        
        this.selectedParameters = [];
        this.coverageParametersList.forEach((value) => {
            if(value.isSelected){
                this.selectedParameters.push({
                    code: value.coverage_code,
                    name: value.coverage_name
                });
            }
        }); 
        if (this.selectedParameters.length == 0) {            
            this.$swal("Failed", " At least one option must be selected..!", 'warning' );
            return;
        }

        this.isItemSaving = true;
        const parameters = {
            session_id: this.$getUser().session_id,
            parent_account_id: this.$getSessionStorage('record_id'),
            coverage_main_id: this.coverageForm.coverage_main_id == '-1' ? null : this.coverageForm.coverage_main_id,
            coverage_detail_id: this.coverageForm.coverage_detail_id == '-1' ? null : this.coverageForm.coverage_detail_id,
            producer_policy_type_id: this.coverageForm.producer_policy_type_id == '-1' ? null : this.coverageForm.producer_policy_type_id,
            search_title: this.coverageForm.search_title,
            selectedParameters: this.selectedParameters
        };
        const that = this;
        axios.post('/api/SupplierAccount/AddMultiCoverageItem', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == '' || response.data.error == undefined){
                this.coverageSettingList = response.data;
                this.$refs.closeCoverageModal.click();
            }            
            setTimeout(() => {
                that.isItemSaving = false;
            }, 500);
        }) 
        .catch(function () {
            setTimeout(() => {
                that.isItemSaving = false;
            }, 500);
        })
        .finally(function () { 
            setTimeout(() => {
                that.isItemSaving = false;
            }, 500);
        });
    },
    editCoverageSetting(){
        if (this.coverageForm.coverage_detail_id == '-1' || this.coverageForm.coverage_detail_id == '-1' || this.coverageForm.coverage_detail_id == null) {
            this.$swal("Failed", " Coverage Main and Coverage Detail must be selected..!", 'warning' );
            return;
        }
        if (this.coverageForm.producer_policy_type_id == '-1' || this.coverageForm.producer_policy_type_id == null) {
            this.$swal("Failed", " Producer Policy Type must be selected..!", 'warning' );
            return;
        }
        this.isItemSaving = true;
        const parameters = {
            id: this.coverageForm.id,
            session_id: this.$getUser().session_id,
            parent_account_id: this.$getSessionStorage('record_id'),
            coverage_main_id: this.coverageForm.coverage_main_id == '-1' ? null : this.coverageForm.coverage_main_id,
            coverage_detail_id: this.coverageForm.coverage_detail_id == '-1' ? null : this.coverageForm.coverage_detail_id,
            producer_coverage_code: this.coverageForm.producer_coverage_code,
            producer_coverage_name: this.coverageForm.producer_coverage_name,
            producer_policy_type_id: this.coverageForm.producer_policy_type_id == '-1' ? null : this.coverageForm.producer_policy_type_id,
            search_title: this.coverageForm.search_title,
        };
        const that = this;
        axios.post('/api/SupplierAccount/EditCoverageItem', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == '' || response.data.error == undefined){
                this.coverageSettingList = response.data;
                this.$refs.closeCoverageModal.click();
            }            
            setTimeout(() => {
                that.isItemSaving = false;
            }, 500);
        }) 
        .catch(function () {
            setTimeout(() => {
                that.isItemSaving = false;
            }, 500);
        })
        .finally(function () { 
            setTimeout(() => {
                that.isItemSaving = false;
            }, 500);
        });
    },
    deleteCoverageItem(){
        this.coverageSettingList.forEach((item) => {
            if (item.isSelected) {
                this.coverageForm.id = item.id;
            }
        });
        this.$swal.fire({
            title: "Are you sure you want to remove this item?",
            confirmButtonColor: "#f1416c",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "DELETE",
            icon: 'info'
        })
        .then((result) => {
            if (result.isConfirmed) {
                const parameters = {
                    session_id: this.$getUser().session_id,
                    parent_account_id: this.$getSessionStorage('record_id'),
                    id: this.coverageForm.id,
                    is_delete_all: 0
                }; 
                axios.post('/api/SupplierAccount/DeleteCoverageItem', parameters, {'Content-Type': 'application/json'})
                .then((response) => {
                    if(response.data.error == null || response.data.error == '' || response.data.error == undefined){
                        this.coverageSettingList = response.data;
                    } 
                }) 
                .catch(function (error) {
                    console.log(error);
                    this.Progress.finish();
                });
            }
        });
    },
    deleteAllCoverages(){ 
        this.$swal.fire({
            title: "Are you sure you want to remove all items?",
            confirmButtonColor: "#f1416c",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "DELETE",
            icon: 'info'
        })
        .then((result) => {
            if (result.isConfirmed) { 
                const parameters = {
                    session_id: this.$getUser().session_id,
                    parent_account_id: this.$getSessionStorage('record_id'),
                    is_delete_all: 1,
                    id: null
                }; 
                axios.post('/api/SupplierAccount/DeleteCoverageItem', parameters, {'Content-Type': 'application/json'})
                .then((response) => {
                    if(response.data.error == null || response.data.error == '' || response.data.error == undefined){
                        this.coverageSettingList = [];
                    } 
                }) 
                .catch(function (error) {
                    console.log(error);
                    this.Progress.finish();
                });
            }
        });
    },

    // Vendor CRUD
    openVendorModal(modalType) {
        this.policyBranchList = [{ id: '-1', text: 'ALL'}],
        this.modalType['vendor'] = modalType;
        this.vendorForm = {
            id: null,
            main_branch_id: '-1',
            policy_branch_id: '-1',
            sub_list_id: '-1',
            commission_type: this.form.supplier_account_type == 3 ? 2 : 1,
            commission_rate: 0.00
        };
        
        if(this.form.supplier_account_type == 2) {
            const parameters = {
                session_id: this.$getUser().session_id,
                list_types: [{ list_type: 1, list_name: "main_branch" }],
            };
            axios.post('/api/Helper/GetMultiPolicyLists', parameters, {'Content-Type': 'application/json'})
            .then((response) => {
                if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                    this.mainBranchList = response.data.main_branch;
                    this.mainBranchList.unshift({ id: '-1', text: 'ALL'});
                }
            })
            .catch(function (error) {
               console.log(error);
            });
        } 
        else if (this.form.supplier_account_type == 3) {
            const parameters = { 
                session_id: this.$getUser().session_id,
                list_name: ["product_group"]
            }; 
            axios.post('/api/Helper/GeneralLists', parameters, {'Content-Type': 'application/json'}).then((response) => {
                this.productGroupList = response.data.product_group;
                this.productGroupList.unshift({ id: '-1', text: 'ALL'});                
                this.onChangeProductGroup();
                this.onChangeProductType();
            })
            .catch(function (error) {
               console.log(error);
            });
        }
    },
    addVendorSetting(){
        if (this.vendorForm.commission_type == 0) {
            this.$swal("Failed", " Commission Type must be selected..!", 'warning' );
            return;
        }
        this.isItemSaving = true;
        const parameters = {
            session_id: this.$getUser().session_id,
            tmp_session_id: this.$getSessionStorage('tmp_session_id'),
            main_branch_id: this.vendorForm.main_branch_id == '-1' ? null : this.vendorForm.main_branch_id,
            policy_branch_id: this.vendorForm.policy_branch_id == '-1' ? null : this.vendorForm.policy_branch_id,
            sub_list_id: this.vendorForm.sub_list_id == '-1' ? null : this.vendorForm.sub_list_id,
            commission_type: this.vendorForm.commission_type,
            commission_rate: this.vendorForm.commission_rate
        };
        const that = this;
        axios.post('/api/SupplierAccount/AddVendorItem', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == '' || response.data.error == undefined){
                this.vendorSettingList = response.data;
                this.$setSessionStorage('tmp_session_id', response.data[0].tmp_session_id);
                this.$refs.closeVendorModal.click();
            }            
            setTimeout(() => {
                that.isItemSaving = false;
            }, 500);
        }) 
        .catch(function () {
            setTimeout(() => {
                that.isItemSaving = false;
            }, 500);
        })
        .finally(function () { 
            setTimeout(() => {
                that.isItemSaving = false;
            }, 500);
        });
    },
    deleteVendorItem(){
        this.vendorSettingList.forEach((item) => {
            if (item.isSelected) {
                this.vendorForm.id = item.id;
            }
        });
        this.$swal.fire({
            title: "Are you sure you want to remove this item?",
            confirmButtonColor: "#f1416c",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "DELETE",
            icon: 'info'
        })
        .then((result) => {
            if (result.isConfirmed) {
                const parameters = {
                    session_id: this.$getUser().session_id,
                    tmp_session_id: this.$getSessionStorage('tmp_session_id'),
                    id: this.vendorForm.id,
                    is_delete_all: 0
                }; 
                axios.post('/api/SupplierAccount/DeleteVendorItem', parameters, {'Content-Type': 'application/json'})
                .then((response) => {
                    if(response.data.error == null || response.data.error == '' || response.data.error == undefined){
                        this.vendorSettingList = response.data;
                    } 
                }) 
                .catch(function (error) {
                    console.log(error);
                    this.Progress.finish();
                });
            }
        });
    },
    deleteAllVendors(){ 
        this.$swal.fire({
            title: "Are you sure you want to remove all items?",
            confirmButtonColor: "#f1416c",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "DELETE",
            icon: 'info'
        })
        .then((result) => {
            if (result.isConfirmed) { 
                const parameters = {
                    session_id: this.$getUser().session_id,
                    tmp_session_id: this.$getSessionStorage('tmp_session_id'),
                    is_delete_all: 1,
                    id: null
                }; 
                axios.post('/api/SupplierAccount/DeleteVendorItem', parameters, {'Content-Type': 'application/json'})
                .then((response) => {
                    if(response.data.error == null || response.data.error == '' || response.data.error == undefined){
                        this.vendorSettingList = [];
                    } 
                }) 
                .catch(function (error) {
                    console.log(error);
                    this.Progress.finish();
                });
            }
        });
    },

    // Data Import Parameters CRUD
    getDataImportParameters() {
        const parameters = {
            session_id: this.$getUser().session_id,
            account_id: this.$getSessionStorage('record_id'),
        };  
        axios.post('/api/SupplierAccount/GetDataImportParameters', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
            this.dataImportSettingsList = response.data;
        })
        .catch(function (error) {
           console.log(error);
        });
    },
    addImportParameterItem(){
        if (this.dataImportForm.data_type == 0 || this.dataImportForm.data_type == null) {
            this.$swal("Failed", " Data Type must be selected..!", 'warning' );
            return;
        }
        this.isItemSaving = true;
        const parameters = {
            session_id: this.$getUser().session_id,
            parent_account_id: this.$getSessionStorage('record_id'),
            data_type: this.dataImportForm.data_type,
            data_code: this.dataImportForm.data_code,
            data_name: this.dataImportForm.data_name,
            data_value: this.dataImportForm.data_value,
            sub_list_type: this.dataImportForm.data_type == 4 || this.dataImportForm.data_type == 14 || this.dataImportForm.data_type == 20 || this.dataImportForm.data_type == 21 || this.dataImportForm.data_type == 28 ? this.dataImportForm.sub_list_type : 0,
            sub_list_id: this.dataImportForm.sub_list_id == '-1' ? null : this.dataImportForm.sub_list_id
        };
        const that = this;
        axios.post('/api/SupplierAccount/AddImportParameterItem', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == '' || response.data.error == undefined){
                this.dataImportSettingsList = response.data;
                this.$refs.closeDataImportModal.click();
            }            
            setTimeout(() => {
                that.isItemSaving = false;
            }, 500);
        }) 
        .catch(function () {
            setTimeout(() => {
                that.isItemSaving = false;
            }, 500);
        })
        .finally(function () { 
            setTimeout(() => {
                that.isItemSaving = false;
            }, 500);
        });
    },
    editImportParameterItem(){
        if (this.dataImportForm.data_type == 0 || this.dataImportForm.data_type == null) {
            this.$swal("Failed", " Data Type must be selected..!", 'warning' );
            return;
        }
        this.isItemSaving = true;
        const parameters = {
            id: this.dataImportForm.id,
            session_id: this.$getUser().session_id,
            parent_account_id: this.$getSessionStorage('record_id'),
            data_type: this.dataImportForm.data_type,
            data_code: this.dataImportForm.data_code,
            data_name: this.dataImportForm.data_name,
            data_value: this.dataImportForm.data_value,
            sub_list_type: this.dataImportForm.data_type == 4 || this.dataImportForm.data_type == 14 || this.dataImportForm.data_type == 20 || this.dataImportForm.data_type == 21 || this.dataImportForm.data_type == 28 ? this.dataImportForm.sub_list_type : 0,
            sub_list_id: this.dataImportForm.sub_list_id == '-1' ? null : this.dataImportForm.sub_list_id
        };
        const that = this;
        axios.post('/api/SupplierAccount/EditImportParameterItem', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == '' || response.data.error == undefined){
                this.dataImportSettingsList = response.data;
                this.$refs.closeDataImportModal.click();
            }            
            setTimeout(() => {
                that.isItemSaving = false;
            }, 500);
        }) 
        .catch(function () {
            setTimeout(() => {
                that.isItemSaving = false;
            }, 500);
        })
        .finally(function () { 
            setTimeout(() => {
                that.isItemSaving = false;
            }, 500);
        });
    },
    deleteImportParameterItem(){
        this.dataImportSettingsList.forEach((item) => {
            if (item.isSelected) {
                this.dataImportForm.id = item.id;
            }
        });
        this.$swal.fire({
            title: "Are you sure you want to remove this item?",
            confirmButtonColor: "#f1416c",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "DELETE",
            icon: 'info'
        })
        .then((result) => {
            if (result.isConfirmed) {
                const parameters = {
                    session_id: this.$getUser().session_id,
                    parent_account_id: this.$getSessionStorage('record_id'),
                    id: this.dataImportForm.id,
                    is_delete_all: 0
                }; 
                axios.post('/api/SupplierAccount/DeleteImportParameterItem', parameters, {'Content-Type': 'application/json'})
                .then((response) => {
                    if(response.data.error == null || response.data.error == '' || response.data.error == undefined){
                        this.dataImportSettingsList = response.data;
                    } 
                }) 
                .catch(function (error) {
                    console.log(error);
                    this.Progress.finish();
                });
            }
        });
    },
    deleteAllImportParameters(){ 
        this.$swal.fire({
            title: "Are you sure you want to remove all items?",
            confirmButtonColor: "#f1416c",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "DELETE",
            icon: 'info'
        })
        .then((result) => {
            if (result.isConfirmed) { 
                const parameters = {
                    session_id: this.$getUser().session_id,
                    parent_account_id: this.$getSessionStorage('record_id'),
                    is_delete_all: 1,
                    id: null
                }; 
                axios.post('/api/SupplierAccount/DeleteImportParameterItem', parameters, {'Content-Type': 'application/json'})
                .then((response) => {
                    if(response.data.error == null || response.data.error == '' || response.data.error == undefined){
                        this.dataImportSettingsList = [];
                    } 
                }) 
                .catch(function (error) {
                    console.log(error);
                    this.Progress.finish();
                });
            }
        });
    },
    openDataImportParameterModal(modalType) {        
        this.modalType['data_import'] = modalType;
        this.dataImportForm = {
            data_type: 0,
            data_code: null,
            data_name: null,
            data_value: null,
            sub_list_id: '-1',
            sub_list_name: 'Data Type List Name',
            sub_list_type: 0
        };
        if(modalType == 'edit'){
            this.dataImportSettingsList.forEach(element => {
                if(element.isSelected) {
                    this.dataImportForm = {
                        id: element.id,
                        data_type: element.data_type,
                        data_code: element.data_code,
                        data_name: element.data_name,
                        data_value: element.data_value,
                        sub_list_id: (element.sub_list_id == null || element.sub_list_id == '') ? '-1' : element.sub_list_id,
                        sub_list_name: element.data_type_name,
                        sub_list_type: element.sub_list_type
                    };
                    const dataTypeParameter = this.dataTypeList.find(item => item.id == this.dataImportForm.data_type);
                    this.onChangeDataType(dataTypeParameter);
                }
            });
        }
    },

    onOpenCoverageParameterModal() {
        this.$refs.CoverageParameters.getData();
    },
    getCoverageParameters() {
        this.coverageForm.isSelectedAll = false;
        const parameters = {
            session_id: this.$getUser().session_id,
            account_id: this.$getSessionStorage('record_id'),
            group_name: null
        };  
        
        const requestOne =  axios.post('/api/SupplierAccount/GetCoverageParameterGroups', parameters, {'Content-Type': 'application/json'});
        const requestTwo =  axios.post('/api/SupplierAccount/GetCoverageParameters', parameters, {'Content-Type': 'application/json'}); 
        
        axios.all([requestOne, requestTwo]).then(axios.spread((...responses) => {
            this.parameterGroups = responses[0].data;
            this.coverageParametersList = responses[1].data;
            this.parameterGroups.unshift({id: '-1', text: 'ALL'});
        }))
        .catch(errors => { 
            console.error(errors); 
        }); 
    },
    getCoverageParametersByGroup() {
        const parameters = {
            session_id: this.$getUser().session_id,
            account_id: this.$getSessionStorage('record_id'),
            group_name: this.coverageForm.parameter_group !== '-1' ? this.coverageForm.parameter_group : null
        };  
        axios.post('/api/SupplierAccount/GetCoverageParameters', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
            this.coverageForm.isSelectedAll = false;
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){              
                this.coverageParametersList = response.data;
            }
        })
        .catch(function (error) {
           console.log(error);
        });
    },
    onSelectAll() {
        this.coverageParametersList.forEach((value) => {
            value.isSelected = this.coverageForm.isSelectedAll;
        });
    },
    onChangeDataType(event, isChanged) {
        if(isChanged) {
            this.dataImportForm.sub_list_id = '-1';
            this.dataImportForm.sub_list_type = 0;
            this.dataImportForm.sub_list_name = 'Data Type List Name';
            this.dataImportForm.data_value = this.dataImportForm.data_type == 24 ? '-1' : null;
        }
        
        this.dataImportForm.sub_list_name = event.id !== '-1' ? event.text : 'Data Type List Name';
        if(this.dataImportForm.data_type == 4 || this.dataImportForm.data_type == 12 || this.dataImportForm.data_type == 14 || this.dataImportForm.data_type == 20 || this.dataImportForm.data_type == 21 || this.dataImportForm.data_type == 28) return;
        // Currency 
        if(this.dataImportForm.data_type == 24){
            const parameters = {
                session_id: this.$getUser().session_id,
                list_name: ["currency"]
            };
            axios.post('/api/Helper/GeneralLists', parameters, {'Content-Type': 'application/json'})
            .then((response) => {
                    this.currencyList = response.data.currency;
                    this.currencyList.unshift({id: '-1', text: 'Select an option'});
                    return;
            })
            .catch(function (error) {
                console.log(error);
            }); 
        }
        //Branch
        else if(this.dataImportForm.data_type == 19){ 
            const parameters = {
                session_id: this.$getUser().session_id,
                list_name: ["branch"],       
                get_access_status: 0,
                type_id: 2
            };        
            axios.post('/api/Helper/GeneralLists', parameters, {'Content-Type': 'application/json'})
            .then((response) => {
                    this.branchList = response.data.branch;
                    this.branchList.unshift({id: '-1', text: 'Select an option'});
                    return;
            })
            .catch(function (error) {
                console.log(error);
            }); 
        }
        //Sales Role / Campaign
        else if(this.dataImportForm.data_type == 25){ 
            const parameters = { 
                session_id: this.$getUser().session_id,
                list_name: ["campaign", "sales_channel"]
            };  
            axios.post('/api/Helper/GeneralLists', parameters, {'Content-Type': 'application/json'})
            .then((response) => {
                    this.campaignList = response.data.campaign;
                    this.campaignList.unshift({id: '-1', text: 'Select an option'});
                    return;
            })
            .catch(function (error) {
                console.log(error);
            }); 
        }
        else if(this.dataImportForm.data_type == 16){ 
            // Insurer Sales Channel
            const parameters = { 
                session_id: this.$getUser().session_id,
                list_name: ["sales_channel"]
            };  
            axios.post('/api/Helper/GeneralLists', parameters, {'Content-Type': 'application/json'})
            .then((response) => {
                if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                    this.salesChannelList = response.data.sales_channel;
                    this.salesChannelList.unshift({ id: '-1', text: 'Select an option'}); 
                }
            })
            .catch(function (error) {
                console.log(error);
            });
        }
        else if(this.dataImportForm.data_type == 23){ 
            // Policy Sales Channel
            const parameters = { 
                session_id: this.$getUser().session_id,
                list_name: ["sales_channel"]
            };
            axios.post('/api/Helper/GeneralLists', parameters, {'Content-Type': 'application/json'})
            .then((response) => {
                if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                    this.salesChannelList = response.data.sales_channel;
                    this.salesChannelList.unshift({ id: '-1', text: 'Select an option'}); 
                }
            })
            .catch(function (error) {
                console.log(error);
            });
        }
        else{
            if(this.dataImportForm.data_type == 15)  // Customer Type
            {
                const parameters = {
                    session_id: this.$getUser().session_id,
                    list_type: 14, // Customer Type: 14            
                    get_access_status: 0,
                    type_id: 2,
                };
                axios.post('/api/Helper/GetLists', parameters, {'Content-Type': 'application/json'})
                .then((response) => {
                    if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                        this.dataTypeSubLists = response.data;
                        this.dataTypeSubLists.unshift({ id: '-1', text: 'Select an option'}); 
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
            }
            else if(this.dataImportForm.data_type == 17){ 
                // Payment Method
                const parameterThree = {
                    session_id: this.$getUser().session_id,
                    list_types: [
                        { list_type: 11, list_name: "payment" }
                    ],
                };   
                axios.post('/api/Helper/GetMultiLists', parameterThree, {'Content-Type': 'application/json'})
                .then((response) => {
                    if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                        this.dataTypeSubLists = response.data.payment;
                        this.dataTypeSubLists.unshift({ id: '-1', text: 'Select an option'}); 
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
            }
            else if(this.dataImportForm.data_type == 18){ 
                // Shipping Type
                const parameterThree = {
                    session_id: this.$getUser().session_id,
                    list_types: [
                        { list_type: 12, list_name: "shipping" }
                    ],
                };   
                axios.post('/api/Helper/GetMultiLists', parameterThree, {'Content-Type': 'application/json'})
                .then((response) => {
                    if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                        this.dataTypeSubLists = response.data.shipping;
                        this.dataTypeSubLists.unshift({ id: '-1', text: 'Select an option'}); 
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
            }
            else if(this.dataImportForm.data_type == 31){
                const parameters = {
                    session_id: this.$getUser().session_id,
                    list_type: event.list_type,
                    type: 3
                }; 
                axios.post('/api/Helper/GetPolicyLists', parameters, {'Content-Type': 'application/json'})
                .then((response) => {
                    if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                        this.dataTypeSubLists = response.data;
                        this.dataTypeSubLists.unshift({id: '-1', text: 'Select an option'});
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
            }
            else
            {
                const parameters = {
                    session_id: this.$getUser().session_id,
                    list_type: event.list_type
                }; 
                axios.post('/api/Helper/GetPolicyLists', parameters, {'Content-Type': 'application/json'})
                .then((response) => {
                    if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                        this.dataTypeSubLists = response.data;
                        this.dataTypeSubLists.unshift({id: '-1', text: 'Select an option'});
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
            }
        }
    },
    onChangeIsMainGroup(isMainGroup) {
        this.form.is_main_group = isMainGroup;
        this.accountInfo.is_main_group = isMainGroup;
    },
    onChangeSubType(event) {
        if(event.id !== '-1') {
            this.dataImportForm.data_code = event.type.toString();
        }
    },
    setMainDefaultList(e){
        this.vendorForm = {
            id: e.data.id,
            main_branch_id: (e.data.main_branch_id == null || e.data.main_branch_id == '') ? '-1' : e.data.main_branch_id,
            policy_branch_id: (e.data.policy_branch_id == null || e.data.policy_branch_id == '') ? '-1' : e.data.policy_branch_id,
            sub_list_id: (e.data.sub_list_id == null || e.data.sub_list_id == '') ? '-1' : e.data.sub_list_id,
            commission_type: e.data.commission_type,
            commission_rate: e.data.commission_rate.replace(/,/g, '')
        };

        if(this.form.supplier_account_type == 2) {
            const parameters = {
                session_id: this.$getUser().session_id,
                list_types: [{ list_type: 1, list_name: "main_branch" }],
            };
            axios.post('/api/Helper/GetMultiPolicyLists', parameters, {'Content-Type': 'application/json'})
            .then((response) => {
                if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                    this.mainBranchList = response.data.main_branch;
                    this.mainBranchList.unshift({ id: '-1', text: 'ALL'});
                    this.getParentLists(2, this.vendorForm.main_branch_id);
                }
            })
            .catch(function (error) {
               console.log(error);
            });
        } else if (this.form.supplier_account_type == 3) {
            const parameters = { 
                session_id: this.$getUser().session_id,
                list_name: ["product_group"]
            }; 
            axios.post('/api/Helper/GeneralLists', parameters, {'Content-Type': 'application/json'}).then((response) => {
                this.productGroupList = response.data.product_group;
                this.productGroupList.unshift({ id: '-1', text: 'ALL'});                
                this.onChangeProductGroup();
                this.onChangeProductType();
            })
            .catch(function (error) {
                console.log(error);
            });
        }
        
    },
    setMainBranch(newData, value){
        newData.main_branch_name = value;
        newData.main_branch_id = value;
        newData.policy_branch_name = '-1';
        newData.policy_branch_id = '-1';
        this.getParentLists(2, value);
    },
    setProductGroup(newData, value){
        newData.main_branch_name = value;
        newData.main_branch_id = value;
        newData.policy_branch_name = '-1';
        newData.policy_branch_id = '-1';
        newData.sub_list_name = '-1';
        newData.sub_list_id = '-1';
        this.onChangeProductGroup(value);
    },
    setProductType(newData, value){
        newData.policy_branch_name = value;
        newData.policy_branch_id = value;
        newData.sub_list_name = '-1';
        newData.sub_list_id = '-1';
        this.onChangeProductType(value);
    },
    setProductDetail(newData, value){
        newData.sub_list_name = value;
        newData.sub_list_id = value;
    },
    setPolicyBranch(newData, value){
        newData.policy_branch_name = value;
        newData.policy_branch_id = value;
    },
    setCommissionTypeList(newData, value){
        newData.commission_type_name = value;
        newData.commission_type = value;
    },
    onVendorRowUpdating(e){
        if (e.oldData.commission_type == 0) {
            this.$swal("Failed", " Commission Type must be selected..!", 'warning' );
            return;
        }
        const parameters = {
            id: e.oldData.id,
            session_id: this.$getUser().session_id,
            tmp_session_id: this.$getSessionStorage('tmp_session_id'),
            main_branch_id: e.newData.main_branch_id ? e.newData.main_branch_id : e.oldData.main_branch_id,
            policy_branch_id: e.newData.policy_branch_id ? e.newData.policy_branch_id  : e.oldData.policy_branch_id,
            sub_list_id: e.newData.sub_list_id ? e.newData.sub_list_id  : e.oldData.sub_list_id,
            commission_type: e.newData.commission_type ? e.newData.commission_type  : e.oldData.commission_type,
            commission_rate: e.newData.commission_rate ? e.newData.commission_rate : e.oldData.commission_rate
        };
        if(parameters.main_branch_id == '-1' || parameters.main_branch_id == '') parameters.main_branch_id = null;
        if(parameters.policy_branch_id == '-1' || parameters.policy_branch_id == '') parameters.policy_branch_id = null;
        if(parameters.sub_list_id == '-1' || parameters.sub_list_id == '') parameters.sub_list_id = null;
        axios.post('/api/SupplierAccount/EditVendorItem', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == '' || response.data.error == undefined){
                this.vendorSettingList = response.data;
                this.$setSessionStorage('tmp_session_id', response.data[0].tmp_session_id);
            } else {
                this.refreshVendorList();
            }
        }) 
        .catch(function(error) {
            console.log(error);
        });
    },
    refreshVendorList() {
        const parameters = {
            session_id: this.$getUser().session_id,
            tmp_session_id: this.$getSessionStorage('tmp_session_id')
        };  
        axios.post('/api/SupplierAccount/GetTempVendors', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
              this.vendorSettingList = response.data;
            }
        })
        .catch(function (error) {
           console.log(error);
        });
    },
    setProducerMainDefaultList(e){
        this.producerForm = {
            id: e.data.id,
            main_branch_id: (e.data.main_branch_id == null || e.data.main_branch_id == '') ? '-1' : e.data.main_branch_id,
            policy_branch_id: (e.data.policy_branch_id == null || e.data.policy_branch_id == '') ? '-1' : e.data.policy_branch_id,
            policy_group_id: (e.data.policy_group_id == null || e.data.policy_group_id == '') ? '-1' : e.data.policy_group_id,
            producer_policy_code: e.data.producer_policy_code,
            producer_policy_name: e.data.producer_policy_name,
            search_title: e.data.search_title,
        };
        const parameters = {
            session_id: this.$getUser().session_id,
            list_types: [
                {
                    list_type: 1,
                    list_name: "main_branch"
                }
            ],
        };
        const requestOne =  axios.post('/api/Helper/GetMultiPolicyLists', parameters, {'Content-Type': 'application/json'});
        axios.all([requestOne]).then(axios.spread((...responses) => {
            this.mainBranchList= responses[0].data.main_branch;
            this.mainBranchList.unshift({ id: '-1', text: 'Select an option'});
            this.getParentLists(2, this.producerForm.main_branch_id);
        }))
        .catch(errors => { 
            console.error(errors); 
        });
    },
    setProducerMainBranch(newData, value){
        newData.main_branch_name = value;
        newData.main_branch_id = value;
        newData.policy_branch_name = '-1';
        newData.policy_branch_id = '-1';
        newData.policy_group_name = '-1';
        this.getParentLists(2, value);
    },
    setProducerPolicyBranch(newData, value){
        newData.policy_branch_name = value;
        newData.policy_branch_id = value;
        newData.policy_group_name = '-1';
        const params = {
            session_id: this.$getUser().session_id,
            list_type: 14,
            parent_id: newData.policy_branch_id 
        };
        axios.post("/api/Helper/GetPolicyParentList", params, {'Content-Type': 'application/json'}).then((response) => { 
            this.policyGroupList = response.data;
            this.policyGroupList.unshift({ id: '-1', text: 'Select an option'});
        })
        .catch(function (error) {
            console.log(error);
        });
    },
    setProducerPolicyGroup(newData, value){
        newData.policy_group_name = value;
        newData.policy_group_id = value;
    },
    onProducerRowUpdating(e){
        if (e.oldData.policy_branch_id == '-1' || e.oldData.policy_branch_id == null) {
            this.$swal("Failed", " Policy Branch must be selected..!", 'warning' );
            return;
        }
        this.isItemSaving = true;
        const parameters = {
            id: e.oldData.id,
            session_id: this.$getUser().session_id,
            parent_account_id: this.$getSessionStorage('record_id'),
            main_branch_id: e.newData.main_branch_id !== undefined ? e.newData.main_branch_id : e.oldData.main_branch_id,
            policy_branch_id: e.newData.policy_branch_id !== undefined ? e.newData.policy_branch_id : e.oldData.policy_branch_id,
            policy_group_id: e.newData.policy_group_id !== undefined ? e.newData.policy_group_id : e.oldData.policy_group_id,
            producer_policy_code: e.newData.producer_policy_code !== undefined ? e.newData.producer_policy_code : e.oldData.producer_policy_code,
            producer_policy_name: e.newData.producer_policy_name !== undefined ? e.newData.producer_policy_name : e.oldData.producer_policy_name ,
            search_title: e.newData.search_title !== undefined ? e.newData.search_title : e.oldData.search_title 
        };
        if(parameters.main_branch_id == '-1' || parameters.main_branch_id == '') parameters.main_branch_id = null;
        if(parameters.policy_branch_id == '-1' || parameters.policy_branch_id == '') parameters.policy_branch_id = null;
        if(parameters.policy_group_id == '-1' || parameters.policy_group_id == '') parameters.policy_group_id = null;
        axios.post('/api/SupplierAccount/EditProducerItem', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == '' || response.data.error == undefined){
                this.producerSettingList = response.data;
            } else {
                this.refreshProducerList();
            }
        }) 
        .catch(function(error) {
            console.log(error);
        });
    },
    refreshProducerList() {
        const parameters = {
            session_id: this.$getUser().session_id,
            account_id: this.$getSessionStorage('record_id')
        };  
        axios.post('/api/SupplierAccount/GetProducerPolicyTypes', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
              this.producerSettingList = response.data;
            }
        })
        .catch(function (error) {
           console.log(error);
        });
    },
    onSelectAddress(item) {
        if(item.id == '-1') return;
        this.form.address = item.fullAddress;
        this.form.city = item.town_or_city;
        this.form.district = item.district;
        this.form.postcode = item.postcode;
    },
    onListInvoices() {
        setTimeout(() => {
            this.$refs.InvoiceList.setCriteriasToDefault(this.form.id);
            this.$refs.InvoiceList.isAccountIdRequired = true;
        }, 500);
    },
    onChangeProductGroup(value) {
        const params = {
            session_id: this.$getUser().session_id,
            parent_id: value ? value : this.vendorForm.main_branch_id
        };
        axios.post("/api/Helper/GetProductTypeParentList", params, {'Content-Type': 'application/json'}).then((response) => { 
            this.productTypeList = response.data;
            this.productTypeList.unshift({ id: '-1', text: 'ALL'}); 
            this.vendorForm.policy_branch_id = '-1';
        })
        .catch(function (error) {
            console.log(error); 
        });  
    },
    onChangeProductType(value) {
        const params = {
            session_id: this.$getUser().session_id,
            parent_id: value ? value : this.vendorForm.policy_branch_id
        };
        axios.post("/api/Helper/GetProductDetailParentList", params, {'Content-Type': 'application/json'}).then((response) => { 
            this.productDetailList = response.data;
            this.productDetailList.unshift({ id: '-1', text: 'ALL'}); 
            this.vendorForm.sub_list_id = '-1';
        })
        .catch(function (error) {
            console.log(error); 
        });  
    },
  },
  created() {
    this.helpPageViewIcon();    
    this.$removeSessionStorage('tmp_session_id');
    this.getData();
  },
};
</script>