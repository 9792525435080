<template>
    <div class="modal-dialog p-9">
        <div class="modal-content modal-rounded">
            <div class="modal-header bg-primary py-7 d-flex justify-content-between">
                <h2 class="text-white">{{ $t('Invoice Numerator Settings') }}</h2>
                <div class="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal" aria-label="Close" ref="closeNumeratorModal">
                    <span class="svg-icon svg-icon-1 text-white text-hover-dark">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                            <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                        </svg>
                    </span>
                </div>
            </div>
            <div class="modal-body scroll-y" :class="{'overlay overlay-block': this.isSaving == true}">
                <div class="d-flex flex-column" data-kt-stepper="true">
                    <form id="kt_ecommerce_settings_general_form"
                        class="form fv-plugins-bootstrap5 fv-plugins-framework">                                
                        <div class="fv-row mb-7 row">
                            <div class="col-lg-6">
                                <label class="fs-6 fw-bold form-label mt-3">
                                    {{ $t('Prefix') }}
                                </label> 
                                <input type="text" class="form-control" name="prefix" v-model="form.prefix">
                                <div class="form-text">
                                    {{ $t('A short code that will be used as prefix. E.g. "CUS". Prefix setting can be empty.') }}
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <label class="fs-6 fw-bold form-label mt-3 required">
                                    {{ $t('Start From') }}
                                </label> 
                                <CurrencyInput type="text" class="form-control" name="start_from" v-model="form.start_from" :precision="0" @focus="$event.target.select()" :min="0"/>
                                <div class="form-text">
                                {{ $t('Defines the number where code will start from. E.g. 100.') }}
                                </div>
                            </div>
                        </div>
                        <div class="d-grid gap-2">
                            <button v-if="!isUpdateMode" type="button" class="btn btn-success" @click="this.saveData()"  data-kt-indicator="on">
                                <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                </svg>
                                </span> 
                                <strong v-if="!this.isSaving"> {{ $t('Save Numerator Setting') }} </strong> 
                                    <span class="indicator-progress" v-if="this.isSaving">
                                        {{ $t('Saving Data') }}... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span> 
                            </button>
                            <button v-if="isUpdateMode" type="button" class="btn btn-primary" @click="this.updateData()"  data-kt-indicator="on">
                                <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                </svg>
                                </span> 
                                <strong v-if="!this.isSaving"> {{ $t('Update Numerator Setting') }} </strong> 
                                    <span class="indicator-progress" v-if="this.isSaving">
                                        {{ $t('Saving Data') }}... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span> 
                            </button>
                        </div>
                    </form>
                </div>
                <div class="overlay-layer bg-dark bg-opacity-5" v-if="this.isSaving == true">
                    <div class="spinner-border text-primary" role="status">
                        <span class="visually-hidden">{{ $t('Loading') }}...</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import axios from "axios"; 
export default {
  name: "AddNewNumerator",
  props: {
    numeratorType: Number
  },
  data() {
    return {        
        form:{
            ai_type: -1,
            prefix: '',
            total_length: 10,
            start_from: 1
        },
        isSaving: false,
        isUpdateMode: false 
    };
  },
  methods: {
    getData() {
        this.form = {
            ai_type: this.numeratorType,
            prefix: '',
            total_length: 10,
            start_from: 1
        }
        const params = {
            session_id : this.$getUser().session_id,
            ai_type: 1
        };  
        axios.post('api/DocumentSettings/GetSettingById', params, {'Content-Type': 'application/json'})
        .then((response) => { 
            if (response.data.prefix && response.data.total_length) {
                this.form = response.data;
                this.$parent.invoiceNumberPattern = this.form.prefix + 'X'.repeat(this.form.total_length);
            }

            if(response.data.ai_type !== 0) {
                this.isUpdateMode = true;
            }
        })
        .catch(function (error) {
            console.log(error);
            this.$swal("Failed", "Data could not get..Please refresh the page and try again..!");   
        });  
    },
    saveData() {
        if(this.numeratorType == null || this.numeratorType == '' || this.numeratorType == -1) {
            this.$swal("Warning", "Type must be selected..!", 'info');
            return;
        }
        this.isSaving = true;
        const that = this;
        const params = {
            session_id : this.$getUser().session_id,
            ai_type: this.numeratorType,
            prefix: this.form.prefix,
            total_length:this.form.total_length,
            start_from:this.form.start_from
        };
        axios.post('api/DocumentSettings/CreateSetting', params, {'Content-Type': 'application/json'})
        .then((response) => { 
            if(!response.data.error){
                this.$refs.closeNumeratorModal.click();
                this.$parent.invoiceNumberPattern = this.form.prefix + 'X'.repeat(this.form.total_length);
            } 
        })
        .catch(function (error) { 
            console.log(error);
        })
        .finally(function () { 
            setTimeout(() => {
                that.isSaving = false;
            }, 500);
        });
    },
    updateData(){
        if(this.numeratorType == null || this.numeratorType == '' || this.numeratorType == -1) {
            this.$swal("Warning", "Type must be selected..!", 'info');
            return;
        }
        this.isSaving = true;
        const that = this;
        const params = {
            session_id : this.$getUser().session_id,
            ai_type: this.numeratorType,
            prefix: this.form.prefix,
            total_length:this.form.total_length,
            start_from:this.form.start_from
        };
        axios.post('api/DocumentSettings/UpdateSetting', params, {'Content-Type': 'application/json'})
        .then((response) => { 
            if(!response.data.error){
                this.$refs.closeNumeratorModal.click();
                this.$parent.invoiceNumberPattern = this.form.prefix + 'X'.repeat(this.form.total_length);
            } 
        })
        .catch(function (error) { 
            console.log(error);
        })
        .finally(function () { 
            setTimeout(() => {
                that.isSaving = false;
            }, 500);
        });
    },
  }
};
</script>