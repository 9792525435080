<template> 
  <div>
    <div class="content flex-row-fluid mt-2" id="kt_content">
        <!-- <StatsBar></StatsBar> -->
        <div class="card">
            <div class="card-body pt-0">
                <div class="row px-0">
                    <div class="col-lg-9">
                        <form class="form fv-plugins-bootstrap5 fv-plugins-framework rounded-2">
                            <div class="fv-row mb-7 row">
                                <div class="col-lg">
                                    <label class="fs-6 fw-bold form-label mt-3">
                                        <span>{{ $t('Start Date') }}</span>
                                    </label>
                                    <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.start_date" inputClassName="form-control" format="dd.MM.yyyy" autoApply :clearable="false" weekNumbers @update:modelValue="getData(true)" :monthChangeOnScroll="'inverse'" :enableTimePicker="false" ></Datepicker>  
                                </div>
                                <div class="col-lg">
                                    <label class="fs-6 fw-bold form-label mt-3">
                                        <span>{{ $t('End Date') }}</span>
                                    </label>
                                    <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.end_date" inputClassName="form-control" format="dd.MM.yyyy" autoApply :clearable="false" weekNumbers @update:modelValue="getData(true)" :monthChangeOnScroll="'inverse'" :enableTimePicker="false" ></Datepicker>
                                </div>
                                <div class="col-lg">
                                    <label class="fs-6 fw-bold form-label mt-3">
                                        <span>{{ $t('Invoice Type') }}</span>
                                    </label>
                                    <Select2 v-model="form.invoice_type" :options="invoiceTypeList" @select="getData(true)" :settings="{width: '100%', dropdownParent: '#kt_modal_transaction_list .modal-content'}" />
                                </div>
                                <div class="col-lg">
                                    <label class="fs-6 fw-bold form-label mt-3">
                                        <span>{{ $t('Sales Type') }}</span>
                                    </label>
                                    <Select2 v-model="form.sales_type" :options="salesTypeList" @select="getData(true)" :settings="{width: '100%', dropdownParent: '#kt_modal_transaction_list .modal-content'}" />
                                </div>
                                <div class="col-lg">
                                    <label class="fs-6 fw-bold form-label mt-3">
                                        <span>{{ $t('Process Type') }}</span>
                                    </label>
                                    <Select2 v-model="form.process_type" :options="processList" @select="getData(true)" :settings="{width: '100%', dropdownParent: '#kt_modal_transaction_list .modal-content'}" />
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="col-lg-3" v-if="this.$isCredentialActive(20,'R')">                        
                        <div class="d-flex justify-content-end align-items-center mt-8" data-kt-customer-table-toolbar="base"> 
                            <Popper hover placement="bottom">
                                <button type="button" class="btn btn-light border border-gray-300 btn-icon me-3" @click="this.setCriteriasToDefault()">
                                    <i class="bi bi-eraser-fill fs-4"> </i>
                                </button>
                                <template #content>
                                    <span class="text-gray-800"> {{ $t('Reset All Criterias') }} </span>
                                </template>
                            </Popper>
                            <button v-if="this.$getUser().is_allow_list_all_data" type="button" class="btn btn-light-primary border border-primary" @click="getData(false)" data-kt-indicator="on">
                                <span class="svg-icon svg-icon-muted"><svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect y="6" width="16" height="3" rx="1.5" fill="currentColor"/>
                                <rect opacity="0.3" y="12" width="8" height="3" rx="1.5" fill="currentColor"/>
                                <rect opacity="0.3" width="12" height="3" rx="1.5" fill="currentColor"/>
                                </svg>
                                </span>
                                <span v-if="!this.isListProgressing"> {{ $t('List Invoices') }}</span> 
                                <span class="indicator-progress" v-if="this.isListProgressing">
                                    {{ $t('Getting Data...') }} <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span> 
                            </button>
                        </div>
                    </div>
                </div>
                <div class="separator my-5 mt-0 border-gray-300"></div>
                <div class="dataTables_wrapper dt-bootstrap4 no-footer">                    
                    <!-- <ListOptions :name="this.$options.name" @click="SaveDXStateToStorage" ref="listOptionComponent"></ListOptions> -->
                    <DxDataGrid id="gridContainer" :show-borders="true" 
                                key-expr="id" 
                                :data-source="invoiceList" 
                                :allow-column-reordering="true"  
                                :allow-column-resizing="true" 
                                :column-auto-width="true" 
                                :row-alternatin-enabled="true"
                                :hover-state-enabled="true" 
                                :show-row-lines="true" 
                                @exporting="this.$onExporting($event , 'Invoice-Transaction-List')"  
                                :ref="'dataGrid'"
                                :customize-columns="onCustomizeColumn"> 

                                <DxFilterRow :visible="true"/>
                                <DxHeaderFilter :visible="true"/>
                                <DxGroupPanel :visible="false"/>      
                                <DxExport :enabled="false" :formats="['xlsx', 'pdf']" :allow-export-selected-data="false"/>
                                <DxPaging :page-size="10"/>
                                <DxPager :visible="false" :allowed-page-sizes="pageSizes" :show-page-size-selector="showPageSizeSelector" :show-info="true" :show-navigation-buttons="true"/>
                                <DxSearchPanel :visible="false"/>
                                <DxScrolling
                                    mode="virtual"
                                    row-rendering-mode="virtual"
                                />
                                <DxColumn data-field="invoice_date" :caption="$t('Record Date')" data-type="date" alignment="right" format="dd.MM.yyyy" header-cell-template="record-date-header" cell-template="invoice_date-cell-template"/> 
                                <DxColumn data-field="invoice_type_name" :caption="$t('Invoice Type')" header-cell-template="invoice-type-header" cell-template="invoice_type_name-cell-template"/> 
                                <DxColumn data-field="sales_type" :caption="$t('Sales Type')" alignment="left" header-cell-template="sales-type-header" cell-template="sales_type-cell-template"/> 
                                <DxColumn data-field="invoice_no" :caption="$t('Invoice Number')" header-cell-template="invoice-number-header" cell-template="invoice_no-cell-template"/> 
                                <DxColumn data-field="account_name" :caption="$t('Account')" cell-template="account_name-cell-template"/> 
                                <DxColumn data-field="gross_amount" :sorting-method="this.$numericSort" :caption="$t('Grand Total')" header-cell-template="grand-total-header" alignment="right" cell-template="gross_amount-cell-template"/> 
                                <DxColumn data-field="rate" :caption="$t('Rate')" alignment="right" cell-template="rate-cell-template"/> 
                                <DxColumn data-field="gross_amount_local" :sorting-method="this.$numericSort" :caption="$t('Grand Total Local')" header-cell-template="grand-total-local-header" alignment="right" cell-template="gross_amount_local-cell-template"/>
                                <DxColumn data-field="process_type_name" :caption="$t('Process Type')" header-cell-template="process_type_name-header" cell-template="process_type_name-cell-template"/>
                                <DxColumn data-field="isPopover" :caption="$t('Security')" cell-template="is-popover-cell-template"/>

                                <!-- Yerel para birimi gösterimi için özel sütun şablonu oluşturulur --> 
                                <template #headerLocalTemplate="{ data: data }">
                                    <div v-html="setColumnTemplateForLocal(data)"></div> 
                                </template>

                                <template #record-date-header>
                                    <div>{{ $t('Record') }}</div><div>{{ $t('Date') }}</div>
                                </template>
                                <template #invoice-type-header>
                                    <div>{{ $t('Invoice') }}</div><div>{{ $t('Type') }}</div>
                                </template>
                                <template #sales-type-header>
                                    <div>{{ $t('Sales') }}</div><div>{{ $t('Type') }}</div>
                                </template>
                                <template #invoice-number-header>
                                    <div>{{ $t('Invoice') }}</div><div>{{ $t('Number') }}</div>
                                </template>
                                <template #grand-total-header>
                                    <div>{{ $t('Grand') }}</div><div>{{ $t('Total') }}</div>
                                </template>
                                <template #grand-total-local-header>
                                    <div>{{ $t('Grand Total') }}</div><div>{{ $t('Local') }}</div>
                                </template> 
                                <template #process_type_name-header>
                                    <div>{{ $t('Process') }}</div><div>{{ $t('Type') }}</div>
                                </template> 

                                 <!-- Cell Templates -->
                                <template #invoice_date-cell-template="{ data }">
                                    <a href="#" class="text-gray-800 text-hover-primary" @click="updateInvoice(data.data.id)">
                                        {{ previewDate(data.data.invoice_date) }} 
                                    </a>
                                </template>
                                 <template #invoice_type_name-cell-template="{ data }">
                                     <a href="#" class="text-gray-800 text-hover-primary" @click="updateInvoice(data.data.id)">
                                        <div>
                                            <span class="badge badge-circle badge-light me-3">
                                                <i class="bi bi-pencil text-gray-900"></i> 
                                            </span> 
                                            <span class="fw-bolder" :class="{ 'text-danger': data.data.invoice_type == 4 || data.data.invoice_type == 3 }">
                                                {{ data.data.invoice_type_name }} 
                                            </span> 
                                        </div>
                                    </a>
                                </template>
                                 <template #sales_type-cell-template="{ data }"> 
                                    <a href="#" class="text-gray-800 text-hover-primary" @click="updateInvoice(data.data.id)">
                                        <span v-if="data.data.sales_type == 1"> {{ $t('Domestic') }} </span>
                                        <span v-if="data.data.sales_type == 2"> {{ $t('Overseas') }} </span>
                                    </a>
                                </template> 
                                <template #invoice_no-cell-template="{ data }"> 
                                    <a href="#" class="text-gray-800 text-hover-primary" @click="updateInvoice(data.data.id)">
                                        {{ data.data.invoice_no }} 
                                    </a>
                                </template>
                                 <template #account_name-cell-template="{ data }">
                                    <a href="#" class="text-gray-800 text-hover-primary" @click="updateInvoice(data.data.id)">
                                        {{ data.data.account_name }}  
                                    </a>
                                </template>
                                 <template #gross_amount-cell-template="{ data }">
                                    <a href="#" class="text-gray-800 text-hover-primary" @click="updateInvoice(data.data.id)">
                                        {{ data.data.gross_amount }} {{ data.data.currency }} 
                                    </a>
                                </template> 
                                <template #rate-cell-template="{ data }"> 
                                    <a href="#" class="text-gray-800 text-hover-primary" @click="updateInvoice(data.data.id)">
                                        {{ data.data.rate }}
                                    </a>
                                </template>
                               <template #gross_amount_local-cell-template="{ data }">
                                    <a href="#" class="text-gray-800 text-hover-primary" @click="updateInvoice(data.data.id)">
                                        {{ data.data.gross_amount_local }} {{ data.data.local_currency }} 
                                    </a>
                                </template> 
                               <template #process_type_name-cell-template="{ data }">
                                    <a href="#" class="text-gray-800 text-hover-primary" @click="updateInvoice(data.data.id)">
                                        {{ data.data.process_type_name }}
                                    </a>
                                </template> 
                                 <template #is-popover-cell-template="{ data }">
                                    <div>
                                        <span @click="updateInvoice(data.data.id)">
                                            <i class="fa fa-regular fa-lock-open fs-4 fw-bolder pe-3 text-primary cursor-pointer"
                                                :id="'popover_company_' + data.rowIndex" 
                                                @mouseenter="this.onEnterPopover(data.data, 'company')" 
                                                @mouseleave="this.onLeavePopover(data.data)">
                                            </i>
                                            <i class="fa fa-regular fa-lock fs-4 fw-bolder pe-3 text-danger cursor-pointer"
                                                :id="'popover_controller_' + data.rowIndex" 
                                                @mouseenter="this.onEnterPopover(data.data, 'controller')" 
                                                @mouseleave="this.onLeavePopover(data.data)">
                                            </i>
                                            <i class="fa fa-regular fa-lock-open fs-4 fw-bolder text-warning opacity-100 cursor-pointer"
                                                :id="'popover_system_' + data.rowIndex" 
                                                @mouseenter="this.onEnterPopover(data.data, 'system')" 
                                                @mouseleave="this.onLeavePopover(data.data)">
                                            </i>
                                        </span>
                                        <span>
                                            <DxPopover
                                                :show-title="false"
                                                :visible="data.data.isPopover"
                                                :target="'#popover_'+ data.data.type + '_' + data.rowIndex"
                                                position="top">
                                                    <span v-if="data.data.isVisibleCompany"> {{ $t('Company') }} </span>
                                                    <span v-if="data.data.isVisibleController"> {{ $t('Controller') }} </span>
                                                    <span v-if="data.data.isVisibleSystem"> {{ $t('System') }} </span>
                                            </DxPopover>
                                        </span>
                                    </div>
                                </template> 

                                <DxSummary :calculate-custom-summary="setSummaries">
                                    <DxTotalItem column="id" summary-type="count" display-format="Record: {0}" show-in-column="invoice_date"/>
                                    <DxGroupItem
                                        :show-in-group-footer="true"
                                        name="gross_amount_local"
                                        summary-type="custom"
                                        show-in-column="gross_amount_local"
                                        display-format="{0}"
                                    /> 
                                    <DxTotalItem
                                        name="gross_amount_local"
                                        summary-type="custom"
                                        show-in-column="gross_amount_local"
                                        display-format="{0}"
                                    />
                                </DxSummary>
                                <DxStateStoring
                                        :enabled="true"
                                        type="custom"
                                        :storage-key="this.storageKey"
                                        :ref="dataGrid" 
                                        :saving-timeout="1000"
                                        :custom-load="LoadDXState"
                                    />
                    </DxDataGrid>   
                </div> 
            </div>
        </div> 
    </div>  
  </div>  
</template>

<script>
import axios from 'axios';
import moment from 'moment';
// import Swal from 'sweetalert2';
import { DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxSummary, DxTotalItem, DxGroupItem, DxStateStoring, DxScrolling } from 'devextreme-vue/data-grid';
import { DxPopover } from 'devextreme-vue/popover';
 
export default {
  name: "InvoiceList",
  components:{
        DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxSummary, DxTotalItem, DxPopover, DxGroupItem, DxStateStoring, DxScrolling
  },
 data() {
    return { 
        storageKey: 'dx_' + this.$options.name,
        localCurrency: null,
        isOpenActionsDropdown: false,      
        page: 1,
        page_size: 10,
        form:  {
            start_date: moment().subtract((parseInt(this.$getUser().list_date_range)), 'days'),
            end_date: moment(),
            sales_type: 0,
            invoice_type: 0,
            process_type: 0,
            account_id: null
        },
        invoiceList: [],
        searchbar: '',
        pageSize: 10,
        pageIndex: 0,
        isListProgressing: false,
        invoiceTypeList: [
            { id: 0, text: 'ALL'},
            { id: 1, text: 'Sales Invoice'},
            { id: 2, text: 'Purchase Invoice'},
            { id: 3, text: 'Sales Return Invoice'},
            { id: 4, text: 'Purchase Return Invoice'},
        ],
        salesTypeList: [
            { id: 0, text: 'ALL'},
            { id: 1, text: 'Domestic'},
            { id: 2, text: 'Overseas'},
        ],
        processList:[
            { id: 0, text: 'ALL'}, 
            { id: 1, text: 'Standard'}, 
            { id: 2, text: 'Fixed Asset'}
        ],
        isAccountIdRequired: false
    };
  },
  computed: { 
    setColumnTemplateForLocal(){
        return (value)=>{
            const captionValue = value.column.caption.replace(' Local', '');
            return this.localCurrency == null ? this.$t(captionValue) : this.localCurrency + '<br>' + this.$t(captionValue);          
        }
   },
     searchableList() { 
        return this.invoiceList.filter(p => { 
            return p.account_name.toLowerCase().indexOf(this.searchbar.toLowerCase()) != -1 || 
                    p.invoice_type_name.toLowerCase().indexOf(this.searchbar.toLowerCase()) != -1 ||
                    p.invoice_no.toLowerCase().indexOf(this.searchbar.toLowerCase()) != -1;
            }
        ).slice((this.page - 1) * this.page_size, this.page * this.page_size);
    },
    previewDate(){
      return (value)=>{
       if (value) {
          return moment(new Date(value)).format('DD.MM.YYYY')
        }
      }
    },
    showPageSizeSelector() {
        return this.invoiceList.length > this.pageSize;
    },
    pageSizes() {
        let arr = [];
        let x = 1;
        let loop = (this.invoiceList.length / this.pageSize);
        for (let i = 1; i <= loop && i <= 5; i++) {
            arr.push(this.pageSize * x);
            x++;
        }
        if(loop > 5) arr.push(100);
        if(loop > 1) arr.push('all');
        return arr;
    }
  },
  methods: { 
    getData(isAuto) {    
        this.invoiceTypeList = [{ id: 0, text: 'ALL'}];
        if(this.$isCredentialActive(27, 'R', 20)) this.invoiceTypeList.push({ id: 1, text: 'Sales Invoice'});
        if(this.$isCredentialActive(28, 'R', 20)) this.invoiceTypeList.push({ id: 2, text: 'Purchase Invoice'});
        if(this.$isCredentialActive(29, 'R', 20)) this.invoiceTypeList.push({ id: 3, text: 'Sales Return Invoice'});
        if(this.$isCredentialActive(30, 'R', 20)) this.invoiceTypeList.push({ id: 4, text: 'Purchase Return Invoice'});

        if(isAuto && this.$getUser().is_allow_list_all_data) return;
        this.invoiceList = [];
        this.$Progress.start(); 
        this.isListProgressing = true;
        this.form.start_date = (this.form.start_date == null || this.form.start_date == '') ? moment() : this.form.start_date;
        this.form.end_date = (this.form.end_date == null || this.form.end_date == '') ? moment() : this.form.end_date;
        const params = {
            session_id: this.$getUser().session_id,
            start_date: this.form.start_date,
            end_date: this.form.end_date,
            invoice_type: this.form.invoice_type,
            sales_type: this.form.sales_type,
            process_type: this.form.process_type,
            account_id: this.form.account_id,
            invoiceTypes: []
        };
        if(params.invoice_type == 0) { params.invoice_type = null }
        if(params.sales_type == 0) { params.sales_type = null } 
        if(params.process_type == 0) { params.process_type = null } 
        params.start_date = moment(params.start_date).format('YYYY-MM-DD[T]HH:mm:ss');
        params.end_date = moment(params.end_date).format('YYYY-MM-DD[T]HH:mm:ss'); 
        axios.post('api/Invoice/GetInvoicesByFilter', params, {'Content-Type': 'application/json'})
        .then((response) => {
            this.invoiceList = response.data;
            this.page = 1;
            this.localCurrency = this.$getUser().currency_code; 
            this.$Progress.finish();
            setTimeout(() => {
                this.isListProgressing = false;
            }, 500);
        })
        .catch(function () {
            this.$Progress.finish();
            setTimeout(() => {
                this.isListProgressing = false;
            }, 500);
        })
    },
    updateInvoice(id){
        this.$setSessionStorage('record_id', id);
        if(!this.isAccountIdRequired) this.$parent.$refs.closeTransactionListModal.click();
        if (this.$route.name == 'InvoiceUpdate') {
            this.$router.go(0);
        } else {
            this.$router.push({ name: 'InvoiceUpdate' });
        }
    },
    setCriteriasToDefault(accountId) {
        if(this.isAccountIdRequired) { accountId = this.form.account_id; }
        this.invoiceList = [];
        if((accountId === null || accountId === undefined || accountId === '-1')  && !this.$getUser().is_allow_list_all_data) return;
        this.form = {
            start_date: moment().subtract((parseInt(this.$getUser().list_date_range)), 'days'),
            end_date: moment(),
            sales_type: 0,
            process_type: 0,
            invoice_type: 0,
            account_id: (accountId !== undefined && accountId !== '' && accountId !== '-1') ? accountId : null
        };
        this.getData();
    },
    onClickDropdown(item) {
        this.invoiceList.forEach(element => {
            if (item.id !== element.id) {
                element.isOpenActionsDropdown = false;
            }
        });
        item.isOpenActionsDropdown = !item.isOpenActionsDropdown;
    },
    onCustomizeColumn(e){
        if(e){
            if(!e._isUpdated){
                if(e.length > 0){ 
                    e._isUpdated = true;
                }
                e.forEach((column) => {   
                     if(column.dataField == 'gross_amount_local'){ 
                         column.caption =  'Grand Total Local';
                         column.headerCellTemplate = 'headerLocalTemplate';
                     }
                });  
            } 
        } 
    },
    onEnterPopover(item, type){ 
         this.invoiceList.forEach((value) => { 
            value.type = type;
            if (item.id === value.id) {
                value.isPopover = true; 
                if (type == 'company') {
                    value.isVisibleCompany = true;
                    value.isVisibleController = false;
                    value.isVisibleSystem = false;
                }
                if (type == 'controller') {
                    value.isVisibleCompany = false;
                    value.isVisibleController = true;
                    value.isVisibleSystem = false;
                }
                if (type == 'system') {
                    value.isVisibleCompany = false;
                    value.isVisibleController = false;
                    value.isVisibleSystem = true;
                }
            }
        });  
    },
    onLeavePopover(value){
        value.isPopover = false; 
        value.isVisibleCompany = false;
        value.isVisibleController = false;
        value.isVisibleSystem = false;
    },
    setSummaries(options){
        if(options.name == "gross_amount_local"){
            if(options.summaryProcess === 'start'){
                options.totalValue = 0;
            }
            else if(options.summaryProcess === 'calculate'){ 
                var gross_amount_local = parseFloat(options.value.gross_amount_local.replaceAll(',', ''));
                (options.totalValue += gross_amount_local); 
            }
            else if(options.summaryProcess === 'finalize'){
                var currencyInfo = this.localCurrency == null || this.localCurrency == '' ? '' : this.localCurrency;
                options.totalValue = Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(options.totalValue) +' '+ currencyInfo;
            }
        } 
    },
    /** DX STATE OPERATIONS **/  
    LoadDXState() {
        return JSON.parse(localStorage.getItem(this.storageKey));
    }, 
    SaveDXStateToStorage() { 
        const state = this.$refs.dataGrid.instance.state();
        localStorage.setItem(this.storageKey, JSON.stringify(state));
        this.$refs.listOptionComponent.getState();        
    }
  }
}; 
</script>